import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { toBnNumber } from '../../../../../common/functions';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';
import InputField from '../../../../../Components/InputField';
import MeasureMentField from '../../../../../Components/MeasureMentField';
import LanguageContext from '../../../../../context/LanguageContext';
import StoreContext from '../../../../../context/StoreContext';
import {
  getBmiProgramStudentListAction,
  getClassWiseShiftListAction,
  getFeetToMeterAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
  postBmiProgramParticipatedStudentAction,
} from '../../../../../redux';
import { postBmiProgramParticipatedStudentRequest } from '../../../../../redux/actions/upgradeClass.action';
import { currentDate } from '../../../../../redux/service/service';
import BmiResults from '../../../../nutrition-pages/BmiResults';
import ProgramHeading from '../ProgramHeading';
import StudentSearch from '../StudentSearch';
const Bmi = ({
  getClassLists,
  classLists,
  getShiftLists,
  shiftList,
  getSectionLists,
  sectionList,
  studentList,
  getStudentList,
  hasShift,
  getFeetToMeter,
  meter,
  PostBmiData,
  bmiResult,
  postBmiProgramParticipatedStudentRequest,
}) => {
  const intl = useIntl();
  const { classList } = useContext(StoreContext);
  const { defaultLanguage } = useContext(LanguageContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [gradeId, setGrade] = useState(null);
  const [shiftId, setShift] = useState(null);
  const [sectionId, setSection] = useState(null);
  const [date, setDate] = useState(() => currentDate());
  const [StudentListArray, setStudentListArray] = useState([]);
  const [studentRoll, setStudentRoll] = useState(null);
  const [StudentId, setStudentId] = useState(null);
  const [StudentDataTShow, setStudentDataTShow] = useState();
  const [round, setRound] = useState('1');
  const [feet, setFeet] = useState('');
  const [Inche, setInches] = useState('');
  const [bmiWeight, setBmiWeight] = useState('');
  const [isHasShift, setIsHasShift] = useState('');
  const [calculatedMeter, setCalculatedMeter] = useState(null);
  const [GradeName, setGradeName] = useState('');
  const [shiftName, setShiftName] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);

  useEffect(() => {
    setCalculatedMeter(meter?.data);
  }, [meter]);
  // check shift status yes or no
  useEffect(() => {
    setIsHasShift(hasShift?.data?.has_shift);
  }, [hasShift]);
  useEffect(() => {
    postBmiProgramParticipatedStudentRequest();
  }, [postBmiProgramParticipatedStudentRequest]);

  // class list handel
  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setShift('');
    setSection('');
    const singleClass = classList.find((classes) => classes.grade === Number(e.target.value));
    setGradeName(singleClass?.name_en);
    if (hasShift?.data?.has_shift === 'yes') {
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      setSectionArray(singleClass?.shift_section);
    }
  };
  // call section based on shift selection
  const handelSelectShift = (e) => {
    setShift(e.target.value);
    const singleShift = shiftArray.find((shifts) => shifts.shift_id === Number(e.target.value));
    setShiftName(singleShift?.shift_name_en);

    setSectionArray(singleShift?.section);
    setSection('');
  };
  // section select
  const handelSelectSection = (e) => {
    setSection(e.target.value);
    const singleSection = sectionArray.find((section) => section.id === Number(e.target.value));
    setSectionName(singleSection?.section_name_en);
  };

  // student list api call
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institution_id: institutionId,
        grade: gradeId,
        shift_id: shiftId,
        section_id: sectionId,
      };
      if (gradeId && shiftId && sectionId) {
        getStudentList(data);
      }
    } else if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institution_id: institutionId,
        grade: gradeId,
        section_id: sectionId,
      };
      if (gradeId && sectionId) {
        getStudentList(data);
      }
    }
  }, [gradeId, sectionId, shiftId]);

  useEffect(() => {
    setStudentListArray(studentList?.data?.data);
  }, [studentList]);

  // filter student by roll

  const handelSelectRoll = (e) => {
    const studentId = e.target.value;
    setStudentId(studentId);
    const studentInfo = StudentListArray.filter((student) => {
      return student.id == studentId;
    });
    setStudentDataTShow(studentInfo[0]);
    setStudentRoll(e.target.value);
  };

  // feet to meter on change
  useEffect(() => {
    const data = {
      feet: feet,
      inch: Inche,
    };
    if (data.feet && data.inch) {
      getFeetToMeter(data);
    }
  }, [getFeetToMeter, feet, Inche]);

  // handel BMI calculation
  const [IsSubmit, setIsSubmit] = useState(false);
  const handelBmiCalculation = () => {
    if (bmiWeight < 20) {
      toast.error('Weight can not be less than 20.');
      return;
    }

    const data = {
      institution_id: institutionId,
      grade: gradeId,
      has_shift: isHasShift,
      shift_id: shiftId,
      section_id: sectionId,
      student_id: StudentId,
      round: round,
      date: date,
      weight: bmiWeight,
      feet: feet,
      inch: Inche,
      height_in_meter: calculatedMeter,
    };
    PostBmiData(data);
    setIsSubmit(!IsSubmit);
  };

  // error handling
  const [ErrorGrade, setErrorGrade] = useState(null);
  const [ErrorSection, setErrorSection] = useState(null);
  const [ErrorStudentId, setErrorStudentId] = useState(null);
  const [ErrorDate, setErrorDate] = useState(null);
  const [ErrorWeight, setErrorWeight] = useState(null);
  const [ErrorFeet, setErrorFeet] = useState(null);
  const [ErrorEnch, setErrorEnch] = useState(null);
  const [ErrorHeight, setErrorHeight] = useState(null);

  useEffect(() => {
    setErrorGrade(bmiResult?.error?.grade);
  }, [bmiResult?.error?.grade]);

  useEffect(() => {
    setErrorSection(bmiResult?.error?.section_id);
  }, [bmiResult?.error?.section_id]);

  useEffect(() => {
    setErrorStudentId(bmiResult?.error?.student_id);
  }, [bmiResult?.error?.student_id]);

  useEffect(() => {
    setErrorDate(bmiResult?.error?.date);
  }, [bmiResult?.error?.date]);

  useEffect(() => {
    setErrorWeight(bmiResult?.error?.weight);
  }, [bmiResult?.error?.weight]);

  useEffect(() => {
    setErrorFeet(bmiResult?.error?.feet);
  }, [bmiResult?.error?.feet]);

  useEffect(() => {
    setErrorEnch(bmiResult?.error?.inch);
  }, [bmiResult?.error?.inch]);

  useEffect(() => {
    setErrorHeight(bmiResult?.error?.height_in_meter);
  }, [bmiResult?.error?.height_in_meter]);

  /********students search*********/
  const [searchResult, setSearchResult] = useState('');
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const handelStudentSearch = (e) => {
    e.preventDefault();
    setSearchResult(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target.nameSearch;
    setIsSubmitSearch(true);
  };
  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* program heading */}
        <ProgramHeading title={<FormattedMessage id='bmiFull' />}>
          {/* <StudentSearch onSubmit={handelSubmit} onChange={handelStudentSearch} value={searchResult} name="nameSearch"/> */}
        </ProgramHeading>
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
            <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
              {/* form grid section */}
              <div className='grid grid-cols-2 gap-4 pb-8 p-4 md:p-6 lg:p-10'>
                {/* class */}
                <div className='w-full'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                    <FormattedMessage id='class' />
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    // value={}
                    onChange={handelSelectClass}
                    name='class'
                    id='class'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                    {classList &&
                      classList?.map((classes, index) => {
                        return (
                          <option key={index} value={classes.grade}>
                            {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorGrade && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorGrade}</small>{' '}
                    </p>
                  )}
                </div>
                {/* shift */}
                {hasShift?.data?.has_shift === 'yes' && (
                  <div className='w-full'>
                    <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                      <FormattedMessage id='shift' />
                    </label>
                    <select
                      // value={shiftId && shiftId}
                      onChange={handelSelectShift}
                      name='shift'
                      id='shift'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                      {shiftArray &&
                        shiftArray?.map((shift, index) => {
                          return (
                            <option value={shift.shift_id} key={index}>
                              {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
                {/* section */}
                <div className='w-full'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                    <FormattedMessage id='section' />
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    value={sectionId && sectionId}
                    onChange={handelSelectSection}
                    name='section'
                    id='section'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                    {sectionArray &&
                      sectionArray?.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorSection && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorSection}</small>{' '}
                    </p>
                  )}
                </div>
                {/* roll */}
                <div className='w-full'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='roll'>
                    <FormattedMessage id='roll' />
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    value={studentRoll}
                    onChange={handelSelectRoll}
                    name='roll'
                    id='roll'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectRoll' })}</option>
                    {StudentListArray &&
                      StudentListArray.map((student, index) => {
                        return (
                          <option value={student.id} key={index}>
                            {student.roll} - {student.name}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorStudentId && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorStudentId}</small>{' '}
                    </p>
                  )}
                </div>
                {/* name */}
                <InputField
                  label={<FormattedMessage id='name' />}
                  name='total-student'
                  type='text'
                  placeholder={`${
                    StudentDataTShow && StudentDataTShow !== undefined
                      ? StudentDataTShow.name
                      : intl.formatMessage({ id: 'name' })
                  }`}
                  readOnly
                />
                {/* <!-- date of birth  --> */}
                <InputField
                  label={<FormattedMessage id='dateOfBirth' />}
                  name='date-of-birth'
                  type='text'
                  placeholder={`${
                    StudentDataTShow && StudentDataTShow !== undefined
                      ? StudentDataTShow.birth_date
                      : intl.formatMessage({ id: 'dateOfBirth' })
                  }`}
                  readOnly
                />
                {/* <!-- Gender  --> */}
                <InputField
                  label={<FormattedMessage id='gender' />}
                  name='gender'
                  type='text'
                  placeholder={`${
                    StudentDataTShow && StudentDataTShow !== undefined
                      ? StudentDataTShow.gender
                      : intl.formatMessage({ id: 'gender' })
                  }`}
                  readOnly
                />
                {/* <!--Age  --> */}
                <InputField
                  label={<FormattedMessage id='age' />}
                  name='Age'
                  type='text'
                  placeholder={`${
                    StudentDataTShow && StudentDataTShow !== undefined
                      ? StudentDataTShow.age
                      : intl.formatMessage({ id: 'age' })
                  }`}
                  readOnly
                />
              </div>
              {/* round tab start */}
              <div className='p-4 md:p-6 lg:p-10'>
                <div className='pb-4'>
                  <h1 className='mr-4 md:w-28 inline-block capitalize text-lg text-black  font-semibold'>
                    <FormattedMessage id='round' />
                  </h1>
                  <div className='rounded p-1 border border-green-600 inline-block'>
                    <button
                      onClick={() => setRound('1')}
                      type='button'
                      className={`${
                        round === '1' ? 'bg-gradient text-white ' : '  '
                      } hover:text-white py-2 px-6 capitalize text-[18px] rounded text-gray-600 hover_gradient `}
                    >
                      <span>
                        <FormattedMessage id='round' /> {defaultLanguage === 'en' ? 1 : toBnNumber(1)}
                      </span>
                    </button>
                    <button
                      onClick={() => setRound('2')}
                      type='button'
                      className={`${
                        round === '2' ? 'bg-gradient text-white' : ''
                      } hover:text-white py-2 px-6 capitalize text-[18px] rounded text-gray-600 hover_gradient`}
                    >
                      <span>
                        <FormattedMessage id='round' /> {defaultLanguage === 'en' ? 2 : toBnNumber('2')}
                      </span>
                    </button>
                  </div>
                </div>
                {/* round tab end */}
                {/* <!-- date  --> */}
                <div className='flex items-center pb-4'>
                  <h1 className='mr-4 md:w-28 inline-block capitalize text-lg text-black  font-semibold'>
                    <FormattedMessage id='date' />
                  </h1>
                  <div className=''>
                    <div className=' xl:w-full flex flex-col'>
                      <div className=' xl:w-full flex flex-row space-x-5'>
                        <div className={` xl:w-full flex flex-col`}>
                          <input
                            className={` uppercase block  w-full  min-w-[75px] px-3 py-1.5  text-base  font-normal border border-solid border-gray-300 rounded  transition ease-in-out m-0 text-gray-700 focus:text-gray-700 focus:border-green-600  focus:outline-none bg-white focus:bg-white`}
                            id='bmi-date'
                            type='date'
                            name='date'
                            value={date}
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- height  --> */}
                <div className=' flex items-center pb-4'>
                  <h1 className='mr-4 md:w-28 inline-block capitalize text-lg text-black  font-semibold'>
                    <FormattedMessage id='height' />
                  </h1>
                  <div className='ml-3 xl:w-full flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-5'>
                    <div className=''>
                      <div className='relative'>
                        <input
                          value={feet}
                          onChange={(e) => {
                            setFeet(e.target.value);
                          }}
                          placeholder={`${intl.formatMessage({ id: 'ex' })}: ${
                            defaultLanguage === 'en' ? 5 : toBnNumber(5)
                          }`}
                          className={` form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                                    focus:text-gray-700 focus:border-green-600 focus:outline-none
                                                    `}
                          type='number'
                          name='height_in_feet'
                          id='height-in-feet'
                        />
                        <span className='ft_inc_kg absolute top-0  right-0 bg-gray-200 text-black'>
                          {<FormattedMessage id='ft' />}
                        </span>
                      </div>
                      {ErrorFeet && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorFeet}</small>{' '}
                        </p>
                      )}
                    </div>

                    <div className=''>
                      <div className='relative'>
                        <input
                          value={Inche}
                          onChange={(e) => {
                            setInches(e.target.value);
                          }}
                          placeholder={`${intl.formatMessage({ id: 'ex' })}: ${
                            defaultLanguage === 'en' ? 5 : toBnNumber(5)
                          }`}
                          className={`  capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                                    focus:text-gray-700 focus:border-green-600 focus:outline-none
                                                    `}
                          type='number'
                          name='height_in_inch'
                          id='height-in-inch'
                        />
                        <span className='ft_inc_kg absolute top-0  right-0 bg-gray-200 text-black'>
                          {<FormattedMessage id='in' />}
                        </span>
                      </div>
                      {ErrorEnch && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorEnch}</small>{' '}
                        </p>
                      )}
                    </div>
                    <div className=''>
                      <MeasureMentField
                        unit={<FormattedMessage id='meter' />}
                        placeholder={`${meter && meter?.data}`}
                        // value={bmiMeter}
                        readOnly
                      />
                      {ErrorGrade && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorGrade}</small>{' '}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* <!-- weight  --> */}
                <div className='flex items-center'>
                  <h1 className='mr-4 md:w-28 inline-block capitalize text-lg text-black  font-semibold'>
                    <FormattedMessage id='weight' />
                  </h1>

                  <div className=''>
                    <div className=' xl:w-full flex flex-row  relative'>
                      <div className='relative'>
                        <input
                          value={bmiWeight}
                          onChange={(e) => {
                            setBmiWeight(e.target.value);
                          }}
                          placeholder={`${intl.formatMessage({ id: 'ex' })}: ${
                            defaultLanguage === 'en' ? 50 : toBnNumber(50)
                          }`}
                          className={`  capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                                    focus:text-gray-700 focus:border-green-600 focus:outline-none
                                                    `}
                          type='number'
                          name='bmiWeight'
                          id='bmi-weight'
                        />
                        <span className='ft_inc_kg absolute top-0  right-0 bg-gray-200 text-black'>
                          <FormattedMessage id='kg' />
                        </span>
                      </div>
                    </div>
                    {ErrorWeight && (
                      <p>
                        {' '}
                        <small className='text-red-700'>{ErrorWeight}</small>{' '}
                      </p>
                    )}
                  </div>
                </div>

                {/* <!-- calculate button  --> */}
                <div className=' flex justify-center mt-6'>
                  <button
                    onClick={handelBmiCalculation}
                    type='button'
                    className='w-2/3 sm:w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white hover:text-white bg-gradient  hover_gradient '
                  >
                    <span className=''>
                      {' '}
                      <FormattedMessage id='calculate' />{' '}
                    </span>
                  </button>
                </div>

                {IsSubmit && IsSubmit && (
                  <div className=' mt-3 flex flex-col text-center justify-center'>
                    <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                      {bmiResult.message}
                    </h1>
                  </div>
                )}

                {/* result  */}
                {bmiResult && bmiResult?.data?.length !== 0 ? (
                  <div className=''>
                    <BmiResults />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentList: state.ProgramStudentList,
    studentMetaData: state.ProgramStudentList?.data?.meta,
    subjectList: state.subjects,
    weekNumber: state.weeks?.data?.week,
    stockList: state.stockList,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
    // show message for posted data info
    postMsg: state.ParticipatedStudents,
    meter: state.meter,
    // BMI
    bmiResult: state.bmiResult,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getStudentList: (studentInfo) => dispatch(getBmiProgramStudentListAction(studentInfo)),
    getFeetToMeter: (studentInfo) => dispatch(getFeetToMeterAction(studentInfo)),
    PostBmiData: (studentInfo) => dispatch(postBmiProgramParticipatedStudentAction(studentInfo)),
    postBmiProgramParticipatedStudentRequest: () => dispatch(postBmiProgramParticipatedStudentRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bmi);
