// get teacher list
export const GET_TEACHER_LIST_REQUEST = 'GET_TEACHER_LIST_REQUEST';
export const GET_TEACHER_LIST_SUCCESS = 'GET_TEACHER_LIST_SUCCESS';
export const GET_TEACHER_LIST_FAILED = 'GET_TEACHER_LIST_FAILED';

// *delete from teacher
export const TEACHER_DELETE_REQUEST = 'TEACHER_DELETE_REQUEST';
export const TEACHER_DELETE_SUCCESS = 'TEACHER_DELETE_SUCCESS';
export const TEACHER_DELETE_FAILED = 'TEACHER_DELETE_FAILED';

// * teacher edit/update

export const TEACHER_UPDATE_REQUEST = 'TEACHER_UPDATE_REQUEST';
export const TEACHER_UPDATE_SUCCESS = 'TEACHER_UPDATE_SUCCESS';
export const TEACHER_UPDATE_FAILED = 'TEACHER_UPDATE_FAILED';

// * teacher ADD REQUEST constants
export const TEACHER_ADD_REQUEST = 'TEACHER_ADD_REQUEST';
export const TEACHER_ADD_SUCCESS = 'TEACHER_ADD_SUCCESS';
export const TEACHER_ADD_FAILED = 'TEACHER_ADD_FAILED';

// TEACHER SEARCH

export const TEACHER_NAME_SEARCH_REQUEST = 'TEACHER_NAME_SEARCH_REQUEST';
export const TEACHER_NAME_SEARCH_SUCCESS = 'TEACHER_NAME_SEARCH_SUCCESS';
export const TEACHER_NAME_SEARCH_FAILED = 'TEACHER_NAME_SEARCH_FAILED';
