import React, { useRef, useState } from 'react';
import BreadCrumbImage from '../../assets/image/breadcrumb/learning.png';
import { useLocation } from 'react-router-dom';
import ProfileBreadCrumb from '../../Components/BreadCrumb/ProfileBreadCrumb';
import { useEffect } from 'react';
import { learningModuleDetailsAction } from '../../redux';
import { connect } from 'react-redux';
import LanguageContext from '../../context/LanguageContext';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { scrollToTop } from '../../redux/service/service';

const LearningDetailsPage = ({ getLearningModuleDetails, moduleDetails }) => {
  const { defaultLanguage } = useContext(LanguageContext);
  const moduleData = useLocation();

  const [isPdfFound, setIsPdfFound] = useState(true);

  useEffect(() => {
    const data = {
      id: moduleData?.state?.id,
      // id: 239345,
    };
    getLearningModuleDetails(data);
  }, [moduleData, getLearningModuleDetails]);

  useEffect(() => {
    console.log(moduleDetails?.data?.pdf_en_path);

    if (Array.isArray(moduleDetails?.data)) {
      // console.log('this is an array');
    } else {
      // console.log('this is an object');
      setIsPdfFound(false);
    }
  }, [moduleDetails]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <React.Fragment>
      {/* <Layout> */}
      {/* <!-- TODO: simple breadCrumb (design 1 ) START  --> */}
      <ProfileBreadCrumb title={<FormattedMessage id='learning-module' />} image={BreadCrumbImage} />
      {/* <!-- TODO: simple breadCrumb END --> */}

      {/* details containers  */}
      <section className='container mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className=''>
          <div className='w-full sm:w-4/5 mx-auto px-4'>
            {/* <!-- title  --> */}
            <div className='flex justify-between items-center pb-5 border-b border-gray-300'>
              <span className='text-base font-semibold md:text-2xl md:font-bold text-black capitalize'>
                {moduleData && defaultLanguage === 'en' ? moduleData?.state?.title_en : moduleData?.state?.title_bn}
              </span>
              <button
                style={{ pointerEvents: isPdfFound ? 'none' : '' }}
                disabled={isPdfFound}
                type='button'
                className='disabled:opacity-50 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white hover:text-white bg-gradient  hover_gradient '
              >
                <a
                  target='_blank'
                  href={`${
                    moduleDetails && defaultLanguage === 'en'
                      ? moduleDetails?.data?.pdf_en_path
                      : moduleDetails?.data?.pdf_bn_path
                  }`}
                  rel='noreferrer'
                >
                  {' '}
                  <FormattedMessage id='downloadPdf' />
                </a>
              </button>
            </div>
            {/* <!-- content  --> */}
            <div className='py-4'>
              {isPdfFound ? (
                <h1 className='bg-red-500 rounded text-white px-6 py-4 text-2xl text-center'>Sorry, no pdf found.</h1>
              ) : (
                <object
                  className='w-full h-screen'
                  title='Iframe Doc'
                  data={
                    moduleDetails && defaultLanguage === 'en'
                      ? moduleDetails?.data?.pdf_en_path
                      : moduleDetails?.data?.pdf_bn_path
                  }
                  // src='https://www.ruet.ac.bd/public/storage/notices/iees-admission-test-2023-24.pdf'
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    moduleDetails: state.moduleDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLearningModuleDetails: (dataInfo) => dispatch(learningModuleDetailsAction(dataInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningDetailsPage);
