import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import BmiTab from './BmiTab';
import BmiWeights from './BmiWeights';

const BmiResults = ({ bmiData }) => {
  const [activeBmiStatus, setActiveBmiStatus] = useState(bmiData?.bmi_status);
  useEffect(() => {
    setActiveBmiStatus(bmiData?.bmi_status);
  }, [bmiData?.bmi_status]);
  console.log('🚀 ~ file: BmiResults.jsx ~ line 7 ~ BmiResults ~ activeBmiStatus', activeBmiStatus);
  return (
    <React.Fragment>
      {/* bmi weight */}
      <div className='flex justify-center pt-14 pb-14 '>
        <BmiWeights />
      </div>

      <div className='grid  grid-cols-1 lg:grid-cols-1 pt-14 pb-14 gap-4'>
        {/* bmi tab start */}
        <div className=' border border-green-600 rounded shadow-md w-full bg-white'>
          <div className=''>
            {/* tab nab */}
            <nav className=' bg-[#8FC15A]'>
              <div className='flex  flex-col md:flex-row'>
                <button
                  className={`${
                    activeBmiStatus === 'severeMalnourished' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white `}
                >
                  Sever Malnourised
                </button>
                <button
                  className={`${
                    activeBmiStatus === 'Moderately Mainourised' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white `}
                >
                  Moderately Malnourised
                </button>
                <button
                  className={`${
                    activeBmiStatus === 'Mild Malnourished' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white `}
                >
                  Mild Malnourised
                </button>
                <button
                  className={`${
                    activeBmiStatus === 'normal' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white`}
                >
                  Normal
                </button>
                <button
                  className={`${
                    activeBmiStatus === 'Mild Overweight' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white`}
                >
                  Mild Overweight
                </button>
                <button
                  className={`${
                    activeBmiStatus === 'overWeight' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white `}
                >
                  Overweight
                </button>
                <button
                  className={`${
                    activeBmiStatus === 'obesity' ? 'border-blue-900 cursor-pointer' : ' cursor-default'
                  } inline-block relative w-full p-2 border-b-2 text-sm font-medium capitalize text-center text-white `}
                >
                  Obesity
                </button>
              </div>
            </nav>
            {/* tab content  */}
            <div className='w-full h-full block p-4 text-center'>
              {activeBmiStatus && activeBmiStatus ? (
                <React.Fragment>
                  <p className='text-base text-black capitalize font-bold'>{activeBmiStatus}</p>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
        {/* bmi tab end */}

        <BmiTab />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    bmiData: state.bmiResult?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BmiResults);
