import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, PDFDownloadLink } from '@react-pdf/renderer';
import fontBold from '../../../../../assets/font/static/DancingScript-Bold.ttf';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';
import InputField from '../../../../../Components/InputField';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';
import Layout from '../../../../../layout/Layout';
import { getStudentProfileAction, postReferralStudentAction } from '../../../../../redux';
import { currentDate } from '../../../../../redux/service/service';
import ProgramHeading from '../ProgramHeading';
import StudentSearch from '../StudentSearch';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { dateRange } from '../../../../../common/functions';

const AddReferral = ({ studentProfile, profileData, hasShift, referral, addReferralStudent, school }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const studentData = useLocation();
  const [DateOfBirth, setDateOfBirth] = useState([]);
  useEffect(() => {
    setDateOfBirth(profileData?.data?.birth_date?.split('-'));
  }, [profileData]);

  // student profile api call
  useEffect(() => {
    if (studentData.state !== null) {
      const data = {
        institution_id: institutionId,
        student_id: studentData.state.id,
      };
      studentProfile(data);
    }
  }, [studentData.state, institutionId, studentProfile]);

  // go back to referral list page if profile data not find
  // useEffect(() => {
  //     if(profileData.data===null){
  //         navigate('/referral')
  //     }
  // },[navigate, profileData]);

  // referral data
  const [date, setDate] = useState(() => currentDate());
  const handelDate = (e) => {
    setDate(e.target.value);
  };

  // post student info
  const [TeacherName, setTeacherName] = useState('');
  const [TeacherDesignation, setTeacherDesignation] = useState('');
  const [ReferralInstitute, setReferralInstitute] = useState('');
  const [ReferralReason, setReferralReason] = useState('');
  const [healthFacilitatorName, setHealthFacilitatorName] = useState('');
  const [PostData, setPostData] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSUbmitButtonDisabled, setIsSUbmitButtonDisabled] = useState(true);
  // console.log('🚀 ~ file: AddReferral.jsx:56 ~ AddReferral ~ isSUbmitButtonDisabled', isSUbmitButtonDisabled);
  const handelReferralStudent = () => {
    let namePattern = /^[a-zA-Z. ]{2,30}$/;

    if (!namePattern.test(TeacherName)) {
      toast.error('Please enter a valid name.');
      return;
    }

    if (!namePattern.test(TeacherDesignation)) {
      toast.error('Please enter a designation.');
      return;
    }

    if (!namePattern.test(ReferralInstitute)) {
      toast.error('Please enter a valid institution name.');
      return;
    }

    if (!namePattern.test(healthFacilitatorName)) {
      toast.error('Please enter a valid health facilitatorname.');
      return;
    }

    const data = {
      institution_id: institutionId,
      grade: profileData?.data?.grade,
      has_shift: hasShift?.data?.has_shift,
      shift_id: hasShift?.data?.has_shift === 'yes' ? profileData?.data?.shift_id : undefined,
      section_id: profileData?.data?.section_id,
      student_id: profileData?.data?.id,
      teacher_name: TeacherName,
      teacher_designation: TeacherDesignation,
      referral_institution: ReferralInstitute,
      referral_reason: ReferralReason,
      date: date,
      health_facilitator_name: healthFacilitatorName,
    };
    setPostData(data);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (TeacherName && TeacherDesignation && ReferralInstitute && ReferralReason && healthFacilitatorName && date) {
      setIsSUbmitButtonDisabled(false);
    } else {
      setIsSUbmitButtonDisabled(true);
    }
  }, [ReferralInstitute, ReferralReason, TeacherDesignation, TeacherName, date, healthFacilitatorName]);

  useEffect(() => {
    if (isSubmit) {
      addReferralStudent(PostData);
      setIsSubmit(false);
    }
  }, [PostData, addReferralStudent, isSubmit]);

  const [ErrorTeacherName, setErrorTeacherName] = useState('');
  useEffect(() => {
    setErrorTeacherName(referral.error?.teacher_name);
  }, [referral.error?.teacher_name]);
  const [ErrorTeacherDesignation, setErrorTeacherDesignation] = useState('');
  useEffect(() => {
    setErrorTeacherDesignation(referral.error?.teacher_designation);
  }, [referral.error?.teacher_designation]);
  const [ErrorReferralReason, setErrorReferralReason] = useState('');
  useEffect(() => {
    setErrorReferralReason(referral.error?.referral_reason);
  }, [referral.error?.referral_reason]);
  const [ErrorReferralInstitution, setErrorReferralInstitution] = useState('');
  useEffect(() => {
    setErrorReferralInstitution(referral.error?.referral_institution);
  }, [referral.error?.referral_institution]);

  const [ErrorHealthFacilitator, setErrorHealthFacilitator] = useState('');
  useEffect(() => {
    setErrorHealthFacilitator(referral.error?.health_facilitator_name);
  }, [referral.error?.health_facilitator_name]);

  /********students search*********/
  const [searchResult, setSearchResult] = useState('');
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const handelStudentSearch = (e) => {
    e.preventDefault();
    setSearchResult(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target.nameSearch;
    setIsSubmitSearch(true);
  };

  // download pdf
  const downloadPdf = (student) => {
    // console.log('student : ',student)
    navigate('/referral-list/referral-pdf', { state: student });
    // navigate('/referral');
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <React.Fragment>
        <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
          <ProgramHeading title={<FormattedMessage id='referral' />}>
            {/* <StudentSearch
                        onSubmit={handelSubmit}
                        onChange={handelStudentSearch}
                        value={searchResult}
                        name="nameSearch"/> */}
          </ProgramHeading>

          {/* form section start */}
          {profileData && profileData.loading && <LoadingSpinner />}
          {profileData.data && profileData.data && (
            <div className='container mx-auto'>
              <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
                <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden p-8'>
                  <div className='grid  grid-cols-1  md:grid-cols-2 gap-4 md:gap-8 '>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='class' />}
                        value={profileData?.data?.class_name_en}
                        type='text'
                        placeholder='Class 6'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='shift' />}
                        value={profileData?.data?.shift_name_en}
                        type='text'
                        placeholder='Morning Shift'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='section' />}
                        value={profileData?.data?.section_name_en}
                        type='text'
                        placeholder='Section Name'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='rollNo' />}
                        value={profileData?.data?.roll}
                        type='text'
                        placeholder='Roll No'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='studentName' />}
                        value={profileData?.data?.name}
                        type='text'
                        placeholder='Student Name'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='gender' />}
                        value={profileData?.data?.gender}
                        type='text'
                        placeholder='Gender'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full md:col-span-2 flex flex-col md:flex-row   md:space-x-8'>
                      <div className='w-full flex space-x-4 md:space-x-8 items-end'>
                        <InputField
                          label={<FormattedMessage id='dateOfBirth' />}
                          value={DateOfBirth && DateOfBirth[1]}
                          type='text'
                          placeholder='Age'
                          name='age'
                          required
                          readOnly={true}
                        />
                        <InputField
                          label=''
                          value={DateOfBirth && DateOfBirth[2]}
                          type='text'
                          placeholder='Age'
                          name='age'
                          required
                          readOnly={true}
                        />
                        <InputField
                          label=''
                          value={DateOfBirth && DateOfBirth[0]}
                          type='text'
                          placeholder='Age'
                          name='age'
                          required
                          readOnly={true}
                        />
                      </div>

                      <div className='w-full'>
                        <InputField
                          label={<FormattedMessage id='age' />}
                          value={profileData?.data?.age}
                          type='text'
                          placeholder='Age'
                          name='age'
                          required
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className='w-full md:col-span-2'>
                      <InputField
                        label={<FormattedMessage id='birthRegistrationNumber' />}
                        value={profileData?.data?.birth_certificate_num}
                        type='text'
                        placeholder='Student Name'
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='teacherName' />}
                        value={TeacherName}
                        onChange={(e) => setTeacherName(e.target.value)}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'teacherNamePlaceholder' })}
                        name='teacher'
                        required
                      />
                      {ErrorTeacherName && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorTeacherName}</small>{' '}
                        </p>
                      )}
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='teacherDesignation' />}
                        value={TeacherDesignation}
                        onChange={(e) => setTeacherDesignation(e.target.value)}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'teacherDesignationPlaceholder' })}
                        name='designation'
                        required
                      />
                      {ErrorTeacherDesignation && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorTeacherDesignation}</small>{' '}
                        </p>
                      )}
                    </div>
                    <div className='w-full'>
                      <InputField
                        label={<FormattedMessage id='referralInstitution' />}
                        value={ReferralInstitute}
                        onChange={(e) => setReferralInstitute(e.target.value)}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'referralInstitutionPlaceholder' })}
                        name='referral'
                        required
                      />
                      {ErrorReferralInstitution && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorReferralInstitution}</small>{' '}
                        </p>
                      )}
                    </div>
                    <div className='w-full '>
                      <InputField
                        label={<FormattedMessage id='healthFacilitatorName' />}
                        value={healthFacilitatorName}
                        onChange={(e) => setHealthFacilitatorName(e.target.value)}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'healthFacilitatorNamePlaceholder' })}
                        name='referral'
                        required
                      />
                      {ErrorHealthFacilitator && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorHealthFacilitator}</small>{' '}
                        </p>
                      )}
                    </div>
                    <div className='w-full md:col-span-2'>
                      <InputField
                        label={<FormattedMessage id='date' />}
                        value={date}
                        onChange={handelDate}
                        type='date'
                        name='date'
                        required
                        min={dateRange('min')}
                        // max={dateRange('max')}
                      />
                    </div>

                    <div className='w-full flex flex-col md:col-span-2'>
                      <label
                        htmlFor='referral-details'
                        className='text-[#212529] capitalize text-[12px] lg:text-[14px]'
                      >
                        {<FormattedMessage id='referralReason' />}
                        <span className=' text-red-700'>*</span>
                      </label>
                      <textarea
                        value={ReferralReason}
                        onChange={(e) => setReferralReason(e.target.value)}
                        id='referral-details'
                        rows='4'
                        cols='50'
                        className='rounded p-2 w-full border border-solid border-gray-300 focus:border-green-600  focus:outline-none'
                        placeholder={intl.formatMessage({ id: 'referralReasonPlaceholder' })}
                      ></textarea>
                      {ErrorReferralReason && (
                        <p>
                          {' '}
                          <small className='text-red-700'>{ErrorReferralReason}</small>{' '}
                        </p>
                      )}
                    </div>
                    <div className='w-full flex flex-col justify-center md:col-span-2'>
                      <button
                        disabled={isSUbmitButtonDisabled}
                        onClick={handelReferralStudent}
                        type='button'
                        className={` mx-auto w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded ${
                          isSUbmitButtonDisabled ? 'bg-gray-300 text-black ' : 'text-white bg-gradient hover_gradient'
                        } `}
                      >
                        <span className=''>{<FormattedMessage id='submit' />}</span>
                      </button>
                    </div>
                  </div>

                  {referral && referral.loading && <LoadingSpinner />}
                  {referral && referral.message && (
                    <div className=' mt-2 flex flex-col text-center justify-center'>
                      <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                        {referral.message}
                      </h1>
                    </div>
                  )}

                  {referral && referral.data.hasOwnProperty('teacher_name') && (
                    <div className='w-full flex flex-col text-center justify-center mt-4'>
                      <PDFDownloadLink
                        document={<PdfFile printData={PostData} school={school} profile={profileData} />}
                        fileName={`${profileData?.data?.name}-referral.pdf`}
                      >
                        <button
                          // onClick={()=>downloadPdf(referral.data)}
                          type='button'
                          className=' mx-auto w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                        >
                          <span className=''>download</span>
                        </button>
                      </PDFDownloadLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* form section end */}
        </section>
      </React.Fragment>
      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    profileData: state.studentProfile,
    referral: state.referral,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
    school: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    studentProfile: (dataInfo) => dispatch(getStudentProfileAction(dataInfo)),
    addReferralStudent: (studentId) => dispatch(postReferralStudentAction(studentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReferral);

const PdfFile = ({ printData, profile, school }) => {
  // Register font
  Font.register({
    family: 'Dancing',
    fonts: [
      {
        src: `${fontBold}`,
      },
      {
        src: `${fontBold}`,
        fontWeight: 'bold',
      },
    ],
  });
  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      color: '#000',
      padding: 15,
    },
    headingText: {
      width: 120,
      fontSize: 12,
      textTransform: 'capitalize',
      flexShrink: 1,
    },
    text: {
      fontSize: 12,
      textTransform: 'capitalize',
    },
    data: {
      flex: 1,
      fontSize: 12,
    },
    document: {},
    section: {
      color: '#000',
      textAlign: 'center',
      margin: 30,
      borderWidth: 2,
      borderColor: '#000',
      borderStyle: 'solid',
      padding: 10,
    },
    dataWrapper: {
      width: 450,
      padding: 5,
      color: '#000',
      marginLeft: 30,
      marginRight: 30,
      marginTop: 10,
      marginBottom: 10,
    },
    view: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingVertical: 5,
    },
    viewer: {
      width: '70%',
      height: '100%',
      minHeight: 800,
      margin: 'auto',
    },
    drowline: {
      color: '#000',
      textAlign: 'center',
      margin: 10,
    },
    signature: {
      fontSize: 10,
      letterSpacing: 2,
      textTransform: 'capitalize',
    },
    signatureData: {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 10,
      fontFamily: 'Dancing',
      textTransform: 'capitalize',
    },
    signatureArea: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  });
  return (
    <Document style={styles.document}>
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.text}>Referral Student Information</Text>
        </View>
        <View style={styles.dataWrapper}>
          <View style={styles.view}>
            <Text style={styles.headingText}>school Name</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${school?.data?.ims_institute_name}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Class</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.class_name_en}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Shift Name</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.shift_name_en}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Section Name </Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.section_name_en}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Student Roll</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.roll}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>student name</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.name}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Student Gender</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.gender}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Student Age</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${profile?.data?.age}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Student Birth Registration Number</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${
              profile?.data?.birth_certificate_num === null ? ' ' : profile?.data?.birth_certificate_num
            }`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Teacher Name</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${printData.teacher_name}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Teacher Designation</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${printData.teacher_designation}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Health Facilitator Name</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${printData.health_facilitator_name}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Referral Institution Name</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${printData.referral_institution}`}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.headingText}>Referral Reason</Text>
            <Text style={styles.text}> : </Text>
            <Text style={styles.data}>{`${printData.referral_reason}`}</Text>
          </View>
        </View>
        {/* signature  */}
        <View style={styles.drowline}>
          <Text>************************</Text>
        </View>
        <View style={styles.dataWrapper}>
          <View style={styles.signatureArea}>
            <View>
              <Text style={styles.signature}>Signature</Text>
              <Text style={styles.signatureData}>{`${printData.teacher_name}`}</Text>
              <Text style={styles.signatureData}>{`${printData.teacher_designation}`}</Text>
            </View>
            <View>
              <Text style={styles.signature}>Signature</Text>
              <Text style={styles.signatureData}>{`${printData.health_facilitator_name}`}</Text>
              <Text style={styles.signatureData}>{`Health Facilitator`}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
