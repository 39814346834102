import React, { useState, useMemo, useEffect } from 'react';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';
import './tabletStock.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLastnYear, toBnNumber } from '../../../../../common/functions';
import LanguageContext from '../../../../../context/LanguageContext';
import { useContext } from 'react';
import Edit from '../../../../../assets/image/svg/edit.svg';
import Trash from '../../../../../assets/image/svg/trash.svg';
import WifaSvg from '../../../../../assets/image/svg/wifa-svg.svg';
import DewormingSvg from '../../../../../assets/image/svg/deworming-svg.svg';
import moment from 'moment';
import TabletDeleteConfirmation from './TabletDeleteConfirmation';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddEditMoadl from './AddEditMoadl';
import Pagination from '../../../../../Components/Pagination/Pagination';
import EmptyList from '../../../../../Components/EmptyList';

const TabletStock = () => {
  const intl = useIntl();
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const { defaultLanguage } = useContext(LanguageContext);

  const [wifaYear, setWifaYear] = useState(new Date().getFullYear());
  const [dewormingYear, setDewormingYear] = useState(new Date().getFullYear());
  const [tabletHistoryProgram, setTabletHistoryProgram] = useState('');
  const [tabletHistoryYear, setTabletHistoryYear] = useState(new Date().getFullYear());

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [editData, setEditData] = useState(null);

  const handleDeleteConfirm = (data) => {
    setDeleteData(data);
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setDeleteData(null);
    setShowDeleteConfirmation(false);
  };

  const checkExpireDate = (date) => {
    const getDate = date;
    const today = moment().format('YYYY-MM-DD');
    const checkDate = moment(getDate).isBefore(today);
    // console.log(checkDate);
    return checkDate;
  };

  const handleEditdata = (data) => {
    // console.log(data);
    setEditData(data);

    const editMode = {
      name: data.name,
      type: 'edit',
    };

    setShowAddEditModal(true);
  };

  const [wifaData, setWifaData] = useState(null);
  const [wifaLoading, setWifaLoading] = useState(false);
  const [dewormingData, setDewormingData] = useState(null);
  const [dewormingLoading, setDewormingLoading] = useState(false);

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const fetchTotalAndUsed = async (type, year, callback) => {
    if (type === 'wifa') setWifaLoading(true);
    if (type === 'deworming') setDewormingLoading(true);

    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}stock/tablet/total/count/and/used`, {
          params: {
            institution_id: institutionId,
            type: type,
            year: year,
          },
        })
        .then((resp) => {
          // console.log(resp.data);

          const data = {
            totalTablet: resp?.data?.data?.totalTabletStock,
            totalUsed: resp?.data?.data?.totalUsed,
          };

          // console.log(data);

          if (type === 'wifa') setWifaData(data);
          if (type === 'deworming') setDewormingData(data);

          if (callback) callback();
        })
        .catch((error) => {
          // console.log(error.response);
          toast.error('Sorry, something went wrong, please try again later.');
        });
    } catch (error) {
      // console.log(error.response);
      toast.error('Sorry, something went wrong, please try again later.');
    }
  };

  const fetchTabletHistory = async (
    type = tabletHistoryProgram,
    year = tabletHistoryYear,
    pagination_limit = 1000000
  ) => {
    setTableDataLoading(true);

    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}stock/tablet/history`, {
          params: {
            institution_id: institutionId,
            type: type,
            year: year,
            pagination_limit: pagination_limit,
          },
        })
        .then((resp) => {
          // console.log(resp.data?.data);
          setTableData(resp?.data?.data);
          setTableDataLoading(false);
        })
        .catch((error) => {
          // console.log(error.response);
          toast.error('Sorry, something went wrong, please try again later.');
        });
    } catch (error) {
      // console.log(error.response);
      toast.error('Sorry, something went wrong, please try again later.');
    }
  };

  useEffect(() => {
    fetchTotalAndUsed('wifa', wifaYear, () => setWifaLoading(false));
    fetchTotalAndUsed('deworming', dewormingYear, () => setDewormingLoading(false));

    fetchTabletHistory(tabletHistoryProgram, tabletHistoryYear);
  }, []);

  const handleAddEditModalClose = () => {
    // console.log('close modal');
    setShowAddEditModal(false);
    setEditData(null);
    setModalType(null);
  };

  const handleSubmitAddEditModal = (data) => {
    // console.log('modal data submit');
    // console.log(data);
    if (data?.type === 'wifa') {
      fetchTotalAndUsed('wifa', wifaYear, () => setWifaLoading(false));
    }

    if (data?.type === 'deworming') {
      fetchTotalAndUsed('deworming', dewormingYear, () => setDewormingLoading(false));
    }

    fetchTabletHistory();
  };

  const handleShowAddEditmodal = (nameType) => {
    setShowAddEditModal(true);
    setModalType(nameType);
    // console.log(nameType);
  };

  const [currentPage, setCurrentPage] = useState(1);

  // pagination
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return tableData && tableData?.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, tableData]);

  const handleFilterWifaYear = (e) => {
    setWifaYear(e.target.value);

    // console.log(e.target.value);
    fetchTotalAndUsed('wifa', e.target.value, () => setWifaLoading(false));
  };

  const handleFilterDewormingYear = (e) => {
    setDewormingYear(e.target.value);

    // console.log(e.target.value);
    fetchTotalAndUsed('deworming', e.target.value, () => setDewormingLoading(false));
  };

  const handleTabletHistoryProgram = (e) => {
    setTabletHistoryProgram(e.target.value);
    fetchTabletHistory(e.target.value);
  };

  const handleTabletHistoryYear = (e) => {
    setTabletHistoryYear(e.target.value);
    fetchTabletHistory(tabletHistoryProgram, e.target.value);
  };

  const afterDeleteGetData = (data) => {
    // console.log(data);
    fetchTabletHistory();

    if (data?.type === 'wifa') {
      fetchTotalAndUsed('wifa', wifaYear, () => setWifaLoading(false));
    }

    if (data?.type === 'deworming') {
      fetchTotalAndUsed('deworming', dewormingYear, () => setDewormingLoading(false));
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb />

      {showAddEditModal && (
        <AddEditMoadl
          institutionId={institutionId}
          onCancel={handleAddEditModalClose}
          handelSubmit={handleSubmitAddEditModal}
          modalType={modalType}
          editData={editData}
        />
      )}

      {showDeleteConfirmation && (
        <TabletDeleteConfirmation
          title={<FormattedMessage id='deleteConfirmation' />}
          data={deleteData}
          onCancel={handleCancelDelete}
          afterDeleteGetData={afterDeleteGetData}
          institutionId={institutionId}
          defaultLanguage={defaultLanguage}
        />
      )}

      <section className='mx-auto bg-white py-4 lg:py-10 space-y-7'>
        {/* tablet show  */}
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto space-y-7'>
            <div className='border border-gray-300 w-full rounded'>
              {/* <!-- row --> */}
              <div className=''>
                {/* <!-- title  --> */}
                <div className='flex flex-col lg:flex-row lg:items-center gap-3 justify-between border-b border-gray-300 p-4'>
                  <div className=''>
                    <FormattedMessage id='wifaFull' /> <FormattedMessage id='tablet' />
                  </div>
                  {/* <!-- submit button  --> */}
                  <div className='flex lg:justify-center'>
                    <div className='flex items-center'>
                      <h1 className='mr-1'>
                        <FormattedMessage id='year' />
                      </h1>
                      <select
                        value={wifaYear}
                        onChange={handleFilterWifaYear}
                        id='year'
                        className='mr-4 form-select block w-auto px-2 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        {/* <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option> */}
                        {getLastnYear()?.map((year) => (
                          <option key={year} value={year}>
                            {defaultLanguage === 'en' ? year : toBnNumber(year)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      onClick={() => handleShowAddEditmodal({ name: 'wifa', type: 'add' })}
                      type='button'
                      className=' py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded text-white bg-gradient hover_gradient'
                    >
                      <span>
                        <FormattedMessage id='addTablet' />
                      </span>
                    </button>
                  </div>
                </div>
                {/* <!-- total stock --> */}
                <div className='grid md:grid-cols-2 gap-5 lg:gap-8 p-5 lg:p-8'>
                  <div className='py-8 px-6 w-full grid place-items-center bg-[#FFF0CF] rounded-md'>
                    <h1 className='text-base font-normal md:text-lg lg:text-xl capitalize'>
                      <FormattedMessage id='totalTabletStock' />
                    </h1>
                    {wifaLoading && <LoadingSpinner />}
                    <h1 className='text-base font-bold md:text-3xl'>
                      {wifaData && (
                        <>{defaultLanguage === 'en' ? wifaData?.totalTablet : toBnNumber(wifaData?.totalTablet)}</>
                      )}
                    </h1>
                  </div>
                  <div className='py-8 px-6 w-full grid place-items-center bg-[#DCF5D5] rounded-md'>
                    <h1 className='text-base font-normal md:text-lg lg:text-xl capitalize'>
                      <FormattedMessage id='tabletStockUsed' />
                    </h1>
                    {wifaLoading && <LoadingSpinner />}
                    <h1 className='text-base font-bold md:text-3xl'>
                      {wifaData && (
                        <>{defaultLanguage === 'en' ? wifaData?.totalUsed : toBnNumber(wifaData?.totalUsed)}</>
                      )}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className='border border-gray-300 w-full rounded'>
              {/* <!-- row --> */}
              <div className=''>
                {/* <!-- title  --> */}
                <div className='flex flex-col lg:flex-row lg:items-center gap-3 justify-between border-b border-gray-300 p-4'>
                  <div className=''>
                    <FormattedMessage id='deworming' /> <FormattedMessage id='tablet' />
                  </div>
                  {/* <!-- submit button  --> */}
                  <div className='flex lg:justify-center'>
                    <div className='flex items-center'>
                      <h1 className='mr-1'>
                        <FormattedMessage id='year' />
                      </h1>
                      <select
                        value={dewormingYear}
                        onChange={handleFilterDewormingYear}
                        id='year'
                        className='mr-4 form-select block w-auto px-2 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        {/* <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option> */}
                        {getLastnYear()?.map((year) => (
                          <option key={year} value={year}>
                            {defaultLanguage === 'en' ? year : toBnNumber(year)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      onClick={() => handleShowAddEditmodal({ name: 'deworming', type: 'add' })}
                      type='button'
                      className=' py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded text-white bg-gradient hover_gradient'
                    >
                      <span>
                        <FormattedMessage id='addTablet' />
                      </span>
                    </button>
                  </div>
                </div>
                {/* <!-- total stock --> */}
                <div className='grid md:grid-cols-2 gap-5 lg:gap-8 p-5 lg:p-8'>
                  <div className='py-8 px-6 w-full grid place-items-center bg-[#FFF0CF] rounded-md'>
                    <h1 className='text-base font-normal md:text-lg lg:text-xl capitalize'>
                      <FormattedMessage id='totalTabletStock' />
                    </h1>
                    {dewormingLoading && <LoadingSpinner />}
                    <h1 className='text-base font-bold md:text-3xl'>
                      {dewormingData && (
                        <>
                          {defaultLanguage === 'en'
                            ? dewormingData?.totalTablet
                            : toBnNumber(dewormingData?.totalTablet)}
                        </>
                      )}
                    </h1>
                  </div>
                  <div className='py-8 px-6 w-full grid place-items-center bg-[#DCF5D5] rounded-md'>
                    <h1 className='text-base font-normal md:text-lg lg:text-xl capitalize'>
                      <FormattedMessage id='tabletStockUsed' />
                    </h1>
                    {dewormingLoading && <LoadingSpinner />}
                    <h1 className='text-base font-bold md:text-3xl'>
                      {dewormingData && (
                        <>
                          {defaultLanguage === 'en' ? dewormingData?.totalUsed : toBnNumber(dewormingData?.totalUsed)}
                        </>
                      )}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* tablet history START */}
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col w-full px-5 sm:w-4/5 mx-auto space-y-7'>
            <div className='border border-gray-300 w-full rounded'>
              {/* <!-- row --> */}
              <div className='overflow-x-scroll md:overflow-auto'>
                {/* <!-- title  --> */}
                <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between p-4 gap-3'>
                  <div className='font-bold text-lg'>
                    <FormattedMessage id='tabletHistory' />
                  </div>
                  {/* <!-- submit button  --> */}
                  <div className='flex flex-col gap-3 sm:flex-row lg:justify-center'>
                    <div className='flex items-center'>
                      <h1 className='mr-1'>
                        <FormattedMessage id='program' />
                      </h1>
                      <select
                        value={tabletHistoryProgram}
                        onChange={handleTabletHistoryProgram}
                        id='year'
                        className='mr-4 form-select block w-auto px-2 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value=''>{intl.formatMessage({ id: 'all' })}</option>
                        <option value='wifa'>{intl.formatMessage({ id: 'wifa' })}</option>
                        <option value='deworming'>{intl.formatMessage({ id: 'deworming' })}</option>
                      </select>
                    </div>
                    <div className='flex items-center'>
                      <h1 className='mr-1'>
                        <FormattedMessage id='year' />
                      </h1>
                      <select
                        value={tabletHistoryYear}
                        onChange={handleTabletHistoryYear}
                        id='year'
                        className='form-select block w-auto px-2 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        {/* <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option> */}
                        {getLastnYear()?.map((year) => (
                          <option key={year} value={year}>
                            {defaultLanguage === 'en' ? year : toBnNumber(year)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* Table */}
                {tableDataLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    {currentTableData && currentTableData?.length ? (
                      <div className='min-w-[950px] mx-3'>
                        <table className='table-fixed w-full border-collapse border-gray-300'>
                          <thead className='text-left'>
                            <tr className='border-b'>
                              <th className='capitalize p-2' style={{ width: '25%', wordBreak: 'break-all' }}>
                                <FormattedMessage id='stockName' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '13%' }}>
                                <FormattedMessage id='program' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '10%' }}>
                                <FormattedMessage id='openingBalance' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '10%' }}>
                                <FormattedMessage id='used' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '10%' }}>
                                <FormattedMessage id='closingBalance' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '12%' }}>
                                <FormattedMessage id='receivingDate' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '12%' }}>
                                <FormattedMessage id='expiredDate' />
                              </th>
                              <th className='capitalize p-2' style={{ width: '8%' }}>
                                <FormattedMessage id='option' />
                              </th>
                            </tr>
                          </thead>
                          <tbody className='text-gray-500'>
                            {currentTableData.map((item) => (
                              <tr key={item?.id} className='border-b'>
                                <td className='p-2'>{item?.name}</td>
                                <td className='p-2'>
                                  <div className='capitalize flex'>
                                    {item.type === 'wifa' && <img src={WifaSvg} alt='' className='mr-1' />}
                                    {item.type === 'deworming' && <img src={DewormingSvg} alt='' className='mr-1' />}
                                    <FormattedMessage id={item?.type} />
                                  </div>
                                </td>
                                <td className='p-2'>
                                  {defaultLanguage === 'en' ? item?.entry_tablet : toBnNumber(item?.entry_tablet)}
                                </td>
                                <td className='p-2'>
                                  {defaultLanguage === 'en' ? item?.used_tablet : toBnNumber(item?.used_tablet)}
                                </td>
                                <td className='p-2 text-green-600'>
                                  {defaultLanguage === 'en'
                                    ? item?.available_tablet
                                    : toBnNumber(item?.available_tablet)}
                                </td>
                                <td className='p-2'>
                                  {defaultLanguage === 'en'
                                    ? moment(item.entry_date).format('DD-MM-YYYY')
                                    : toBnNumber(moment(item.entry_date).format('DD-MM-YYYY'))}
                                </td>
                                <td
                                  className={`p-2 capitalize ${
                                    checkExpireDate(item.expired_date) ? 'text-red-500' : 'text-green-500'
                                  }`}
                                >
                                  {defaultLanguage === 'en'
                                    ? moment(item.expired_date).format('DD-MM-YYYY')
                                    : toBnNumber(moment(item.expired_date).format('DD-MM-YYYY'))}
                                </td>
                                <td className='p-2'>
                                  <div className='flex'>
                                    <img
                                      src={Edit}
                                      alt=''
                                      className='w-5 cursor-pointer'
                                      onClick={() => handleEditdata(item)}
                                    />
                                    {/* <img
                                      src={Trash}
                                      alt=''
                                      className='w-4 cursor-pointer ml-2'
                                      onClick={() => handleDeleteConfirm(item)}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <EmptyList message='Sorry, no data found.' />
                    )}

                    <div className='my-5'>
                      <div className='w-full px-4 sm:w-4/5 mx-auto'>
                        <Pagination
                          className='pagination-bar'
                          currentPage={currentPage}
                          totalCount={tableData?.length || 0}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* tablet history END */}
      </section>
    </React.Fragment>
  );
};

export default TabletStock;
