import axios from 'axios';
import {
  GET_TEACHER_LIST_REQUEST,
  GET_TEACHER_LIST_SUCCESS,
  GET_TEACHER_LIST_FAILED,
  TEACHER_DELETE_REQUEST,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_FAILED,
  TEACHER_UPDATE_REQUEST,
  TEACHER_UPDATE_SUCCESS,
  TEACHER_UPDATE_FAILED,
  TEACHER_ADD_REQUEST,
  TEACHER_ADD_SUCCESS,
  TEACHER_ADD_FAILED,
  TEACHER_NAME_SEARCH_REQUEST,
  TEACHER_NAME_SEARCH_SUCCESS,
  TEACHER_NAME_SEARCH_FAILED,
} from '../constance/teacher.constants';
import { getTokenAndId } from '../service/service';
// !teacher get request
export const getTeacherListRequest = () => {
  return {
    type: GET_TEACHER_LIST_REQUEST,
    info: 'request for teacher list',
  };
};

export const getTeacherListSuccess = (res) => {
  return {
    type: GET_TEACHER_LIST_SUCCESS,
    payload: res,
  };
};

export const getTeacherListFailed = (error) => {
  return {
    type: GET_TEACHER_LIST_FAILED,
    payload: error,
  };
};

//*fetch teacher list
export const getTeacherList = () => {
  // get this function from service folder
  let { apiUrl, config } = getTokenAndId();

  return (dispatch) => {
    dispatch(getTeacherListRequest());
    axios
      .get(`${apiUrl}teacher/list`, config)
      .then((response) => {
        dispatch(getTeacherListSuccess(response.data.data));
      })
      .catch((error) => {
        console.log('🚀 error', error.message);
        dispatch(getTeacherListFailed(error.message));
      });
  };
};

// !teacher DELETE request Action-Creator
export const teacherDeleteRequest = (req) => {
  return {
    type: TEACHER_DELETE_REQUEST,
    payload: req,
  };
};

export const teacherDeleteSuccess = (teacherId) => {
  return {
    type: TEACHER_DELETE_SUCCESS,
    payload: teacherId,
  };
};

export const teacherDeleteFailed = (error) => {
  return {
    type: TEACHER_DELETE_FAILED,
    payload: error,
  };
};
// * Delete teacher Actin
export const teacherDelete = (teacherId) => {
  let { apiUrl, config } = getTokenAndId();
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getTeacherListRequest());
    axios
      .delete(`${apiUrl}teacher/delete?teacher_id=${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // const dataId = {id:studentInfo.student_id};
        // const returnedTarget = Object.assign(response.data, dataId);
        dispatch(teacherDeleteSuccess(res));
      })
      .catch((error) => {
        console.log(error.message);
        dispatch(teacherDeleteFailed(error));
      });
  };
};

// !teacher UPDATE request action-creator
export const teacherUpdateRequest = () => {
  return {
    type: TEACHER_UPDATE_REQUEST,
    info: 'teacher updated requests',
  };
};

export const teacherUpdateSuccess = (res) => {
  return {
    type: TEACHER_UPDATE_SUCCESS,
    payload: res,
  };
};

export const teacherUpdateFailed = (error) => {
  return {
    type: TEACHER_UPDATE_FAILED,
    payload: error,
  };
};
// * UPDATE teacher Actin
export const teacherUpdateAction = (teacherData, avatar) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    let data = new FormData();
    await data.append('teacher_profile_image', avatar);
    await data.append('name', teacherData.teacherName);
    await data.append('username', teacherData.teacherUserName);
    await data.append('institution_id', teacherData.teacherInstitutionId);
    await data.append('grade_id', teacherData.teacherClass);
    dispatch(teacherUpdateRequest());

    axios
      .post(`${apiUrl}teacher/update/${teacherData.teacherID}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('🚀 ~ file: teacher.actions.js ~ line 149 ~ .then ~ response', response);
        dispatch(teacherUpdateSuccess(response));
      })
      .catch((error) => {
        dispatch(teacherUpdateFailed(error));
      });
  };
};

// !teacher ADD request action-creator
export const teacherAddRequest = () => {
  return {
    type: TEACHER_ADD_REQUEST,
    info: 'teacher add request',
  };
};
export const teacherAddSuccess = (res) => {
  return {
    type: TEACHER_ADD_SUCCESS,
    payload: res,
  };
};
export const teacherAddFailed = (error) => {
  return {
    type: TEACHER_ADD_FAILED,
    payload: error,
  };
};

export const teacherAddAction = (teacherInfo, avatar) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    let data = new FormData();
    await data.append('institution_id', teacherInfo?.institution_id);
    await data.append('grade', teacherInfo?.grade);
    await data.append('shift_id', teacherInfo?.shift_id);
    await data.append('section_id', teacherInfo?.section_id);
    await data.append('nid', teacherInfo?.teacherInd);
    await data.append('name', teacherInfo?.teacherName);
    await data.append('photo', avatar);

    dispatch(getTeacherListRequest());
    axios
      .post(`${apiUrl}section/add/teacher`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(teacherAddSuccess(response.data));
      })
      .catch((error) => {
        dispatch(teacherAddFailed());
      });
  };
};

// TEACHER SEARCH

export const teacherNameSearchRequest = () => {
  return {
    type: TEACHER_NAME_SEARCH_REQUEST,
    info: 'teacher name search',
  };
};
export const teacherNameSearchSuccess = (res) => {
  return {
    type: TEACHER_NAME_SEARCH_SUCCESS,
    payload: res,
  };
};
export const teacherNameSearchFailed = (err) => {
  return {
    type: TEACHER_NAME_SEARCH_FAILED,
    payload: err,
  };
};

export const teacherNameSearchAction = (info) => {
  const bodyInfo = {
    institution_id: info.institution_id,
    name: info.name,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(teacherNameSearchRequest());
    axios
      .get(`${apiUrl}teacher/search?institution_id=${bodyInfo.institution_id}&name=${bodyInfo.name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(
          '🚀 ~ file: teacher.actions.js ~ line 243 ~ .then ~ response=========================================',
          response
        );
        dispatch(teacherNameSearchSuccess(response.data));
      })
      .catch((err) => {
        console.log(
          '🚀 ~ file: teacher.actions.js ~ line 247 ~ return ~ err=====================================================',
          err
        );
        dispatch(teacherNameSearchFailed(err));
      });
  };
};
