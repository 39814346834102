import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
const ProgramHeading = (props) => {
  //   console.log("🚀 ~ file: ProgramHeading.jsx ~ line 5 ~ ProgramHeading ~ props", props)

  return (
    <React.Fragment>
      <div className='container mx-auto'>
        <div className='flex flex-wrap items-center w-full px-4 sm:w-4/5 mx-auto'>
          {/* <!-- nutrition education  --> */}
          <div className=''>
            <h1 className='text-xl md:text-3xl capitalize font-semibold text-black'>{props.title}</h1>
          </div>
          {/* <!-- nutrition education input  --> */}

          {props.children && (
            <div className=' ml-auto relative order-2 lg:order-1 w-full lg:w-auto mt-4 lg:mt-0'>{props.children}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProgramHeading;
