import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../../Components/EmptyList';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import Pagination from '../../../../Components/Pagination/Pagination';
import LanguageContext from '../../../../context/LanguageContext';
import StoreContext from '../../../../context/StoreContext';
import {
  getClassWiseShiftListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
  sectionStudentAction,
} from '../../../../redux';
import {
  createSectionStudentFromShiftRequest,
  deleteSectionStudentRequest,
} from '../../../../redux/actions/upgradeClass.action';
import { validateClass, validateSection, validateShift } from '../../../../redux/service/service';
import StudentDeletePopUp from '../studentPopup/StudentDeletePopUp';
import { toBnNumber } from '../../../../common/functions';
const StudentLists = ({
  deleteSectionStudentRequest,
  createSectionStudentFromShiftRequest,
  getStudentList,
  studentData,
  studentList,
  studentMetaData,
  message,
  // class list
  getClassLists,
  classLists,
  // shift
  shiftList,
  getShiftLists,
  // section
  sectionList,
  getSectionLists,

  // check shift
  hasShift,
}) => {
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList } = useContext(StoreContext);
  const navigationData = useLocation();
  const navigate = useNavigate();
  // all state

  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [gradeId, setGrade] = useState('');
  const [shiftId, setShift] = useState('');
  const [sectionId, setSection] = useState('');
  const [defaultGradeId, setDefaultGradeId] = useState('');
  const [defaultShiftId, setDefaultShiftId] = useState('');
  const [defaultSectionId, setDefaultSectionId] = useState('');
  const [filterIsSubmit, setFilterIsSubmit] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(250);
  const [currentPage, setCurrentPage] = useState(1);

  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);

  useEffect(() => {
    const allClass = classList;
    let defSectionId = '';
    setDefaultGradeId(allClass[0]?.grade);

    if (hasShift?.data?.has_shift === 'yes') {
      setDefaultShiftId(allClass[0]?.shift_section[0]?.shift_id);
      setDefaultSectionId(allClass[0]?.shift_section[0]?.section[0]?.id);
      defSectionId = allClass[0]?.shift_section[0]?.section[0]?.id;
      setShiftArray(allClass[0]?.shift_section);
      setSectionArray(allClass[0]?.shift_section[0]?.section);
    } else {
      setDefaultSectionId(allClass[0]?.shift_section[0]?.id);
      defSectionId = allClass[0]?.shift_section[0]?.id;
      setSectionArray(allClass[0]?.shift_section);
    }

    const data = {
      institution_id: institutionId,
      grade: allClass[0]?.grade,
      section_id: defSectionId,
      // page: pageNumber,
      pagination_limit: pageLimit,
    };

    if (hasShift?.data?.has_shift === 'yes') {
      data['shift_id'] = allClass[0]?.shift_section[0]?.shift_id;
    }

    // console.log(data);

    if (classList.length !== 0) {
      getStudentList(data);
    }
  }, [classList]);

  //handel class
  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setDefaultGradeId(e.target.value);
    setDefaultShiftId('');
    setDefaultSectionId('');
    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    if (hasShift?.data?.has_shift === 'yes') {
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      setSectionArray(singleClass?.shift_section);
    }
  };
  // handel shift
  const handelSelectShift = (e) => {
    setShift(e.target.value);
    setDefaultShiftId(e.target.value);

    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));

    setSectionArray(singleShift?.section);
    setDefaultSectionId('');
  };
  // section list
  const handelSelectSection = (e) => {
    setSection(e.target.value);
    setDefaultSectionId(e.target.value);
  };

  // student filter api

  const [ErrorClassSmg, setErrorClassSmg] = useState(false);
  const [ErrorShiftSmg, setErrorShiftSmg] = useState(false);
  const [ErrorSectionSmg, setErrorSectionSmg] = useState(false);
  const [setData, setSetData] = useState({});

  const handelStudentFilter = () => {
    setCurrentPage(1);
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institution_id: institutionId,
        grade: defaultGradeId,
        section_id: defaultSectionId,
        shift_id: defaultShiftId,
        pagination_limit: pageLimit,
      };
      setErrorClassSmg(validateClass(data.grade));
      setErrorShiftSmg(validateShift(data.shift_id));
      setErrorSectionSmg(validateSection(data.section_id));
      setSetData(data);
      getStudentList(data);
    }
    if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institution_id: institutionId,
        grade: defaultGradeId,
        section_id: defaultSectionId,
        pagination_limit: pageLimit,
      };
      setErrorSectionSmg(validateSection(data.section_id));
      setErrorClassSmg(validateClass(data.grade));
      setSetData(data);
      getStudentList(data);
    }
    setFilterIsSubmit(true);
  };

  // pagination handler

  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return studentList?.data?.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, studentList?.data]);

  // student delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteStudentInfo, setDeleteStudentInfo] = useState({});
  const handelStudentDelete = (data) => {
    setDeleteStudentInfo(data);
    setIsDeleteModalOpen(true);
    deleteSectionStudentRequest();
  };

  const handelModalClose = () => {
    setIsDeleteModalOpen(false);
    deleteSectionStudentRequest();
  };

  // close modal if Successfully delete
  useEffect(() => {
    if (message && message === 'Student Deleted Successfully') {
      setTimeout(function () {
        setIsDeleteModalOpen(false);
      }, 2000);
    }
  }, [message]);

  // go to add student page
  const gotoAddStudentPage = () => {
    createSectionStudentFromShiftRequest();
    navigate(`/student-list/add-student`);
  };
  // go to edit page
  const goToEditPage = (student) => {
    createSectionStudentFromShiftRequest();
    // localStorage.setItem('sectionInfo',JSON.stringify({}));
    navigate(`/student-list/edit-student`, { state: student });
  };
  // go to profile page
  const gotoProfilePage = (student) => {
    navigate(`/student-list/student-profile`, { state: student });
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      {isDeleteModalOpen && (
        <StudentDeletePopUp
          title={<FormattedMessage id='deleteStudent' />}
          data={deleteStudentInfo}
          onCancel={handelModalClose}
        />
      )}
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* <!-- search/add student section  --> */}
        <div className='container mx-auto'>
          <div className='flex flex-wrap items-center w-full px-4 sm:w-4/5 mx-auto'>
            {/* <!-- add student  --> */}
            <div className=''>
              <h1 className='text-xl md:text-3xl capitalize font-semibold text-black'>
                <FormattedMessage id='students' />
              </h1>
            </div>
            {/* <!-- student search input  --> */}
            <div className=' ml-auto relative order-2 lg:order-1 w-full lg:w-auto mt-4 lg:mt-0'>
              {/* <form
                            // onSubmit={handelStudentSearch}
                            >
                                <input
                                    className='form-select capitalize block w-full pl-3 pr-6 py-1.5 text-base font-normal text-gray-700 bg-white0 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-full transition ease-in-out m-0
                                focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                                    type='search'
                                    name="studentNameSearch"
                                    id='studentNameSearch'
                                    // value={studentNameSearch}
                                    placeholder='search'
                                    // onChange={(e)=>setStudentNameSearch(e.target.value)}
                                />
                                <button type="submit"  className='text-gray-500 hover:text-green-600 cursor-pointer absolute top-2 right-2'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </button>
                            </form> */}
            </div>

            {/* <!-- button  --> */}
            <div className='ml-auto lg:ml-4 order-1 lg:order-2'>
              <button
                onClick={gotoAddStudentPage}
                type='button'
                className='py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-white bg-gradient hover_gradient'
              >
                <span>
                  <FormattedMessage id='studentAdd' />
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* <!-- student list if not empty  --> */}
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
            <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
              {/* <!-- total number  --> */}
              <div className='flex flex-wrap flex-col md:flex-row items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                <div className='mb-3 md:mb-0'>
                  <p className='text-base text-black capitalize'>
                    <FormattedMessage id='totalStudent' />:{' '}
                    <span>
                      {studentMetaData && defaultLanguage === 'bn'
                        ? toBnNumber(studentMetaData?.total)
                        : studentMetaData?.total}
                    </span>
                  </p>
                </div>
                {/* filter section  */}
                <div className='flex items-center space-x-3'>
                  {/* class */}
                  <div className='w-full flex items-center space-x-3'>
                    <div className=''>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                        <FormattedMessage id='class' />
                      </label>
                    </div>
                    {/* <!--select  class --> */}
                    <div className=''>
                      <select
                        value={defaultGradeId}
                        onChange={handelSelectClass}
                        name='class'
                        id='class'
                        className={`${
                          ErrorClassSmg ? 'border-red-600' : 'border-green-600 focus:border-green-600'
                        } form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid   rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none`}
                        aria-label='Default select example'
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectClass' })}</option>
                        {classList &&
                          classList?.map((classes, index) => {
                            return (
                              <option key={classes + index} value={classes.grade}>
                                {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* shift */}
                  {hasShift?.data?.has_shift === 'yes' && (
                    <div className='w-full flex items-center space-x-3'>
                      <div className=''>
                        <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                          <FormattedMessage id='shift' />
                        </label>
                      </div>
                      {/* <!--select  class --> */}
                      <div className=''>
                        <select
                          value={defaultShiftId}
                          onChange={handelSelectShift}
                          name='shift'
                          id='shift'
                          className={`${
                            ErrorShiftSmg ? 'border-red-600' : 'border-green-600 focus:border-green-600'
                          } form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid   rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none`}
                        >
                          <option value='0'>{intl.formatMessage({ id: 'selectShift' })}</option>
                          {shiftArray &&
                            shiftArray?.map((shift, index) => {
                              return (
                                <option value={shift.shift_id} key={index}>
                                  {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  {/* section */}
                  <div className='w-full flex items-center space-x-3'>
                    <div className=''>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                        <FormattedMessage id='section' />
                      </label>
                    </div>
                    {/* <!--select  class --> */}
                    <div className=''>
                      <select
                        value={defaultSectionId}
                        onChange={handelSelectSection}
                        name='section'
                        id='section'
                        className={`${
                          ErrorSectionSmg ? 'border-red-600' : 'border-green-600 focus:border-green-600'
                        } form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid   rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white  focus:outline-none`}
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectSection' })}</option>
                        {sectionArray &&
                          sectionArray?.map((section, index) => {
                            return (
                              <option key={index + section.section_name_en} value={section.id}>
                                {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <!-- button  --> */}
                  <div className=''>
                    <button
                      onClick={handelStudentFilter}
                      type='button'
                      className='  py-2 px-6 capitalize text-[14px] rounded text-white bg-gradient'
                    >
                      <span>
                        <FormattedMessage id='filter' />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- table header  --> */}
              {studentList && studentList.loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  {currentTableData && currentTableData?.length >= 1 ? (
                    <React.Fragment>
                      <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                        <thead className=' text-left bg-[#BFE3B5]'>
                          <tr>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='rollNo' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='studentName' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='gender' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='age' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='option' />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData &&
                            currentTableData.map((student, index) => {
                              return (
                                <tr key={student.name + index} className='hover:bg-green-50 cursor-pointer'>
                                  <td className=' border border-gray-300 p-2' onClick={() => gotoProfilePage(student)}>
                                    {student.roll}
                                  </td>
                                  <td
                                    className=' border border-gray-300 p-2 capitalize'
                                    onClick={() => gotoProfilePage(student)}
                                  >
                                    {student.name}
                                  </td>
                                  <td className=' border border-gray-300 p-2' onClick={() => gotoProfilePage(student)}>
                                    {student.gender}
                                  </td>
                                  <td className=' border border-gray-300 p-2' onClick={() => gotoProfilePage(student)}>
                                    {student.age}
                                  </td>
                                  <td className=' border border-gray-300 p-2 space-x-2 relative z-10'>
                                    <button onClick={() => goToEditPage(student)}>
                                      <span className='cursor-pointer w-8 h-8 leading-8 bg-[#FEEBDB] text-[#F87D2C] inline-block text-center text-base rounded-full'>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                      </span>
                                    </button>

                                    <button onClick={() => handelStudentDelete(student)}>
                                      <span className='cursor-pointer w-8 h-8 leading-8 bg-[#FDE4E9] text-[#EF5E76] inline-block text-center text-base rounded-full'>
                                        <FontAwesomeIcon icon={faTrashCan} />
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          {/* <!-- table single row --> */}
                        </tbody>
                      </table>
                    </React.Fragment>
                  ) : null}

                  <React.Fragment>
                    {((studentList && studentList?.data?.length === 0) ||
                      (studentList && studentList?.data?.length === null) ||
                      (studentList && studentList?.data?.length === undefined)) && (
                      <EmptyList message='Student List is empty' />
                    )}
                  </React.Fragment>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {/* pagination section start */}
        {/* if student list less than 10 pagination is hide  */}

        <div className='container mx-auto'>
          <div className='w-full px-4 sm:w-4/5 mx-auto'>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={studentList?.data?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>

        {/* ) } */}

        {/* pagination section end */}
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentData: state.studentData,
    studentList: state.studentData,
    studentMetaData: state.studentData?.meta,
    message: state.studentData.message,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStudentList: (studentInfo) => dispatch(sectionStudentAction(studentInfo)),
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    deleteSectionStudentRequest: () => dispatch(deleteSectionStudentRequest()),
    createSectionStudentFromShiftRequest: () => dispatch(createSectionStudentFromShiftRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentLists);
