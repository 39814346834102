import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import UseAuth from './UseAuth';

const ProtectedRoutes = ({ userLoggedIn }) => {
  const auth = UseAuth();
  const sampleLocation = useLocation();
  const [redirectToUrl, setRedirectToUrl] = useState(() => localStorage.getItem('redirectedUrl'));

  useEffect(() => {
    setRedirectToUrl(() => localStorage.setItem('redirectedUrl', window.location.href));
  }, [window.location]);

  return auth ? <Outlet /> : <Navigate to='/login' state={{ userLoggedIn }} />;
};

const mapStateToProps = (state) => {
  return {
    userLoggedIn: state.login,
  };
};

export default connect(mapStateToProps)(ProtectedRoutes);
