import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import InputField from '../../../Components/InputField';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import StoreContext from '../../../context/StoreContext';
import { createSectionListAction, deleteSectionListAction, updateSectionListAction } from '../../../redux';

const SectionDeletePopUp = ({
  sectionLists,
  deleteSectionLists,
  title = 'set title',
  message = 'add required message !',
  data,
  onDelete,
  onCancel,
  handelModalClose,
}) => {
  const { getClassList } = useContext(StoreContext);

  const [sectionListName, setSectionListName] = useState('');
  // console.log('🚀 ~ file: SectionDeletePopUp.jsx ~ line 15 ~ SectionDeletePopUp ~ sectionListName', sectionListName);
  const [sectionId, setSectionId] = useState('');

  useEffect(() => {
    setSectionListName(data.section_name_en);
    setSectionId(data.id);
  }, [data.id, data.section_name_en]);

  const sectionName = {
    name_en: data.section_name_en,
    name_bn: data.section_name_bn,
  };
  const [sectionNames, setSectionNames] = useState(sectionName);
  const [formError, setFormError] = useState({});
  const [postData, setPostData] = useState({});

  const handelFormChange = (e) => {
    const { name, value } = e.target;
    setSectionNames({ ...sectionNames, [name]: value });
  };

  const onSubmit = () => {
    let data = {
      section_id: sectionId,
    };
    setPostData(data);
    setIsSubmit(true);
  };

  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    if (isSubmit) {
      deleteSectionLists(postData, getClassList);
    }
  }, [formError, isSubmit]);

  useEffect(() => {
    if (sectionLists?.message === 'Section Deleted Successfully') {
      setTimeout(function () {
        handelModalClose();
      }, 2000);
    }
  }, [handelModalClose, sectionLists?.message]);
  return (
    <React.Fragment>
      <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
        <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
          <div className='flex justify-between'>
            <h1 className=' text-black font-semibold text-xl capitalize'>{title}</h1>
            <span className=' cursor-pointer hover:text-green-600'>
              <FontAwesomeIcon icon={faXmark} onClick={onCancel} />
            </span>
          </div>
          {/* <!-- horizontal line  --> */}
          <div className=' my-2 border border-gray-300 '></div>
          {/* <!-- update form start  --> */}
          <div className='w-full pt-5 pb-5'>
            {/* <!-- login forms  --> */}
            <div className='flex justify-center  flex-col space-y-5   px-4'>
              {/* <!-- delete message  --> */}
              <div className='text-center py-10'>
                <h1 className='text-black font-semibold text-base md:text-xl'>
                  Are you sure you want to <span className=' text-red-700 uppercase'>delete </span>
                  the <span className='font-bold'> {sectionListName}</span> <span> ?</span>
                </h1>
              </div>

              {/* <!-- button  --> */}
              <div className=' text-center w-full flex  space-x-5'>
                <button
                  onClick={onCancel}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
                >
                  <span className=''>
                    {' '}
                    <FormattedMessage id='cancel' />{' '}
                  </span>
                </button>

                <button
                  onClick={onSubmit}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=''>
                    <FormattedMessage id='delete' />
                  </span>
                </button>
              </div>

              {/* <!-- submit success-fully  --> */}
              {/* {sectionLists && sectionLists.loading && <LoadingSpinner/>} */}
              {sectionLists && sectionLists.error ? (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-500'>
                    {sectionLists?.error?.section_id[0]}
                  </h1>
                </div>
              ) : (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {sectionLists.message}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    sectionLists: state.sectionLists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSectionLists: (listInfo, getClassList) => dispatch(deleteSectionListAction(listInfo, getClassList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDeletePopUp);
