import axios from 'axios';
import { GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILED } from '../constance/profile.constants';

export const getProfileRequest = () => {
  return {
    type: GET_PROFILE_REQUEST,
    inof: 'profile request',
  };
};

export const getProfileSuccess = (res) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: res,
  };
};
export const getProfileFailed = (err) => {
  return {
    type: GET_PROFILE_FAILED,
    payload: err,
  };
};

export const getProfileAction = (institution_id) => {
  // console.log("🚀 ~ file: profile.action.js ~ line 29 ~ getProfileAction ~ data", institution_id)
  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;
  return (dispatch) => {
    dispatch(getProfileRequest());
    axios
      .get(`${apiUrl}institution/profile?institution_id=${institution_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getProfileSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getProfileFailed(err));
      });
  };
};
