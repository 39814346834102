import React from 'react';

const DropDownMenu = ({ children }) => {
  return (
    <React.Fragment>
      <ul className='space-y-1 bg-white w-60 md:w-36 lg:w-60'>{children}</ul>
    </React.Fragment>
  );
};

export default DropDownMenu;
