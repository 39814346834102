import { faFaceFrown, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import Layout from '../../../layout/Layout';
import EmptyList from '../../../Components/EmptyList';
import { getSectionListFromShiftAction } from '../../../redux';
import { connect } from 'react-redux';
import { useState } from 'react';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import SectionCreatePopUp from './SectionCreatePopUp';
import SectionEditPopUp from './SectionEditPopUp';
import SectionDeletePopUp from './SectionDeletePopUp';
import { shiftNameConverter } from '../../../redux/service/service';
import {
  createSectionListRequest,
  createSectionTeacherRequest,
  deleteSectionListRequest,
  updateSectionListRequest,
} from '../../../redux/actions/upgradeClass.action';
import StoreContext from '../../../context/StoreContext';
import { FormattedMessage } from 'react-intl';
import LanguageContext from '../../../context/LanguageContext';
const SectionListPage = ({
  createSectionTeacherRequest,
  createSectionListRequest,
  updateSectionListRequest,
  deleteSectionListRequest,
  getSectionLists,
  sectionData,
  hasShift,
}) => {
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList, classLoading } = useContext(StoreContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  // location uses for getting data from shift page
  const location = useLocation();
  //   console.log('🚀 ~ file: SectionListPage.jsx ~ line 27 ~ location data==', location);
  const navigate = useNavigate();
  const [CurrentState, setCurrentState] = useState(false);

  // this modal is used for create section and open close modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handelCreateSection = () => {
    setOpenCreateModal(true);
    createSectionListRequest();
  };

  const [sectionList, setSectionList] = useState([]);
  // console.log('🚀 ~ file: SectionListPage.jsx:48 ~ sectionList', sectionList);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedContent, setEditedContent] = useState({});
  const handelEditSection = (data) => {
    setEditedContent(data);
    setOpenEditModal(true);
    updateSectionListRequest();
  };

  // delete section lists
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handelDeleteSection = (data) => {
    setEditedContent(data);
    setOpenDeleteModal(true);
    deleteSectionListRequest();
  };

  // close section create modal
  const handelModalClose = () => {
    setOpenCreateModal(false);
    setOpenEditModal(false);
    setOpenDeleteModal(false);
    // setCurrentState(true);

    // updateSectionListRequest();
  };

  // go to inside section or add teacher before enter the section

  // go to teacher-add page if teacher not added
  // if teacher added then goto student add page from shift and section
  const handelSectionTeacherOrEntrance = (data) => {
    localStorage.setItem(
      'sectionInfo',
      JSON.stringify({
        grade: data?.grade,
        institution_id: data?.institution_id,
        shift_id: data?.shift_id,
        section_id: data?.id,
        class_name_bn: data?.class_name_bn,
        class_name_en: data?.class_name_en,
        section_name_bn: data?.section_name_bn,
        section_name_en: data?.section_name_en,
        shift_name_bn: data?.shift_name_bn,
        shift_name_en: data?.shift_name_en,
      })
    );
    if (data.teacher_name === null) {
      createSectionTeacherRequest();
      if (hasShift?.data?.has_shift === 'yes') {
        navigate(
          `/classes/shift-list/${shiftNameConverter(data.shift_id)}/section-${data.section_name_en}-add-teacher`,
          { state: data }
        );
      } else {
        navigate(`/classes/section-list/section-${data.section_name_en}-add-teacher`, { state: data });
      }
    } else {
      if (hasShift?.data?.has_shift === 'yes') {
        navigate(
          `/classes/shift-list/${shiftNameConverter(data.shift_id)}/section-${data.section_name_en}-student-list`,
          { state: data }
        );
      } else {
        navigate(`/classes/section-list/section-${data.section_name_en}-student-list`, { state: data });
      }
    }
  };

  // data from localStorage
  const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
  useEffect(() => {
    // if data have in localStorage
    const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
    // console.log(newData);
    const grade = location?.state === null ? newData.grade : location?.state?.grade;
    const data = {
      institutionId: institutionId,
      grade: grade,
      shift_id:
        hasShift?.data?.has_shift === 'yes'
          ? location?.state === null
            ? newData.shift_id
            : location?.state?.shift_id
          : undefined,
    };
    if (hasShift?.data?.has_shift === 'yes' && data.shift_id !== undefined) {
      const getShifts = classList?.find((c) => c.grade === Number(grade));
      const getSections = getShifts?.shift_section?.find((c) => c.shift_id === Number(data.shift_id));

      setSectionList(getSections?.section.sort((a, b) => a.section_name_en.localeCompare(b.section_name_en)));
    }
    if (hasShift?.data?.has_shift === 'no' && data.shift_id === undefined) {
      const allSections = classList?.find((c) => c.grade === Number(grade));

      setSectionList(allSections?.shift_section.sort((a, b) => a.section_name_en.localeCompare(b.section_name_en)));
    }
  }, [classList]);

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      {/* pass location data to popup all modal for delete,edit,create  */}
      {openCreateModal && (
        <SectionCreatePopUp
          data={location.state === null ? newData : location.state}
          title={<FormattedMessage id='createSection' />}
          onCancel={handelModalClose}
          handelModalClose={handelModalClose}
        />
      )}
      {openEditModal && (
        <SectionEditPopUp
          data={editedContent}
          title={<FormattedMessage id='editSection' />}
          onCancel={handelModalClose}
          handelModalClose={handelModalClose}
        />
      )}
      {openDeleteModal && (
        <SectionDeletePopUp
          data={editedContent}
          title={<FormattedMessage id='deleteSection' />}
          onCancel={handelModalClose}
          handelModalClose={handelModalClose}
        />
      )}

      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <div className='container mx-auto'>
          <div className='flex flex-wrap items-center w-full px-4 sm:w-4/5 mx-auto'>
            {/* <!-- section list  --> */}
            <div className=''>
              <h1 className='text-xl md:text-3xl capitalize font-semibold text-black'>
                <FormattedMessage id='section-list' />
              </h1>
            </div>
            {/* <!-- student search input  --> */}
            <div className=' ml-auto relative order-2 lg:order-1 w-full lg:w-auto mt-4 lg:mt-0'></div>

            {/* <!-- button  --> */}
            <div className='ml-auto lg:ml-4 order-1 lg:order-2'>
              <button
                onClick={handelCreateSection}
                type='button'
                className='py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-white bg-gradient hover_gradient'
              >
                <span>
                  <FormattedMessage id='createSection' />
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* table start */}
        {classLoading ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            {sectionList && sectionList.length ? (
              <div className='container mx-auto'>
                <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
                  <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                    {/* <!-- total table info  --> */}
                    <div className='flex flex-wrap flex-col md:flex-row items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                      <div className='mb-3 md:mb-0 flex space-x-5'>
                        <p className='text-base text-black capitalize'>
                          <FormattedMessage id='class' />:
                          <span className='ml-1'>
                            {defaultLanguage === 'en' ? newData?.class_name_en : newData?.class_name_bn}
                          </span>
                        </p>

                        {hasShift?.data?.has_shift === 'yes' && (
                          <p className='text-base text-black capitalize'>
                            <FormattedMessage id='shift' />:
                            <span className='ml-1'>
                              {defaultLanguage === 'en' ? newData?.shift_name_en : newData?.shift_name_bn}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                      <thead className=' text-left bg-[#BFE3B5]'>
                        <tr>
                          <th className=' border border-gray-300 capitalize p-2'>
                            <FormattedMessage id='section' />
                          </th>
                          <th className=' border border-gray-300 capitalize p-2'>
                            <FormattedMessage id='students' />
                          </th>
                          <th className=' border border-gray-300 capitalize p-2'>
                            <FormattedMessage id='teachers' />
                          </th>

                          <th className=' border border-gray-300 capitalize p-2'>
                            <FormattedMessage id='option' />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sectionList?.map((data, index) => {
                          return (
                            <tr key={index} className='hover:bg-green-50 cursor-pointer'>
                              <td
                                className='border border-gray-300 p-2'
                                onClick={() => handelSectionTeacherOrEntrance(data)}
                              >
                                {data.section_name_en}
                              </td>
                              <td
                                className='border border-gray-300 p-2 capitalize'
                                onClick={() => handelSectionTeacherOrEntrance(data)}
                              >
                                <span className=' cursor-pointer'>{data.total_student}</span>
                              </td>
                              <td
                                className='border border-gray-300 p-2 capitalize'
                                onClick={() => handelSectionTeacherOrEntrance(data)}
                              >
                                {data.teacher_name === null ? 'Not assigned' : <span>{data.teacher_name}</span>}
                              </td>

                              <td className='border border-gray-300 p-2 space-x-2 relative z-10'>
                                <span onClick={() => handelEditSection(data)}>
                                  <span className='cursor-pointer w-8 h-8 leading-8 bg-[#FEEBDB] text-[#F87D2C] inline-block text-center text-base rounded-full'>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                  </span>
                                </span>
                                <span
                                  onClick={() => handelDeleteSection(data)}
                                  className='cursor-pointer w-8 h-8 leading-8 bg-[#FDE4E9] text-[#EF5E76] inline-block text-center text-base rounded-full'
                                >
                                  <FontAwesomeIcon icon={faTrashCan} />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyList message='Your section List is empty' border />
            )}
          </React.Fragment>
        )}

        {/* table end */}
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sectionData: state.sectionData,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSectionLists: (shiftInfo) => dispatch(getSectionListFromShiftAction(shiftInfo)),
    updateSectionListRequest: () => dispatch(updateSectionListRequest()),
    deleteSectionListRequest: () => dispatch(deleteSectionListRequest()),
    createSectionListRequest: () => dispatch(createSectionListRequest()),
    createSectionTeacherRequest: () => dispatch(createSectionTeacherRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionListPage);
