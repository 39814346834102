import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InstitutionInfo from './InstitutionInfo';
import { getLoggedInUser, userLogIn } from '../../redux';
import Modal from '../../Components/Modal';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import EyeSvg from '../../assets/image/svg/eye.svg';
import EyeOffSvg from '../../assets/image/svg/eye-off.svg';

const LogInFormNext = ({ userLogIn, auth, login, getLoggedInUser, institutionInfo }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const handelBack = () => {
    navigate('/login');
    const getLocalLanguage = localStorage.getItem('defaultLanguage');
    window.localStorage.clear();
    if (getLocalLanguage) {
      localStorage.setItem('defaultLanguage', getLocalLanguage);
    }
    window.location.reload();
    window.localStorage.setItem('isEng', true);
  };
  // check if user loggedin or not
  const [userLoggedIn, setUserLoggedIn] = useState(() => window.localStorage.token);

  // get eiin id from localStorage
  const [eiin, setEiim] = useState(() => window.localStorage.getItem('eiim'));
  const iniTialValue = {
    eiin: eiin,
    password: '',
  };
  const [percentageValue, setPercentageValue] = useState(50);
  const [formValue, setFormValue] = useState(iniTialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    // console.log(formValue);
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForms(formValue));
    setIsSubmit(true);
  };

  const validateForms = (values) => {
    const error = {};
    if (!values.eiin || values.eiin === '0') {
      error.eiin = 'Education type is Required';
    }
    if (!values.password.trim()) {
      error.password = 'Password is Required';
    }
    if (values.password.trim().length < 6) {
      error.password = 'Password must be 6 character long.';
    }

    return error;
  };

  useEffect(() => {
    // console.log('Error : ', formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      userLogIn(formValue);
      setIsSubmitSuccess(true);

      // navigate('/');
    }
  }, [formErrors]);

  const token = localStorage.getItem('token');
  // useEffect(() => {
  // 	if (token) {
  // 		getLoggedInUser();
  // 		// navigate('/');
  // 	} else {
  // 		console.log('no token find', login);
  // 	}
  // }, [getLoggedInUser, login, navigate, token]);

  // handling modal if not login success
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handelModal = () => {
    const getLocalLanguage = localStorage.getItem('defaultLanguage');
    setIsModalOpen(!isModalOpen);
    window.localStorage.clear();
    if (getLocalLanguage) {
      localStorage.setItem('defaultLanguage', getLocalLanguage);
    }
    window.location.reload();
    window.localStorage.setItem('isEng', true);
    navigate('/login');
  };
  const handelModalIfSuccess = () => {
    setIsModalOpen(!isModalOpen);
    navigate('/');
  };

  useEffect(() => {
    if (isSubmitSuccess && auth?.userData?.error === false) {
      setTimeout(() => {
        navigate('/', {
          state: {
            isLoggedIn: true,
          },
        });
        getLoggedInUser();
      }, 0);
    }
  }, [auth?.userData?.error]);

  useEffect(() => {
    // console.log('formValue.password--------------------------------', typeof formValue.password);
    if (formValue.password && formValue.eiin) {
      setPercentageValue(100);
    } else {
      setPercentageValue(50);
    }
  }, [formValue]);

  return (
    <React.Fragment>
      {/* {auth?.userLoggedIn ? <Modal /> : null} */}
      {institutionInfo && institutionInfo ? <InstitutionInfo institutionInfo={institutionInfo} /> : null}

      <section className='mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className='container flex flex-col space-y-10 justify-center mx-auto lg:w-1/2 xl:w-2/4 2xl:w-1/3'>
          {/* <!-- login forms  --> */}
          <form onSubmit={handelSubmit}>
            <div className='flex justify-center  flex-col  space-y-5  px-4'>
              {/* <!-- input institute id option  --> */}
              {/*  ! EIIN number */}
              <div className=' relative xl:w-full flex flex-col'>
                <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='instituteEiinNumber'>
                  <FormattedMessage id='eiinNumber' />
                </label>
                <input
                  className='form-select js__Password_show_hide block w-full px-3 py-1.5  text-base font-normal text-gray-700   bg-clip-padding bg-no-repeat  border border-solid border-gray-300  rounded transition  ease-in-out m-0 focus:text-gray-700 focus:border-green-600 focus:outline-none bg-gray-300 focus:bg-gray-300'
                  name='eiin'
                  type='text'
                  id='instituteEiinNumber'
                  value={formValue.eiin}
                  readOnly
                />
                <p>
                  <small className='text-red-700'>{formErrors.eiin}</small>
                </p>
              </div>
              {/* password  */}
              <div className=' relative xl:w-full flex flex-col'>
                <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='password'>
                  <FormattedMessage id='password' />
                  <span className=' text-red-700'>*</span>
                </label>
                <div className='password-show-hide'>
                  <input
                    className={`
									form-select js__Password_show_hide block w-full px-3 py-1.5  text-base font-normal text-gray-700  bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray-300  rounded transition  ease-in-out m-0 focus:text-gray-700
									focus:bg-white
									focus:outline-none ${auth.isPassCorrect ? 'focus:border-green-600' : 'focus: border-red-600'}`}
                    name='password'
                    value={formValue.password}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    placeholder={intl.formatMessage({ id: 'password' })}
                  />

                  <div className='eye-button'>
                    {showPassword ? (
                      <img src={EyeOffSvg} alt='' onClick={() => setShowPassword(false)} />
                    ) : (
                      <img src={EyeSvg} alt='' onClick={() => setShowPassword(true)} />
                    )}
                  </div>
                </div>

                {auth.isPassCorrect ? null : (
                  <p>
                    <small className='text-red-700'>{auth?.userData?.message}</small>
                  </p>
                )}
                <p>
                  <small className='text-red-700'>{formErrors.password}</small>
                </p>
              </div>

              {/* <!-- button  --> */}
              <div className=' text-center w-full flex space-x-5'>
                <button
                  onClick={handelBack}
                  type='button'
                  className=' w-full py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=''>
                    <FormattedMessage id='back' />
                  </span>
                </button>
                <button
                  type='submit'
                  className=' w-full py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=''>
                    <FormattedMessage id='submit' />
                  </span>
                </button>
              </div>
              {/* <!-- progress-bar  --> */}
              <div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
                <div
                  className='bg-gradient h-2.5 rounded-full'
                  // style={{
                  // 	width: isSubmitSuccess ? `${percentageValue}%`:'50%',
                  // 	transition:"width 1s"
                  // }}
                  style={{
                    width: `${percentageValue}%`,
                    transition: 'width 1s',
                  }}
                ></div>
              </div>

              {/* login successfull message  */}
              {/* {isSubmitSuccess && auth?.userData?.error === false ?(

								<div className=' flex flex-col text-center justify-center'>
									<h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
										{auth?.userData?.message}

									</h1>
								</div>

							):null} */}

              {/* <!-- submit success-fully  --> */}
              {isSubmitSuccess && auth.loading ? (
                <div className=' mx-auto'>
                  <LoadingSpinner />
                </div>
              ) : isSubmitSuccess && auth?.userData?.error === true ? (
                <React.Fragment>
                  <div
                    className={` p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 ${
                      isModalOpen ? 'z-50' : ' hidden -z-50'
                    }  `}
                  >
                    <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
                      <div className='flex justify-between'>
                        <h1 className=' text-black font-semibold text-xl capitalize'>login failed</h1>
                        <span className=' cursor-pointer hover:text-green-600'>
                          <FontAwesomeIcon icon={faXmark} onClick={handelModal} />
                        </span>
                      </div>
                      {/* <!-- horizontal line  --> */}
                      <div className=' my-2 border border-gray-300 '></div>
                      {/* <!-- update form start  --> */}
                      <div className='w-full pt-5 pb-5'>
                        {/* <!-- login forms  --> */}
                        <div className='flex justify-center  flex-col  space-y-5  px-4'>
                          {/* <!-- delete message  --> */}
                          <div className='text-center py-10'>
                            <h1 className='text-red-600 font-bold capitalize text-base md:text-xl'>
                              {auth?.userData?.message}
                            </h1>
                          </div>

                          {/* <!-- button  --> */}
                          <div className=' text-center w-full flex justify-center  space-x-5'>
                            <button
                              onClick={handelModal}
                              type='button'
                              className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
												hover:border-transparent  hover_gradient '
                            >
                              <span className=''> cancel </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              {/* <React.Fragment>
									<div className={` p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 ${isModalOpen ?'z-50':' hidden -z-50'}  `}>
										<div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
											<div className='flex justify-between'>
												<h1 className=' text-black font-semibold text-xl capitalize'>
													login success
												</h1>
												<span className=' cursor-pointer hover:text-green-600'>
													<FontAwesomeIcon icon={faXmark}  onClick={handelModalIfSuccess}/>
												</span>
											</div>

											<div className=' my-2 border border-gray-300 '></div>

											<div className='w-full pt-5 pb-5'>

												<div className='flex justify-center  flex-col  space-y-5  px-4'>

													<div className='text-center py-10'>
														<h1 className='text-green-400 font-bold capitalize text-base md:text-xl'>
															{auth?.userData?.message}
														</h1>
													</div>


													<div className=' text-center w-full flex justify-center  space-x-5'>
														<button
															onClick={handelModalIfSuccess}
															type='button'
															className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
													hover:border-transparent  hover_gradient '
														>
															<span className=''> go to home page </span>
														</button>
													</div>

												</div>
											</div>
										</div>
									</div>
								</React.Fragment> */}
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.user,
    login: state.login,
    institutionInfo: state.institutionCheck,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogIn: (loginData) => dispatch(userLogIn(loginData)),
    getLoggedInUser: () => dispatch(getLoggedInUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInFormNext);
