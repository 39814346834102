import {
  GET_TABLET_STOCK_REQUEST,
  GET_TABLET_STOCK_SUCCESS,
  GET_TABLET_STOCK_FAILED,
  ADD_TABLET_STOCK_REQUEST,
  ADD_TABLET_STOCK_SUCCESS,
  ADD_TABLET_STOCK_FAILED,
} from '../constance/programs.constants';

const initialState = {
  loading: false,
  tablet: {
    data: {
      wifa_tablet_stock: '',
      deworming_tablet_stock: '',
      wifa_tablet_used: '',
      deworming_tablet_used: '',
    },
  },
  error: '',
  status: '',
  message: '',
};

export const tabletStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TABLET_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        tablet: {
          data: {
            wifa_tablet_stock: '',
            deworming_tablet_stock: '',
            wifa_tablet_used: '',
            deworming_tablet_used: '',
          },
        },
        error: '',
        status: '',
        message: '',
      };
    case GET_TABLET_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        tablet: {
          data: {
            wifa_tablet_stock: action.payload.data.wifa_tablet_stock,
            deworming_tablet_stock: action.payload.data.deworming_tablet_stock,
            wifa_tablet_used: action.payload.data.wifa_tablet_used,
            deworming_tablet_used: action.payload.data.deworming_tablet_used,
          },
        },
        error: action.payload.error,
        status: action.payload.status,
        message: '',
      };
    case GET_TABLET_STOCK_FAILED:
      return {
        ...state,
        loading: false,
        tablet: [],
        error: action.payload,
      };
    case ADD_TABLET_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        tablet: {
          data: {
            wifa_tablet_stock: '',
            deworming_tablet_stock: '',
            wifa_tablet_used: '',
            deworming_tablet_used: '',
          },
        },
        error: '',
        status: '',
        message: '',
      };
    case ADD_TABLET_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        tablet: {
          data: {
            wifa_tablet_stock: action.payload.data.wifa_tablet_stock,
            deworming_tablet_stock: action.payload.data.deworming_tablet_stock,
            wifa_tablet_used: action.payload.data.wifa_tablet_used,
            deworming_tablet_used: action.payload.data.deworming_tablet_used,
          },
        },
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    case ADD_TABLET_STOCK_FAILED:
      return {
        ...state,
        loading: false,
        tablet: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
