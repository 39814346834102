import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import ImageUpload from '../../../Components/imageUpload/ImageUpload';
import InputField from '../../../Components/InputField';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import LanguageContext from '../../../context/LanguageContext';
import StoreContext from '../../../context/StoreContext';
import {
  getClassWiseShiftListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
  updateSectionStudentAction,
} from '../../../redux';
import {
  calculateAge,
  validateClass,
  validateDate,
  validateGender,
  validateNumber,
  validateSection,
  validateShift,
  validNameField,
} from '../../../redux/service/service';

import { birthYears, days, months, studentGender as studentGenderList, monthsWithZero } from '../../../common/datas';
import { toBnNumber } from '../../../common/functions';
import { updateSectionStudentRequest } from '../../../redux/actions/upgradeClass.action';

const UpdateSectionStudentFromShift = ({
  // class data
  getUpdatedClassLists,
  classData,
  // class wise shift
  getShiftLists,
  shiftList,
  // section list
  getSectionLists,
  sectionList,
  // update student
  updateStudent,
  // update message
  message,
  initialAfterUpdate,
  hasShift,
  validation,
}) => {
  // all state
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList, classLoading } = useContext(StoreContext);
  const navigate = useNavigate();
  const studentData = useLocation();
  const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
  const { id, name, roll, gender, age, birth_date, grade, shift_name_en, shift_id, student_profile_image } =
    studentData.state;
  const [gradeId, setGrade] = useState(studentData.state === null ? newData.grade : studentData.state?.grade);
  const [shiftId, setShift] = useState(studentData.state === null ? newData.shift_id : studentData.state?.shift_id);
  const [sectionId, setSection] = useState(
    studentData.state === null ? newData.section_id : studentData.state?.section_id
  );
  const [studentGender, setStudentGender] = useState(
    studentData.state === null ? newData.gender : studentData.state?.gender
  );
  const [totalAge, setTotalAge] = useState(studentData.state === null ? newData.age : studentData.state?.age);
  const [birthCertificationNumber, setBirthCertificationNumber] = useState(
    studentData.state === null ? newData.birth_certificate_num : studentData.state?.birth_certificate_num
  );
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));

  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);

  // class api call
  useEffect(() => {
    const sClass = studentData?.state?.grade;
    const sShift = studentData?.state?.shift_id;
    const sSection = studentData?.state?.section_id;
    // console.log(studentData?.state);
    if (hasShift?.data?.has_shift === 'yes') {
      const shifts = classList?.find((c) => c?.grade === sClass);
      setShiftArray(shifts?.shift_section);
      const sections = shifts?.shift_section?.find((c) => c?.shift_id === sShift);
      //   console.log(shifts?.shift_section);
      setSectionArray(sections?.section);
    } else if (hasShift?.data?.has_shift === 'no') {
      const shifts = classList?.find((c) => c?.grade === sClass);
      setSectionArray(shifts?.shift_section);
      //   getSectionLists(data);
      //   setSectionArray(singleClass?.shift_section);
    }
  }, [classList]);
  // useEffect(() => {
  //     getUpdatedClassLists()
  // },[getUpdatedClassLists])
  // shift api call
  // useEffect(() => {
  //     const data = {
  //         institutionId:institutionId,
  //         grade:gradeId
  //     }
  //     if (gradeId && gradeId !=='0') {
  //         getShiftLists(data)
  //     }
  // },[gradeId,institutionId,getShiftLists])
  // section api call
  // useEffect(() => {
  //     const data = {
  //         institutionId:institutionId,
  //         grade:gradeId,
  //         shift_id: hasShift?.data?.has_shift==='yes' ? shiftId: undefined,
  //     }
  //     if (gradeId && gradeId !=='0' && institutionId!==null) {
  //         getSectionLists(data)
  //     }
  // },[getSectionLists, gradeId, institutionId, shiftId,hasShift])

  // image upload
  // input image file
  const [toViewAvatar, setToViewAvatar] = useState(student_profile_image);
  const [avatar, setAvatar] = useState(null);
  const inputFile = (event) => {
    setToViewAvatar(URL.createObjectURL(event.target.files[0]));
    setAvatar(event.target.files[0]);
  };
  // class list
  const [errorClass, setErrorClass] = useState(false);
  // const handelSelectClass = (e)=>{
  //     setGrade(e.target.value)
  // }
  // shift list
  const [shiftError, setShiftError] = useState(false);
  // const handelSelectShift=(e)=>{
  //     setShift(e.target.value)
  // }
  // section list
  const [sectionError, setSectionError] = useState(false);
  // const handelSelectSection = (e)=>{
  //     setSection(e.target.value)
  // }

  // name validation
  const [studentName, setStudentName] = useState(name || null);
  const [nameError, setNameError] = useState(false);
  const handelNameField = (e) => {
    setStudentName(e.target.value);
  };

  // validate roll
  const [rollNo, setRollNo] = useState(roll || null);
  const [rollError, setRollError] = useState(false);
  const handelRoll = (e) => {
    setRollNo(e.target.value);
  };

  // validate roll from server
  const [rollErrorFromServer, setRollErrorFromServer] = useState(null);
  useEffect(() => {
    setRollErrorFromServer(validation?.error?.roll);
  }, [validation?.error?.roll]);
  let msRollError = rollErrorFromServer && (
    <p>
      {' '}
      <small className='text-red-700'>{rollErrorFromServer}</small>{' '}
    </p>
  );

  // validate citification number from server
  const [birthCertificateErrorFromServer, setBirthCertificateErrorFromServer] = useState(null);
  useEffect(() => {
    setBirthCertificateErrorFromServer(validation?.error?.birth_certificate_num);
  }, [validation?.error?.birth_certificate_num]);
  let msBirthCertificateError = birthCertificateErrorFromServer && (
    <p>
      {' '}
      <small className='text-red-700'>{birthCertificateErrorFromServer}</small>{' '}
    </p>
  );

  // validate gender

  const [genderError, setGenderError] = useState(false);
  // const handelGender = (e)=>{
  //     setStudentGender(e.target.value)
  // }
  // Date validate
  const initialDate = {
    year: studentData?.state === null ? newData.birth_date.split('-')[0] : studentData?.state?.birth_date.split('-')[0],
    month:
      studentData?.state === null ? newData.birth_date.split('-')[1] : studentData?.state?.birth_date.split('-')[1],
    day: studentData?.state === null ? newData.birth_date.split('-')[2] : studentData?.state?.birth_date.split('-')[2],
  };
  const [dateValue, setDateValue] = useState(initialDate);
  const [dateError, setDateError] = useState({});

  const handelDate = (e) => {
    const { name, value } = e.target;
    setDateValue({ ...dateValue, [name]: value });
  };

  useEffect(() => {
    if (dateValue.day && dateValue.month && dateValue.year) {
      setTotalAge(calculateAge(dateValue));
    }
  }, [dateValue]);

  // form submit
  const [isSubmit, setIsSubmit] = useState(false);
  const [postData, setSetPostData] = useState({});

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = {
      student_id: id,
      name: studentName,
      roll: rollNo,
      gender: studentGender,
      birth_date: `${dateValue.year}-${dateValue.month}-${dateValue.day}`,
      institution_id: institutionId,
      grade: gradeId,
      section_id: sectionId,
      age: totalAge,
      shift_id: hasShift?.data?.has_shift === 'yes' ? shiftId : undefined,
      student_profile_image: avatar,
      birth_certificate_num: birthCertificationNumber,
    };

    setErrorClass(validateClass(gradeId));
    setNameError(validNameField(studentName));
    setRollError(validateNumber(data.roll));
    setGenderError(validateGender(studentGender));
    // setShiftError(validateShift(shiftId))
    setSectionError(validateSection(sectionId));
    setDateError(validateDate(dateValue));
    setSetPostData(data);
    setIsSubmit(true);
    // updateStudent(data)
  };

  useEffect(() => {
    if (
      isSubmit &&
      Object.keys(dateError).length === 0 &&
      errorClass === false &&
      nameError === false &&
      rollError === false &&
      genderError === false &&
      sectionError === false
    ) {
      updateStudent(postData);
    }
  }, [
    dateError,
    errorClass,
    genderError,
    isSubmit,
    nameError,
    postData,
    rollError,
    sectionError,
    shiftError,
    updateStudent,
  ]);

  useEffect(() => {
    if (message && message === 'Student Updated Successfully') {
      setTimeout(function () {
        navigate(-1);
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    return () => {
      initialAfterUpdate();
    };
  }, []);

  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    // setInitialClassId(e.target.value);
    setShift('');
    setSection('');

    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    // setGradeName(singleClass?.name_en);

    if (hasShift?.data?.has_shift === 'yes') {
      //   getShiftLists(data);
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      //   getSectionLists(data);
      setSectionArray(singleClass?.shift_section);
    }
  };

  const handelSelectShift = (e) => {
    setShift(e.target.value);
    // console.log(e.target.value);

    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    // setShiftName(singleShift?.shift_name_en);
    // console.log(singleShift);
    // console.log(singleShift?.section);
    setSectionArray(singleShift?.section);
    setSection('');
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col items-center w-full px-4 lg:w-1/2 mx-auto'>
            <div className='border border-gray-300 w-full  py-4 rounded'>
              <div className='border-b border-gray-300'>
                <h1 className=' text-black font-semibold text-xl capitalize pb-4 px-4'>
                  <FormattedMessage id='editProfile' />
                </h1>
              </div>
              {/* .student add form   start  */}
              <form onSubmit={handelSubmit}>
                <div className='w-full pt-5 pb-5'>
                  <div className='flex justify-center  flex-col  space-y-5  px-4'>
                    {/* image upload  */}
                    <ImageUpload avatar={toViewAvatar} inputFile={inputFile} />
                    {/* class */}
                    {classLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <React.Fragment>
                        <div className='xl:w-full'>
                          <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                            <FormattedMessage id='class' />
                            <span className=' text-red-700'>*</span>
                          </label>
                          <select
                            value={gradeId}
                            onChange={handelSelectClass}
                            name='class'
                            id='class'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                          >
                            <option value='0'>{intl.formatMessage({ id: 'selectClass' })}</option>
                            {classList &&
                              classList?.map((className, index) => {
                                return (
                                  <option key={index} value={className.grade}>
                                    {defaultLanguage === 'en' ? className.name_en : className.name_bn}
                                  </option>
                                );
                              })}
                          </select>
                          {errorClass && (
                            <p>
                              <small className='text-red-700'>Gender is required!</small>
                            </p>
                          )}
                        </div>
                        <div className='flex space-x-5'>
                          {/* section */}

                          {hasShift?.data?.has_shift === 'yes' && (
                            <div className='xl:w-full'>
                              <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                                <FormattedMessage id='shift' />
                                <span className=' text-red-700'>*</span>
                              </label>
                              <select
                                value={shiftId}
                                onChange={handelSelectShift}
                                name='shift'
                                id='shift'
                                className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                              >
                                <option value='0'>{intl.formatMessage({ id: 'selectShift' })}</option>
                                {shiftArray &&
                                  shiftArray?.map((shiftName, index) => {
                                    return (
                                      <option key={index} value={shiftName.shift_id}>
                                        {defaultLanguage === 'en' ? shiftName.shift_name_en : shiftName.shift_name_bn}
                                      </option>
                                    );
                                  })}
                              </select>
                              {shiftError && (
                                <p>
                                  <small className='text-red-700'>shift is required!</small>
                                </p>
                              )}
                            </div>
                          )}

                          {/* section */}
                          <div className='xl:w-full'>
                            <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                              <FormattedMessage id='section' />
                              <span className=' text-red-700'>*</span>
                            </label>
                            <select
                              value={sectionId}
                              onChange={(e) => setSection(e.target.value)}
                              name='section'
                              id='section'
                              className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                              aria-label=''
                            >
                              <option value='0'>{intl.formatMessage({ id: 'selectSection' })}</option>
                              {sectionArray &&
                                sectionArray?.map((sectionName, index) => {
                                  return (
                                    <option key={index} value={sectionName.id}>
                                      {defaultLanguage === 'en'
                                        ? sectionName.section_name_en
                                        : sectionName.section_name_bn}
                                    </option>
                                  );
                                })}
                            </select>
                            {sectionError && (
                              <p>
                                <small className='text-red-700'>Section field is required</small>
                              </p>
                            )}
                          </div>
                        </div>
                        {/* student name */}
                        <div className=''>
                          <InputField
                            required
                            onChange={handelNameField}
                            label={<FormattedMessage id='name' />}
                            placeholder={intl.formatMessage({ id: 'enterStockName' })}
                            value={studentName}
                            type='text'
                          />
                          {nameError && (
                            <p>
                              <small className='text-red-700'>Name is required Only A-Za-z</small>
                            </p>
                          )}
                        </div>

                        {/* student roll */}
                        <div className=''>
                          <InputField
                            required
                            label={<FormattedMessage id='roll' />}
                            placeholder={intl.formatMessage({ id: 'enterRoll' })}
                            value={rollNo}
                            onChange={handelRoll}
                          />
                          {rollError && (
                            <p>
                              <small className='text-red-700'>Roll is required!</small>
                            </p>
                          )}

                          {msRollError && msRollError}
                        </div>
                        {/* Birth Certificate Number */}
                        <div className=''>
                          <InputField
                            // required
                            type='number'
                            label={<FormattedMessage id='birthRegistrationNumber' />}
                            placeholder={intl.formatMessage({ id: 'enterBirthRegistrationNumber' })}
                            value={birthCertificationNumber}
                            onChange={(e) => setBirthCertificationNumber(e.target.value)}
                          />
                          {rollError && (
                            <p>
                              <small className='text-red-700'>Birth Certificate Number is required!</small>
                            </p>
                          )}

                          {msBirthCertificateError && msBirthCertificateError}
                        </div>

                        {/* student gender */}
                        <div className='xl:w-full'>
                          <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                            <FormattedMessage id='gender' />
                            <span className=' text-red-700'>*</span>
                          </label>
                          <select
                            value={studentGender}
                            onChange={(e) => setStudentGender(e.target.value)}
                            name='gender'
                            id='institute'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value='0'>{intl.formatMessage({ id: 'selectGender' })}</option>
                            {studentGenderList?.map((gender) => (
                              <option value={gender.name_en}>
                                {defaultLanguage === 'en' ? gender.name_en : gender.name_bn}
                              </option>
                            ))}
                            {/* <option value='Boy'>Boy</option> */}
                            {/* <option value='Girl'>Girl</option> */}
                          </select>

                          {genderError && (
                            <p>
                              <small className='text-red-700'>Gender is required!</small>
                            </p>
                          )}
                        </div>
                        {/* birth date */}
                        <div className=''>
                          <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                            <FormattedMessage id='dateOfBirth' />
                            <span className=' text-red-700'>*</span>
                          </label>
                          <div className='flex space-x-5'>
                            {/* day */}
                            <div className='w-full'>
                              <select
                                value={dateValue.day && dateValue.day}
                                onChange={handelDate}
                                name='day'
                                id='institute'
                                className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                                aria-label='Default select example'
                              >
                                <option value='' selected>
                                  {intl.formatMessage({ id: 'selectDay' })}
                                </option>
                                {days?.map((day) => (
                                  <option value={day}>{defaultLanguage === 'en' ? day : toBnNumber(day)}</option>
                                ))}
                              </select>

                              {dateError.day && (
                                <p>
                                  <small className='text-red-700'>day is required!</small>
                                </p>
                              )}
                            </div>
                            <div className='w-full'>
                              <select
                                value={dateValue.month && dateValue.month}
                                onChange={handelDate}
                                name='month'
                                id='institute'
                                className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                                aria-label='Default select example'
                              >
                                <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                                {monthsWithZero?.map((month) => (
                                  <option value={month.id}>
                                    {defaultLanguage === 'en' ? month.name_en : month.name_bn}
                                  </option>
                                ))}
                              </select>
                              {dateError.month && (
                                <p>
                                  <small className='text-red-700'>month is required!</small>
                                </p>
                              )}
                            </div>
                            <div className='w-full'>
                              <select
                                value={dateValue.year && dateValue.year}
                                onChange={handelDate}
                                name='year'
                                id='institute'
                                className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                                aria-label='Default select example'
                              >
                                <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                                {birthYears?.map((year) => (
                                  <option value={year}>{defaultLanguage === 'en' ? year : toBnNumber(year)}</option>
                                ))}
                              </select>

                              {dateError.year && (
                                <p>
                                  <small className='text-red-700'>year is required!</small>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* age  */}
                        {/* <div className=''>
                          <InputField
                            label={<FormattedMessage id='age' />}
                            placeholder='age'
                            value={totalAge}
                            readOnly={true}
                            type='number'
                          />
                        </div> */}
                        <div className=''>
                          <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                            {<FormattedMessage id='age' />}
                          </div>
                          <div className='bg-gray-300 focus:bg-gray-300 text-gray-700 block  w-full  min-w-[75px] px-3 py-1.5  text-base  font-normal border border-solid border-gray-300 rounded'>
                            {defaultLanguage === 'en' ? totalAge : toBnNumber(totalAge)}
                          </div>
                        </div>
                        <div className=' text-center w-full flex  space-x-5'>
                          <button
                            onClick={() => navigate(-1)}
                            type='button'
                            className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
								hover:border-transparent  hover_gradient '
                          >
                            <span className=''>
                              {' '}
                              <FormattedMessage id='back' />{' '}
                            </span>
                          </button>

                          <button
                            type='submit'
                            className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                          >
                            <span className=''>
                              <FormattedMessage id='submit' />
                            </span>
                          </button>
                        </div>
                        <div className=''>
                          {/* {validation && validation.loading &&<LoadingSpinner/>} */}
                          {message && message && (
                            <div className=' flex justify-center'>
                              <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                                {message}
                              </h1>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classData: state.upgradedClassState,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    message: state.shiftStudent?.message,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
    validation: state.shiftStudent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // addStudent: (studentInfo,avatar) => dispatch(createSectionStudentFromShiftAction(studentInfo,avatar)),
    // addStudentRequest:()=>{dispatch(createSectionStudentFromShiftRequest())}
    getUpdatedClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    updateStudent: (dataInfo) => dispatch(updateSectionStudentAction(dataInfo)),
    initialAfterUpdate: () => dispatch(updateSectionStudentRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSectionStudentFromShift);
