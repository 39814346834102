import { combineReducers } from 'redux';
import { userReducer, loggedInUserReducer } from './reducers/user.reducer';
import institutionCheckReducer from './reducers/checkInstitution.reducer';
import { classStudentInfoReducer, classListsUnderInstituteReducer } from './reducers/class.reducer';
import { teacherListReducer } from './reducers/teacher.reducer';
import { studentReducer } from './reducers/student.reducer';
import { getAllProgramsReducer, referralReportReducer } from './reducers/report.reducers';
import { nutritionEducationReducer } from './reducers/nutritionEducation.reducer';
import { nutritionWifaReducer } from './reducers/nutritionWifa.reducer';
import { nutritionDewormingReducer } from './reducers/nutritionDeworming.reducer';
import { bmiCalculationReducer } from './reducers/nutritionBmiCalculation.reducer';
import { getReferralPdfReducer, referralStudentsReducer } from './reducers/referrals.reducer';
import { getProfileReducer } from './reducers/Profile.reducer';
import { tabletStockReducer } from './reducers/tabletStock.reducers';
import { changePasswordReducer } from './reducers/passwordChange.reducer';
import {
  learningModuleCategoryReducer,
  learningModuleDetailsReducer,
  learningModuleReducer,
} from './reducers/learningModule.reducer';
import { languageReducer } from './reducers/language.reducer';
import {
  upgradeClassesReducer,
  getSectionListReducer,
  createSectionListReducer,
  createSectionTeacherReducer,
  sectionStudentReducer,
  createSectionStudentFromShiftReducer,
  getClassWiseShiftListReducer,
  teacherProfileReducer,
  getSubjectListReducer,
  getProgramStudentListReducer,
  getWeekNumberReducer,
  postProgramStudentListReducer,
  getStockListReducer,
  getFeetToMeterReducer,
  bmiResultReducer,
  getWifaTabletStock,
  getDewormingTabletStock,
  getDewormingTabletUsedStockReducer,
  getWifaTabletUsedStockReducer,
  wifaHistoryReducer,
  dewormingHistoryReducer,
  wifaAddTabletReducer,
  dewormingAddTabletReducer,
  getProgramListReducer,
  getReportReducer,
  studentProfileReducer,
  ReferralReducer,
  referralStudentProfileReducer,
  checkShiftStatusReducer,
  uploadStudentReducer,
  studentSampleDownloadReducer,
  upgradeStudentReducer,
} from './reducers/UpgradeClasses.reducer';
const rootReducers = combineReducers({
  lang: languageReducer,
  institutionCheck: institutionCheckReducer,
  user: userReducer,
  login: loggedInUserReducer,
  changePass: changePasswordReducer,
  classStudentInfo: classStudentInfoReducer,
  classListUnderInstitution: classListsUnderInstituteReducer,
  students: studentReducer,
  programs: getAllProgramsReducer,
  // monthlyReport: monthlyReportReducer,
  // weeklyReport: weeklyReportReducer,
  // annualReport: annualReportReducer,
  referralReport: referralReportReducer,
  nutritionEducation: nutritionEducationReducer,
  nutritionWifa: nutritionWifaReducer,
  nutritionDeworming: nutritionDewormingReducer,
  nutritionBmi: bmiCalculationReducer,
  referrals: referralStudentsReducer,
  referralPdf: getReferralPdfReducer,
  profile: getProfileReducer,
  tabletStock: tabletStockReducer,
  // dashboard: getDashboardDataReducer,
  learningModule: learningModuleReducer,
  learningModuleCategory: learningModuleCategoryReducer,
  moduleDetails: learningModuleDetailsReducer,
  // ======================================================================
  // upgrade classes
  sectionData: getSectionListReducer,
  sectionLists: createSectionListReducer,
  shiftStatus: checkShiftStatusReducer,
  upgradedClassState: upgradeClassesReducer,
  shiftData: getClassWiseShiftListReducer,
  sectionTeachers: createSectionTeacherReducer,
  teacherList: teacherListReducer,
  teacherProfile: teacherProfileReducer,
  teacher: createSectionTeacherReducer,
  // Student list
  studentProfile: studentProfileReducer,

  // NOTE: all section student list

  studentData: sectionStudentReducer,
  shiftStudent: createSectionStudentFromShiftReducer,
  uploadStudents: uploadStudentReducer,
  // student upgrade  class
  upgrade: upgradeStudentReducer,

  // programs
  subjects: getSubjectListReducer,
  stockList: getStockListReducer,
  weeks: getWeekNumberReducer,
  ProgramStudentList: getProgramStudentListReducer,
  ParticipatedStudents: postProgramStudentListReducer,
  // feet to meter
  meter: getFeetToMeterReducer,
  bmiResult: bmiResultReducer,
  // tablet
  wifaTotalTablet: getWifaTabletStock,
  dewormingTotalTablet: getDewormingTabletStock,

  dewormingUsedTablet: getDewormingTabletUsedStockReducer,
  wifaUsedTablet: getWifaTabletUsedStockReducer,

  wifaHistory: wifaHistoryReducer,
  dewormingHistory: dewormingHistoryReducer,

  wifaAddTablet: wifaAddTabletReducer,
  dewormingAddTablet: dewormingAddTabletReducer,
  // report
  programList: getProgramListReducer,
  report: getReportReducer,
  // referrals add
  referral: ReferralReducer,
  referralStudent: referralStudentProfileReducer,
  // sample download
  download: studentSampleDownloadReducer,
});

export default rootReducers;
