import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileBreadCrumb from '../../../Components/BreadCrumb/ProfileBreadCrumb';
import BreadCrumbImage from '../../../assets/image/breadcrumb/breadcrumb.png';
import { getStudentProfileAction } from '../../../redux';
import { shiftNameConverter } from '../../../redux/service/service';
import logo from '../../../assets/image/logo/logo.png';
import { FormattedMessage } from 'react-intl';
import LanguageContext from '../../../context/LanguageContext';
import { toBnNumber } from '../../../common/functions';
const StudentProfilePage = ({ variant, studentProfile, profileData, hasShift }) => {
  // console.log('🚀 ~ file: StudentProfilePage.jsx ~ line 11 ~ StudentProfilePage ~ variant', variant);
  const { defaultLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const studentData = useLocation();
  // const {institution_id,id} =studentData.state;

  useEffect(() => {
    const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
    const data = {
      institution_id: studentData?.state === null ? newData.institution_id : studentData?.state?.institution_id,
      student_id: studentData?.state === null ? newData.studentId : studentData?.state?.id,
    };
    studentProfile(data);
  }, [studentData, studentProfile]);

  // go to edit page
  const goToEditPage = (profileData) => {
    localStorage.setItem(
      'sectionInfo',
      JSON.stringify({
        grade: profileData?.grade,
        institution_id: profileData?.institution_id,
        shift_id: profileData?.shift_id,
        section_id: profileData?.section_id,
        class_name_bn: profileData?.class_name_bn,
        class_name_en: profileData?.class_name_en,
        section_name_bn: profileData?.section_name_bn,
        section_name_en: profileData?.section_name_en,
        shift_name_bn: profileData?.shift_name_bn,
        shift_name_en: profileData?.shift_name_en,
        gender: profileData?.gender,
        studentId: profileData?.id,
      })
    );
    if (variant === 'from-list') {
      navigate(`/student-list/edit-student`, { state: profileData });
    }
    if (variant === 'from-shift') {
      navigate(
        `/classes/shift-list/${shiftNameConverter(profileData?.shift_id)}/section-${
          profileData?.section_name_en
        }-student-list/student-profile/edit-student`,
        { state: profileData }
      );
    }
    if (variant === 'from-section') {
      navigate(
        `/classes/section-list/section-${profileData?.section_name_en}-student-list/student-profile/edit-student`,
        { state: profileData }
      );
    }
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      {/* profile breadcrumb start */}
      <ProfileBreadCrumb title={<FormattedMessage id='studentProfile' />} image={BreadCrumbImage} />
      {/* profile breadcrumb end */}
      <section className='mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className='container flex flex-col space-y-10 justify-center mx-auto lg:w-1/2 xl:w-2/4 2xl:w-1/3'>
          {/* <!-- login forms  --> */}
          <div className='flex justify-center  flex-col  space-y-5   border border-green-200 p-8 shadow-md rounded'>
            {/* <!-- heading  --> */}
            <div className='justify-center text-center flex flex-col items-center'>
              <img
                className='student_profile_image mr-2 w-36 h-30 rounded-full p-[2px] border border-green-600'
                src={`${
                  profileData && profileData?.student_profile_image !== null ? profileData?.student_profile_image : logo
                }`}
                alt='profile avatar'
              />
              <div className=''>
                <h1 className=' text-black font-semibold capitalize text-2xl lg:text-4xl'>{profileData?.name}</h1>
                <p className=' text-base capitalize text-gray-700'>
                  <FormattedMessage id='rollNo' />: <span>{profileData?.roll}</span>
                </p>
              </div>
            </div>

            {/* <!-- student class --> */}
            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='class' />{' '}
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
							focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {defaultLanguage === 'en' ? profileData?.class_name_en : profileData?.class_name_bn}
              </div>
            </div>
            <div className=' xl:w-full flex space-x-5'>
              {hasShift?.data?.has_shift === 'yes' && (
                <div className=' xl:w-full flex flex-col'>
                  <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                    <FormattedMessage id='shift' />{' '}
                  </div>
                  <div
                    className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
									focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
                  >
                    {defaultLanguage === 'en' ? profileData?.shift_name_en : profileData?.shift_name_bn}
                  </div>
                </div>
              )}
              <div className=' xl:w-full flex flex-col'>
                <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                  <FormattedMessage id='section' />
                </div>
                <div
                  className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
                >
                  {defaultLanguage === 'en' ? profileData?.section_name_en : profileData?.section_name_bn}
                </div>
              </div>
            </div>
            {/* <!-- Gender --> */}
            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='gender' />{' '}
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
							focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {/* {profileData && <FormattedMessage id={profileData?.gender} />} */}
                {/* {profileData?.gender} */}
                {profileData && !Array.isArray(profileData) && <>{<FormattedMessage id={profileData?.gender} />}</>}
              </div>
            </div>
            {/* <!-- student Date of Birth --> */}
            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='dateOfBirth' />{' '}
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
							focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {profileData && !Array.isArray(profileData) && (
                  <>{defaultLanguage === 'en' ? profileData?.birth_date : toBnNumber(profileData?.birth_date)}</>
                )}
              </div>
            </div>
            {/* <!-- Birth Registration Number --> */}
            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='birthRegistrationNumber' />
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
							focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {profileData && !Array.isArray(profileData) && (
                  <>
                    {profileData?.birth_certificate_num === null ? (
                      'No Number'
                    ) : (
                      <>
                        {defaultLanguage === 'en'
                          ? profileData?.birth_certificate_num
                          : toBnNumber(profileData?.birth_certificate_num)}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* <!-- student Age --> */}
            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='age' />{' '}
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
							focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {profileData && !Array.isArray(profileData) && (
                  <>{defaultLanguage === 'en' ? profileData?.age : toBnNumber(profileData?.age)}</>
                )}
              </div>
            </div>

            {/* <!-- button  --> */}
            <div className='flex space-x-5 text-center w-full'>
              <button
                // onClick={handelModal}
                // onClick={handelModal}
                onClick={() => navigate(-1)}
                type='button'
                className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded  border border-green-500 hover:border-green-500 hover:text-white text-black hover_gradient '
              >
                <span className=''>
                  <FormattedMessage id='back' />
                </span>
              </button>
              <button
                onClick={() => goToEditPage(profileData)}
                type='button'
                className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
              >
                <span className=''>
                  <FormattedMessage id='editProfile' />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.studentProfile?.data,
    studentList: state.students,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    studentProfile: (dataInfo) => dispatch(getStudentProfileAction(dataInfo)),
    // deleteStudent: (studentId) => dispatch(studentDeleteAction(studentId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentProfilePage);
