export const getLastnYear = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  let years = [];

  for (let year = startYear; year <= currentYear; year++) {
    // years.push(year);
    years = [year, ...years];
  }

  return years;
};

export const toBnNumber = (enNum) => {
  const nums = { 0: '০', 1: '১', 2: '২', 3: '৩', 4: '৪', 5: '৫', 6: '৬', 7: '৭', 8: '৮', 9: '৯' };
  return enNum.toString().replace(/[0123456789]/g, (m) => nums[m]);
};

const daysInMonth = function (m, y) {
  switch (m) {
    case 1:
      return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
};

export const isValidDate = function (d, m, y) {
  m = parseInt(m, 10) - 1;
  return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y);
};

export const getErrors = function (error) {
  let errorArray = [];

  for (const key in error) {
    errorArray.push({ name: key, value: error[key][0] });
  }

  return errorArray;
};

export function dateRange(type) {
  const year = new Date().getFullYear();
  if (type === 'min') {
    return `${year}-01-01`;
  } else {
    return `${year}-12-31`;
  }
}
