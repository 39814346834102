import React, { useState } from 'react';
import { faXmark, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { toast } from 'react-toastify';

const TabletDeleteConfirmation = ({
  title = 'set title',
  data,
  onCancel,
  afterDeleteGetData,
  institutionId,
  defaultLanguage,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    // console.log(data);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}stock/tablet/single/delete`, {
        params: {
          institution_id: institutionId,
          id: data?.id,
        },
      })
      .then((resp) => {
        onCancel();
        // console.log(resp.data);
        afterDeleteGetData(data);
        setLoading(false);
        toast.success('Tablet deleted successfully.');
      })
      .catch((error) => {
        setLoading(false);
        onCancel();

        if (error?.response?.data?.error?.used_tablet?.length !== 0) {
          // console.log(error?.response?.data?.error?.used_tablet[0]);
          toast.error(error?.response?.data?.error?.used_tablet[0]);
          return;
        }

        toast.error('Sorry, something went wrong, please try again later.');
      });
  };

  return (
    <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
      <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
        <div className='flex justify-between'>
          <h1 className=' text-black font-semibold text-xl capitalize'>{title}</h1>
          <span className=' cursor-pointer hover:text-green-600'>
            <FontAwesomeIcon icon={faXmark} onClick={onCancel} />
          </span>
        </div>
        {/* <!-- horizontal line  --> */}
        <div className=' my-2 border border-gray-300 '></div>
        {/* <!-- update form start  --> */}
        <div className='w-full pt-5 pb-5'>
          {/* <!-- login forms  --> */}
          <div className='flex justify-center  flex-col space-y-5   px-4'>
            {/* <!-- delete message  --> */}
            <div className='text-center py-10'>
              <h1 className='text-black font-semibold text-base md:text-xl'>
                {defaultLanguage === 'bn' ? (
                  <>
                    আপনি কি <span className='text-red-700 uppercase'>মুছে </span> ফেলতে চান
                  </>
                ) : (
                  <>
                    Are ou sure you wan to <span className='text-red-700 uppercase'>delete </span>
                  </>
                )}
                , <span className='font-bold capitalize'>{data?.name}</span>?
              </h1>
            </div>

            {/* <!-- button  --> */}
            <div className=' text-center w-full flex  space-x-5'>
              <button
                onClick={onCancel}
                type='button'
                className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
              >
                <span className=''>
                  <FormattedMessage id='cancel' />
                </span>
              </button>

              <button
                onClick={onSubmit}
                disabled={loading ? 'disabled' : ''}
                type='button'
                className='disabled:opacity-50 w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
              >
                <span className=''>
                  {loading && <FontAwesomeIcon icon={faCircleNotch} className='fontAwesomeLoading' />}{' '}
                  <FormattedMessage id='delete' />
                </span>
              </button>
            </div>

            {/* <!-- submit success-fully  --> */}
            {/* {sectionLists && sectionLists.loading && <LoadingSpinner/>} */}

            {/* {sectionLists && (
              <div className=' flex flex-col text-center justify-center'>
                <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                  {sectionLists.message}
                </h1>
              </div>
            )} */}
            {/* <div className=' flex flex-col text-center justify-center'>
								<h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
									message
								</h1>
							</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabletDeleteConfirmation;
