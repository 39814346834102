import React from 'react';

const RadioInput = ({ name, value, id, label, type, onChange }) => {
  const styleCss = {
    display: 'grid',
    gridTemplateColumns: '1em auto',
    gridGap: '0px 20px',
    accentColor: 'green',
  };
  return (
    <div className=' capitalize my-3 cursor-pointer hover:text-[#83C55E]' style={styleCss}>
      <input id={id} type={type} name={name} value={value} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
export default RadioInput;
