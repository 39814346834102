import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';

import React, { useContext, useEffect, useState } from 'react';
import logo from '../assets/image/logo/logo.png';
import DropDownMenu from './DropDownMenu';
import DropdownItem from './DropdownItem';
import SubDropdownMenu from './SubDropdownMenu';
import LogInButton from './LogInButton';
import UserProfileLogo from './UserProfileLogo';
import { connect } from 'react-redux';
import LanguageButton from './LanguageButton';

import { languageChangeAction } from '../redux';
import UseAuth from '../routes/UseAuth';
import StoreContext from '../context/StoreContext';
import { FormattedMessage } from 'react-intl';
const Header = ({ auth, setIsModalOpen, isModalOpen, language }) => {
  const userAuth = UseAuth();
  const { isLoggedIn } = useContext(StoreContext);

  const [isClassMenuOpen, setIsClassMenuOpen] = useState(false);
  const [isTeacherMenuOpen, setIsTeacherMenuOpen] = useState(false);
  const [isStudentMenuOpen, setIsStudentMenuOpen] = useState(false);
  const [click, setClick] = useState(false);
  const handelClick = (e) => {
    e.preventDefault();
    setClick(!click);
  };
  // hover dropdown menu
  const [classMenuIsOpen, setClassMenuIsOpen] = useState(false);
  const [nutritionMenuIsOpen, setNutritionMenuIsOpen] = useState(false);
  const [reportMenuIsOpen, setReportMenuIsOpen] = useState(false);
  // sub dropdown menu is open
  const [isEng, setIsEng] = useState('');

  return (
    <React.Fragment>
      <header className='mx-auto bg-white shadow relative'>
        <div className='container mx-auto px-4 py-2 md:py-0'>
          <div className='flex flex-wrap w-full items-center'>
            {/* NOTE: logo */}
            <div className='mr-4 self-center' style={{ marginBottom: '2px' }}>
              <Link to='/'>
                <img src={logo} alt='logo' width='75' />
              </Link>
            </div>

            {/* profile login  */}
            <div className=' ml-auto flex items-center md:order-2'>
              {/* <!-- TODO: profile/login image  --> */}
              <React.Fragment>
                {/* { auth?.isLoggedIn ? <UserProfileLogo /> : <LogInButton />} */}
                {isLoggedIn ? <UserProfileLogo /> : <LogInButton />}
                {/* {userAuth ? 't' : 'f'} */}
                {/* {loginCheck ? 't' : 'f'} */}
                {/* {console.log(isLoggedIn ? 't' : 'f')} */}
              </React.Fragment>
              {/* <!-- NOTE: language enable/disable button  --> */}

              <LanguageButton lang={window.localStorage.getItem('isEng') || true} />

              <div className='md:hidden ' onClick={handelClick}>
                <FontAwesomeIcon icon={faBars} className='cursor-pointer hover:text-green-600' />
              </div>
            </div>

            {/* <!-- NOTE: header menu  --> */}
            <div
              className={`md:order-1 w-full md:w-auto flex-col md:flex-row md:inline-flex left-auto md:items-start lg:items-center ${
                click ? '' : 'hidden'
              }`}
            >
              {/* // <!-- NOTE: header menu  --> */}
              <ul className='flex md:flex-row flex-col space-y-3 md:space-y-0 md:space-x-3 lg:space-x-5 md:mr-3 lg:mr-4 lg:py-0 py-3'>
                {/* <!--NOTE: menu list (home)  --> */}
                <li className=''>
                  <NavLink
                    to='/'
                    className={(navData) =>
                      navData.isActive
                        ? 'border-b-2 border-green-600 text-green-600  menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600'
                        : 'menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600 hover:text-green-600'
                    }
                  >
                    {/* {language && language ? 'home' : 'হোম'} */}
                    <FormattedMessage id='nav-home' />
                  </NavLink>
                </li>

                {auth && auth.isLoggedIn !== null && auth.isLoggedIn ? (
                  <React.Fragment>
                    {/* <!--NOTE:  menu list (classroom) --> */}
                    <li
                      className='relative'
                      onMouseOver={() => {
                        setClassMenuIsOpen(true);
                      }}
                      onMouseLeave={() => {
                        setClassMenuIsOpen(false);
                      }}
                    >
                      <div
                        className={`menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600 hover:text-green-600 ${
                          click ? 'clicked' : 'not-clicked'
                        }`}
                      >
                        {/* {language && language ? 'School' : 'বিদ্যালয়'} */}
                        <FormattedMessage id='nav-school' />
                        <span className='relative top-[2px] left-1'>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </div>
                      {/* dropdown menu for (classroom)  */}
                      <div
                        className={`absolute left-0 bg-white shadow-md border border-green-200 text-white flex flex-col z-20 ${
                          classMenuIsOpen ? '' : ' hidden'
                        }`}
                      >
                        <ul className='flex flex-col space-y-1 bg-white w-60 md:w-36 lg:w-60'>
                          <li className='relative' onClick={() => setIsClassMenuOpen(!isClassMenuOpen)}>
                            <div className='sub_menu'>
                              <FormattedMessage id='classes' />
                              <span className='relative top-[2px] left-1 '>
                                <FontAwesomeIcon icon={faAngleDown} />
                              </span>
                            </div>
                            {isClassMenuOpen && (
                              <ul>
                                <li>
                                  <NavLink
                                    onClick={() => setClassMenuIsOpen(false)}
                                    className='sub_menu pl-6'
                                    to='/classes'
                                  >
                                    <FormattedMessage id='classesList' />
                                  </NavLink>
                                </li>
                                {/* <li><NavLink className='sub_menu pl-6' to="/">class add</NavLink></li> */}
                              </ul>
                            )}
                          </li>
                          <li className='relative' onClick={() => setIsTeacherMenuOpen(!isTeacherMenuOpen)}>
                            <div className='sub_menu'>
                              <FormattedMessage id='teachers' />
                              <span className='relative top-[2px] left-1 '>
                                <FontAwesomeIcon icon={faAngleDown} />
                              </span>
                            </div>
                            {isTeacherMenuOpen && (
                              <ul>
                                <li>
                                  <NavLink
                                    onClick={() => setClassMenuIsOpen(false)}
                                    className='sub_menu pl-6'
                                    to='/teacher-list'
                                  >
                                    <FormattedMessage id='teacherList' />
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    onClick={() => setClassMenuIsOpen(false)}
                                    className='sub_menu pl-6'
                                    to='/teacher-list/add-teacher'
                                  >
                                    <FormattedMessage id='add-teacher' />
                                  </NavLink>
                                </li>
                              </ul>
                            )}
                          </li>
                          <li className='relative' onClick={() => setIsStudentMenuOpen(!isStudentMenuOpen)}>
                            <div className='sub_menu'>
                              <FormattedMessage id='students' />
                              <span className='relative top-[2px] left-1 '>
                                <FontAwesomeIcon icon={faAngleDown} />
                              </span>
                            </div>
                            {isStudentMenuOpen && (
                              <ul>
                                <li>
                                  <NavLink
                                    onClick={() => setClassMenuIsOpen(false)}
                                    className='sub_menu pl-6'
                                    to='/student-list'
                                  >
                                    <FormattedMessage id='student-list' />
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    onClick={() => setClassMenuIsOpen(false)}
                                    className='sub_menu pl-6'
                                    to='/student-add'
                                  >
                                    <FormattedMessage id='add-student' />
                                  </NavLink>
                                </li>
                              </ul>
                            )}
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <!--NOTE:  menu list (nutrition's programs) --> */}
                    <li
                      className='relative'
                      onMouseOver={() => {
                        setNutritionMenuIsOpen(true);
                      }}
                      onMouseLeave={() => {
                        setNutritionMenuIsOpen(false);
                      }}
                    >
                      <div className='menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600 '>
                        {/* {language && language ? "nutrition's programs" : 'নিউট্রিশন প্রোগ্রাম'} */}
                        <FormattedMessage id='nav-nprograms' />
                        <span className='relative top-[2px] left-1 '>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </div>
                      {/* dropdown menu for (	nutrition's programs)  */}
                      <div
                        className={`absolute left-0 bg-white shadow-md border border-green-200 text-white flex flex-col z-20 ${
                          nutritionMenuIsOpen ? '' : ' hidden'
                        }`}
                      >
                        <DropDownMenu>
                          <DropdownItem
                            link={`nutrition-education`}
                            itemName={<FormattedMessage id='nutrition-education' />}
                          />
                          <DropdownItem link={`wifa`} itemName={<FormattedMessage id='wifa' />} />
                          <DropdownItem link={`deworming`} itemName={<FormattedMessage id='deworming' />} />
                          <DropdownItem link={`bmi`} itemName={<FormattedMessage id='bmi' />} />
                          <DropdownItem link={`referral-list`} itemName={<FormattedMessage id='referral' />} />

                          <DropdownItem link={`tablet-stock`} itemName={<FormattedMessage id='tablet-stock' />} />
                        </DropDownMenu>
                      </div>
                    </li>
                    {/* <!--NOTE:  menu list (reports) --> */}
                    <li
                      className='relative'
                      onMouseOver={() => {
                        setReportMenuIsOpen(true);
                      }}
                      onMouseLeave={() => {
                        setReportMenuIsOpen(false);
                      }}
                    >
                      <div className='menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600'>
                        {/* {language && language ? 'reports' : 'রিপোর্ট '} */}
                        <FormattedMessage id='nav-reports' />
                        <span className='relative top-[2px] left-1 '>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </div>
                      {/* dropdown menu for (	reports)  */}
                      <div
                        className={`absolute left-0 bg-white shadow-md border border-green-200 text-white flex flex-col z-20 ${
                          reportMenuIsOpen ? '' : ' hidden'
                        }`}
                      >
                        <DropDownMenu>
                          <DropdownItem itemName={<FormattedMessage id='weeklyReport' />} link={`weekly`} />
                          <DropdownItem itemName={<FormattedMessage id='monthlyReport' />} link={`monthly`} />
                          <DropdownItem itemName={<FormattedMessage id='annualReport' />} link={`annual`} />
                          <DropdownItem itemName={<FormattedMessage id='referral-report' />} link={`referral-report`} />
                        </DropDownMenu>
                      </div>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <!--NOTE:  menu list (classroom) --> */}
                    <li
                      className='relative'
                      onMouseOver={() => {
                        setClassMenuIsOpen(true);
                      }}
                      onMouseLeave={() => {
                        setClassMenuIsOpen(false);
                      }}
                    >
                      <div
                        className={`menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600 ${
                          click ? 'clicked' : 'not-clicked'
                        }`}
                      >
                        {/* {language && language ? 'School' : 'বিদ্যালয়'} */}
                        <FormattedMessage id='nav-school' />
                        <span className='relative top-[2px] left-1'>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </div>
                      {/* dropdown menu for (classroom)  */}
                      <div
                        className={`absolute left-0 bg-white shadow-md border border-green-200 text-white flex flex-col z-20 ${
                          classMenuIsOpen ? '' : ' hidden'
                        }`}
                      >
                        <DropDownMenu>
                          <DropdownItem itemName={<FormattedMessage id='classes' />} link='/'>
                            <li className='relative'>
                              <button
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                type='button'
                                className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-1 hover:border-green-600 hover:bg-green-50'
                              >
                                <span className=' ml-2'>
                                  <FormattedMessage id='classesList' />
                                </span>
                              </button>
                            </li>
                          </DropdownItem>
                          <DropdownItem itemName={<FormattedMessage id='teachers' />} link='/'>
                            <li className='relative'>
                              <button
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                type='button'
                                className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-1 hover:border-green-600 hover:bg-green-50'
                              >
                                <span className=' ml-2'>
                                  <FormattedMessage id='teacherList' />
                                </span>
                              </button>
                            </li>
                            <li className='relative'>
                              <button
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                type='button'
                                className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-1 hover:border-green-600 hover:bg-green-50'
                              >
                                <span className=' ml-2'>
                                  <FormattedMessage id='add-teacher' />
                                </span>
                              </button>
                            </li>
                          </DropdownItem>
                          <DropdownItem itemName={<FormattedMessage id='students' />} link='/'>
                            <li className='relative'>
                              <button
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                type='button'
                                className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-1 hover:border-green-600 hover:bg-green-50'
                              >
                                <span className=' ml-2'>
                                  <FormattedMessage id='student-list' />
                                </span>
                              </button>
                            </li>
                            <li className='relative'>
                              <button
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                type='button'
                                className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-1 hover:border-green-600 hover:bg-green-50'
                              >
                                <span className=' ml-2'>
                                  <FormattedMessage id='add-student' />
                                </span>
                              </button>
                            </li>
                          </DropdownItem>
                        </DropDownMenu>
                      </div>
                    </li>
                    {/* <!--NOTE:  menu list (nutrition's programs) --> */}
                    <li
                      className='relative'
                      onMouseOver={() => {
                        setNutritionMenuIsOpen(true);
                      }}
                      onMouseLeave={() => {
                        setNutritionMenuIsOpen(false);
                      }}
                    >
                      <div className='menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600'>
                        {/* {language && language ? "nutrition's programs" : 'নিউট্রিশন প্রোগ্রাম'} */}
                        <FormattedMessage id='nav-nprograms' />
                        <span className='relative top-[2px] left-1 '>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </div>
                      {/* dropdown menu for (	nutrition's programs)  */}
                      <div
                        className={`absolute left-0 bg-white shadow-md border border-green-200 text-white flex flex-col z-20 ${
                          nutritionMenuIsOpen ? '' : ' hidden'
                        }`}
                      >
                        <ul className='space-y-1 bg-white w-60 md:w-36 lg:w-60'>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='nutrition-education' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='wifa' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='deworming' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='bmi' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='referral' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='tablet-stock' />
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <!--NOTE:  menu list (reports) --> */}
                    <li
                      className='relative'
                      onMouseOver={() => {
                        setReportMenuIsOpen(true);
                      }}
                      onMouseLeave={() => {
                        setReportMenuIsOpen(false);
                      }}
                    >
                      <div className='menu__list py-2 lg:py-[30px]  hover:border-b-2 hover:border-green-600'>
                        {/* {language && language ? 'reports' : 'রিপোর্ট '} */}
                        <FormattedMessage id='nav-reports' />
                        <span className='relative top-[2px] left-1 '>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </div>
                      {/* dropdown menu for (	reports)  */}
                      <div
                        className={`absolute left-0 bg-white shadow-md border border-green-200 text-white flex flex-col z-20 ${
                          reportMenuIsOpen ? '' : ' hidden'
                        }`}
                      >
                        <ul className='space-y-1 bg-white w-60 md:w-36 lg:w-60'>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='weeklyReport' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='monthlyReport' />
                              </span>
                            </button>
                          </li>

                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='annualReport' />
                              </span>
                            </button>
                          </li>
                          <li className='relative'>
                            <button
                              onClick={() => setIsModalOpen(!isModalOpen)}
                              type='button'
                              className='menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
                            >
                              <span>
                                <FormattedMessage id='referral-report' />
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.login,
    language: state.lang?.isEng,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
