import {
  INSTITUTION_CHECK_REQUEST,
  INSTITUTION_CHECK_SUCCESS,
  INSTITUTION_CHECK_FAILURE,
} from '../constance/checkInstitution.constant';

const checkInstituteState = {
  loading: false,
  institution: [],
  goToNext: false,
  error: '',
};

const institutionCheckReducer = (state = checkInstituteState, action) => {
  switch (action.type) {
    case INSTITUTION_CHECK_REQUEST:
      return {
        ...state,
        institution: action.payload,
        loading: true,
        goToNext: false,
        error: '',
      };
    case INSTITUTION_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        institution: action.payload,
        goToNext: true,
        error: '',
      };
    case INSTITUTION_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        institution: action.payload,
        goToNext: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default institutionCheckReducer;
