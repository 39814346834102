import React from 'react';

const InputField = ({
  value,
  label,
  required,
  name,
  placeholder,
  type,
  onChange,
  readOnly,
  className = null,
  id,
  pattern,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className={` xl:w-full flex flex-col ${className && className}`}>
        {label && (
          <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor={id}>
            {label}
            {required && <span className=' text-red-700'>*</span>}
          </label>
        )}
        <input
          className={`  form-select   block  w-full  min-w-[75px] px-3 py-1.5  text-base  font-normal border border-solid border-gray-300 rounded  transition ease-in-out m-0 text-gray-700 focus:text-gray-700 focus:border-green-600  focus:outline-none
                ${readOnly ? 'bg-gray-300 focus:bg-gray-300' : ' bg-white focus:bg-white'}`}
          type={type}
          name={name}
          value={value}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={readOnly ? true : false}
          pattern={pattern}
          {...rest}
        />
      </div>
    </React.Fragment>
  );
};

export default InputField;
