import React from 'react';

const MeasureMentField = ({ type, name, readOnly, onChange, placeholder, unit = 'unit' }) => {
  return (
    <React.Fragment>
      <div className='relative'>
        <input
          onChange={onChange}
          placeholder={placeholder}
          className={`form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
									focus:text-gray-700 focus:border-green-600 focus:outline-none 
                  ${readOnly ? 'bg-gray-300 focus:bg-gray-300' : ' bg-white focus:bg-white'}`}
          type={type}
          name={name}
          id='instituteId'
          readOnly={readOnly ? true : false}
        />
        <span className='ft_inc_kg absolute top-0  right-0 bg-gray-200 text-black'>{unit}</span>
      </div>
    </React.Fragment>
  );
};

export default MeasureMentField;
