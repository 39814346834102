import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../Components/InputField';
import { connect } from 'react-redux';
import { changePasswordAction } from '../../redux';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { FormattedMessage, useIntl } from 'react-intl';

const ProfilePopUp = ({ onClick, login, changePass, changePassword }) => {
  const intl = useIntl();
  console.log('🚀 ~ file: ProfilePopUp.jsx ~ line 10 ~ ProfilePopUp ~ changePassword', changePassword?.data);
  const initialValues = {
    institution_id: login?.data?.id,
    old_password: '',
    password: '',
    password_confirmation: '',
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForms(formValues));
    setIsSubmit(true);
  };
  const validateForms = (values) => {
    const error = {};
    if (!values.old_password) {
      error.old_password = 'Old Password is Required';
    }
    if (!values.password) {
      error.password = 'New Password is Required';
    }
    if (!values.password_confirmation) {
      error.password_confirmation = 'Confirm Password is Required';
    }
    return error;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      changePass(formValues);
      setIsSubmitSuccess(true);
    }
  }, [changePass, formErrors]);

  useEffect(() => {
    setIsSubmitSuccess(false);
  }, [onClick]);

  return (
    <React.Fragment>
      <div
        className={` p-4 top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 fixed z-50 `}
      >
        {/* heading  */}

        <div className=' lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
          <div className=' w-full flex justify-between border-b px-4 py-3 border-gray-400'>
            <h1 className=' text-black font-semibold text-xl capitalize'>
              <FormattedMessage id='changePassword' />
            </h1>
            <button className=' text-lg' onClick={onClick}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          {/* input forms  */}
          <form onSubmit={handelSubmit}>
            <div className=' px-5 py-5 flex flex-col space-y-4'>
              <div className=''>
                <InputField
                  id='old_password'
                  type='password'
                  value={formValues.old_password}
                  name='old_password'
                  label={<FormattedMessage id='oldPassword' />}
                  placeholder={intl.formatMessage({ id: 'enterOldPassword' })}
                  onChange={handelChange}
                  required
                />
                <p>
                  <small className='text-red-700'>{formErrors.old_password}</small>
                </p>
              </div>
              <div className=''>
                <InputField
                  id='password'
                  type='password'
                  value={formValues.password}
                  name='password'
                  label={<FormattedMessage id='newPassword' />}
                  placeholder={intl.formatMessage({ id: 'enterNewPassword' })}
                  onChange={handelChange}
                  required
                />
                <p>
                  <small className='text-red-700'>{formErrors.password}</small>
                </p>
              </div>
              <div className=''>
                <InputField
                  id='password_confirmation'
                  type='password'
                  value={formValues.password_confirmation}
                  name='password_confirmation'
                  label={<FormattedMessage id='confirmNewPassword' />}
                  placeholder={intl.formatMessage({ id: 'confirmNewPassword' })}
                  onChange={handelChange}
                  required
                />
                <p>
                  <small className='text-red-700'>{formErrors.password_confirmation}</small>
                </p>
              </div>
            </div>
            {/* <!-- buttons  --> */}
            <div className='text-center  my-6 flex space-x-4 justify-center w-3/4 mx-auto'>
              <button
                onClick={onClick}
                type='button'
                className=' w-full rounded text-black py-2 px-6 capitalize text-[18px] border-2 border-green-500'
              >
                <span>
                  <FormattedMessage id='cancel' />
                </span>
              </button>
              <button type='submit' className=' w-full rounded text-white py-2 px-6 capitalize text-[18px] bg-gradient'>
                <span>
                  <FormattedMessage id='update' />
                </span>
              </button>
            </div>

            <React.Fragment>
              {changePassword && changePassword?.loading ? (
                <div className=' mx-auto'>
                  <LoadingSpinner />
                </div>
              ) : changePassword && changePassword.error ? (
                <div className=' mx-auto'>
                  <h1>{changePassword.error}</h1>
                </div>
              ) : changePassword && changePassword?.data.length === 0 ? null : (
                <div className=' flex justify-center'>
                  <h1 className=' pb-3 text-sm lg:text-xl xl:text-2xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {changePassword && changePassword?.data?.message}
                  </h1>
                </div>
              )}
            </React.Fragment>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.login?.currentUser,
    changePassword: state.changePass,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePass: (info) => dispatch(changePasswordAction(info)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePopUp);
