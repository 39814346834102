import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSectionTeacherAction } from '../../../redux';
import ImageUpload from '../../../Components/imageUpload/ImageUpload';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import InputField from '../../../Components/InputField';
import { shiftNameConverter, validateNid, validNameField } from '../../../redux/service/service';
import StoreContext from '../../../context/StoreContext';
import { FormattedMessage, useIntl } from 'react-intl';

const AddSectionTeacherPage = ({ teacherData, addTeacher, hasShift }) => {
  const { getClassList } = useContext(StoreContext);
  const intl = useIntl();
  const { error = null, message } = teacherData;
  // console.log('🚀 ~ file: AddSectionTeacherPage.jsx ~ line 13 ~ AddSectionTeacherPage ~ message', message);

  const navigate = useNavigate();
  const navigationData = useLocation();
  const { class_name_en, shift_name_en, section_name_en, grade, shift_id, id } = navigationData.state;

  // all form data
  const [teacherInstitutionId] = useState(() => window.localStorage.getItem('institution'));
  const [avatar, setAvatar] = useState(null);
  const [toViewAvatar, setToViewAvatar] = useState(null);
  const [teacherNid, setTeacherNid] = useState(null);

  // teacher name
  // name validation
  const [teacherName, setTeacherName] = useState(null);
  const [nameError, setNameError] = useState(false);
  const handelNameField = (e) => {
    setTeacherName(e.target.value);
  };

  // handel NID
  const [nidNum, setNidNum] = useState(null);
  const [errorNid, setErrorNid] = useState(false);
  const handelNid = (e) => {
    setNidNum(e.target.value);
  };

  const initialFormValues = {
    institution_id: teacherInstitutionId,
    grade: grade,
    section_id: id,
    shift_id: shift_id,
    teacherInd: '',
    teacherName: '',
  };
  const [formValues, setFormValues] = useState(initialFormValues);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // input file avatar for teacher
  const inputFile = (event) => {
    setToViewAvatar(URL.createObjectURL(event.target.files[0]));
    setAvatar(event.target.files[0]);
  };

  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [postData, setSetPostData] = useState({});

  const handelSubmitTeacherInfo = (e) => {
    const data = {
      institution_id: teacherInstitutionId,
      grade: grade,
      section_id: id,
      shift_id: shift_id,
      teacherInd: nidNum,
      teacherName: teacherName,
    };
    e.preventDefault();
    setNameError(validNameField(teacherName));
    setErrorNid(validateNid(nidNum.toString()));
    setSetPostData(data);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (isSubmit && errorNid === false && nameError === false) {
      addTeacher(postData, avatar, getClassList);
    }
  }, [addTeacher, avatar, errorNid, isSubmit, nameError, postData]);

  // error validate from server

  const [nidError, setNidError] = useState(null);
  const [photoError, setPhotoError] = useState(null);
  useEffect(() => {
    setNidError(teacherData?.error?.nid);
  }, [teacherData?.error?.nid]);
  useEffect(() => {
    setPhotoError(teacherData?.error?.photo);
  }, [teacherData?.error?.photo]);

  const msgNid = nidError && (
    <p>
      <small className='text-red-700'>{nidError}</small>
    </p>
  );
  const msgPhoto = photoError && (
    <p>
      <small className='text-red-700'>{photoError}</small>
    </p>
  );

  useEffect(() => {
    if (message === 'Teacher Save Successfully') {
      setTimeout(function () {
        if (hasShift?.data?.has_shift === 'yes') {
          navigate(`/classes/shift-list/${shiftNameConverter(shift_id)}/section-${section_name_en}-student-list`, {
            state: navigationData.state,
          });
        } else {
          navigate(`/classes/section-list/section-${section_name_en}-student-list`, { state: navigationData.state });
        }
      }, 2000);
    }
  });

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* <!-- teacher added  forms  --> */}
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col items-center w-full px-4 lg:w-1/2 mx-auto'>
            {/* <!-- title  --> */}
            <div className='border border-gray-300 w-full  py-4 rounded'>
              <div className='border-b border-gray-300'>
                <h1 className=' text-black font-semibold text-xl capitalize pb-4 px-4'>
                  <FormattedMessage id='add-teacher' />
                </h1>
              </div>
              {/* <!-- teacher added form  --> */}
              <div className='w-full pt-5 pb-5'>
                {/* <!--  forms  --> */}
                <form action='' onSubmit={handelSubmitTeacherInfo}>
                  <div className='flex justify-center  flex-col  space-y-5  px-4'>
                    {/* image upload  */}
                    <div className=' text-center '>
                      <ImageUpload avatar={toViewAvatar} inputFile={inputFile} />
                      {msgPhoto}
                    </div>
                    {/* <!-- teacher name --> */}
                    <div className={` xl:w-full flex flex-col`}>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='teacher-name'>
                        <FormattedMessage id='teacherName' />
                        <span className=' text-red-700'>*</span>
                      </label>

                      <input
                        className={`form-select   block  w-full  min-w-[75px] px-3 py-1.5  text-base  font-normal border border-solid border-gray-300 rounded  transition ease-in-out m-0 text-gray-700 focus:text-gray-700 focus:border-green-600
											focus:outline-none bg-white`}
                        id='teacher-name'
                        name='teacherName'
                        placeholder={intl.formatMessage({ id: 'teacherNamePlaceholder' })}
                        value={teacherName}
                        onChange={handelNameField}
                      />
                      {nameError && (
                        <p>
                          <small className='text-red-700'>Name is required Only A-Za-z</small>
                        </p>
                      )}
                    </div>
                    {/* <!-- teacher ID /(username)--> */}
                    <div className={` xl:w-full flex flex-col`}>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='teacher-name'>
                        <FormattedMessage id='teacherNid' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <input
                        type='number'
                        className={`form-select   block  w-full  min-w-[75px] px-3 py-1.5  text-base  font-normal border border-solid border-gray-300 rounded  transition ease-in-out m-0 text-gray-700 focus:text-gray-700 focus:border-green-600
											focus:outline-none bg-white`}
                        id='teacher-name'
                        name='teacherInd'
                        placeholder={intl.formatMessage({ id: 'teacherNid' })}
                        value={nidNum}
                        onChange={handelNid}
                      />
                      {msgNid}
                      {errorNid && (
                        <p>
                          <small className='text-red-700'>Enter NID Number 13 to 17 digit.</small>
                        </p>
                      )}
                    </div>
                    {/* <!-- select institute option  --> */}
                    <InputField label={<FormattedMessage id='class' />} readOnly={true} value={class_name_en} />
                    {hasShift?.data?.has_shift === 'yes' && (
                      <InputField label={<FormattedMessage id='shift' />} readOnly={true} value={shift_name_en} />
                    )}

                    <InputField label={<FormattedMessage id='section' />} readOnly={true} value={section_name_en} />
                    {/* <!-- button  --> */}
                    <div className=' text-center w-full flex  space-x-5'>
                      <button
                        onClick={() => navigate(-1)}
                        type='button'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
                      hover:border-transparent hover_gradient '
                      >
                        <span className=''>
                          {' '}
                          <FormattedMessage id='back' />{' '}
                        </span>
                      </button>

                      <button
                        type='submit'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                      >
                        <span className=''>
                          <FormattedMessage id='submit' />
                        </span>
                      </button>
                    </div>

                    {/* <!-- submit success-fully  --> */}
                    {teacherData.message && (
                      <div className=' flex justify-center'>
                        <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                          {teacherData.message}
                        </h1>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    teacherData: state.sectionTeachers,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTeacher: (teacherInfo, avatar, getClassList) =>
      dispatch(createSectionTeacherAction(teacherInfo, avatar, getClassList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSectionTeacherPage);
