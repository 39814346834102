import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { getReferralStudentDataAction } from '../../../../../redux';
import { useLocation } from 'react-router-dom';

import fontBold from '../../../../../assets/font/static/DancingScript-Bold.ttf';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';

const PdfDocument = ({ getReferralStudent, referralStudent, school }) => {
  const studentData = useLocation();
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  // Register font
  Font.register({
    family: 'Dancing',
    fonts: [
      {
        src: `${fontBold}`,
      },
      {
        src: `${fontBold}`,
        fontWeight: 'bold',
      },
    ],
  });
  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      color: '#000',
      padding: 15,
    },
    headingText: {
      width: 120,
      fontSize: 12,
      textTransform: 'capitalize',
      flexShrink: 1,
    },
    text: {
      fontSize: 12,
      textTransform: 'capitalize',
    },
    data: {
      flex: 1,
      fontSize: 12,
    },
    document: {},
    section: {
      color: '#000',
      textAlign: 'center',
      margin: 30,
      borderWidth: 2,
      borderColor: '#000',
      borderStyle: 'solid',
      padding: 10,
    },
    dataWrapper: {
      width: 450,
      padding: 5,
      color: '#000',
      marginLeft: 30,
      marginRight: 30,
      marginTop: 10,
      marginBottom: 10,
    },
    view: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingVertical: 5,
    },
    viewer: {
      width: '70%',
      height: '100%',
      minHeight: 800,
      margin: 'auto',
    },
    drowline: {
      color: '#000',
      textAlign: 'center',
      margin: 10,
    },
    signature: {
      fontSize: 10,
      letterSpacing: 2,
      textTransform: 'capitalize',
    },
    signatureData: {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 10,
      fontFamily: 'Dancing',
      textTransform: 'capitalize',
    },
    signatureArea: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  });

  useEffect(() => {
    const data = {
      institution_id: institutionId,
      grade: studentData?.state?.grade,
      student_id: studentData?.state?.id,
    };
    if (data.grade !== null && data.grade !== undefined && data.student_id !== null && data.student_id !== undefined) {
      getReferralStudent(data);
    }
  }, [getReferralStudent, institutionId, studentData?.state]);
  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      {referralStudent && referralStudent.data?.loading && <LoadingSpinner />}
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4 '>
        <PDFViewer style={styles.viewer}>
          <Document style={styles.document}>
            <Page size='A4' style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.text}>Referral Student Information</Text>
              </View>
              <View style={styles.dataWrapper}>
                <View style={styles.view}>
                  <Text style={styles.headingText}>school Name</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.institution_name}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Class</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.class_name_en}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Shift Name</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.shift_name_en}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Section Name </Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.section_name_en}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Student Roll</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.roll}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>student name</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.institution_name}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Student Gender</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.name}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Student Age</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.age}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Student Birth Registration Number</Text>
                  <Text style={styles.text}> : </Text>
                  {/* <Text style={styles.data}>{`${referralStudent?.data?.institution_name===null ? ' ': referralStudent?.data?.institution_name}`}</Text> */}
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Teacher Name</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.teacher_name}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Teacher Designation</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.teacher_designation}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Health Facilitator Name</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.health_facilitator_name}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Referral Institution Name</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.referral_institution}`}</Text>
                </View>
                <View style={styles.view}>
                  <Text style={styles.headingText}>Referral Reason</Text>
                  <Text style={styles.text}> : </Text>
                  <Text style={styles.data}>{`${referralStudent?.data?.referral_reason}`}</Text>
                </View>
              </View>
              {/* signature  */}
              <View style={styles.drowline}>
                <Text>************************</Text>
              </View>
              <View style={styles.dataWrapper}>
                <View style={styles.signatureArea}>
                  <View>
                    <Text style={styles.signature}>Signature</Text>
                    <Text style={styles.signatureData}>{`${referralStudent?.data?.teacher_name}`}</Text>
                    <Text style={styles.signatureData}>{`${referralStudent?.data?.teacher_designation}`}</Text>
                  </View>
                  <View>
                    <Text style={styles.signature}>Signature</Text>
                    <Text style={styles.signatureData}>{`${referralStudent?.data?.health_facilitator_name}`}</Text>
                    <Text style={styles.signatureData}>{`Health Facilitator`}</Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>

      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    referralStudent: state.referralStudent,
    school: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReferralStudent: (dataInfo) => dispatch(getReferralStudentDataAction(dataInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfDocument);
