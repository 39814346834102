import {
  BMI_CALCULATION_REQUEST,
  BMI_CALCULATION_SUCCESS,
  BMI_CALCULATION_FAILED,
} from '../constance/programs.constants';

const initialState = {
  loading: false,
  data: [],
  error: '',
  message: '',
};

export const bmiCalculationReducer = (state = initialState, action) => {
  switch (action.type) {
    case BMI_CALCULATION_REQUEST:
      return {
        loading: true,
        error: '',
        message: '',
      };
    case BMI_CALCULATION_SUCCESS:
      console.log('-------------------action.payload-----------------------', action.payload?.data);
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: action.payload?.data.error,
        message: action.payload?.data.message,
      };
    case BMI_CALCULATION_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        message: '',
      };

    default:
      return state;
  }
};
