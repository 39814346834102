import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import LoadingSpinner from '../../Components/LoadingSpinner';
// import { checkInstitution } from '../../redux';
const InstitutionInfo = ({ institutionInfo }) => {
  return (
    <React.Fragment>
      <section className='mx-auto bg-white p-4'>
        <div className='container flex flex-col space-y-10 justify-center mx-auto lg:w-1/3 xl:w-2/4 2xl:w-1/3 p-4'>
          <div className=' bg-gray-200 p-4 rounded'>
            <div className=''>
              {/*Education type */}
              <div className='flex capitalize'>
                <span className=' w-[150px] shrink-0'>
                  <FormattedMessage id='educationType' />
                </span>
                <span className=' font-semibold '>: {institutionInfo?.institution?.data?.education_type}</span>
              </div>
              {/*institution type */}
              <div className='flex capitalize'>
                <span className=' w-[150px] shrink-0'>
                  <FormattedMessage id='institutionType' />
                </span>
                <span className=' font-semibold '>: {institutionInfo?.institution?.data?.institution_type}</span>
              </div>
              {/*institution name */}
              <div className='flex capitalize'>
                <span className=' w-[150px] shrink-0'>
                  <FormattedMessage id='institutionName' />
                </span>
                <span className=' font-semibold '>: {institutionInfo?.institution?.data?.institution_name}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // institution: state.institutionCheck,
  };
};
// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		checkInstitution: () => dispatch(checkInstitution()),
// 	};
// };
export default connect(mapStateToProps, null)(InstitutionInfo);
