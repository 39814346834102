export const getTokenAndId = () => {
  const token = JSON.parse(localStorage.token);
  const institution_id = JSON.parse(localStorage.institution);
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      institution_id: institution_id,
    },
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  return { apiUrl, config };
};

export const getAge = (dateString) => {
  var today = new Date();
  if (dateString === null || dateString === undefined) {
    let age = 'input Birth Date';
    return age.toString();
  } else {
    var birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }
};

export const checkLogInLocalStorage = (tokenDetails) => {
  localStorage.setItem('token', JSON.stringify(tokenDetails));
};

export const validNameField = (dataString) => {
  let letters = /^[A-Za-z ]*$/;
  if (!dataString || !dataString.match(letters)) {
    return true;
  } else if (dataString === '') {
    return true;
  } else if (dataString === ' ') {
    return true;
  } else {
    return false;
  }
};

export const validateNumber = (numberString) => {
  let number = /^\d*[.]?\d*$/;
  if (!numberString) {
    return true;
  } else if (!String(numberString).match(number)) {
    return true;
  } else if (numberString === '') {
    return true;
  } else if (numberString === undefined) {
    return true;
  } else {
    return false;
  }
};

export const validateRollNumber = (numberString) => {
  let number = /^\d*[.]?\d*$/;
  if (!numberString) {
    return true;
  } else if (!String(numberString).match(number)) {
    return true;
  } else if (numberString === '') {
    return true;
  } else if (numberString === '0') {
    return true;
  } else if (numberString === undefined) {
    return true;
  } else {
    return false;
  }
};

export const validateClass = (numberString) => {
  let number = /^\d{1,20}$/;
  if (!String(numberString) || !String(numberString).match(number)) {
    return true;
  } else if (numberString === '') {
    return true;
  } else if (numberString === '0') {
    return true;
  } else if (numberString === undefined) {
    return true;
  } else {
    return false;
  }
};

export const validateGender = (genderStr) => {
  if (!genderStr || genderStr === '0') {
    return true;
  } else if (genderStr === '') {
    return true;
  } else {
    return false;
  }
};

export const validateShift = (shiftStr) => {
  if (!shiftStr || shiftStr === '0') {
    return true;
  } else if (shiftStr === '') {
    return true;
  } else if (shiftStr === undefined) {
    return true;
  } else {
    return false;
  }
};

export const validateSection = (sectionStr) => {
  if (!sectionStr || sectionStr === '0') {
    return true;
  } else if (sectionStr === '') {
    return true;
  } else if (sectionStr === undefined) {
    return true;
  } else {
    return false;
  }
};

export const validateDate = (dateStr) => {
  const error = {};

  if (!dateStr.day || dateStr.day === '0' || dateStr === '') {
    error.day = true;
  }
  if (!dateStr.month || dateStr.month === '0' || dateStr === '') {
    error.month = true;
  }
  if (!dateStr.year || dateStr.year === '0' || dateStr === '') {
    error.year = true;
  }

  return error;
};

export const validateNid = (nid) => {
  let isnum = /^\d+$/.test(nid);
  if (!nid || nid === '0') {
    return true;
  } else if (18 <= nid.trim().length) {
    return true;
  } else if (nid.trim().length <= 9) {
    return true;
  } else if (isnum === false) {
    return true;
  } else {
    return false;
  }
};
// age calculation

export const calculateAge = (dateString) => {
  const { day, month, year } = dateString;
  let age = 0;

  if (!year || year === '0') {
    return age;
  }

  if (!day || day === '0') {
    return age;
  }
  if (!month || month === '0') {
    return age;
  }

  if (day && month && year) {
    var today = new Date();
    var birthDate = new Date(`${month}-${day}-${year}`);
    age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return isNaN(age) ? (age = 0) : age;
  }

  return age;
};

// current date
export const currentDate = () => {
  const someDate = new Date();
  const numberOfDaysToAdd = 0;
  const date = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split('T')[0];
  return defaultValue;
};

// calculate month

export const calculateMonth = (date) => {
  var today = new Date(`${date}`); // yyyy-mm-dd
  var monthName = today.toLocaleString('default', { month: 'long' });
  return monthName;
};

export const calculateMonthNumber = (date) => {
  var today = new Date(`${date}`);
  var monthNumber = today.toLocaleString('default', { month: 'numeric' });
  return monthNumber;
};

// expiration date from current date
export const expireDate = (date) => {
  const inputDate = new Date(`${date}`);
  const today = new Date();
  var total_seconds = (inputDate - today) / 1000;
  var days_difference = Math.floor(total_seconds / (60 * 60 * 24));
  if (days_difference >= 30) {
    return true;
  } else {
    return false;
  }
};

export const getWeeksInMonth = (year, month) => {
  const weeks = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();

  let dayOfWeekCounter = firstDate.getDay();

  for (let date = 1; date <= numDays; date++) {
    if (dayOfWeekCounter === 0 || weeks.length === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(date);
    dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
  }

  return weeks
    .filter((w) => !!w.length)
    .map((w) => ({
      start: w[0],
      end: w[w.length - 1],
      dates: w,
    }));
};

export const shiftNameConverter = (id) => {
  if (id === '1' || id === 1) {
    return 'Morning-shift';
  }
  if (id === 2 || id === '2') {
    return 'day-shift';
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

// get year from current year
export const generateArrayOfYears = () => {
  var min = new Date().getFullYear();
  var max = min + 9;
  var years = [];

  for (var i = min - 1; i <= max; i++) {
    let obj = {};
    obj['value'] = i;
    obj['yearName'] = i;
    years.push(obj);
  }
  return years;
};

// let year = generateArrayOfYears();
