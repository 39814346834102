import { useEffect } from 'react';
import { useState } from 'react';

const StudentRow = ({ student, SelectedStudentId, setSelectedStudentId }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (student.participation_status === 'yes') {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, []);

  const handelChecked = (e) => {
    const { checked, value } = e.currentTarget;
    setSelectedStudentId((prev) => (checked ? [...prev, value] : prev.filter((val) => val !== value)));
    setIsChecked(!isChecked);
  };

  return (
    <tr>
      <td className=' border border-gray-300 p-2'>{student.roll}</td>
      <td className=' border border-gray-300 p-2 capitalize'>{student.name}</td>
      <td className=' border border-gray-300 p-2 capitalize'>{student.gender}</td>
      <td className=' border border-gray-300 p-2'>{student.age}</td>
      <td className=' border border-gray-300 p-2 '>
        <label className='gradient-checkbox-container'>
          <input
            type='checkbox'
            id='student'
            name='student'
            value={student.id}
            checked={isChecked ? 'checked' : ''}
            onChange={(e) => handelChecked(e)}
          />
          <span className='checkmark' />
        </label>
      </td>
    </tr>
  );
};
export default StudentRow;
