import {
  GET_TEACHER_LIST_REQUEST,
  GET_TEACHER_LIST_SUCCESS,
  GET_TEACHER_LIST_FAILED,
  TEACHER_DELETE_REQUEST,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_FAILED,
  TEACHER_UPDATE_REQUEST,
  TEACHER_UPDATE_SUCCESS,
  TEACHER_UPDATE_FAILED,
  TEACHER_ADD_REQUEST,
  TEACHER_ADD_SUCCESS,
  TEACHER_ADD_FAILED,
  TEACHER_NAME_SEARCH_REQUEST,
  TEACHER_NAME_SEARCH_SUCCESS,
  TEACHER_NAME_SEARCH_FAILED,
} from '../constance/teacher.constants';

const teacherListInitialState = {
  loading: false,
  error: '',
  updateSuccess: false,
  message: '',
  data: [],
};

export const teacherListReducer = (state = teacherListInitialState, action) => {
  switch (action.type) {
    // get teacher lists
    case GET_TEACHER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        updateSuccess: false,
        message: '',
        data: [],
      };
    case GET_TEACHER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
        message: '',
      };
    case GET_TEACHER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        message: '',
      };
    //* teacher DELETE
    case TEACHER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
        error: '',
        message: '',
      };
    case TEACHER_DELETE_SUCCESS:
      console.log('--------------------teacher-------------------------');
      console.log('--------------------teacher-------------------------', action.payload);
      console.log('--------------------teacher-------------------------');
      return {
        ...state,
        error: '',
        loading: false,
        data: action.payload.data,
        message: action.payload.message,
      };
    case TEACHER_DELETE_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        message: '',
      };
    //* teacher UPDATE
    case TEACHER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload,
        error: '',
        updateSuccess: false,
        message: '',
      };
    case TEACHER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
        updateSuccess: true,
        message: action.payload.data.message,
      };
    case TEACHER_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        updateSuccess: false,
        message: '',
      };
    //* teacher ADD
    case TEACHER_ADD_REQUEST:
      return {
        ...state,
        loading: false,
        message: '',
      };
    case TEACHER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
        error: '',
        message: action.payload.message,
      };
    case TEACHER_ADD_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        message: '',
      };
    case TEACHER_NAME_SEARCH_REQUEST:
      return {
        ...state,
        loading: false,
        data: [],
        message: '',
      };
    case TEACHER_NAME_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
      };
    case TEACHER_NAME_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        message: '',
      };
    default:
      return state;
  }
};
