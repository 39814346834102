import {
  POST_NUTRITION_DEWORMING_REQUEST,
  POST_NUTRITION_DEWORMING_SUCCESS,
  POST_NUTRITION_DEWORMING_FAILED,
  FILTER_NUTRITION_DEWORMING_REQUEST,
  FILTER_NUTRITION_DEWORMING_SUCCESS,
  FILTER_NUTRITION_DEWORMING_FAILED,
} from '../constance/programs.constants';

const initialState = {
  loading: false,
  data: [],
  error: '',
  status: true,
  message: '',
};
export const nutritionDewormingReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_NUTRITION_DEWORMING_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        message: '',
      };
    case POST_NUTRITION_DEWORMING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    case POST_NUTRITION_DEWORMING_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error,
        message: '',
      };
    //! filter
    case FILTER_NUTRITION_DEWORMING_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: action.payload,
        message: '',
      };
    case FILTER_NUTRITION_DEWORMING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: action.payload.error,
        message: action.payload.message,
        status: action.payload.status,
      };
    case FILTER_NUTRITION_DEWORMING_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        message: '',
      };
    default:
      return state;
  }
};
