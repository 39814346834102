import { GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILED } from '../constance/profile.constants';

const initialState = {
  loading: false,
  profileData: [],
  error: '',
};

export const getProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload,
        error: '',
      };
    case GET_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        profileData: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
