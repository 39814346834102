import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { months, years } from '../../../../common/datas';
import { toBnNumber } from '../../../../common/functions';
import BreadCrumb from '../../../../Components/BreadCrumb/BreadCrumb';
import LanguageContext from '../../../../context/LanguageContext';
import StoreContext from '../../../../context/StoreContext';
import {
  getClassWiseShiftListAction,
  getReportProgramListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
  getWeeklyReportAction,
  getRegularProgramStudentListAction,
} from '../../../../redux';
import { getWeeklyReportRequest } from '../../../../redux/actions/upgradeClass.action';
import { getWeeksInMonth } from '../../../../redux/service/service';
import Heading from './Heading';
import NutritionEducationResult from './NutritionEducationResult';
import WifaResult from './WifaResult';

const WeeklyReport = ({
  getClassLists,
  classLists,

  getProgramList,
  //   programList,

  getShiftLists,
  shiftList,

  getSectionLists,
  sectionList,

  getStudentList,
  studentList,
  studentMetaData,

  getReport,
  report,

  hasShift,
  getWeeklyReportRequest,
}) => {
  const intl = useIntl();
  const { classList, programList } = useContext(StoreContext);
  const { defaultLanguage } = useContext(LanguageContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));

  const [gradeId, setGrade] = useState('');
  const [shiftId, setShift] = useState('');
  const [sectionId, setSection] = useState('');
  const [ProgramId, setProgramId] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [WeeksId, setWeeksId] = useState('');

  const [WeekNumber, setWeekNumber] = useState([]);
  const [GradeName, setGradeName] = useState('');
  const [shiftArray, setShiftArray] = useState([]);
  const [shiftName, setShiftName] = useState('');
  const [sectionArray, setSectionArray] = useState([]);
  const [sectionName, setSectionName] = useState('');

  const [IsButtonDisabled, setIsButtonDisabled] = useState(true);
  const [PostData, setPostData] = useState(null);

  useEffect(() => {
    getWeeklyReportRequest();
  }, [getWeeklyReportRequest]);

  useEffect(() => {
    if (gradeId && sectionId && ProgramId && year && month && WeeksId) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [ProgramId, WeeksId, gradeId, month, sectionId, year]);

  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setShift('');
    setSection('');

    const singleClass = classList.find((classes) => classes.grade === Number(e.target.value));

    setGradeName(singleClass?.name_en);
    if (hasShift?.data?.has_shift === 'yes') {
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      setSectionArray(singleClass?.shift_section);
    }
  };

  // call section based on shift selection

  const handelSelectShift = (e) => {
    setShift(e.target.value);
    const singleShift = shiftArray.find((shifts) => shifts.shift_id === Number(e.target.value));
    setShiftName(singleShift?.shift_name_en);

    setSectionArray(singleShift?.section);
    setSection('');
  };

  // section select
  const handelSelectSection = (e) => {
    setSection(e.target.value);
    const singleSection = sectionArray.find((section) => section.id === Number(e.target.value));
    setSectionName(singleSection?.section_name_en);
  };

  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      if (gradeId && shiftId && sectionId) {
        const data = {
          institution_id: institutionId,
          grade: gradeId,
          shift_id: shiftId,
          section_id: sectionId,
        };
        getStudentList(data);
      }
    } else if (hasShift?.data?.has_shift === 'no') {
      if (gradeId && sectionId) {
        const data = {
          institution_id: institutionId,
          grade: gradeId,
          section_id: sectionId,
        };

        getStudentList(data);
      }
    }
  }, [gradeId, shiftId, sectionId]);

  // program select
  const handelProgram = (e) => {
    setProgramId(e.target.value);
  };

  // get weeks number
  useEffect(() => {
    if (Number(year) !== null && Number(month) !== null) {
      setWeekNumber(() => getWeeksInMonth(Number(year), Number(month)));
    }
  }, [month, year]);

  // handel weeks
  const handelWeeks = (e) => {
    setWeeksId(e.target.value);
  };

  const [ErrorInstitute, setErrorInstitute] = useState(null);
  useEffect(() => {
    setErrorInstitute(report?.error?.institution_id);
  }, [report?.error?.institution_id]);
  const [ErrorGrade, setErrorGrade] = useState(null);
  useEffect(() => {
    setErrorGrade(report?.error?.grade);
  }, [report?.error?.grade]);
  const [ErrorSection, setErrorSection] = useState(null);
  useEffect(() => {
    setErrorSection(report?.error?.section_id);
  }, [report?.error?.section_id]);
  const [ErrorProgram, setErrorProgram] = useState(null);
  useEffect(() => {
    setErrorProgram(report?.error?.program);
  }, [report?.error?.program]);
  const [ErrorYear, setErrorYear] = useState(null);
  useEffect(() => {
    setErrorYear(report?.error?.year);
  }, [report?.error?.year]);
  const [ErrorMonth, setErrorMonth] = useState(null);
  useEffect(() => {
    setErrorMonth(report?.error?.month);
  }, [report?.error?.month]);
  const [ErrorWeek, setErrorWeek] = useState(null);
  useEffect(() => {
    setErrorWeek(report?.error?.week);
  }, [report?.error?.week]);
  const [ErrorShift, setErrorShift] = useState(null);
  useEffect(() => {
    setErrorShift(report?.error?.shift_id);
  }, [report?.error?.shift_id]);

  const handleSubmit = () => {
    const data = {
      institution_id: institutionId,
      grade: gradeId,
      has_shift: hasShift?.data?.has_shift,
      shift_id: shiftId,
      section_id: sectionId,
      program: ProgramId,
      year: year,
      month: month,
      week: WeeksId,
    };
    const title = {
      GradeName: GradeName,
      shiftName: shiftName,
      sectionName: sectionName,
    };
    getReport(data);
    setPostData(title);
    localStorage.setItem(
      'sectionInfo',
      JSON.stringify({
        institution_id: data?.institution_id,
        grade: data?.grade,
        has_shift: data?.has_shift,
        shift_id: shiftName,
        sectionName: sectionName,
        program: data?.program,
        year: data?.year,
        month: data?.month,
        week: data?.week,
      })
    );
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <Heading title={<FormattedMessage id='weeklyReport' />} />
        {/* report form input  */}
        <div className='container mx-auto'>
          <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
            <div className='border border-gray-300 w-full rounded p-10'>
              <div className='grid grid-cols-2 gap-4 pb-8 items-center'>
                {/* <!-- class  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                    <FormattedMessage id='class' />
                  </label>
                  <select
                    value={gradeId && gradeId}
                    onChange={handelSelectClass}
                    id='class'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                    {classList &&
                      classList?.map((classes, index) => {
                        return (
                          <option key={index} value={classes.grade}>
                            {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorGrade && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorGrade}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- shift  --> */}
                {hasShift?.data?.has_shift === 'yes' && (
                  <div className=' xl:w-full flex flex-col'>
                    <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                      <FormattedMessage id='shift' />
                    </label>
                    <select
                      value={shiftId && shiftId}
                      onChange={handelSelectShift}
                      id='shift'
                      className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                      {shiftArray &&
                        shiftArray?.map((shift, index) => {
                          return (
                            <option value={shift.shift_id} key={index}>
                              {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                            </option>
                          );
                        })}
                    </select>
                    {ErrorShift && (
                      <p>
                        {' '}
                        <small className='text-red-700'>{ErrorShift}</small>{' '}
                      </p>
                    )}
                  </div>
                )}
                {/* <!-- section  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                    {' '}
                    <FormattedMessage id='section' />
                  </label>
                  <select
                    value={sectionId && sectionId}
                    onChange={handelSelectSection}
                    id='section'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                    {sectionArray &&
                      sectionArray?.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorSection && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorSection}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!--total student  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='total-student'>
                    <FormattedMessage id='totalStudent' />
                  </label>
                  {hasShift?.data?.has_shift === 'yes' ? (
                    <input
                      className='form-select capitalize block w-full min-w-[75px] px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-200 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                                        focus:text-gray-700 focus:bg-gray-200 focus:border-green-600 focus:outline-none'
                      type='text'
                      name=''
                      id='total-student'
                      placeholder='00'
                      value={
                        gradeId && shiftId && sectionId && studentMetaData
                          ? defaultLanguage === 'en'
                            ? studentMetaData.total
                            : toBnNumber(studentMetaData.total)
                          : defaultLanguage === 'en'
                          ? '0'
                          : toBnNumber(0)
                      }
                      readOnly
                    />
                  ) : (
                    <input
                      className='form-select capitalize block w-full min-w-[75px] px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-200 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                                        focus:text-gray-700 focus:bg-gray-200 focus:border-green-600 focus:outline-none'
                      type='text'
                      name=''
                      id='total-student'
                      placeholder='00'
                      value={
                        gradeId && sectionId && studentMetaData
                          ? defaultLanguage === 'en'
                            ? studentMetaData.total
                            : toBnNumber(studentMetaData.total)
                          : defaultLanguage === 'en'
                          ? '0'
                          : toBnNumber(0)
                      }
                      readOnly
                    />
                  )}
                </div>
                {/* <!-- programs   --> */}
                <div className='xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='programs'>
                    <FormattedMessage id='programs' />
                  </label>
                  <select
                    onChange={handelProgram}
                    value={ProgramId}
                    id='programs'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectPrograms' })}</option>
                    {programList &&
                      programList
                        ?.map((program, index) => {
                          return (
                            <option value={program.id} key={index}>
                              {intl.formatMessage({ id: program.name })}
                            </option>
                          );
                        })
                        .slice(0, 2)}
                  </select>
                  {ErrorProgram && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorProgram}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- year   --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='year'>
                    {' '}
                    <FormattedMessage id='year' />
                  </label>
                  <select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    id='year'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                    {years?.map((year) => (
                      <option value={year}>{defaultLanguage === 'en' ? year : toBnNumber(year)}</option>
                    ))}
                  </select>
                  {ErrorYear && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorYear}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- month   --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='month'>
                    {' '}
                    <FormattedMessage id='month' />
                  </label>
                  <select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    id='month'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectMonth' })}</option>
                    {months?.map((month) => (
                      <option value={month.id}>{defaultLanguage === 'en' ? month.name_en : month.name_bn}</option>
                    ))}
                  </select>
                  {ErrorMonth && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorMonth}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- week   --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='weeks'>
                    {' '}
                    <FormattedMessage id='week' />
                  </label>
                  <select
                    onChange={handelWeeks}
                    value={WeeksId}
                    id='weeks'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectWeek' })}</option>
                    {WeekNumber &&
                      WeekNumber?.map((week, index) => {
                        return (
                          <option key={index} value={index + 1}>
                            {intl.formatMessage({ id: 'week' })}-
                            {defaultLanguage === 'en' ? index + 1 : toBnNumber(index + 1)}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorWeek && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorWeek}</small>{' '}
                    </p>
                  )}
                </div>
                {/* submit  */}
                <div className='col-span-2  text-center mt-6'>
                  <button
                    disabled={IsButtonDisabled}
                    title={`${
                      IsButtonDisabled
                        ? 'Select all the field to enable this Button'
                        : 'Select all the field to enable button'
                    }`}
                    onClick={handleSubmit}
                    type='button'
                    className={`w-full md:w-1/2  py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded ${
                      IsButtonDisabled
                        ? ' bg-gray-300 text-black '
                        : ' hover_gradient bg-gradient hover:text-white text-white'
                    }`}
                  >
                    <span>
                      <FormattedMessage id='viewReport' />
                    </span>
                  </button>
                </div>
              </div>

              {report && report.message && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {report.message}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* report result start */}

        {report && report.data.length >= 0 && (
          <React.Fragment>
            {report?.data[0]?.program === 'wifas' && (
              <React.Fragment>
                <WifaResult
                  title='Wifa'
                  sectionName={PostData?.sectionName}
                  shiftName={PostData?.shiftName}
                  GradeName={PostData?.GradeName}
                  report={report}
                />
              </React.Fragment>
            )}
            {report?.data[0]?.program === 'nutrition_education' && (
              <React.Fragment>
                <NutritionEducationResult
                  title='nutrition education'
                  sectionName={PostData?.sectionName}
                  shiftName={PostData?.shiftName}
                  GradeName={PostData?.GradeName}
                  report={report}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentList: state.ProgramStudentList,
    studentMetaData: state.ProgramStudentList?.data?.meta,
    // programList: state.programList,
    weekNumber: state.weeks?.data?.week,
    // stockList       : state.stockList,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
    report: state.report,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getProgramList: () => dispatch(getReportProgramListAction()),
    // getStockList: (dataInfo) => dispatch(getStockListAction(dataInfo)),
    getStudentList: (studentInfo) => dispatch(getRegularProgramStudentListAction(studentInfo)),
    // getWeekNumber: (dateInfo) => dispatch(getWeekNumberAction(dateInfo)),
    getReport: (dateInfo) => dispatch(getWeeklyReportAction(dateInfo)),
    getWeeklyReportRequest: () => dispatch(getWeeklyReportRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyReport);
