import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import DropDownMenu from './DropDownMenu';

const DropdownItem = ({ children, itemName, link }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  return (
    <React.Fragment>
      <li
        className='relative'
        onClick={() => {
          setDropdownIsOpen(!dropdownIsOpen);
        }}
        // onMouseLeave={() => {
        // 	setDropdownIsOpen(false);
        // }}
      >
        <NavLink
          to={`/${link}`}
          className='masud menu__list inline-flex justify-between py-2 md:py-1 lg:py-2 hover:border-b-2 hover:border-green-600 hover:bg-green-50'
        >
          <span>{itemName}</span>
          {children && children ? (
            <span className=' '>
              {dropdownIsOpen && dropdownIsOpen ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
          ) : null}
        </NavLink>
        {dropdownIsOpen && dropdownIsOpen ? (
          <div className=''>{children && children ? <DropDownMenu>{children}</DropDownMenu> : null}</div>
        ) : null}
      </li>
    </React.Fragment>
  );
};

export default DropdownItem;
