import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../../../Components/EmptyList';
import Layout from '../../../../../layout/Layout';
import {
  getClassWiseShiftListAction,
  getProgramStudentListAction,
  getSectionListFromShiftAction,
  getSubjectListAction,
  getUpgradeClassListsAction,
  getWeekNumberAction,
  postProgramStudentListAction,
  sectionStudentAction,
} from '../../../../../redux';
import { currentDate } from '../../../../../redux/service/service';
import ProgramHeading from '../ProgramHeading';
import StudentSearch from '../StudentSearch';
import ReactPaginate from 'react-paginate';
const NutritionPrograms = ({
  classLists,
  shiftList,
  sectionList,
  getClassLists,
  getShiftLists,
  getSectionLists,
  hasShift,
  getSubjectList,
  subjectList,
  getStudentList,
  studentList,
  studentMetaData,
  getWeekNumber,
  weekNumber,
  postStudentIdForProgram,
  postMsg,
}) => {
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [pageNumber, setPageNumber] = useState(1);
  const [InitialClassId, setInitialClassId] = useState(null);
  const [gradeId, setGrade] = useState(null);
  const [shiftId, setShift] = useState(null);
  const [sectionId, setSection] = useState(null);
  const [date, setDate] = useState(() => currentDate());
  const [week, setWeek] = useState(weekNumber);
  const [Subject, setSubject] = useState(null);
  const [studentIds, setStudentIds] = useState([]);

  const [IsSubmit, setIsSubmit] = useState(false);
  // class list api call
  useEffect(() => {
    getClassLists();
    getSubjectList();
  }, [getClassLists, getSubjectList]);
  // shift api call
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institutionId: institutionId,
        grade: classLists[0]?.grade,
      };
      if (data.grade !== null && data.grade !== undefined) {
        // set to show default value to filter
        setInitialClassId(data.grade);
        getShiftLists(data);
      }
    }
  }, [classLists, getShiftLists, hasShift, institutionId]);
  // section api call if has shift
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institutionId: institutionId,
        grade: classLists[0]?.grade,
        shift_id: shiftList?.data[0]?.id,
      };
      if (data.grade !== null && data.grade !== undefined && data.shift_id !== null && data.shift_id !== undefined) {
        // set to show default value to filter
        setShift(data.shift_id);
        getSectionLists(data);
      }
    }
  }, [classLists, getSectionLists, hasShift, shiftList]);
  // section api call if no shift
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institutionId: institutionId,
        grade: classLists[0]?.grade,
      };
      if (data.grade !== null && data.grade !== undefined) {
        // set to show default value to filter
        setInitialClassId(data.grade);
        setShift(null);
        getSectionLists(data);
      }
    }
  }, [classLists, getSectionLists, hasShift]);

  // call api on selection class/shift/section
  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setInitialClassId(e.target.value);
  };
  // call shift based on class selection
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institutionId: institutionId,
        grade: gradeId,
      };
      if (gradeId !== 0 && gradeId !== '0' && gradeId !== null && gradeId !== undefined) {
        getShiftLists(data);
      }
    }
  }, [getShiftLists, gradeId]);

  // call section based on shift selection
  const handelSelectShift = (e) => {
    setShift(e.target.value);
  };
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institutionId: institutionId,
        grade: gradeId,
        shift_id: shiftId,
      };
      if (
        gradeId !== '0' &&
        gradeId !== 0 &&
        shiftId !== '0' &&
        shiftId !== 0 &&
        gradeId !== null &&
        gradeId !== undefined &&
        shiftId !== null &&
        shiftId !== undefined
      ) {
        getSectionLists(data);
      }
    }
    if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institutionId: institutionId,
        grade: gradeId,
      };
      if (gradeId !== '0' && gradeId !== 0 && gradeId !== null && gradeId !== undefined) {
        getSectionLists(data);
      }
    }
  }, [getSectionLists, gradeId, hasShift, shiftId]);

  // set section default value
  useEffect(() => {
    setSection(sectionList?.data[0]?.id);
  }, [sectionList]);

  // section select
  const handelSelectSection = (e) => {
    setSection(e.target.value);
  };

  /* get program student lists based on initial class / shift / section */
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institution_id: institutionId,
        grade: classLists[0]?.grade,
        shift_id: shiftList?.data[0]?.id,
        section_id: sectionList?.data[0]?.id,
        page: pageNumber,
        pagination_limit: 10,
      };
      if (
        data.grade !== null &&
        data.grade !== undefined &&
        data.shift_id !== null &&
        data.shift_id !== undefined &&
        data.section_id !== null &&
        data.section_id !== undefined
      ) {
        getStudentList(data);
      }
    }
  }, [getStudentList, classLists, sectionList, shiftList, hasShift, institutionId, pageNumber]);
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institution_id: institutionId,
        grade: classLists[0]?.grade,
        section_id: sectionList?.data[0]?.id,
        page: pageNumber,
        pagination_limit: 10,
      };
      if (
        data.grade !== null &&
        data.grade !== undefined &&
        data.section_id !== null &&
        data.section_id !== undefined
      ) {
        getStudentList(data);
      }
    }
  }, [classLists, getStudentList, hasShift, institutionId, sectionList, pageNumber]);

  // on change event class/shift/section
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      const data = {
        institution_id: institutionId,
        grade: gradeId,
        shift_id: shiftId,
        section_id: sectionId,
        page: pageNumber,
        pagination_limit: 10,
      };
      if (
        gradeId !== null &&
        gradeId !== undefined &&
        shiftId !== null &&
        shiftId !== undefined &&
        sectionId !== null &&
        sectionId !== undefined
      ) {
        getStudentList(data);
      }
    }
    if (hasShift?.data?.has_shift === 'no') {
      const data = {
        institution_id: institutionId,
        grade: gradeId,
        section_id: sectionId,
        page: pageNumber,
        pagination_limit: 10,
      };
      if (gradeId !== null && gradeId !== undefined && sectionId !== null && sectionId !== undefined) {
        getStudentList(data);
      }
    }
    // set null when select class shift/section
    setWeek(null);
    setSubject(null);
  }, [getStudentList, gradeId, sectionId, shiftId, pageNumber]);

  // handel date
  const handelDate = (e) => {
    setDate(e.target.value);
  };
  // get weeks number
  useEffect(() => {
    getWeekNumber(date);
  }, [date, getWeekNumber]);
  // set week number
  useEffect(() => {
    setWeek(weekNumber);
  }, [weekNumber]);
  // select SUBJECT
  const handelSubject = (e) => {
    setSubject(e.target.value);
    // setIsSubmit(false)
  };

  useEffect(() => {
    const postData = {
      institution_id: institutionId,
      grade: InitialClassId,
      shift_id: shiftId,
      section_id: sectionId,
      subject_id: Subject,
      week: weekNumber,
      date: date,
      page: pageNumber,
      pagination_limit: 10,
    };
    if (postData.week !== null && Subject !== null && Subject !== '0' && Subject !== 0) {
      getStudentList(postData);
    }
  }, [Subject, date]);

  const handelSubmitStudentData = () => {
    setIsSubmit(true);
  };

  // post student id for program
  useEffect(() => {
    const data = {
      institution_id: institutionId,
      grade: InitialClassId,
      has_shift: hasShift?.data?.has_shift,
      shift_id: shiftId,
      section_id: sectionId,
      subject_id: Subject,
      week: weekNumber,
      date: date,
      student_id: studentIds.map(Number),
    };
    if (IsSubmit) {
      postStudentIdForProgram(data);
      setIsSubmit(false);
      setStudentIds([]);
    }
  }, [IsSubmit]);

  // error handel from server
  const [ErrorInstitute, setErrorInstitute] = useState(null);
  const [ErrorGrade, setErrorGrade] = useState(null);
  const [ErrorSection, setErrorSection] = useState(null);
  const [ErrorSubject, setErrorSubject] = useState(null);
  const [ErrorWeek, setErrorWeek] = useState(null);
  const [ErrorDate, setErrorDate] = useState(null);
  const [ErrorStudentId, setErrorStudentId] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  useEffect(() => {
    setErrorInstitute(postMsg?.error?.institution_id);
  }, [postMsg?.error?.institution_id]);
  useEffect(() => {
    setErrorGrade(postMsg?.error?.grade);
  }, [postMsg?.error?.grade]);
  useEffect(() => {
    setErrorSection(postMsg?.error?.section_id);
  }, [postMsg?.error?.section_id]);
  useEffect(() => {
    setErrorSubject(postMsg?.error?.subject_id);
  }, [postMsg?.error?.subject_id]);
  useEffect(() => {
    setErrorWeek(postMsg?.error?.week);
  }, [postMsg?.error?.week]);
  useEffect(() => {
    setErrorDate(postMsg?.error?.date);
  }, [postMsg?.error?.date]);
  useEffect(() => {
    setErrorStudentId(postMsg?.error?.student_id);
  }, [postMsg?.error?.student_id]);
  useEffect(() => {
    setResponseMessage(postMsg.message);
  }, [postMsg.message]);

  /********students search*********/
  const [searchResult, setSearchResult] = useState('');
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const handelStudentSearch = (e) => {
    e.preventDefault();
    setSearchResult(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target.nameSearch;
    setIsSubmitSearch(true);
  };

  // pagination
  const handlePageClick = (data) => {
    setPageNumber(data.selected + 1);
  };

  // filter participated non participated students
  const [StudentArray, setStudentArray] = useState(null);
  const [participatedStudent, setParticipatedStudent] = useState(null);
  useEffect(() => {
    setParticipatedStudent(studentList?.data?.data);
    setStudentArray(studentList?.data?.data);
  }, [studentList]);

  const filterParticipatedStudent = (e) => {
    let filterValue = e.target.value;
    if (filterValue === null || filterValue.trim() === '') {
      setParticipatedStudent(StudentArray);
    } else if (filterValue === 'yes') {
      const participatedStudents = StudentArray.filter((student) => {
        return student.participation_status === 'yes';
      });
      setParticipatedStudent(participatedStudents);
    } else if (filterValue === 'no') {
      const participatedStudents = StudentArray.filter((student) => {
        return student.participation_status === 'no';
      });
      setParticipatedStudent(participatedStudents);
    }
  };

  // check all students
  const [StudentChecked, setStudentChecked] = useState(null);
  // console.log("🚀 ~ file: NutritionPrograms.jsx ~ line 367 ~ StudentChecked", StudentChecked)
  const handelSelectStudent = (e) => {
    const { checked, value } = e.currentTarget;
    if (checked) {
      // console.log('======================================================================checked==',checked);
    }
    setStudentIds((prev) => (checked ? [...prev, value] : prev.filter((val) => val !== value)));
  };

  return (
    <Layout>
      <BreadCrumb />
      <React.Fragment>
        <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
          {/* program heading */}
          <ProgramHeading title='nutrition Education program'>
            {/* <StudentSearch onSubmit={handelSubmit} onChange={handelStudentSearch} value={searchResult} name="nameSearch"/> */}
          </ProgramHeading>
          {/* initial query class/shift/section start*/}
          {/* {studentList && studentList.loading ? <LoadingSpinner/>: ( */}
          <div className='container mx-auto'>
            <div className='flex flex-wrap items-center w-full px-4  sm:w-4/5 mx-auto '>
              <div className='w-full flex flex-row space-x-5 bg-[#399737] p-4 rounded-md'>
                {/* class */}
                <div className='w-full'>
                  <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                    class
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    value={InitialClassId && InitialClassId}
                    onChange={handelSelectClass}
                    name='class'
                    id='class'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value='0'>select class</option>
                    {classLists &&
                      classLists.map((classes, index) => {
                        return (
                          <option key={index} value={classes.grade}>
                            {classes.name_en}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {/* shift */}
                {hasShift?.data?.has_shift === 'yes' && (
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                      shift
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={shiftId && shiftId}
                      onChange={handelSelectShift}
                      name='shift'
                      id='shift'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value='0'>select shift</option>
                      {shiftList &&
                        shiftList?.data.map((shift, index) => {
                          return (
                            <option value={shift.id} key={index}>
                              {shift.name_en}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
                {/* section */}
                <div className='w-full'>
                  <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                    section
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    value={sectionId && sectionId}
                    onChange={handelSelectSection}
                    name='section'
                    id='section'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value='0'>select section</option>
                    {sectionList &&
                      sectionList?.data.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {section.section_name_en}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/*  )} */}
          {/* initial query class/shift/section end*/}

          {/* student list with filter section */}
          <div className='container mx-auto'>
            <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
              <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                {/* <!-- total number  --> */}
                <div className='flex flex-wrap flex-col md:flex-row items-center md:items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                  <div className='mb-3 md:mb-0'>
                    <p className='text-base text-black capitalize'>
                      total student : <span>{studentMetaData && studentMetaData?.total}</span>{' '}
                    </p>
                  </div>

                  <div className='w-full md:w-3/4 grid grid-cols-2 md:grid-cols-3 gap-4'>
                    {/* <!--select  date --> */}
                    <div className=' xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='date'>
                        date
                      </label>
                      <input
                        className='form-select uppercase block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                            focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        type='date'
                        name='date'
                        id='date'
                        onChange={handelDate}
                        value={date}
                      />
                      {ErrorDate && ErrorDate && (
                        <p>
                          <small className='text-red-700'>{ErrorDate}</small>
                        </p>
                      )}
                    </div>
                    {/* <!--select  weeks --> */}
                    <div className=' xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='weeks'>
                        weeks{' '}
                      </label>
                      <select
                        id='weeks'
                        className='form-select block w-auto px-3 py-2 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none '
                        aria-label='Default select example'
                      >
                        <option value={weekNumber && weekNumber} selected>
                          weeks : {weekNumber}
                        </option>
                      </select>
                      {ErrorWeek && ErrorWeek && (
                        <p>
                          <small className='text-red-700'>{ErrorWeek}</small>
                        </p>
                      )}
                    </div>
                    {/* <!--select  subject --> */}
                    <div className='xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='subject'>
                        subject
                      </label>
                      <select
                        onChange={handelSubject}
                        value={Subject}
                        id='subject'
                        className='form-select block w-auto px-3 py-2 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value='0'>select Subject</option>
                        {subjectList &&
                          subjectList?.data.map((subject, index) => {
                            return (
                              <option key={index + subject} value={subject.id}>
                                {subject.name_en}
                              </option>
                            );
                          })}
                      </select>
                      {ErrorSubject && ErrorSubject && (
                        <p>
                          <small className='text-red-700'>{ErrorSubject}</small>
                        </p>
                      )}
                    </div>
                    {/* <!-- end  --> */}
                  </div>
                </div>
                {/* <!-- table header start --> */}
                {studentList && studentList.loading ? (
                  <LoadingSpinner />
                ) : (
                  <React.Fragment>
                    {studentList && studentList?.data?.data?.length ? (
                      <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                        <thead className=' text-left bg-[#BFE3B5]'>
                          <tr>
                            <th className=' border border-gray-300 capitalize p-2'>roll no</th>
                            <th className=' border border-gray-300 capitalize p-2'>student name</th>
                            <th className=' border border-gray-300 capitalize p-2'>gender</th>
                            <th className=' border border-gray-300 capitalize p-2'>age</th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <select
                                onChange={filterParticipatedStudent}
                                // value={FilterStudents}
                                id='institute'
                                className='w-auto bg-[#B8E6B6] capitalize border-0 focus:outline-none'
                                aria-label='Default select example'
                              >
                                <option value=''>all</option>
                                <option value='yes'>participated</option>
                                <option value='no'>not participated</option>
                              </select>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <!-- table single row --> */}

                          {participatedStudent &&
                            participatedStudent?.map((student, index) => {
                              const checked = student.participation_status === 'yes';
                              // setStudentChecked(checked)
                              console.log(
                                '🚀 ~ file: NutritionPrograms.jsx ~ line 570 ~ {participatedStudent&&participatedStudent?.map ~ checked',
                                checked
                              );

                              // setIsChecked(checked)

                              return (
                                <tr key={index + student} className='hover:bg-green-50'>
                                  <td className=' border border-gray-300 p-2'>{student.roll}</td>
                                  <td className=' border border-gray-300 p-2 capitalize'>{student.name}</td>
                                  <td className=' border border-gray-300 p-2 capitalize'>{student.gender}</td>
                                  <td className=' border border-gray-300 p-2'>{student.age}</td>
                                  <td className=' border border-gray-300 p-2 '>
                                    {/* <input  
                                                                    type="checkbox" 
                                                                    id="student" 
                                                                    name="student" 
                                                                    value={student.id}
                                                                    onChange={(e)=>handelSelectStudent(e)}  
                                                                    checked={checked}
                                                                /> */}

                                    {checked ? (
                                      <input
                                        type='checkbox'
                                        id='student'
                                        name='student'
                                        value={student.id}
                                        onChange={(e) => handelSelectStudent(e)}
                                        checked={checked}
                                      />
                                    ) : (
                                      <input
                                        type='checkbox'
                                        id='student'
                                        name='student'
                                        value={student.id}
                                        onChange={(e) => handelSelectStudent(e)}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <EmptyList message='Your section List is empty' border={false} />
                    )}
                  </React.Fragment>
                )}
                {/* <!-- table header end --> */}
              </div>

              {/* pagination section start */}

              <div className='container mx-auto my-5'>
                <div className='w-full px-4 sm:w-4/5 mx-auto'>
                  <ReactPaginate
                    onPageChange={handlePageClick}
                    pageCount={studentMetaData && studentMetaData?.last_page}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageClassName='page-item  inline'
                    pageLinkClassName='page_link_pagination  text-center hover:bg-[#83C55E] hover:text-white text-gray inline-block w-10 h-10 mx-1 rounded-full leading-10'
                    previousClassName='page-item inline'
                    previousLinkClassName='page_link_pagination text-center hover:bg-[#83C55E] hover:text-white text-gray inline-block w-10 h-10 mx-1 rounded-full leading-10'
                    nextClassName='page-item inline'
                    nextLinkClassName='page_link_pagination text-center hover:bg-[#83C55E] hover:text-white text-gray inline-block w-10 h-10 mx-1 rounded-full leading-10'
                    breakLabel='...'
                    breakClassName='page-item inline'
                    breakLinkClassName='page_link_pagination text-center hover:bg-[#83C55E] hover:text-white text-gray inline-block w-10 h-10 mx-1 rounded-full leading-10'
                    containerClassName='pagination text-center'
                    activeClassName='active'
                    previousLabel={studentMetaData && studentMetaData.total >= 10 && '<'}
                    nextLabel={studentMetaData && studentMetaData.total >= 10 && '>'}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>

              {/* pagination section end */}

              {/* <!-- submit button  --> */}
              <div className='flex justify-center mt-4'>
                <button
                  onClick={handelSubmitStudentData}
                  type='button'
                  className='w-1/3 py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded text-white bg-gradient hover_gradient'
                >
                  <span>submit</span>
                </button>
              </div>

              {responseMessage && responseMessage && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {responseMessage}
                  </h1>
                </div>
              )}
              {ErrorStudentId && ErrorStudentId && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-500'>
                    {ErrorStudentId}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentList: state.ProgramStudentList,
    studentMetaData: state.ProgramStudentList?.data?.meta,
    subjectList: state.subjects,
    weekNumber: state.weeks?.data?.week,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
    // show message for posted data info
    postMsg: state.ParticipatedStudents,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getSubjectList: () => dispatch(getSubjectListAction()),
    getStudentList: (studentInfo) => dispatch(getProgramStudentListAction(studentInfo)),
    getWeekNumber: (dateInfo) => dispatch(getWeekNumberAction(dateInfo)),
    postStudentIdForProgram: (dateInfo) => dispatch(postProgramStudentListAction(dateInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NutritionPrograms);
