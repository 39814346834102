import axios from 'axios';
import {
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILED,
} from '../constance/passwordChange.constants';
import { checkLogInLocalStorage } from './user.actions';

export const passwordChangeRequest = () => {
  return { type: PASSWORD_CHANGE_REQUEST, info: 'change password' };
};
export const passwordChangeSuccess = (res) => {
  return { type: PASSWORD_CHANGE_SUCCESS, payload: res };
};
export const passwordChangeFailed = (err) => {
  return { type: PASSWORD_CHANGE_FAILED, payload: err };
};

export const changePasswordAction = (info) => {
  const dataBody = {
    institution_id: info?.institution_id,
    old_password: info?.old_password,
    password: info?.password,
    password_confirmation: info?.password_confirmation,
  };

  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;
  return (dispatch) => {
    dispatch(passwordChangeRequest());
    axios
      .post(`${apiUrl}change/password`, dataBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          checkLogInLocalStorage(res.data.token);
        }

        dispatch(passwordChangeSuccess(res.data));
        // checkLogInLocalStorage(res.data.token);
      })
      .catch((err) => {
        dispatch(passwordChangeFailed(err));
      });
  };
};
