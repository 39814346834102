import {
  LEARNING_MODULE_REQUEST,
  LEARNING_MODULE_SUCCESS,
  LEARNING_MODULE_FAILED,
  LEARNING_MODULE_CATEGORY_REQUEST,
  LEARNING_MODULE_CATEGORY_SUCCESS,
  LEARNING_MODULE_CATEGORY_FAILED,
  LEARNING_MODULE_DETAILS_REQUEST,
  LEARNING_MODULE_DETAILS_SUCCESS,
  LEARNING_MODULE_DETAILS_FAILED,
} from '../constance/learningModule.constants';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const learningModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEARNING_MODULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case LEARNING_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: '',
      };
    case LEARNING_MODULE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const categoryState = {
  status: true,
  loading: false,
  error: false,
  message: '',
  data: [],
};

export const learningModuleCategoryReducer = (state = categoryState, action) => {
  switch (action.type) {
    case LEARNING_MODULE_CATEGORY_REQUEST:
      return {
        ...state,
        status: true,
        loading: true,
        error: false,
        message: '',
        data: [],
      };
    case LEARNING_MODULE_CATEGORY_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case LEARNING_MODULE_CATEGORY_FAILED:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

const moduleDetails = {
  status: true,
  loading: false,
  error: false,
  message: '',
  data: [],
};
export const learningModuleDetailsReducer = (state = moduleDetails, action) => {
  switch (action.type) {
    case LEARNING_MODULE_DETAILS_REQUEST:
      return {
        ...state,
        status: true,
        loading: true,
        error: false,
        message: '',
        data: [],
      };
    case LEARNING_MODULE_DETAILS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case LEARNING_MODULE_DETAILS_FAILED:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};
