export const years = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];

export const birthYears = [
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
];

export const days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

export const months = [
  { id: '1', name_en: 'January', name_bn: 'জানুয়ারি' },
  { id: '2', name_en: 'February', name_bn: 'ফেব্রুয়ারি' },
  { id: '3', name_en: 'March', name_bn: 'মার্চ' },
  { id: '4', name_en: 'April', name_bn: 'এপ্রিল' },
  { id: '5', name_en: 'May', name_bn: 'মে' },
  { id: '6', name_en: 'June', name_bn: 'জুন' },
  { id: '7', name_en: 'July', name_bn: 'জুলাই' },
  { id: '8', name_en: 'August', name_bn: 'আগস্ট' },
  { id: '9', name_en: 'September', name_bn: 'সেপ্টেম্বর' },
  { id: '10', name_en: 'October', name_bn: 'অক্টোবর' },
  { id: '11', name_en: 'November', name_bn: 'নভেম্বর' },
  { id: '12', name_en: 'December', name_bn: 'ডিসেম্বর' },
];

export const monthsWithZero = [
  { id: '01', name_en: 'January', name_bn: 'জানুয়ারি' },
  { id: '02', name_en: 'February', name_bn: 'ফেব্রুয়ারি' },
  { id: '03', name_en: 'March', name_bn: 'মার্চ' },
  { id: '04', name_en: 'April', name_bn: 'এপ্রিল' },
  { id: '05', name_en: 'May', name_bn: 'মে' },
  { id: '06', name_en: 'June', name_bn: 'জুন' },
  { id: '07', name_en: 'July', name_bn: 'জুলাই' },
  { id: '08', name_en: 'August', name_bn: 'আগস্ট' },
  { id: '09', name_en: 'September', name_bn: 'সেপ্টেম্বর' },
  { id: '10', name_en: 'October', name_bn: 'অক্টোবর' },
  { id: '11', name_en: 'November', name_bn: 'নভেম্বর' },
  { id: '12', name_en: 'December', name_bn: 'ডিসেম্বর' },
];

export const studentGender = [
  { name_en: 'Boy', name_bn: 'ছাত্র ' },
  { name_en: 'Girl', name_bn: 'ছাত্রী' },
];
