import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { birthYears, days, months, studentGender, years, monthsWithZero } from '../../../common/datas';
import { isValidDate, toBnNumber } from '../../../common/functions';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import ImageUpload from '../../../Components/imageUpload/ImageUpload';
import InputField from '../../../Components/InputField';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import LanguageContext from '../../../context/LanguageContext';
import StoreContext from '../../../context/StoreContext';
import Layout from '../../../layout/Layout';
import { createSectionStudentFromShiftAction, createSectionStudentFromShiftRequest } from '../../../redux';
import {
  calculateAge,
  shiftNameConverter,
  validateDate,
  validateGender,
  validateNumber,
  validNameField,
} from '../../../redux/service/service';

const AddSectionStudentFromShift = ({ addStudent, validation, addStudentRequest, hasShift }) => {
  const { getClassList } = useContext(StoreContext);
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const addStudentData = useLocation();
  const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
  // console.log(
  //   '🚀 ~ file: AddSectionStudentFromShift.jsx ~ line 18 ~ AddSectionStudentFromShift ~ addStudentData',
  //   addStudentData
  // );
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const { grade, institution_id, class_name_en, shift_name_en, section_name_en, shift_id, section_id } =
    addStudentData.state;
  // input image file
  const [toViewAvatar, setToViewAvatar] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const inputFile = (event) => {
    setToViewAvatar(URL.createObjectURL(event.target.files[0]));
    setAvatar(event.target.files[0]);
  };

  const initialFormValues = {
    name: null,
    roll: '',
    gender: '',
    birth_date: '',
    institution_id: institutionId,
    grade: '',
    shift_id: '',
    section_id: '',
    age: '',
    student_profile_image: '',
  };
  // name validation
  const [name, setName] = useState(initialFormValues.name);
  const [nameError, setNameError] = useState(false);
  const handelNameField = (e) => {
    setName(e.target.value);
  };

  // roll validation
  const [roll, setRoll] = useState(initialFormValues.roll);
  const [rollError, setRollError] = useState(false);
  const handelRoll = (e) => {
    setRoll(e.target.value);
  };
  // validate roll from server
  const [rollErrorFromServer, setRollErrorFromServer] = useState(null);
  useEffect(() => {
    setRollErrorFromServer(validation?.error?.roll);
  }, [validation?.error?.roll]);
  let msRollError = rollErrorFromServer && (
    <p>
      {' '}
      <small className='text-red-700'>{rollErrorFromServer}</small>{' '}
    </p>
  );

  // gender validation
  const [gender, setGender] = useState(initialFormValues.gender);
  const [genderError, setGenderError] = useState(false);
  const handelGender = (e) => {
    setGender(e.target.value);
  };
  const initialDate = {
    day: '',
    month: '',
    year: '',
  };

  // Date validate
  const [dateValue, setDateValue] = useState(initialDate);
  const [dateError, setDateError] = useState({});
  const [totalAge, setTotalAge] = useState('');
  const handelDate = (e) => {
    const { name, value } = e.target;
    setDateValue({ ...dateValue, [name]: value });
  };
  useEffect(() => {
    setTotalAge(calculateAge(dateValue));
  }, [dateValue]);

  // birth citification number
  const [birthCertificate, setBirthCertificate] = useState('');

  // validate citification number from server
  const [birthCertificateErrorFromServer, setBirthCertificateErrorFromServer] = useState(null);
  useEffect(() => {
    setBirthCertificateErrorFromServer(validation?.error?.birth_certificate_num);
  }, [validation?.error?.birth_certificate_num]);
  let msBirthCertificateError = birthCertificateErrorFromServer && (
    <p>
      {' '}
      <small className='text-red-700'>{birthCertificateErrorFromServer}</small>{' '}
    </p>
  );

  // form submission
  const [isSubmit, setIsSubmit] = useState(false);
  const [postData, setSetPostData] = useState({});
  const handelSubmit = (e) => {
    e.preventDefault();

    if (!isValidDate(dateValue?.day, dateValue?.month, dateValue?.year)) {
      toast.error('Please enter a valid date.');
      return;
    }

    const data = {
      name: name,
      roll: roll,
      gender: gender,
      birth_date: `${dateValue.year}-${dateValue.month}-${dateValue.day}`,
      institution_id: institutionId,
      grade: grade,
      shift_id: hasShift?.data?.has_shift === 'yes' ? shift_id : '',
      section_id: section_id,
      age: totalAge,
      birth_certificate_num: birthCertificate,
      student_profile_image: avatar,
    };
    setNameError(validNameField(name));
    setRollError(validateNumber(roll));
    setGenderError(validateGender(gender));
    setDateError(validateDate(dateValue));
    setSetPostData(data);
    setIsSubmit(true);
  };

  // submit data if all are right
  useEffect(() => {
    if (
      Object.keys(dateError).length === 0 &&
      nameError === false &&
      rollError === false &&
      genderError === false &&
      isSubmit
    ) {
      addStudent(postData, null, getClassList);
      console.log('🚀 ~ file: AddSectionStudentFromShift.jsx ~ line 113 ~ useEffect ~ postData', postData);
    }
  }, [addStudent, dateError, genderError, isSubmit, nameError, postData, rollError]);

  // page refresh first time

  useEffect(() => {
    addStudentRequest();
  }, [addStudentRequest]);

  useEffect(() => {
    if (validation.message === 'Student Save Successfully') {
      setTimeout(function () {
        localStorage.setItem(
          'sectionInfo',
          JSON.stringify({
            grade: newData.grade,
            institution_id: newData.institution_id,
            shift_id: newData.shift_id,
            class_name_en: newData.class_name_en,
            class_name_bn: newData.class_name_bn,
            has_shift: hasShift?.data?.has_shift === 'yes' ? hasShift?.data?.has_shift : undefined,
            shift_name_bn: newData.shift_name_bn,
            shift_name_en: newData.shift_name_en,
            section_id: newData.section_id,
            section_name_bn: newData.section_name_bn,
            section_name_en: newData.section_name_en,
          })
        );
        if (hasShift?.data?.has_shift === 'yes') {
          // navigate(-1)
          navigate(`/classes/shift-list/${shiftNameConverter(shift_id)}/section-${section_name_en}-student-list`, {
            state: newData,
          });
        } else {
          // navigate(-1)
          navigate(`/classes/section-list/section-${section_name_en}-student-list`, { state: newData });
        }
      }, 2000);
    }
  });

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col items-center w-full px-4 lg:w-1/2 mx-auto'>
            <div className='border border-gray-300 w-full  py-4 rounded'>
              <div className='border-b border-gray-300'>
                <h1 className=' text-black font-semibold text-xl capitalize pb-4 px-4'>
                  <FormattedMessage id='add-student' />
                </h1>
              </div>
              {/* .student add form   start  */}
              <form onSubmit={handelSubmit}>
                <div className='w-full pt-5 pb-5'>
                  <div className='flex justify-center  flex-col  space-y-5  px-4'>
                    {/* image upload  */}
                    <ImageUpload avatar={toViewAvatar} inputFile={inputFile} />
                    {/* class */}
                    <InputField
                      label={<FormattedMessage id='class' />}
                      placeholder={class_name_en}
                      value={class_name_en}
                      readOnly={true}
                    />
                    <div className='flex space-x-5'>
                      {hasShift?.data?.has_shift === 'yes' && (
                        <InputField
                          label={<FormattedMessage id='shift' />}
                          placeholder={shift_name_en}
                          readOnly={true}
                          value={shift_name_en}
                        />
                      )}
                      <InputField
                        label={<FormattedMessage id='section' />}
                        placeholder={section_name_en}
                        readOnly={true}
                        value={section_name_en}
                      />
                    </div>
                    {/* student name */}
                    <div className=''>
                      <InputField
                        required
                        onChange={handelNameField}
                        label={<FormattedMessage id='name' />}
                        placeholder={intl.formatMessage({ id: 'name' })}
                        value={name}
                        type='text'
                      />
                      {nameError && (
                        <p>
                          <small className='text-red-700'>Name is required Only A-Za-z</small>
                        </p>
                      )}
                    </div>
                    {/* student roll */}
                    <div className=''>
                      <InputField
                        required
                        label={<FormattedMessage id='roll' />}
                        placeholder={intl.formatMessage({ id: 'enterRoll' })}
                        value={roll}
                        onChange={handelRoll}
                        type='number'
                      />
                      {rollError && (
                        <p>
                          <small className='text-red-700'>Roll is required!</small>
                        </p>
                      )}
                      {msRollError && msRollError}
                    </div>
                    {/* birth certificate number */}
                    <div className=''>
                      <InputField
                        // required
                        type='number'
                        label={<FormattedMessage id='birthRegistrationNumber' />}
                        placeholder={intl.formatMessage({ id: 'enterBirthRegistrationNumber' })}
                        value={birthCertificate}
                        onChange={(e) => setBirthCertificate(e.target.value)}
                      />
                      {msBirthCertificateError && msBirthCertificateError}
                    </div>
                    {/* student gender */}
                    <div className='xl:w-full'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                        <FormattedMessage id='gender' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        onChange={handelGender}
                        name={gender}
                        id='institute'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectGender' })}</option>
                        {studentGender?.map((gender) => (
                          <option value={gender.name_en}>
                            {defaultLanguage === 'en' ? gender.name_en : gender.name_bn}
                          </option>
                        ))}
                      </select>
                      {genderError && (
                        <p>
                          <small className='text-red-700'>Gender is required!</small>
                        </p>
                      )}
                    </div>
                    {/* birth date */}
                    <div className=''>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='dob'>
                        <FormattedMessage id='dateOfBirth' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <div className='flex space-x-5'>
                        {/* day */}
                        <div className='w-full'>
                          <select
                            onChange={handelDate}
                            name='day'
                            id='dob'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value='' selected>
                              {intl.formatMessage({ id: 'selectDay' })}
                            </option>
                            {days?.map((day) => (
                              <option value={day}>{defaultLanguage === 'en' ? day : toBnNumber(day)}</option>
                            ))}
                            {/* <option value='0'>DD</option>
                            <option value='01'>01</option>
                            <option value='02'>02</option>
                            <option value='03'>03</option>
                            <option value='04'>04</option>
                            <option value='05'>05</option>
                            <option value='06'>06</option>
                            <option value='07'>07</option>
                            <option value='08'>08</option>
                            <option value='09'>09</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                            <option value='31'>31</option> */}
                          </select>
                          {dateError.day && (
                            <p>
                              <small className='text-red-700'>day is required!</small>
                            </p>
                          )}
                        </div>
                        <div className='w-full'>
                          <select
                            onChange={handelDate}
                            name='month'
                            id='dob'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                            {monthsWithZero?.map((month) => (
                              <option value={month.id}>
                                {defaultLanguage === 'en' ? month.name_en : month.name_bn}
                              </option>
                            ))}
                            {/* <option value='0'>MM</option>
                            <option value='01'>January</option>
                            <option value='02'>February</option>
                            <option value='03'>March</option>
                            <option value='04'>April</option>
                            <option value='05'>May</option>
                            <option value='06'>June</option>
                            <option value='07'>July</option>
                            <option value='08'>August</option>
                            <option value='09'>September</option>
                            <option value='10'>October</option>
                            <option value='11'>November</option>
                            <option value='12'>December</option> */}
                          </select>
                          {dateError.month && (
                            <p>
                              <small className='text-red-700'>month is required!</small>
                            </p>
                          )}
                        </div>
                        <div className='w-full'>
                          <select
                            onChange={handelDate}
                            name='year'
                            id='dob'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                            {birthYears?.map((year) => (
                              <option value={year}>{defaultLanguage === 'en' ? year : toBnNumber(year)}</option>
                            ))}
                            {/* <option value='0'>YYYY</option>
                            <option value='2001'>2001</option>
                            <option value='2002'>2002</option>
                            <option value='2003'>2003</option>
                            <option value='2004'>2004</option>
                            <option value='2005'>2005</option>
                            <option value='2006'>2006</option>
                            <option value='2007'>2007</option>
                            <option value='2008'>2008</option>
                            <option value='2009'>2009</option>
                            <option value='2010'>2010</option>
                            <option value='2011'>2011</option>
                            <option value='2012'>2012</option>
                            <option value='2013'>2013</option>
                            <option value='2014'>2014</option>
                            <option value='2015'>2015</option>
                            <option value='2016'>2016</option>
                            <option value='2017'>2017</option>
                            <option value='2018'>2018</option>
                            <option value='2019'>2019</option>
                            <option value='2020'>2020</option> */}
                          </select>
                          {dateError.year && (
                            <p>
                              <small className='text-red-700'>year is required!</small>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* age  */}
                    <div className=''>
                      <InputField
                        label={<FormattedMessage id='age' />}
                        placeholder='age'
                        value={totalAge}
                        readOnly={true}
                        type='number'
                      />
                    </div>
                    <div className=' text-center w-full flex  space-x-5'>
                      <button
                        onClick={() => navigate(-1)}
                        type='button'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
								hover:border-transparent  hover_gradient '
                      >
                        <span className=''>
                          {' '}
                          <FormattedMessage id='back' />{' '}
                        </span>
                      </button>

                      <button
                        type='submit'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                      >
                        <span className=''>
                          <FormattedMessage id='submit' />
                        </span>
                      </button>
                    </div>
                    <div className=''>
                      {/* {validation && validation.loading &&<LoadingSpinner/>} */}
                      {validation && validation.message && (
                        <div className=''>
                          <h1
                            className={`text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-center
                            ${validation.message !== 'Validation Error' ? 'text-green-500' : 'text-red-500'}
                          `}
                          >
                            {validation.message}
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    validation: state.shiftStudent,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addStudent: (studentInfo, avatar, getClassList) =>
      dispatch(createSectionStudentFromShiftAction(studentInfo, avatar, getClassList)),
    addStudentRequest: () => {
      dispatch(createSectionStudentFromShiftRequest());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSectionStudentFromShift);
