import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import { deleteSectionStudentAction } from '../../../../redux';

const StudentUploadPopUp = ({
  sectionLists,
  deleteStudent,
  title = 'Student Upload Message',
  data,
  onDelete,
  onCancel,
}) => {
  const onSubmit = () => {
    // deleteStudent(deleteData)
    // window.location.reload();
    onCancel();
  };

  const doNothing = (s = 's') => {
    return s;
  };

  return (
    <React.Fragment>
      <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
        <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
          <div className='flex justify-between'>
            <h1 className=' text-black font-semibold text-xl capitalize'>{title}</h1>
            <span className=' cursor-pointer hover:text-green-600'>
              <FontAwesomeIcon icon={faXmark} onClick={data?.loading ? doNothing : onCancel} />
            </span>
          </div>
          {/* <!-- horizontal line  --> */}
          <div className=' my-2 border border-gray-300 '></div>
          {/* <!-- update form start  --> */}
          <div className='w-full pt-5 pb-5'>
            {/* <!-- login forms  --> */}
            <div className='flex justify-center  flex-col space-y-5   px-4'>
              {/* <!-- delete message  --> */}

              {data && data?.error === false ? (
                <h1 className='text-green-500 text-center font-semibold text-base md:text-xl'>
                  {data && data?.message}
                </h1>
              ) : null}
              <div className='text-left pb-10 max-h-96 overflow-y-scroll'>
                {data && data?.error && (
                  <h1 className='text-red-500 font-semibold text-base md:text-xl mb-4'>{data && data?.message}</h1>
                )}
                {data && data.error && <p>{data?.error?.import_file}</p>}

                {data && data.error === true ? (
                  <React.Fragment>
                    <div className='flex gap-3 bg-green-50 p-3'>
                      <span className='flex-none w-10 h-5 text-base font-semibold capitalize'>row</span>
                      <span className='flex-1 text-base font-semibold capitalize'>error message</span>
                    </div>

                    {data &&
                      data.data !== null &&
                      data.data.map((msg, index) => {
                        return (
                          <div key={index} className='flex gap-3 bg-white p-3 border border-gray-300'>
                            <span className='flex-none w-10 h-5 text-base font-thin'>{msg?.row}</span>
                            <span className='flex-1 text-base font-thin'>{msg?.errors[0]}</span>
                          </div>
                        );
                      })}
                  </React.Fragment>
                ) : null}
              </div>

              {/* <!-- button  --> */}
              {data?.loading ? (
                <LoadingSpinner />
              ) : (
                <div className=' text-center w-full flex  space-x-5'>
                  <button
                    onClick={onCancel}
                    type='button'
                    className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
                  >
                    <span className=''> cancel </span>
                  </button>

                  <button
                    onClick={onSubmit}
                    type='button'
                    className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                  >
                    <span className=''>ok</span>
                  </button>
                </div>
              )}

              {/* <!-- submit success-fully  --> */}
              {/* {sectionLists && sectionLists.loading && <LoadingSpinner/>} */}

              {sectionLists && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {sectionLists.message}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    sectionLists: state.shiftStudent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteStudent: (listInfo) => dispatch(deleteSectionStudentAction(listInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentUploadPopUp);
