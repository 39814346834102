import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { logOut } from '../redux';
import DropdownItem from './DropdownItem';
import DropDownMenu from './DropDownMenu';
import InstitutionLogo from '../assets/image/logo/logo.png';
import { FormattedMessage } from 'react-intl';

const UserProfileLogo = ({ logOut, auth }) => {
  const [isProfileDropdownMenuOpen, setIsProfileDropdownMenuOpen] = useState(false);

  const handelClickToLogOut = () => {
    logOut();
  };

  return (
    <React.Fragment>
      {/* <!-- TODO: profile image  --> */}
      {/* {auth && auth.currentUser ?(<React.Fragment></React.Fragment>):(<LogInButton />)} */}
      <div
        className='relative'
        onMouseOver={() => setIsProfileDropdownMenuOpen(true)}
        onMouseLeave={() => setIsProfileDropdownMenuOpen(false)}
      >
        <div className='flex items-center cursor-pointer lg:py-[25px]'>
          <img
            className='mr-2 w-9 h-9 rounded-full border border-green-600 p-[2px] institutionlogo'
            src={InstitutionLogo}
            alt='profile avatar'
          />
          <h1 className=' text-black capitalize text-base'>
            {auth && auth.currentUser?.data?.ims_institute_name
              ? auth.currentUser?.data?.ims_institute_name.split(' ')[0]
              : '....'}
          </h1>
          <span className='ml-2'>
            <i className='fa-solid fa-angle-down'></i>
          </span>
        </div>
        {/* <!-- dropdown --> */}
        <div
          className={`absolute z-50  right-0 bg-white shadow-md text-white 
                	${isProfileDropdownMenuOpen ? '' : 'hidden  '} `}
        >
          <DropDownMenu>
            <DropdownItem link={`dashboard`} itemName={<FormattedMessage id='dashboard' />} />
            <DropdownItem link={`profile`} itemName={<FormattedMessage id='profile' />} />
            <li className='relative' onClick={handelClickToLogOut}>
              <button className='dropdown__list hover:border-b-2 hover:border-green-600 hover:bg-green-100'>
                <span>
                  <FormattedMessage id='logout' />
                </span>
              </button>
            </li>
          </DropDownMenu>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfileLogo);
