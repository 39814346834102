import axios from 'axios';
import {
  INSTITUTION_CHECK_REQUEST,
  INSTITUTION_CHECK_SUCCESS,
  INSTITUTION_CHECK_FAILURE,
} from '../constance/checkInstitution.constant';
// action creator
export const institutionCheckRequest = (institution) => {
  return {
    type: INSTITUTION_CHECK_REQUEST,
    payload: institution,
  };
};

export const institutionCheckSuccess = (institution) => {
  return {
    type: INSTITUTION_CHECK_SUCCESS,
    payload: institution,
  };
};
export const institutionCheckFailure = (error) => {
  return {
    type: INSTITUTION_CHECK_FAILURE,
    payload: error,
  };
};

// ! check institution from api....
export const checkInstitution = (institutionCheck) => {
  const inputData = {
    education_type: institutionCheck.education_type,
    eiin: institutionCheck.eiinNumber,
  };
  // console.log('🚀 ~ file: checkInstitution.actions.js ~ line 33 ~ checkInstitution ~ inputData', inputData);
  return (dispatch) => {
    dispatch(institutionCheckRequest(inputData));
    const apiUrl = process.env.REACT_APP_API_URL;
    axios
      .post(`${apiUrl}institution/check`, inputData)
      .then((response) => {
        // console.log(response.data);
        dispatch(institutionCheckSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(institutionCheckFailure(error.message));
      });
  };
};
