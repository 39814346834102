import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  GET_LOGGED_IN_USER,
  GET_LOGGED_OUT_USER,
  USER_PASSWORD_INCORRECT,
  USER_LOGGED_OUT_REQUEST,
  USER_LOGGED_OUT_SUCCESS,
  USER_LOGGED_OUT_FAILED,
} from '../constance/user.constants';

const initialState = {
  userData: [],
  userLoggedIn: false,
  loading: true,
  isPassCorrect: true,
  isLoggedIn: false,
  error: '',
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        userData: action.payload,
        userLoggedIn: false,
        loading: true,
        isPassCorrect: true,
        error: '',
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        userLoggedIn: true,
        loading: false,
        isPassCorrect: true,
        isLoggedIn: true,
        error: '',
      };
    case USER_PASSWORD_INCORRECT:
      return {
        ...state,
        userData: action.payload,
        userLoggedIn: false,
        loading: false,
        isPassCorrect: false,
        error: '',
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        userData: action.payload,
        userLoggedIn: false,
        loading: false,
        isPassCorrect: true,
        error: action.payload,
      };
    case USER_LOGGED_OUT_REQUEST:
      return {
        ...state,
        userData: action.payload,
        userLoggedIn: true,
        loading: true,
        isPassCorrect: true,
        isLoggedIn: true,
        error: '',
      };
    case USER_LOGGED_OUT_SUCCESS:
      return {
        ...state,
        userData: [],
        userLoggedIn: false,
        loading: false,
        isPassCorrect: false,
        isLoggedIn: false,
        error: '',
      };
    case USER_LOGGED_OUT_FAILED:
      return {
        ...state,
        userData: [],
        userLoggedIn: false,
        loading: false,
        isPassCorrect: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

// loggged-in user data
const loginUserState = {
  loading: false,
  isLoggedIn: null,
  currentUser: [],
};
export const loggedInUserReducer = (state = loginUserState, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER:
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: true,
      };
    case GET_LOGGED_OUT_USER:
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
