import { faMagnifyingGlass, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toBnNumber } from '../../../common/functions';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../Components/EmptyList';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import LanguageContext from '../../../context/LanguageContext';
import Layout from '../../../layout/Layout';
import { getTeacherList } from '../../../redux';
import { teacherAddRequest } from '../../../redux/actions/teacher.actions';
import {
  deleteTeacherRequest,
  teacherListAction,
  updateTeacherRequest,
} from '../../../redux/actions/upgradeClass.action';
import TeacherDeletePopUp from './teacherPopup/TeacherDeletePopUp';
const TeacherListPage = ({
  getTeacherList,
  teacherList,
  hasShift,
  teacherAddRequest,
  deleteTeacherRequest,
  teacherListAction,
  updateTeacherRequest,
}) => {
  const navigate = useNavigate();
  const studentData = useLocation();
  const { defaultLanguage } = useContext(LanguageContext);
  // teacher list api call
  useEffect(() => {
    // getTeacherList();
    teacherListAction();
  }, [getTeacherList, teacherListAction]);

  // open delete modal
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState(null);

  const handelModalClose = () => {
    setOpenDeleteModal(false);
    // window.location.reload()
  };

  const handelDeleteSection = (data) => {
    deleteTeacherRequest();
    setOpenDeleteModal(true);
    setData(data);
  };

  // go to teacher edit page
  const goToEditPage = (teacher) => {
    updateTeacherRequest();
    navigate(`/teacher-list/edit-teacher`, { state: teacher });
  };
  // go to teacher Profile page
  const gotoProfilePage = (teacher) => {
    navigate(`/teacher-list/teacher-profile`, { state: teacher });
  };
  const gotoTeacherAddPage = () => {
    teacherAddRequest();
    navigate(`/teacher-list/add-teacher`);
  };
  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />

      {/* all popup message here  */}
      {OpenDeleteModal && (
        <TeacherDeletePopUp
          data={data}
          title={<FormattedMessage id='deleteTeacher' />}
          handelModalClose={handelModalClose}
          onCancel={handelModalClose}
        />
      )}

      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <div className='container mx-auto'>
          <div className='flex flex-wrap items-center w-full px-4 sm:w-4/5 mx-auto'>
            {/* <!-- add teachers  --> */}
            <div className=''>
              <h1 className='text-2xl md:text-3xl capitalize font-semibold text-black'>
                <FormattedMessage id='teacherList' />
              </h1>
            </div>
            {/* <!-- teacher search input  --> */}
            <div className=' ml-auto relative order-2 md:order-1 w-full md:w-auto mt-4 md:mt-0'>
              {/* <form
                                // onSubmit={handelTeacherSearch}
                                >
                                    <input
                                        className='form-select capitalize block w-full pl-3 pr-6 py-1.5 text-base font-normal text-gray-700 bg-white0 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-full transition ease-in-out m-0
                                    focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                                        type='search'
                                        name="studentNameSearch"
                                        id='studentNameSearch'
                                        // value={teacherNameSearch}
                                        placeholder='search'
                                        // onChange={(e)=>setTeacherNameSearch(e.target.value)}
                                    />
                                    <button type="submit"  className='text-gray-500 hover:text-green-600 cursor-pointer absolute top-2 right-2'>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                </form> */}
            </div>

            {/* <!-- button  --> */}
            <div className='ml-auto md:ml-4 order-1 md:order-2'>
              <button
                onClick={gotoTeacherAddPage}
                type='button'
                className='py-2 px-6 capitalize text-base md:text-[14px] rounded-full text-white bg-gradient hover_gradient'
              >
                <span>
                  <FormattedMessage id='add-teacher' />
                </span>
              </button>
            </div>
          </div>
        </div>
        {teacherList && teacherList.loading ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            {teacherList && teacherList.data?.length ? (
              <React.Fragment>
                {/* table start */}
                <div className='container mx-auto'>
                  <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
                    <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                      {/* <!-- total table info  --> */}
                      <div className='flex flex-wrap flex-col md:flex-row items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                        <div className='mb-3 md:mb-0 flex space-x-5'>
                          <p className='text-base text-black capitalize'>
                            <span className='ml-1'>
                              <FormattedMessage id='allTeacher' />:{' '}
                              <span>
                                {defaultLanguage === 'bn'
                                  ? toBnNumber(teacherList.data?.length)
                                  : teacherList.data?.length}
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                        <thead className=' text-left bg-[#BFE3B5]'>
                          <tr>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='teacherNid' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='teacherName' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='class' />
                            </th>
                            {hasShift && hasShift?.data?.has_shift === 'yes' && (
                              <th className=' border border-gray-300 capitalize p-2'>
                                <FormattedMessage id='shift' />
                              </th>
                            )}
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='section' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='option' />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {teacherList &&
                            teacherList.data.map((teacher, index) => {
                              return (
                                <tr key={teacher.name + index} className='hover:bg-green-50 cursor-pointer'>
                                  <td
                                    onClick={() => gotoProfilePage(teacher)}
                                    className='break-all border border-gray-300 p-2'
                                  >
                                    {teacher.nid}
                                  </td>
                                  <td
                                    onClick={() => gotoProfilePage(teacher)}
                                    className='break-all border border-gray-300 p-2 capitalize'
                                  >
                                    <span className=' cursor-pointer'>{teacher.name}</span>
                                  </td>
                                  <td
                                    onClick={() => gotoProfilePage(teacher)}
                                    className='break-all border border-gray-300 p-2 capitalize'
                                  >
                                    {teacher.class_name_en}
                                  </td>
                                  {hasShift && hasShift?.data?.has_shift === 'yes' && (
                                    <td
                                      onClick={() => gotoProfilePage(teacher)}
                                      className='break-all border border-gray-300 p-2 capitalize'
                                    >
                                      {teacher.shift_name_en === null ? (
                                        'Not Assign'
                                      ) : (
                                        <span>{teacher.shift_name_en}</span>
                                      )}
                                    </td>
                                  )}
                                  <td
                                    onClick={() => gotoProfilePage(teacher)}
                                    className='break-all border border-gray-300 p-2 capitalize'
                                  >
                                    {teacher.section_name_en === null ? (
                                      'Not Assign'
                                    ) : (
                                      <span>{teacher.section_name_en}</span>
                                    )}
                                  </td>

                                  <td className='break-all border border-gray-300 p-2 space-x-2 relative z-10'>
                                    <span onClick={() => goToEditPage(teacher)}>
                                      <span className='cursor-pointer w-8 h-8 leading-8 bg-[#FEEBDB] text-[#F87D2C] inline-block text-center text-base rounded-full'>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                      </span>
                                    </span>
                                    <span
                                      onClick={() => handelDeleteSection(teacher)}
                                      className='cursor-pointer w-8 h-8 leading-8 bg-[#FDE4E9] text-[#EF5E76] inline-block text-center text-base rounded-full'
                                    >
                                      <FontAwesomeIcon icon={faTrashCan} />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* table end */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <EmptyList message='Your teacher list is empty' />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    teacherList: state.sectionTeachers,
    message: state.teacherList.message || null,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTeacherList: () => dispatch(getTeacherList()),
    teacherAddRequest: () => dispatch(teacherAddRequest()),
    deleteTeacherRequest: () => dispatch(deleteTeacherRequest()),
    teacherListAction: () => dispatch(teacherListAction()),
    updateTeacherRequest: () => dispatch(updateTeacherRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherListPage);
