import {
  POST_NUTRITION_EDUCATION_REQUEST,
  POST_NUTRITION_EDUCATION_SUCCESS,
  POST_NUTRITION_EDUCATION_FAILED,
  FILTER_NUTRITION_EDUCATION_REQUEST,
  FILTER_NUTRITION_EDUCATION_SUCCESS,
  FILTER_NUTRITION_EDUCATION_FAILED,
} from '../constance/DemoPrograms.constants';

const initialState = {
  loading: false,
  data: [],
  error: '',
  message: '',
  status: true,
};
export const nutritionEducationReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_NUTRITION_EDUCATION_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: '',
        message: '',
      };
    case POST_NUTRITION_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.data.status,
        data: action.payload,
        error: action.payload.data.error,
        message: action.payload.data.message,
      };
    case POST_NUTRITION_EDUCATION_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        data: [],
        error: action.payload,
        message: '',
      };
    // !filter student
    case FILTER_NUTRITION_EDUCATION_REQUEST:
      return {
        ...state,
        loading: false,
        status: true,
        data: [],
        error: '',
        message: '',
      };
    case FILTER_NUTRITION_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        data: action.payload,
        error: '',
        message: '',
      };
    case FILTER_NUTRITION_EDUCATION_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        data: [],
        error: action.payload,
        message: '',
      };

    default:
      return state;
  }
};
