import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { deleteTeacherAction } from '../../../../redux';
// import InputField from "../../../Components/InputField"
// import LoadingSpinner from "../../../Components/LoadingSpinner"
// import { createSectionListAction, deleteSectionListAction, updateSectionListAction } from "../../../redux"

const TeacherDeletePopUp = ({
  teacherDelete,
  title = 'set title',
  deleteMessage,
  message = 'add required message !',
  data,
  onCancel,
  handelModalClose,
}) => {
  const { name } = data;
  const onDelete = () => {
    let teacherData = {
      teacher_id: data.id,
      institution_id: data.institution_id,
    };
    teacherDelete(teacherData);
  };

  useEffect(() => {
    if (deleteMessage === 'Teacher Deleted Successfully') {
      setTimeout(function () {
        handelModalClose();
      }, 2000);
    }
  }, [handelModalClose, deleteMessage]);

  return (
    <React.Fragment>
      <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
        <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
          <div className='flex justify-between'>
            <h1 className=' text-black font-semibold text-xl capitalize'>{title && title}</h1>
            <span className=' cursor-pointer hover:text-green-600'>
              <FontAwesomeIcon icon={faXmark} onClick={onCancel} />
            </span>
          </div>
          {/* <!-- horizontal line  --> */}
          <div className=' my-2 border border-gray-300 '></div>
          {/* <!-- update form start  --> */}
          <div className='w-full pt-5 pb-5'>
            {/* <!-- login forms  --> */}
            <div className='flex justify-center  flex-col  space-y-5  px-4'>
              {/* <!-- delete message  --> */}
              <div className='text-center py-10'>
                <h1 className='text-black font-semibold  text-base md:text-xl'>
                  Are you sure want to <span className=' text-red-700 uppercase'>delete </span>
                  <span className='font-bold'> {name}</span>
                  <span> ?</span>
                </h1>
              </div>

              {/* <!-- button  --> */}
              <div className=' text-center w-full flex  space-x-5'>
                <button
                  onClick={onCancel}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
                >
                  <span className=''>
                    <FormattedMessage id='cancel' />
                  </span>
                </button>

                <button
                  onClick={onDelete}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=''>
                    <FormattedMessage id='delete' />
                  </span>
                </button>
              </div>

              {/* <!-- submit success-fully  --> */}
              {deleteMessage && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {deleteMessage}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    deleteMessage: state.sectionTeachers?.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teacherDelete: (dataInfo) => dispatch(deleteTeacherAction(dataInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDeletePopUp);
