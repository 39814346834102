import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../../../Components/EmptyList';
import {
  getClassWiseShiftListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
} from '../../../../../redux';
import { calculateMonth, calculateMonthNumber, currentDate } from '../../../../../redux/service/service';
import ProgramHeading from '../ProgramHeading';
import StudentSearch from '../StudentSearch';
import StudentRow from '../Nutrition/StudentRow';
import {
  getDewormingProgramStudentListAction,
  getStockListAction,
  postDewormingProgramStudentListAction,
  postProgramStudentListRequest,
} from '../../../../../redux/actions/upgradeClass.action';
import { useContext } from 'react';
import StoreContext from '../../../../../context/StoreContext';
import { useMemo } from 'react';
import Pagination from '../../../../../Components/Pagination/Pagination';
import LanguageContext from '../../../../../context/LanguageContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { toBnNumber } from '../../../../../common/functions';
const DewormingPrograms02 = ({
  // method
  getClassLists,
  getShiftLists,
  getSectionLists,
  getDewormingProgramStudentList,
  postStudentForProgram,
  postProgramStudentListRequest,
  getStockList,
  // state
  hasShift,
  classLists,
  shiftList,
  sectionList,
  studentList,
  studentMetaData,
  postMsg,
  stockList,
}) => {
  const intl = useIntl();
  const { classList, classLoading } = useContext(StoreContext);
  const { defaultLanguage } = useContext(LanguageContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [checkShift, setCheckShift] = useState(false);
  const [GradId, setGradId] = useState('');
  const [ShiftId, setShiftId] = useState('');
  const [SectionId, setSectionId] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [filterSectionCheck, setFilterSectionCheck] = useState(false);
  const [RoundId, setRoundId] = useState('');
  const [IsRoundChange, setIsRoundChange] = useState(false);
  const [StockId, setStockId] = useState('');
  const [AvailableStock, setAvailableStock] = useState(0);
  const [StockListArray, setStockListArray] = useState(null);
  const [SelectedDate, setSelectedDate] = useState(() => currentDate());
  const [IsDateChange, setIsDateChange] = useState(false);
  const [monthName, setMonthName] = useState('');
  const [MonthNumber, setMonthNumber] = useState('');
  const [SelectedStudentId, setSelectedStudentId] = useState([]);
  const [StudentArray, setStudentArray] = useState([]);
  const [StudentFilteredArray, setStudentFilteredArray] = useState([]);
  const [SubmitStudent, setSubmitStudent] = useState(false);
  const [StockError, setStockError] = useState('');
  const [StudentError, setStudentError] = useState('');
  const [RoundError, setRoundError] = useState('');

  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(250);

  useEffect(() => {
    const allClass = classList;
    let defaultSectionId = '';
    // console.log(allClass);
    setGradId(allClass[0]?.grade);

    if (hasShift?.data?.has_shift === 'yes') {
      setShiftId(allClass[0]?.shift_section[0]?.shift_id);
      setSectionId(allClass[0]?.shift_section[0]?.section[0]?.id);
      defaultSectionId = allClass[0]?.shift_section[0]?.section[0]?.id;
      setShiftArray(allClass[0]?.shift_section);
      setSectionArray(allClass[0]?.shift_section[0]?.section);
    } else {
      setSectionId(allClass[0]?.shift_section[0]?.id);
      defaultSectionId = allClass[0]?.shift_section[0]?.id;
      setSectionArray(allClass[0]?.shift_section);
      // console.log(allClass[0]?.shift_section)
    }
  }, [classList]);

  useEffect(() => {
    const data = {
      institution_id: institutionId,
      grade: GradId,
      section_id: SectionId,
      pagination_limit: pageLimit,
    };

    if (RoundId) {
      data['round'] = RoundId;
      data['date'] = SelectedDate;
    }

    if (hasShift?.data?.has_shift === 'yes') {
      data['shift_id'] = ShiftId;
      if (GradId && ShiftId && SectionId) {
        getDewormingProgramStudentList(data);
      }
    } else {
      if (GradId && SectionId) {
        getDewormingProgramStudentList(data);
      }
    }
  }, [GradId, ShiftId, SectionId, RoundId]);

  // //NOTE: stock list api call
  useEffect(() => {
    const data = {
      institution_id: institutionId,
      type: 'deworming',
    };
    getStockList(data);
  }, []);
  useEffect(() => {
    setStockListArray(stockList?.data);
  }, [stockList]);

  // NOTE: FILTER STUDENT LIST BASED ON PARTICIPATED NON-PARTICIPATED DATA
  useEffect(() => {
    setStudentFilteredArray(studentList?.data?.data);
    setStudentArray(studentList?.data?.data);
  }, [studentList]);
  const handelFilterStudent = (e) => {
    let filterValue = e.target.value;

    if (filterValue === '') {
      setStudentFilteredArray(StudentArray);
    }
    if (filterValue === 'yes') {
      const data = StudentArray.filter((student) => {
        return student.participation_status === 'yes';
      });
      setStudentFilteredArray(data);
    }
    if (filterValue === 'no') {
      const data = StudentArray.filter((student) => {
        return student.participation_status === 'no';
      });
      setStudentFilteredArray(data);
    }
  };

  // NOTE:POST STUDENT WITH ID
  useEffect(() => {
    const data = {
      institution_id: institutionId,
      grade: GradId,
      has_shift: hasShift?.data?.has_shift,
      shift_id: ShiftId,
      section_id: SectionId,
      stock_id: StockId,
      month: MonthNumber,
      date: SelectedDate,
      round: Number(RoundId),
      student_id: SelectedStudentId.map(Number),
    };
    if (SelectedStudentId.value !== 0 && SubmitStudent) {
      postStudentForProgram(data);
      setSelectedStudentId([]);
      setSubmitStudent(false);
      setTimeout(() => {
        postProgramStudentListRequest();
      }, 5000);
    }
  }, [SubmitStudent]);

  // pagination
  // const handlePageClick = (data) => {
  //   setPageNumber(data.selected + 1);
  // };

  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return StudentFilteredArray && StudentFilteredArray?.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, StudentFilteredArray]);

  useEffect(() => {
    setStockError(postMsg?.error?.stock_id);
  }, [postMsg?.error?.stock_id]);
  useEffect(() => {
    setStudentError(postMsg?.error?.student_id);
  }, [postMsg?.error?.student_id]);
  useEffect(() => {
    setRoundError(postMsg?.error?.round);
  }, [postMsg?.error?.round]);

  const handelChangeStock = (e) => {
    let stockId = e.target.value;
    setStockId(stockId);
    const saveId = StockListArray.filter((tablet) => {
      return tablet.id == stockId;
    });
    if (!stockId) {
      setAvailableStock(0);
    } else {
      setAvailableStock(saveId);
    }
  };

  const handelSelectDate = (e) => {
    setSelectedDate(e.target.value);
    // setIsDateChange(true)
  };
  const handelSelectRound = (e) => {
    setRoundId(e.target.value);
    setIsRoundChange(true);
  };

  // get month name
  useEffect(() => {
    setMonthName(calculateMonth(SelectedDate));
    setMonthNumber(calculateMonthNumber(SelectedDate));
  }, [SelectedDate]);

  const handelSelectClass = (e) => {
    setGradId(e.target.value);
    // setInitialClassId(e.target.value);

    setShiftId('');
    setSectionId('');

    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    // setGradeName(singleClass?.name_en);

    if (hasShift?.data?.has_shift === 'yes') {
      //   getShiftLists(data);
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      //   getSectionLists(data);
      setSectionArray(singleClass?.shift_section);
    }
  };

  const handelSelectShift = (e) => {
    setShiftId(e.target.value);

    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    // setShiftName(singleShift?.shift_name_en);
    setSectionArray(singleShift?.section);
    setSectionId('');
  };

  const handelSelectSection = (e) => {
    setSectionId(e.target.value);
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <React.Fragment>
        <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
          {/* program heading */}
          <ProgramHeading title={<FormattedMessage id='deworming' />}>
            {/* <StudentSearch onSubmit={handelSubmit} onChange={handelStudentSearch} value={searchResult} name="nameSearch"/> */}
          </ProgramHeading>
          {/* initial query class/shift/section start*/}

          <div className='container mx-auto'>
            <div className='flex flex-wrap items-center w-full px-4  sm:w-4/5 mx-auto '>
              {!classLoading ? (
                <div className='w-full flex flex-row space-x-5 bg-[#399737] p-4 rounded-md'>
                  {/* class */}
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                      <FormattedMessage id='class' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={GradId}
                      onChange={handelSelectClass}
                      name='class'
                      id='class'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                      {classList &&
                        classList?.map((grade, index) => {
                          return (
                            <option key={index} value={grade.grade}>
                              {defaultLanguage === 'en' ? grade.name_en : grade.name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* shift */}
                  {hasShift?.data?.has_shift === 'yes' && (
                    <div className='w-full'>
                      <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                        <FormattedMessage id='shift' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        value={ShiftId}
                        onChange={handelSelectShift}
                        name='shift'
                        id='shift'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                        {shiftArray &&
                          shiftArray?.map((shift, index) => {
                            return (
                              <option value={shift.shift_id} key={index}>
                                {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {/* section */}
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                      <FormattedMessage id='section' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={SectionId}
                      onChange={handelSelectSection}
                      name='section'
                      id='section'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                      {sectionArray &&
                        sectionArray?.map((section, index) => {
                          return (
                            <option value={section.id} key={index}>
                              {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </div>
          {/*  )} */}
          {/* initial query class/shift/section end */}

          {/* student list with filter section */}
          <div className='container mx-auto'>
            <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
              <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                {/* <!-- total number  --> */}
                <div className='flex flex-wrap flex-col md:flex-row items-center md:items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                  <div className='mb-3 md:mb-0'>
                    <p className='text-base text-black capitalize'>
                      <FormattedMessage id='totalStudent' />:{' '}
                      {studentMetaData && (
                        <span>
                          {defaultLanguage === 'bn' ? toBnNumber(studentMetaData.total) : studentMetaData.total}
                        </span>
                      )}
                    </p>
                  </div>
                  {/* filter options  */}
                  <div className='w-full md:w-3/4 grid grid-cols-2 md:grid-cols-5 gap-4'>
                    {/* <!--select  round --> */}
                    <div className=' xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='round'>
                        <FormattedMessage id='round' />
                      </label>
                      <select
                        value={RoundId}
                        onChange={handelSelectRound}
                        id='round'
                        className='form-select block w-auto px-3 py-2 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none '
                        aria-label='Default select example'
                      >
                        <option value=''>{intl.formatMessage({ id: 'selectRound' })}</option>
                        <option value='1'>
                          {intl.formatMessage({ id: 'round' })} {defaultLanguage === 'en' ? 1 : toBnNumber(1)}
                        </option>
                        <option value='2'>
                          {intl.formatMessage({ id: 'round' })} {defaultLanguage === 'en' ? 2 : toBnNumber(2)}
                        </option>
                      </select>
                      {/* {ErrorRound &&ErrorRound && (
                                            <p><small className='text-red-700'>{ErrorRound}</small></p>
                                        )} */}
                    </div>
                    {/* <!--select  date --> */}
                    <div className=' xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='date'>
                        <FormattedMessage id='date' />
                      </label>
                      <input
                        className='form-select uppercase block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                            focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        type='date'
                        name='date'
                        id='date'
                        value={SelectedDate}
                        onChange={handelSelectDate}
                      />
                    </div>
                    {/* <!--select  weeks --> */}
                    <div className=' xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='month'>
                        <FormattedMessage id='month' />
                      </label>
                      {/* monthName
                                            MonthNumber */}
                      <select
                        id='month'
                        value={MonthNumber && MonthNumber}
                        className='form-select block w-auto px-3 py-2 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none '
                        aria-label='Default select example'
                      >
                        <option value={MonthNumber && MonthNumber}>
                          {monthName && intl.formatMessage({ id: monthName })}{' '}
                        </option>
                      </select>
                    </div>
                    {/* <!--select  stock --> */}
                    <div className='xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='subject'>
                        <FormattedMessage id='stockName' />
                      </label>
                      <select
                        value={StockId}
                        onChange={handelChangeStock}
                        id='subject'
                        className='form-select block w-auto px-3 py-2 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value=''>{intl.formatMessage({ id: 'selectStock' })}</option>
                        {stockList &&
                          stockList?.data.map((stock, index) => {
                            return (
                              <option key={index + stock} value={stock.id}>
                                {stock.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {/* <!--available tablet --> */}
                    <div className=' xl:w-full flex flex-col'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='weeks'>
                        <FormattedMessage id='availableTablet' />
                      </label>
                      <input
                        // value={AvailableStock ? AvailableStock[0]?.available_tablet : 0}
                        value={
                          AvailableStock && AvailableStock.length > 0
                            ? defaultLanguage === 'bn'
                              ? toBnNumber(AvailableStock[0]?.available_tablet)
                              : AvailableStock[0]?.available_tablet
                            : defaultLanguage === 'bn'
                            ? toBnNumber(0)
                            : 0
                        }
                        readOnly
                        id='weeks'
                        className='form-select block w-auto px-3 py-2 text-center text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none '
                        aria-label='Default select example'
                      />
                    </div>
                    {/* <!-- end  --> */}
                  </div>
                </div>
                {/* <!-- table header start --> */}
              </div>
              <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                {studentList && studentList?.loading ? (
                  <LoadingSpinner />
                ) : (
                  <React.Fragment>
                    {currentTableData && currentTableData?.length ? (
                      <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                        <thead className=' text-left bg-[#BFE3B5]'>
                          <tr>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='rollNo' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='studentName' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='gender' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='age' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <select
                                onChange={handelFilterStudent}
                                id='institute'
                                className='w-auto bg-[#B8E6B6] capitalize border-0 focus:outline-none font-bold'
                                aria-label='Default select example'
                              >
                                <option value=''>{intl.formatMessage({ id: 'all' })}</option>
                                <option value='yes'>{intl.formatMessage({ id: 'intaked' })}</option>
                                <option value='no'>{intl.formatMessage({ id: 'notIntaked' })}</option>
                              </select>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData &&
                            currentTableData.map((student, index) => {
                              return (
                                <StudentRow
                                  key={student.id}
                                  student={student}
                                  SelectedStudentId={SelectedStudentId}
                                  setSelectedStudentId={setSelectedStudentId}
                                />
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <EmptyList message='Your section List is empty' />
                    )}
                  </React.Fragment>
                )}
              </div>
              {/* pagination section start */}

              <div className='my-5'>
                <div className='w-full px-4 sm:w-4/5 mx-auto'>
                  <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={StudentFilteredArray?.length || 0}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>

              {/* pagination section end */}

              {/* <!-- submit button  --> */}
              <div className='flex justify-center mt-4'>
                <button
                  disabled={SelectedStudentId.length === 0 ? true : false}
                  onClick={() => setSubmitStudent(true)}
                  type='button'
                  className={`w-1/3 py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded ${
                    SelectedStudentId.length === 0
                      ? 'bg-gray-300 text-black '
                      : ' text-white bg-gradient hover_gradient '
                  } `}
                >
                  <span>
                    <FormattedMessage id='submit' />
                  </span>
                </button>
              </div>

              {StockError && StockError && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm  font-bold lg:font-medium xl:font-semibold text-red-500'>{StockError}</h1>
                </div>
              )}
              {StudentError && StudentError && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm  font-bold lg:font-medium xl:font-semibold text-red-500'>{StudentError}</h1>
                </div>
              )}
              {RoundError && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm  font-bold lg:font-medium xl:font-semibold text-red-500'>{RoundError}</h1>
                </div>
              )}

              {postMsg && postMsg && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {postMsg.message}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentList: state.ProgramStudentList,
    studentMetaData: state.ProgramStudentList?.data?.meta,
    subjectList: state.subjects,
    weekNumber: state.weeks?.data?.week,
    stockList: state.stockList,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
    // show message for posted data info
    postMsg: state.ParticipatedStudents,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getStockList: (dataInfo) => dispatch(getStockListAction(dataInfo)),
    getDewormingProgramStudentList: (studentInfo) => dispatch(getDewormingProgramStudentListAction(studentInfo)),
    postStudentForProgram: (dateInfo) => dispatch(postDewormingProgramStudentListAction(dateInfo)),
    postProgramStudentListRequest: () => dispatch(postProgramStudentListRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DewormingPrograms02);
