import React from 'react';

const BmiResult = ({ report }) => {
  console.log('🚀 ~ file: BmiResult.jsx ~ line 4 ~ BmiResult ~ report', report);

  const {
    boy_normal,
    boy_mildOverWeight,
    boy_overWeight,
    boy_obesity,
    boy_mildMalnourished,
    boy_moderatelyMalnourished,
    boy_severeMalnourished,
    girl_normal,
    girl_mildOverWeight,
    girl_overWeight,
    girl_obesity,
    girl_mildMalnourished,
    girl_moderatelyMalnourished,
    girl_severeMalnourished,
  } = report?.data[0];

  return (
    <React.Fragment>
      <div className='container mx-auto'>
        <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
          <div className='border border-gray-300 w-full rounded p-10'>
            <div className=''>
              {/* <!-- title  --> */}
              <div className='text-left text-base font-semibold md:text-2xl md:font-bold'>BMI Result</div>
              <div className='flex flex-col md:flex-row md:space-x-10'>
                {/* <!-- boys  --> */}
                <div className='w-full md:w-1/2'>
                  <div className=' text-base font-semibold md:text-xl md:font-bold text-center capitalize mb-4'>
                    boys
                  </div>
                  <div className=' space-y-4'>
                    <div className='w-full bg-[#BFDEC1] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Normal weight student: {boy_normal}{' '}
                    </div>
                    <div className='w-full bg-[#FFE4C0] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Mild Overweight student: {boy_mildOverWeight}{' '}
                    </div>
                    <div className='w-full bg-[#FFCCCC] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Overweight student: {boy_overWeight}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Obesity student: {boy_obesity}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Mild Malnourished student: {boy_mildMalnourished}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Moderately Malnourished student: {boy_moderatelyMalnourished}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Severe Malnourished student: {boy_severeMalnourished}{' '}
                    </div>
                  </div>
                </div>
                {/* <!-- girls  --> */}
                <div className='w-full md:w-1/2'>
                  <div className=' text-base font-semibold md:text-xl md:font-bold text-center capitalize mb-4'>
                    girls
                  </div>
                  <div className=' space-y-4'>
                    <div className='w-full bg-[#BFDEC1] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Normal weight student: {girl_normal}{' '}
                    </div>
                    <div className='w-full bg-[#FFE4C0] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Mild Overweight student: {girl_mildOverWeight}{' '}
                    </div>
                    <div className='w-full bg-[#FFCCCC] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Overweight student: {girl_overWeight}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Obesity student: {girl_obesity}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Mild Malnourished student: {girl_mildMalnourished}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Moderately Malnourished student: {girl_moderatelyMalnourished}{' '}
                    </div>
                    <div className='w-full bg-[#FFA3A3] p-3 rounded text-base md:text-xl font-semibold text-gray-600'>
                      Severe Malnourished student: {girl_severeMalnourished}{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BmiResult;
