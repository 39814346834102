import React, { useContext, useEffect, useState } from 'react';
import BreadCrumbImage from '../../assets/image/breadcrumb/learning.png';
import SingleLearningModule from './SingleLearningModule';
import { connect } from 'react-redux';
import { getLearningModuleAction, getLearningModuleCategoryAction } from '../../redux';
import LoadingSpinner from '../../Components/LoadingSpinner';
import ProfileBreadCrumb from '../../Components/BreadCrumb/ProfileBreadCrumb';
import EmptyList from '../../Components/EmptyList';
import LanguageContext from '../../context/LanguageContext';
import { FormattedMessage } from 'react-intl';
const LearningModulePage = ({
  learningModule,
  learningModuleCategory,
  getLearningModule,
  getLearningModuleCategory,
}) => {
  const { defaultLanguage } = useContext(LanguageContext);
  const [ActiveModule, setActiveModule] = useState('1');
  // console.log('🚀 ~ file: LearningModulePage.jsx ~ line 19 ~ ActiveModule', ActiveModule);

  useEffect(() => {
    getLearningModuleCategory();
  }, [getLearningModuleCategory]);
  useEffect(() => {
    const data = {
      id: ActiveModule,
    };
    getLearningModule(data);
  }, [getLearningModule, ActiveModule]);

  return (
    <React.Fragment>
      {/* <Layout> */}
      {/* <!-- TODO: simple breadCrumb (design 1 ) START  --> */}
      <ProfileBreadCrumb title={<FormattedMessage id='learning-module' />} image={BreadCrumbImage} />

      {/* <!-- TODO: simple breadCrumb END --> */}
      {/* learning module container  */}
      <section className='mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className='container mx-auto'>
          <div className='w-full sm:w-4/5 mx-auto px-4'>
            <div className='flex flex-col sm:flex-row mx-auto w-full items-center justify-center gap-5 sm:gap-10'>
              {/* {learningModuleCategory && learningModuleCategory.loading && <LoadingSpinner/>} */}
              {learningModuleCategory &&
                learningModuleCategory.data.map((category) => {
                  return (
                    <button
                      key={category.id}
                      onClick={() => setActiveModule(`${category.id}`)}
                      className={` ${
                        ActiveModule === `${category.id}`
                          ? 'bg-gradient text-white border border-[#83C55E]'
                          : ' border-dashed border border-[#83C55E] '
                      } hover_gradient  hover:text-white w-full sm:w-1/2 md:w-1/3 h-16 text-center px-4   rounded-md font-semibold`}
                    >
                      {defaultLanguage === 'en' ? category.name_en : category.name_bn}
                    </button>
                  );
                })}
            </div>

            {/* <LoadingSpinner /> */}
            <div className='grid md:grid-cols gap-4 md:gap-8 lg:p-8'>
              {learningModule && learningModule.loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  {learningModule && learningModule?.data.length === 0 ? (
                    <EmptyList message='learning Module is empty' border />
                  ) : (
                    <React.Fragment>
                      {learningModule &&
                        learningModule.data.map((module) => {
                          return (
                            <SingleLearningModule module={module} key={module.id} defaultLanguage={defaultLanguage} />
                          );
                        })}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    learningModule: state.learningModule,
    learningModuleCategory: state.learningModuleCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLearningModule: (dataInfo) => dispatch(getLearningModuleAction(dataInfo)),
    getLearningModuleCategory: () => dispatch(getLearningModuleCategoryAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LearningModulePage);
