import {
  GET_CLASSES_LIST_REQUEST,
  GET_CLASSES_LIST_SUCCESS,
  GET_CLASSES_LIST_FAILED,
  GET_CLASS_STUDENT_INFO_REQUEST,
  GET_CLASS_STUDENT_INFO_SUCCESS,
  GET_CLASS_STUDENT_INFO_FAILED,
} from '../constance/class.constants';

const initialClassState = {
  loading: true,
  class: [],
  error: '',
};

export const classListsUnderInstituteReducer = (state = initialClassState, action) => {
  switch (action.type) {
    case GET_CLASSES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        class: [],
        error: '',
      };
    case GET_CLASSES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        class: action.payload,
        error: '',
      };
    case GET_CLASSES_LIST_FAILED:
      return {
        ...state,
        loading: false,
        class: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

const initialStudentInfoState = {
  loading: true,
  studentInfo: [],
  error: '',
};

export const classStudentInfoReducer = (state = initialStudentInfoState, action) => {
  switch (action.type) {
    case GET_CLASS_STUDENT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        studentInfo: action.payload,
        error: '',
      };
    case GET_CLASS_STUDENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        studentInfo: action.payload,
        error: '',
      };
    case GET_CLASS_STUDENT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        studentInfo: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
