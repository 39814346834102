import React from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import ChartResultList from '../ChartResultList';
const DashboardNutrition = ({ title = 'select Program', report }) => {
  // Gender tabs
  const [activeTabs, setActiveTabs] = useState('gender');
  const handelGenderTab = (e) => {
    e.preventDefault();
    setActiveTabs('gender');
  };
  const handelAgeTab = (e) => {
    e.preventDefault();
    setActiveTabs('age');
  };

  const genderData = {
    options: {
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
            enabled: true,
            offsetY: 100,
          },
        },
      },
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: [''],
      },
      stroke: {
        colors: ['transparent'],
        width: 50,
      },
      dataLabels: {
        style: {
          colors: ['#304758'],
        },
      },
      legend: {
        onItemHover: {
          highlightDataSeries: false,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    },
    series: [
      {
        name: 'Girls',
        data: [report?.data[0]?.gender?.participatedGirl],
      },
      {
        name: 'Boys',
        data: [report?.data[0]?.gender?.participatedBoy],
      },
    ],
  };
  const ageData = {
    options: {
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
            enabled: true,
            offsetY: 100,
          },
        },
      },
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: [''],
      },
      stroke: {
        colors: ['transparent'],
        width: 50,
      },
    },
    series: [
      {
        name: '10 - 14',
        data: [report?.data[0]?.age?.age10to14],
      },
      {
        name: '15 - 19',
        data: [report?.data[0]?.age?.age15to19],
      },
    ],
  };

  return (
    <React.Fragment>
      <div className='flex justify-between items-center pt-16 pb-16 border-t border-gray-300'>
        <div className=''>
          <h1 className=' text-base text-black md:text-lg capitalize font-bold'>
            <span className=' text-gray-500 font-semibold'>Program : </span> <span>{title && title}</span>
          </h1>
        </div>
        <div className=''>
          <div className=' rounded p-1 border border-green-600 inline-block'>
            <button
              onClick={handelGenderTab}
              type='button'
              className={`${
                activeTabs === 'gender' ? 'active' : null
              } py-2 px-6 capitalize text-[18px] rounded text-gray-600 hover:text-white hover_gradient bg_gradient_active`}
            >
              <span>gender </span>
            </button>
            <button
              onClick={handelAgeTab}
              type='button'
              className={`${
                activeTabs === 'age' ? 'active' : null
              } py-2 px-6 capitalize text-[18px] rounded text-gray-600 hover:text-white hover_gradient bg_gradient_active`}
            >
              <span>age</span>
            </button>
          </div>
        </div>
      </div>

      <div className=''>
        {activeTabs === 'gender' ? (
          <React.Fragment>
            <div className='w-full lg:w-2/5 mx-auto'>
              <Chart options={genderData.options} series={genderData.series} type='bar' height={320} />
            </div>
            <ChartResultList
              title='girls'
              totalNumber={report?.data[0]?.gender?.participatedGirl}
              percentage={report?.data[0]?.gender?.participatedGirlPercentage}
            />
            <ChartResultList
              title='boys'
              totalNumber={report?.data[0]?.gender?.participatedBoy}
              percentage={report?.data[0]?.gender?.participatedBoyPercentage}
            />
          </React.Fragment>
        ) : null}
        {activeTabs === 'age' ? (
          <React.Fragment>
            <div className='w-full lg:w-2/5 mx-auto'>
              <Chart options={ageData.options} series={ageData.series} type='bar' height={320} />
            </div>
            <ChartResultList
              title='10 - 14 Age'
              totalNumber={report?.data[0]?.age?.age10to14}
              percentage={report?.data[0]?.age?.age10to14Percentage}
            />
            <ChartResultList
              title='15 - 19 Age'
              totalNumber={report?.data[0]?.age?.age15to19}
              percentage={report?.data[0]?.age?.age15to19Percentage}
            />
          </React.Fragment>
        ) : null}
        <ChartResultList
          title=' total students'
          totalNumber={report?.data[0]?.gender?.totalStudent}
          percentage={report?.data[0]?.gender?.totalStudentPercentage}
        />
      </div>
    </React.Fragment>
  );
};
export default DashboardNutrition;
