import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import StoreContext from '../../../context/StoreContext';

import { getUpgradeClassListsAction } from '../../../redux';
import CheckShiftModal from './CheckShiftModal';
import SIngleSectionList from './SIngleSectionList';
const AddClassesPage = ({ getUpdatedClassLists, classData, checkShift }) => {
  const { classList, classLoading } = useContext(StoreContext);
  const navigate = useNavigate();

  const [IsShiftStatusOpen, setIsShiftStatusOpen] = useState(false);

  const goToNextPage = (checkShift, classList) => {
    localStorage.setItem(
      'sectionInfo',
      JSON.stringify({
        grade: classList?.grade,
        classId: classList?.id,
        class_name_en: classList?.name_en,
        class_name_bn: classList?.name_bn,
      })
    );
    if (checkShift?.data?.has_shift === 'no') {
      navigate(`/classes/section-list`, { state: classList });
    }
    if (checkShift?.data?.has_shift === 'yes') {
      navigate(`/classes/shift-list`, { state: classList });
    }
    if (checkShift?.data?.has_shift === null) {
      setIsShiftStatusOpen(true);
      // window.location.reload();
    }
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      {IsShiftStatusOpen && (
        <CheckShiftModal IsShiftStatusOpen={IsShiftStatusOpen} setIsShiftStatusOpen={setIsShiftStatusOpen} />
      )}
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* <!-- all classes --> */}
        <div className='container mx-auto'>
          {/* <!-- class title  --> */}
          <div className='flex justify-center w-full px-4 sm:w-4/5 mx-auto pb-4 lg:pb-10'>
            <h1 className=' capitalize text-2xl text-black font-semibold md:font-bold'>
              <FormattedMessage id='classes' />
            </h1>
          </div>

          {classLoading ? (
            <LoadingSpinner />
          ) : (
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 lg:gap-6  w-full px-4 sm:w-4/5 mx-auto '>
              {classList &&
                classList.map((classList, index) => {
                  return (
                    <div key={index} onClick={() => goToNextPage(checkShift, classList)}>
                      <SIngleSectionList classInfo={classList} />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classData: state.upgradedClassState,
    checkShift: state.login?.currentUser,
    // // check is shift yes or not
    // hasShift:state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getClasses: () => dispatch(getClassList()),
    getUpdatedClassLists: () => dispatch(getUpgradeClassListsAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddClassesPage);
