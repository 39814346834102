import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classBgIcon from '../../../assets/image/class-page/class-bg.png';
import { toBnNumber } from '../../../common/functions';
import LanguageContext from '../../../context/LanguageContext';

const SIngleSectionList = ({ classInfo, hasShift }) => {
  const { total_section, total_shift, total_student, name_en, grade, name_bn } = classInfo;
  const { defaultLanguage } = useContext(LanguageContext);
  return (
    <React.Fragment>
      {/* <!-- single class  --> */}

      {/* <Link to={`/classes/class-${grade}/shift`}> */}
      <div className=' cursor-pointer w-full border border-green-600 rounded bg-[#F8FFF6] group hover:bg-[#8FC15A] p-4 space-y-3 relative'>
        <span>
          <img className=' absolute right-0 bottom-0' src={classBgIcon} alt='' />
        </span>

        <h1 className='capitalize text-[18px] font-semibold md:text-2xl md:font-bold text-green-500 group-hover:text-white'>
          {defaultLanguage === 'en' ? name_en : name_bn}
        </h1>

        <div className='flex items-center space-x-2'>
          <h1 className='capitalize text-base font-medium  text-black'>
            <span>
              <FormattedMessage id='students' />
            </span>
            : <span>{defaultLanguage === 'en' ? total_student : toBnNumber(total_student)}</span>
          </h1>
        </div>
        {hasShift?.data?.has_shift === 'yes' && (
          <div className='flex items-center space-x-2'>
            <h1 className='capitalize text-base font-medium  text-black'>
              <span>
                <FormattedMessage id='shift' />
              </span>{' '}
              : <span>{defaultLanguage === 'en' ? total_shift : toBnNumber(total_shift)}</span>
            </h1>
          </div>
        )}
        <div className='flex items-center space-x-2'>
          <h1 className='capitalize text-base font-medium  text-black'>
            <span>
              <FormattedMessage id='section' />
            </span>
            : <span>{defaultLanguage === 'en' ? total_section : toBnNumber(total_section)}</span>
          </h1>
        </div>
      </div>
      {/* </Link> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // classData: state.upgradedClassState,
    hasShift: state.login?.currentUser,
    // // check is shift yes or not
    // hasShift:state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getClasses: () => dispatch(getClassList()),
    // getUpdatedClassLists: () => dispatch(getUpgradeClassListsAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SIngleSectionList);
