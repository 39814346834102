import React from 'react';
// import Tab from '../../Components/Tab'

const BmiTab = () => {
  // const tabContent = [
  //     {
  //       title: "normal",
  //       content: `normal content`,
  //     },
  //     {
  //       title: "mild Overweight",
  //       content: `mild Overweight content`,
  //     },
  //     {
  //       title: "Overweight",
  //       content: `Overweight content`,
  //     },
  //     {
  //       title: "Obesity",
  //       content: `Obesity content`,
  //     },

  //   ];

  return (
    <React.Fragment>
      {/* <div className=" border border-green-600 rounded shadow-md w-full bg-white">
            <div  className="">
                <Tab >
                    {tabContent.map((tab,idx) => (
                        <Tab.TabPane key={`tab-${idx}`} tab={tab.title}>
                            {tab.content}
                        </Tab.TabPane>
                    ))}
                </Tab>
            </div>
        </div> */}
      <div className=' border border-green-600 rounded shadow-md w-full bg-white'>
        <div className=''>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className=' p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>স্থুলতা</span>
            <span className=' p-4 border border-gray-300 grow'>বি, এম, আই, এর অবস্থান 29.7 score এর উপর।</span>
          </div>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className='p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>অধিক ওজন</span>
            <span className='p-4 border border-gray-300 grow'>
              বি.এম.আই. এর অবস্থান 25.4 score এর উপর কিন্তু 29.7 score এর নিচে।
            </span>
          </div>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className='p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>স্বল্প মুটিয়ে যাওয়া</span>
            <span className='p-4 border border-gray-300 grow'>
              বি.এম.আই, এর অবস্থান 22.2 score এর উপর কিন্তু 25.4 score এর নিচে।
            </span>
          </div>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className='p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>স্বাভাবিক পুষ্টি</span>
            <span className='p-4 border border-gray-300 grow'>
              বি.এম.আই, এর অবস্থান 22.2 score এর নিচে এবং 19.6 score এর ওপরে।
            </span>
          </div>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className='p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>স্বল্প পুষ্টি</span>
            <span className='p-4 border border-gray-300 grow'>
              বি.এম.আই, এর অবস্থান 19.6 score এর নিচে এবং 17.6 score এর ওপরে।
            </span>
          </div>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className='p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>মাঝারি অপুষ্টি</span>
            <span className='p-4 border border-gray-300 grow'>
              বি.এম.আই, এর অবস্থান 17.6 score এর নিচে এবং 15.9 score এর ওপরে।
            </span>
          </div>
          {/* items  */}
          <div className='w-full inline-flex'>
            <span className='p-4 border border-gray-300 min-w-[110px] md:min-w-[250px]'>মারাত্মক অপুষ্টি</span>
            <span className='p-4 border border-gray-300 grow'>বি.এম.আই. এর অবস্থান 15.9 score এর নিচে।</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BmiTab;
