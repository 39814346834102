import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../Components/BreadCrumb/BreadCrumb';
import Layout from '../../layout/Layout';
import profileLogo from '../../assets/image/profile-page/profile-page-logo.png';
import { connect } from 'react-redux';
import { getProfileAction } from '../../redux';
import ProfilePopUp from './ProfilePopUp';
import { passwordChangeRequest } from '../../redux/actions/passwordChange.action';
import { FormattedMessage } from 'react-intl';
const ProfilePage = ({ getProfile, profile, institution, passwordChangeRequest, changePassword }) => {
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [path] = useState(window.location.pathname.toString().split('/'));
  useEffect(() => {
    getProfile(institutionId);
  }, [getProfile, institutionId]);

  const [changePass, setChangePass] = useState(false);
  const handelPopUp = (e) => {
    e.preventDefault();
    passwordChangeRequest();
    setChangePass(!changePass);
  };
  useEffect(() => {
    if (changePassword && changePassword.data.message === 'Change Password Successfully') {
      setTimeout(() => {
        setChangePass(false);
      }, 2000);
    }
  }, [changePassword]);
  return (
    <React.Fragment>
      {changePass && <ProfilePopUp onClick={handelPopUp} />}

      {/* <Layout> */}
      {/* <BreadCrumb path={path} /> */}
      <section className='mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className='container flex flex-col space-y-10 justify-center mx-auto lg:w-1/2 xl:w-2/4 2xl:w-1/3'>
          {/* <!-- login logo img  --> */}
          <div className=' flex justify-center'>
            <img src={profileLogo} alt='profile logo' />
          </div>
          <div className=' flex justify-center'>
            <h1 className='text-black font-semibold capitalize text-3xl'>
              <FormattedMessage id='instituteProfile' />
            </h1>
          </div>

          {/* <!-- login forms  --> */}
          <div className='flex justify-center  flex-col  space-y-5  px-4'>
            {/* <!-- education type--> */}
            <div className=' xl:w-full flex flex-col'>
              <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' for='instituteId'>
                <FormattedMessage id='educationType' />
                <span className=' text-red-700'>*</span>
              </label>
              <input
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-50 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                        focus:text-gray-700 focus:bg-gray-300 focus:border-green-600 focus:outline-none'
                type='text'
                name=''
                id='instituteId'
                placeholder=' name'
                value={`${profile && profile?.profileData?.data?.education_type}`}
                readOnly
              />
            </div>
            {/* <!-- institute type--> */}
            <div className=' xl:w-full flex flex-col'>
              <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' for='instituteId'>
                <FormattedMessage id='instituteType' />
                <span className=' text-red-700'>*</span>
              </label>
              <input
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-50 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                        focus:text-gray-700 focus:bg-gray-300 focus:border-green-600 focus:outline-none'
                type='text'
                name=''
                id='instituteId'
                placeholder=' name'
                value={`${profile && profile?.profileData?.data?.institution_type}`}
                readOnly
              />
            </div>
            {/* <!-- institute  name --> */}
            <div className=' xl:w-full flex flex-col'>
              <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' for='instituteId'>
                <FormattedMessage id='instituteName' />
                <span className=' text-red-700'>*</span>
              </label>
              <input
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-50 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                        focus:text-gray-700 focus:bg-gray-300 focus:border-green-600 focus:outline-none'
                type='text'
                name=''
                id='instituteId'
                placeholder=' name'
                value={`${profile && profile?.profileData?.data?.institution_name}`}
                readOnly
              />
            </div>

            {/* <!-- teacher class --> */}
            <div className=' xl:w-full flex flex-col'>
              <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' for='instituteId'>
                <FormattedMessage id='password' />
                <span className=' text-red-700'>*</span>
              </label>
              <input
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-50 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                        focus:text-gray-700 focus:bg-gray-300 focus:border-green-600 focus:outline-none'
                type='password'
                name=''
                id='instituteId'
                placeholder='Secondary school/College/Madrasah/Club Category'
                value='******'
                readOnly
              />
            </div>
            {/* <!-- button  --> */}
            <div className=' flex justify-end w-full'>
              <button
                onClick={handelPopUp}
                type='button'
                className=' cursor-pointer hover:text-green-400 font-bold capitalize text-green-600'
              >
                <span className=''>
                  <FormattedMessage id='changePassword' />?
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.user,
    profile: state.profile,
    changePassword: state.changePass,
    // institution: state.institutionCheck?.institution,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (instituteId) => dispatch(getProfileAction(instituteId)),
    passwordChangeRequest: () => dispatch(passwordChangeRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
