import React from 'react';
import loadingSpinner from '../assets/image/loading/spinner.gif';
const LoadingSpinner = () => {
  return (
    <React.Fragment>
      <div className='mx-auto'>
        <div className='mx-auto text-center'>
          <img className=' mx-auto ' src={loadingSpinner} alt='loading' />
          <h1>loading...</h1>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingSpinner;
