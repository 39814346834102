import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
const StudentSearch = (props) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <form onSubmit={props.onSubmit}>
        <input
          className='form-select capitalize block w-full pl-3 pr-6 py-1.5 text-base font-normal text-gray-700 bg-white0 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-full transition ease-in-out m-0
            focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
          type='search'
          name={props.name}
          id='studentNameSearch'
          value={props.searchResult}
          placeholder={intl.formatMessage({ id: 'search' })}
          onChange={props.onChange}
        />
        <button type='submit' className='text-gray-500 hover:text-green-600 cursor-pointer absolute top-2 right-2'>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button>
      </form>
    </React.Fragment>
  );
};
export default StudentSearch;
