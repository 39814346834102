import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const LogInPopup = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        className={` p-4 top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 fixed ${
          isModalOpen ? 'transition duration-700 ease-in-out  z-40' : 'transition duration-700 ease-in-out hidden -z-40'
        }`}
      >
        <div className=' min-h-[300px] grid place-items-center  px-9 py-9 lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white text-center rounded shadow-lg'>
          <h1 className=' text-black font-semibold text-2xl '>
            <FormattedMessage id='loginMessage' />
          </h1>
          {/* <!-- button  --> */}
          <div className='text-center mt-6 flex space-x-4 justify-center'>
            <button
              onClick={() => {
                setIsModalOpen(false);
                navigate('/login');
              }}
              type='button'
              className=' rounded text-white py-2 px-6 capitalize text-[18px] bg-gradient'
            >
              <span>
                <FormattedMessage id='login' />
              </span>
            </button>
            <button
              onClick={() => {
                setIsModalOpen(false);
                // navigate('/');
              }}
              type='button'
              className=' rounded text-white py-2 px-6 capitalize text-[18px] bg-gradient'
            >
              <span>
                <FormattedMessage id='cancel' />
              </span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogInPopup;
