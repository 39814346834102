import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { birthYears, days, months, studentGender, monthsWithZero } from '../../../../common/datas';
import { isValidDate, toBnNumber } from '../../../../common/functions';
import BreadCrumb from '../../../../Components/BreadCrumb/BreadCrumb';
import ImageUpload from '../../../../Components/imageUpload/ImageUpload';
import InputField from '../../../../Components/InputField';
import LanguageContext from '../../../../context/LanguageContext';
import StoreContext from '../../../../context/StoreContext';
import {
  createSectionStudentFromShiftAction,
  createSectionStudentFromShiftRequest,
  getClassWiseShiftListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
} from '../../../../redux';
import {
  calculateAge,
  validateClass,
  validateDate,
  validateGender,
  validateRollNumber,
  validateSection,
  validateShift,
  validNameField,
} from '../../../../redux/service/service';
const AddStudents = ({
  //class
  getClassLists,
  classLists,
  // shift
  getShiftLists,
  shiftList,
  //section
  getSectionLists,
  sectionList,
  // add student
  addStudent,
  validation,
  addStudentRequest,
  // check had shift
  hasShift,
}) => {
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList } = useContext(StoreContext);
  const navigate = useNavigate();
  const addStudentData = useLocation();

  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [gradeId, setGrade] = useState(null);
  const [errorClass, setErrorClass] = useState(false);

  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);

  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setShift('');
    setSection('');

    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    // setGradeName(singleClass?.name_en);

    if (hasShift?.data?.has_shift === 'yes') {
      //   getShiftLists(data);
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      //   getSectionLists(data);
      setSectionArray(singleClass?.shift_section);
      // console.log(singleClass?.shift_section);
    }
  };
  // useEffect(() => {
  //   getClassLists();
  //   addStudentRequest();
  //   createSectionStudentFromShiftRequest();
  // }, [getClassLists]);

  // handel shift
  const [shiftId, setShift] = useState(null);
  const [shiftError, setShiftError] = useState(false);
  const handelSelectShift = (e) => {
    setShift(e.target.value);

    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    setSectionArray(singleShift?.section);
    setSection('');
  };
  // shift api call
  // useEffect(() => {
  //   const data = {
  //     institutionId: institutionId,
  //     grade: gradeId,
  //   };
  //   if (gradeId && gradeId !== '0') {
  //     getShiftLists(data);
  //   }
  // }, [gradeId, institutionId, getShiftLists]);
  // section list
  const [sectionId, setSection] = useState(null);
  const [sectionError, setSectionError] = useState(false);
  const handelSelectSection = (e) => {
    setSection(e.target.value);
  };
  // section api call
  // useEffect(() => {
  //   const data = {
  //     institutionId: institutionId,
  //     grade: gradeId,
  //     shift_id: shiftId,
  //   };
  //   if (gradeId && gradeId !== '0' && institutionId !== null) {
  //     getSectionLists(data);
  //   }
  // }, [getSectionLists, gradeId, institutionId, shiftId]);

  // input image file
  const [toViewAvatar, setToViewAvatar] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const inputFile = (event) => {
    setToViewAvatar(URL.createObjectURL(event.target.files[0]));
    setAvatar(event.target.files[0]);
  };
  // name validation
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(false);
  const handelNameField = (e) => {
    setName(e.target.value);
  };
  // roll validation
  const [roll, setRoll] = useState(null);
  const [rollError, setRollError] = useState(false);
  const handelRoll = (e) => {
    setRoll(e.target.value);
  };
  // validate roll from server
  const [rollErrorFromServer, setRollErrorFromServer] = useState(null);
  useEffect(() => {
    setRollErrorFromServer(validation?.error?.roll);
  }, [validation?.error?.roll]);
  let msRollError = rollErrorFromServer && (
    <p>
      {' '}
      <small className='text-red-700'>{rollErrorFromServer}</small>{' '}
    </p>
  );
  // gender validation
  const [gender, setGender] = useState(null);
  const [genderError, setGenderError] = useState(false);
  const handelGender = (e) => {
    setGender(e.target.value);
  };
  const initialDate = {
    day: '',
    month: '',
    year: '',
  };

  // Date validate
  const [dateValue, setDateValue] = useState(initialDate);
  const [dateError, setDateError] = useState({});
  const [totalAge, setTotalAge] = useState('');
  const handelDate = (e) => {
    const { name, value } = e.target;
    setDateValue({ ...dateValue, [name]: value });
  };
  useEffect(() => {
    setTotalAge(calculateAge(dateValue));
  }, [dateValue]);

  // birth citification number
  const [birthCertificate, setBirthCertificate] = useState('');
  // validate citification number from server
  const [birthCertificateErrorFromServer, setBirthCertificateErrorFromServer] = useState(null);
  useEffect(() => {
    setBirthCertificateErrorFromServer(validation?.error?.birth_certificate_num);
  }, [validation?.error?.birth_certificate_num]);
  let msBirthCertificateError = birthCertificateErrorFromServer && (
    <p>
      {' '}
      <small className='text-red-700'>{birthCertificateErrorFromServer}</small>{' '}
    </p>
  );

  // form submission
  const [isSubmit, setIsSubmit] = useState(false);
  const [postData, setSetPostData] = useState({});
  const handelSubmit = (e) => {
    e.preventDefault();

    if (!isValidDate(dateValue?.day, dateValue?.month, dateValue?.year)) {
      toast.error('Please enter a valid date of birth.');
      return;
    }

    let namePattern = /^[a-zA-Z. ]{2,30}$/;

    if (!namePattern.test(name)) {
      toast.error('Please enter a valid name.');
      return;
    }

    const data = {
      institution_id: institutionId,
      grade: gradeId,
      shift_id: shiftId,
      section_id: sectionId,
      name: name,
      roll: roll,
      gender: gender,
      birth_date: `${dateValue.year}-${dateValue.month}-${dateValue.day}`,
      age: totalAge,
      birth_certificate_num: birthCertificate,
    };
    setNameError(validNameField(name));
    setErrorClass(validateClass(gradeId));
    setRollError(validateRollNumber(roll));
    setGenderError(validateGender(gender));
    setDateError(validateDate(dateValue));
    setSectionError(validateSection(sectionId));
    setShiftError(validateShift(shiftId));
    setSetPostData(data);
    setIsSubmit(true);
  };

  // submit data if all are right
  useEffect(() => {
    if (
      Object.keys(dateError).length === 0 &&
      nameError === false &&
      errorClass === false &&
      sectionError === false &&
      rollError === false &&
      genderError === false &&
      isSubmit
    ) {
      addStudent(postData, avatar);
    }
  }, [addStudent, dateError, errorClass, genderError, isSubmit, nameError, postData, rollError, sectionError, avatar]);

  useEffect(() => {
    if (validation && validation.message === 'Student Save Successfully') {
      setTimeout(function () {
        navigate('/student-list');
      }, 2000);
    }
  }, [validation.message]);

  return (
    <React.Fragment>
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col items-center w-full px-4 lg:w-1/2 mx-auto'>
            <div className='border border-gray-300 w-full  py-4 rounded'>
              <div className='border-b border-gray-300'>
                <h1 className=' text-black font-semibold text-xl capitalize pb-4 px-4'>
                  <FormattedMessage id='add-student' />
                </h1>
              </div>
              {/* .student add form   start  */}
              <form onSubmit={handelSubmit}>
                <div className='w-full pt-5 pb-5'>
                  <div className='flex justify-center  flex-col  space-y-5  px-4'>
                    {/* image upload  */}
                    <ImageUpload avatar={toViewAvatar} inputFile={inputFile} />
                    {/* class */}
                    <div className='xl:w-full'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                        <FormattedMessage id='class' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        value={gradeId}
                        onChange={handelSelectClass}
                        name='class'
                        id='class'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectClass' })}</option>
                        {classList &&
                          classList?.map((classes, index) => {
                            return (
                              <option key={index} value={classes.grade}>
                                {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                              </option>
                            );
                          })}
                      </select>
                      {errorClass && (
                        <p>
                          <small className='text-red-700'>Class is required!</small>
                        </p>
                      )}
                    </div>
                    {/* shift/section */}
                    <div className='flex space-x-5'>
                      {/* shift */}

                      {hasShift?.data?.has_shift === 'yes' && (
                        <div className='w-full'>
                          <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                            <FormattedMessage id='shift' />
                            <span className=' text-red-700'>*</span>
                          </label>
                          <select
                            value={shiftId}
                            onChange={handelSelectShift}
                            name='shift'
                            id='shift'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value='0'>{intl.formatMessage({ id: 'selectShift' })}</option>
                            {shiftArray &&
                              shiftArray?.map((shift, index) => {
                                return (
                                  <option value={shift.shift_id} key={index}>
                                    {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                                  </option>
                                );
                              })}
                          </select>
                          {shiftError && (
                            <p>
                              <small className='text-red-700'>Shift is required!</small>
                            </p>
                          )}
                        </div>
                      )}
                      {/* section */}
                      <div className='w-full'>
                        <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                          <FormattedMessage id='section' />
                          <span className=' text-red-700'>*</span>
                        </label>
                        <select
                          value={sectionId}
                          onChange={handelSelectSection}
                          name='section'
                          id='section'
                          className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                          aria-label='Default select example'
                        >
                          <option value='0'>{intl.formatMessage({ id: 'selectSection' })}</option>
                          {sectionArray &&
                            sectionArray?.map((section, index) => {
                              return (
                                <option value={section.id} key={index}>
                                  {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                                </option>
                              );
                            })}
                        </select>
                        {sectionError && (
                          <p>
                            <small className='text-red-700'>Section is required!</small>
                          </p>
                        )}
                      </div>
                    </div>
                    {/* student name */}
                    <div className=''>
                      <InputField
                        required
                        onChange={handelNameField}
                        label={<FormattedMessage id='name' />}
                        placeholder={intl.formatMessage({ id: 'enterStockName' })}
                        value={name}
                        type='text'
                      />
                      {nameError && (
                        <p>
                          <small className='text-red-700'>Name is required, only character</small>
                        </p>
                      )}
                    </div>
                    {/* student roll */}
                    <div className=''>
                      <InputField
                        required
                        label={<FormattedMessage id='roll' />}
                        placeholder={intl.formatMessage({ id: 'enterRoll' })}
                        value={roll}
                        onChange={handelRoll}
                        type='number'
                      />
                      {rollError && (
                        <p>
                          <small className='text-red-700'>Roll is required!</small>
                        </p>
                      )}
                      {msRollError && msRollError}
                    </div>
                    {/* birth certificate number */}
                    <div className=''>
                      <InputField
                        // required
                        type='number'
                        label={<FormattedMessage id='birthRegistrationNumber' />}
                        placeholder={intl.formatMessage({ id: 'enterBirthRegistrationNumber' })}
                        value={birthCertificate}
                        onChange={(e) => setBirthCertificate(e.target.value)}
                      />
                      {msBirthCertificateError && msBirthCertificateError}
                    </div>
                    {/* student gender */}
                    <div className='xl:w-full'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                        {<FormattedMessage id='gender' />}
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        onChange={handelGender}
                        name={gender}
                        id='institute'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectGender' })}</option>
                        {studentGender?.map((gender) => (
                          <option value={gender.name_en}>
                            {defaultLanguage === 'en' ? gender.name_en : gender.name_bn}
                          </option>
                        ))}
                      </select>
                      {genderError && (
                        <p>
                          <small className='text-red-700'>Gender is required!</small>
                        </p>
                      )}
                    </div>
                    {/* birth date */}
                    <div className=''>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                        <FormattedMessage id='dateOfBirth' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <div className='flex space-x-5'>
                        {/* day */}
                        <div className='w-full'>
                          <select
                            onChange={handelDate}
                            name='day'
                            id='institute'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value='' selected>
                              {intl.formatMessage({ id: 'selectDay' })}
                            </option>
                            {days?.map((day) => (
                              <option value={day}>{defaultLanguage === 'en' ? day : toBnNumber(day)}</option>
                            ))}
                          </select>
                          {dateError.day && (
                            <p>
                              <small className='text-red-700'>day is required!</small>
                            </p>
                          )}
                        </div>
                        <div className='w-full'>
                          <select
                            onChange={handelDate}
                            name='month'
                            id='institute'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value=''>{intl.formatMessage({ id: 'selectMonth' })}</option>
                            {monthsWithZero?.map((month) => (
                              <option value={month.id}>
                                {defaultLanguage === 'en' ? month.name_en : month.name_bn}
                              </option>
                            ))}
                          </select>
                          {dateError.month && (
                            <p>
                              <small className='text-red-700'>month is required!</small>
                            </p>
                          )}
                        </div>
                        <div className='w-full'>
                          <select
                            onChange={handelDate}
                            name='year'
                            id='institute'
                            className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                            {birthYears?.map((year) => (
                              <option value={year}>{defaultLanguage === 'en' ? year : toBnNumber(year)}</option>
                            ))}
                          </select>
                          {dateError.year && (
                            <p>
                              <small className='text-red-700'>year is required!</small>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* age  */}
                    {/* <div className=''>
                      <InputField
                        label={<FormattedMessage id='age' />}
                        placeholder='age'
                        value={totalAge}
                        readOnly={true}
                        type='number'
                      />
                    </div> */}
                    <div className=''>
                      <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                        {<FormattedMessage id='age' />}
                      </div>
                      <div className='bg-gray-300 focus:bg-gray-300 text-gray-700 block  w-full  min-w-[75px] px-3 py-1.5  text-base  font-normal border border-solid border-gray-300 rounded'>
                        {defaultLanguage === 'en' ? totalAge : toBnNumber(totalAge)}
                      </div>
                    </div>
                    <div className=' text-center w-full flex  space-x-5'>
                      <button
                        onClick={() => navigate(-1)}
                        type='button'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
								hover:border-transparent  hover_gradient '
                      >
                        <span className=''> {<FormattedMessage id='back' />} </span>
                      </button>

                      <button
                        type='submit'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                      >
                        <span className=''>{<FormattedMessage id='submit' />}</span>
                      </button>
                    </div>
                    <div className=''>
                      {/* {validation && validation.loading &&<LoadingSpinner/>} */}
                      {validation && validation.message && (
                        <div className=' flex justify-center'>
                          <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-500'>
                            {validation.message}
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentData: state.studentData,
    studentList: state.studentData.data?.data,
    studentMetaData: state.studentData.data?.meta,
    validation: state.shiftStudent,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addStudent: (studentInfo, avatar) => dispatch(createSectionStudentFromShiftAction(studentInfo, avatar)),
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    addStudentRequest: () => dispatch(createSectionStudentFromShiftRequest()),
    // createSectionStudentFromShiftRequest: () => dispatch(createSectionStudentFromShiftRequest())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddStudents);
