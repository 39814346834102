// *monthly report
export const GET_MONTHLY_REPORT_REQUEST = 'GET_MONTHLY_REPORT_REQUEST';
export const GET_MONTHLY_REPORT_SUCCESS = 'GET_MONTHLY_REPORT_SUCCESS';
export const GET_MONTHLY_REPORT_FAILED = 'GET_MONTHLY_REPORT_FAILED';
// *weekly report
export const GET_WEEKLY_REPORT_REQUEST = 'GET_WEEKLY_REPORT_REQUEST';
export const GET_WEEKLY_REPORT_SUCCESS = 'GET_WEEKLY_REPORT_SUCCESS';
export const GET_WEEKLY_REPORT_FAILED = 'GET_WEEKLY_REPORT_FAILED';
// *weekly report
export const GET_ANNUAL_REPORT_REQUEST = 'GET_ANNUAL_REPORT_REQUEST';
export const GET_ANNUAL_REPORT_SUCCESS = 'GET_ANNUAL_REPORT_SUCCESS';
export const GET_ANNUAL_REPORT_FAILED = 'GET_ANNUAL_REPORT_FAILED';
// *referral report
export const GET_REFERRAL_REPORT_REQUEST = 'GET_REFERRAL_REPORT_REQUEST';
export const GET_REFERRAL_REPORT_SUCCESS = 'GET_REFERRAL_REPORT_SUCCESS';
export const GET_REFERRAL_REPORT_FAILED = 'GET_REFERRAL_REPORT_FAILED';
