import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const EmptyList = ({ message, border }) => {
  return (
    <React.Fragment>
      <div className=' mx-auto container pt-5'>
        <div className='w-full   px-4 sm:w-4/5 mx-auto '>
          <div
            className={`w-full min-h-[200px] grid place-items-center ${
              border ? ' border border-gray-300 ' : ' '
            } rounded-md`}
          >
            <div className=' text-center '>
              <FontAwesomeIcon icon={faFaceFrown} className=' text-gray-400 text-[30px] mb-4 ' />
              <h1 className=' font-serif font-semibold capitalize text-base  text-gray-400 '>{message} !</h1>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmptyList;
