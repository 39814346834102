import { Routes, Route, useLocation } from 'react-router-dom';
import {
  AnnualReport,
  // Home,
  MonthlyReport,
  ReferralReport,
  TeacherListPage,
  // LogInHome,
  // NotFoundPage,
  ProfilePage,
  // FaqPage,
  LearningModulePage,
  LearningDetailsPage,
  StudentProfilePage,
  ShiftsPage,
  SectionListPage,
  AddSectionStudentFromShift,
  StudentListFromShiftPage,
  UpdateSectionStudentFromShift,
  AddTeacher,
  EditTeacher,
  TeacherProfilePage,
  StudentLists,
  AddStudents,
  Bmi,
  TabletStock,
  WeeklyReport,
  Referral,
  AddReferral,
  PdfDocument,
  Dashboard,
  AddClassesPage,
  NutritionPrograms02,
  WifaPrograms02,
  DewormingPrograms02,
  UpgradeStudent,
} from './pages';
import ProtectedRoutes from './routes/ProtectedRoutes';
import AddSectionTeacherPage from './pages/class-room-pages/classUpdate/AddSectionTeacherPage';
import Layout from './layout/Layout';
import React from 'react';
import { StoreProvider } from './context/StoreContext';
import { LanguageProvider } from './context/LanguageContext';
import loadingSpinner from './assets/image/loading/spinner.gif';
import { lazy } from 'react';
import { Suspense } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import LearningDetailsPageV2 from './pages/learning-module/LearningDetailsPageV2';

const Home = lazy(() => import('./pages/Home/Home'));
const LogInHome = lazy(() => import('./pages/login-page/LogInHome'));
const FaqPage = lazy(() => import('./pages/faq/FaqPage'));
const NotFoundPage = lazy(() => import('./pages/not-found/NotFoundPage'));

let token = localStorage.getItem('token');

if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(token) ?? '';
}

// 103212

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    // document.querySelector('html').style.scrollBehavior = 'smooth';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  return (
    <StoreProvider>
      <LanguageProvider>
        <Layout>
          <Routes>
            <Route
              path='/'
              element={
                <Suspense fallback={<Loading />}>
                  <Home />
                </Suspense>
              }
            />
            {/* new route for shift update start */}
            <Route path='/' element={<ProtectedRoutes />}>
              <Route path='/upgrade' element={<UpgradeStudent />} />
              <Route path='/profile' element={<ProfilePage />} />
              <Route path='/classes' element={<AddClassesPage />} />
              {/*NOTE: flow start if shift:yes */}
              <Route path='/classes/shift-list' element={<ShiftsPage />} />
              <Route path='/classes/shift-list/:shift' element={<SectionListPage />} />
              <Route path='/classes/shift-list/:shift/:section-add-teacher' element={<AddSectionTeacherPage />} />
              <Route path='/classes/shift-list/:shift/:section-student-list' element={<StudentListFromShiftPage />} />
              <Route
                path='/classes/shift-list/:shift/:section-student-list/student-profile'
                element={<StudentProfilePage variant='from-shift' />}
              />
              <Route
                path='/classes/shift-list/:shift/:section-student-list/student-profile/edit-student'
                element={<UpdateSectionStudentFromShift />}
              />
              <Route
                path='/classes/shift-list/:shift/:section-student-list/edit-student'
                element={<UpdateSectionStudentFromShift />}
              />
              <Route
                path='/classes/shift-list/:shift/:section-student-list/add-student'
                element={<AddSectionStudentFromShift />}
              />

              {/*NOTE: if teacher null and shift NO  */}
              <Route path='/classes/section-list' element={<SectionListPage />} />
              <Route path='/classes/section-list/:section-add-teacher' element={<AddSectionTeacherPage />} />
              <Route path='/classes/section-list/:section-student-list' element={<StudentListFromShiftPage />} />
              <Route
                path='/classes/section-list/:section-student-list/student-profile'
                element={<StudentProfilePage variant='from-section' />}
              />
              <Route
                path='/classes/section-list/:section-student-list/student-profile/edit-student'
                element={<UpdateSectionStudentFromShift />}
              />
              <Route
                path='/classes/section-list/:section-student-list/add-student'
                element={<AddSectionStudentFromShift />}
              />
              <Route
                path='/classes/section-list/:section-student-list/edit-student'
                element={<UpdateSectionStudentFromShift />}
              />

              {/* NOTE: STUDENT LIST  */}
              <Route path='/student-list' element={<StudentLists />} />
              <Route path='/student-add' element={<AddStudents />} />
              <Route path='/student-list/add-student' element={<AddStudents />} />
              <Route path='/student-list/edit-student' element={<UpdateSectionStudentFromShift />} />
              <Route path='/student-list/student-profile' element={<StudentProfilePage variant='from-list' />} />

              {/* programs */}
              <Route path='/nutrition-education' element={<NutritionPrograms02 />} />
              <Route path='/wifa' element={<WifaPrograms02 />} />
              <Route path='/deworming' element={<DewormingPrograms02 />} />
              <Route path='/bmi' element={<Bmi />} />
              <Route path='/tablet-stock' element={<TabletStock />} />
              {/* report  */}
              <Route path='/weekly' element={<WeeklyReport />} />
              <Route path='/monthly' element={<MonthlyReport />} />
              <Route path='/annual' element={<AnnualReport />} />
              <Route path='/referral-report' element={<ReferralReport />} />
              <Route path='/dashboard' element={<Dashboard />} />
              {/* Referral */}
              <Route path='/referral-list' element={<Referral />} />
              <Route path='/referral-list/add-referral' element={<AddReferral />} />
              <Route path='/referral-list/referral-pdf' element={<PdfDocument />} />

              {/*NOTE:TEACHER LIST add teacher from teacher list */}
              <Route path='/teacher-list' element={<TeacherListPage />} />
              <Route path='/teacher-list/add-teacher' element={<AddTeacher />} />
              <Route path='/teacher-list/edit-teacher' element={<EditTeacher />} />
              <Route path='/teacher-list/teacher-profile' element={<TeacherProfilePage />} />

              <Route path='/learning-module' element={<LearningModulePage />} />
              <Route path='/learning-module/learning-details' element={<LearningDetailsPageV2 />} />
            </Route>
            {/* new route for shift update end */}
            {/* login  */}
            <Route
              path='/login'
              element={
                <Suspense fallback={<Loading />}>
                  <LogInHome />
                </Suspense>
              }
            />
            {/* not found page  */}
            <Route
              path='/faq'
              element={
                <Suspense fallback={<Loading />}>
                  <FaqPage />
                </Suspense>
              }
            />

            <Route
              path='*'
              element={
                <Suspense fallback={<Loading />}>
                  <NotFoundPage />
                </Suspense>
              }
            />
          </Routes>
        </Layout>
      </LanguageProvider>
    </StoreProvider>
  );
};

export default App;

const Loading = () => (
  <div className='mx-auto py-5'>
    <div className='mx-auto text-center'>
      <img className=' mx-auto ' src={loadingSpinner} alt='loading' />
      <h1>loading...</h1>
    </div>
  </div>
);

// 1. student na thakle nutrition's programs a loading hoi
// 2. first shift yes or no deoar por section createw korte gele class name dekhai na
// 3.
