import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const LogInButton = () => {
  return (
    <React.Fragment>
      <div className='flex items-center'>
        <Link to={`/login`} className='bg-gradient py-2 px-6 capitalize text-lg text-white hover_gradient rounded'>
          <FormattedMessage id='login' />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default LogInButton;
