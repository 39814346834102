import axios from 'axios';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  GET_LOGGED_IN_USER,
  GET_LOGGED_IN_USER_FAILED,
  GET_LOGGED_OUT_USER,
  USER_LOGGED_OUT_REQUEST,
  USER_LOGGED_OUT_SUCCESS,
  USER_LOGGED_OUT_FAILED,
  USER_PASSWORD_INCORRECT,
} from '../constance/user.constants';
// action creator

export const logInRequest = (loginData) => {
  return {
    type: USER_LOGIN_REQUEST,
    payload: loginData,
  };
};

export const logInSuccess = (loginData) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: loginData,
  };
};
export const logInFailed = (error) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error,
  };
};
export const checkPasswordIncorrect = (message) => {
  return {
    type: USER_PASSWORD_INCORRECT,
    payload: message,
  };
};

// ! user login with password from api....

export const checkLogInLocalStorage = (tokenDetails) => {
  localStorage.setItem('token', JSON.stringify(tokenDetails));
};

export const userLogIn = (loginData) => {
  const inputData = {
    eiin: loginData.eiin,
    password: loginData.password,
  };
  return (dispatch) => {
    dispatch(logInRequest(loginData));
    const apiUrl = process.env.REACT_APP_API_URL;
    axios
      .post(`${apiUrl}institution/login`, inputData)
      .then((response) => {
        checkLogInLocalStorage(response.data.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        dispatch(logInSuccess(response.data));
      })
      .catch((error) => {
        dispatch(logInFailed(error));
      });
  };
};

// getlogged-in user actio n creator

export const loggedInUser = (userInfo) => {
  return {
    type: GET_LOGGED_IN_USER,
    payload: userInfo,
  };
};

export const loggedInUserFailed = (userInfo) => {
  return {
    type: GET_LOGGED_IN_USER_FAILED,
    payload: userInfo,
  };
};

export const loggedOutUser = (userInfo) => {
  return {
    type: GET_LOGGED_OUT_USER,
    payload: userInfo,
  };
};

// logged-in user
export const getLoggedInUser = () => {
  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;

  return (dispatch) => {
    axios
      .get(`${apiUrl}token/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (token) {
          window.localStorage.setItem('institution', response.data.data.id);
          dispatch(loggedInUser(response.data));
        } else {
          window.localStorage.setItem('token', response.data.token);
        }
      })
      .catch((error) => {
        dispatch(loggedInUserFailed(error.message));
      });
  };
};

//* logout action creator
export const logOutRequest = () => {
  return {
    type: USER_LOGGED_OUT_REQUEST,
  };
};
export const logOutSuccess = (res) => {
  return {
    type: USER_LOGGED_OUT_SUCCESS,
    payload: res,
  };
};
export const logOutFailed = (error) => {
  return {
    type: USER_LOGGED_OUT_FAILED,
    payload: error,
  };
};

// logout
export const logOut = () => {
  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;
  const getLocalLanguage = localStorage.getItem('defaultLanguage');
  return (dispatch) => {
    dispatch(logOutRequest());
    axios
      .get(`${apiUrl}logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(loggedOutUser(response));
        window.localStorage.clear();
        if (getLocalLanguage) {
          localStorage.setItem('defaultLanguage', getLocalLanguage);
        }
        window.location.reload();
        window.localStorage.setItem('isEng', true);
      })
      .catch((error) => {
        dispatch(logOutFailed(error));
        if (error.response.status === 401) {
          // dispatch(loggedOutUser(response));
          window.localStorage.clear();
          if (getLocalLanguage) {
            localStorage.setItem('defaultLanguage', getLocalLanguage);
          }
          window.location.reload();
          window.localStorage.setItem('isEng', true);
        }
      });
  };
};
