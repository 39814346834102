import axios from 'axios';
import {
  LEARNING_MODULE_REQUEST,
  LEARNING_MODULE_SUCCESS,
  LEARNING_MODULE_FAILED,
  LEARNING_MODULE_CATEGORY_REQUEST,
  LEARNING_MODULE_CATEGORY_SUCCESS,
  LEARNING_MODULE_CATEGORY_FAILED,
  LEARNING_MODULE_DETAILS_REQUEST,
  LEARNING_MODULE_DETAILS_SUCCESS,
  LEARNING_MODULE_DETAILS_FAILED,
} from '../constance/learningModule.constants';

export const getLearningModuleRequest = () => {
  return { type: LEARNING_MODULE_REQUEST, info: 'learning module' };
};
export const getLearningModuleSuccess = (res) => {
  return { type: LEARNING_MODULE_SUCCESS, payload: res };
};
export const getLearningModuleFailed = (err) => {
  return { type: LEARNING_MODULE_FAILED, payload: err };
};

export const getLearningModuleAction = (dataInfo) => {
  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;
  return (dispatch) => {
    dispatch(getLearningModuleRequest());
    axios
      .get(`${apiUrl}category-wise/module?id=${dataInfo.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getLearningModuleSuccess(res.data));
      })
      .catch((err) => dispatch(getLearningModuleFailed(err)));
  };
};

export const learningModuleCategoryRequest = () => {
  return { type: LEARNING_MODULE_CATEGORY_REQUEST, payload: 'learning module category api call' };
};
export const learningModuleCategorySuccess = (res) => {
  return { type: LEARNING_MODULE_CATEGORY_SUCCESS, payload: res };
};
export const learningModuleCategoryFailed = (error) => {
  return { type: LEARNING_MODULE_CATEGORY_FAILED, payload: error };
};

export const getLearningModuleCategoryAction = () => {
  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;
  return (dispatch) => {
    dispatch(learningModuleCategoryRequest());
    axios
      .get(`${apiUrl}learning/module/category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(learningModuleCategorySuccess(res.data));
      })
      .catch((err) => dispatch(learningModuleCategoryFailed(err)));
  };
};

// LEARNING_MODULE_DETAILS_REQUEST,
// LEARNING_MODULE_DETAILS_SUCCESS,
// LEARNING_MODULE_DETAILS_FAILED,

export const learningModuleDetailsRequest = () => {
  return { type: LEARNING_MODULE_DETAILS_REQUEST, payload: 'this is detail learning module' };
};
export const learningModuleDetailsSuccess = (res) => {
  return { type: LEARNING_MODULE_DETAILS_SUCCESS, payload: res };
};
export const learningModuleDetailsFailed = (err) => {
  return { type: LEARNING_MODULE_DETAILS_FAILED, payload: err };
};
export const learningModuleDetailsAction = (dataInfo) => {
  const token = JSON.parse(localStorage.token);
  const apiUrl = process.env.REACT_APP_API_URL;
  return (dispatch) => {
    dispatch(learningModuleDetailsRequest());
    axios
      .get(`${apiUrl}module-wise/pdf?id=${dataInfo.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(learningModuleDetailsSuccess(res.data));
      })
      .catch((err) => dispatch(learningModuleDetailsFailed(err)));
  };
};
