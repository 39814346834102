import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { checkInstitution } from '../../redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext } from 'react';
import LanguageContext from '../../context/LanguageContext';
const LogInForm = ({ checkInstitution, status }) => {
  const { defaultLanguage } = useContext(LanguageContext);
  const intl = useIntl();
  const navigate = useNavigate();
  const initialValues = { education_type: '', eiinNumber: '' };
  const [percentageValue, setPercentageValue] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForms(formValues));
    setIsSubmit(true);
  };

  const validateForms = (values) => {
    const error = {};
    if (!values.education_type || values.education_type === '0') {
      error.education_type = 'Education type is Required';
    }
    if (!values.eiinNumber) {
      error.eiinNumber = 'EIIN Number is Required';
    }

    return error;
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      window.localStorage.setItem('eiim', `${formValues.eiinNumber}`);
      checkInstitution(formValues);
    }
  }, [formErrors]);

  useEffect(() => {
    if (formValues.education_type && formValues.eiinNumber) {
      setPercentageValue(100 / 2);
    } else {
      setPercentageValue(0);
    }
  }, [formValues]);

  return (
    <React.Fragment>
      <section className='mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className='container flex flex-col space-y-10 justify-center mx-auto lg:w-1/2 xl:w-2/4 2xl:w-1/3'>
          {/* <!-- login forms  --> */}
          <form action='' onSubmit={handelSubmit}>
            <div className='flex justify-center  flex-col  space-y-5  px-4'>
              {/* <!-- select institute option  --> */}
              <div className=' xl:w-full'>
                <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                  <FormattedMessage id='educationType' />
                  <span className=' text-red-700'>*</span>
                </label>
                <select
                  name='education_type'
                  value={formValues.education_type}
                  onChange={handelChange}
                  id='institute'
                  className='form-select  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                  aria-label='Default select example'
                >
                  <option value=''>{intl.formatMessage({ id: 'selectType' })}</option>
                  <option value='1'>{intl.formatMessage({ id: 'general' })}</option>
                  <option value='2'>{intl.formatMessage({ id: 'madrasha' })}</option>
                </select>

                {formErrors.education_type && (
                  <p>
                    <small className='text-red-700'>{formErrors.education_type}</small>
                  </p>
                )}
              </div>
              {/* <!-- input institute id option  --> */}
              <div className=' relative xl:w-full flex flex-col'>
                <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='instituteEiinNumber'>
                  <FormattedMessage id='eiinNumber' />
                  <span className=' text-red-700'>*</span>
                </label>
                <input
                  className='form-select js__Password_show_hide block w-full px-3 py-1.5  text-base font-normal text-gray-700  bg-white bg-clip-padding bg-no-repeat  border border-solid border-gray-300  rounded transition  ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                  name='eiinNumber'
                  value={formValues.eiinNumber}
                  onChange={handelChange}
                  type='number'
                  id='instituteEiinNumber'
                  placeholder={intl.formatMessage({ id: 'enterInstitutionEiin' })}
                />
                {formErrors.eiinNumber && (
                  <p>
                    <small className='text-red-700'>{formErrors.eiinNumber}</small>
                  </p>
                )}
              </div>

              {/* <!-- button  --> */}
              <div className=' text-center w-full'>
                <button
                  type='submit'
                  className=' w-full py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=' mr-3'>
                    <FormattedMessage id='continue' />
                  </span>
                  <span className=''>
                    {' '}
                    <FontAwesomeIcon icon={faAngleRight} />{' '}
                  </span>
                </button>
              </div>
              {/* <!-- progress-bar  --> */}
              <div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
                <div
                  className='bg-gradient h-2.5 rounded-full'
                  style={{
                    width: `${percentageValue}%`,
                    transition: 'width 2s',
                  }}
                ></div>
              </div>
              {checkInstitution && checkInstitution.error}
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.institutionCheck,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkInstitution: (institutionCheck) => dispatch(checkInstitution(institutionCheck)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
