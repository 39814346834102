import { faMagnifyingGlass, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../Components/EmptyList';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import Layout from '../../../layout/Layout';
import { sectionStudentAction } from '../../../redux';
import ReactPaginate from 'react-paginate';
import StudentDeletePopUp from './studentPopup/StudentDeletePopUp';
import { shiftNameConverter } from '../../../redux/service/service';
import uploadIcon from '../../../assets/image/nutritions-page/pdf-icon.png';
import {
  createSectionStudentFromShiftRequest,
  deleteSectionStudentRequest,
  studentSampleDownloadAction,
  studentUploadAction,
  updateSectionStudentRequest,
} from '../../../redux/actions/upgradeClass.action';
import StudentUploadPopUp from './studentPopup/StudentUploadPopUp';
import { useMemo } from 'react';
import Pagination from '../../../Components/Pagination/Pagination';
import LanguageContext from '../../../context/LanguageContext';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { studentGender } from '../../../common/datas';
import moment from 'moment/moment';
import StoreContext from '../../../context/StoreContext';
import { toast } from 'react-toastify';
import { toBnNumber } from '../../../common/functions';

const StudentListFromShiftPage = ({
  deleteSectionStudentRequest,
  createSectionStudentFromShiftRequest,
  updateSectionStudentRequest,
  studentSampleDownload,
  getStudent,
  studentData,
  studentList,
  studentMetaData,
  message,
  studentUpload,
  uploadStudents,
  sampleDownload,
  // check shift
  hasShift,
}) => {
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { getClassList } = useContext(StoreContext);
  const navigationData = useLocation();
  const navigate = useNavigate();
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  // data from localStorage
  const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
  // handel-student search
  const handelStudentSearch = (e) => {
    console.log('----');
  };

  // all state
  const [pageNumber, setPageNumber] = useState(1);
  const [gender, setGender] = useState('');
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(250);
  const [uploadStudentList, setUploadStudentList] = useState('');

  const [fileSelectTime, setFileSelectTime] = useState(null);

  useEffect(() => {
    studentSampleDownload();
  }, [studentSampleDownload]);

  const getInitialStudents = () => {
    const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
    const data = {
      institution_id: navigationData.state === null ? newData.institution_id : navigationData.state.institution_id,
      grade: navigationData?.state === null ? newData.grade : navigationData?.state?.grade,
      shift_id:
        hasShift?.data?.has_shift === 'yes'
          ? navigationData?.state === null
            ? newData.shift_id
            : navigationData?.state?.shift_id
          : undefined,
      section_id:
        navigationData?.state === null
          ? newData.section_id
          : navigationData?.state?.section_id || navigationData?.state?.id,
      pagination_limit: pageLimit,
      // page: pageNumber,
    };
    // console.log('🚀 ~ file: StudentListFromShiftPage.jsx:82 ~ useEffect ~ data', data);

    // getStudent(data);

    if (hasShift?.data?.has_shift === 'yes' && data?.shift_id !== undefined) {
      getStudent(data);
    }
    if (hasShift?.data?.has_shift === 'no' && data?.shift_id === undefined) {
      getStudent(data);
    }
  };

  useEffect(() => {
    getInitialStudents();
  }, [hasShift]);

  // gender
  const handelGender = (e) => {
    e.target.value === '0' ? setGender(null) : setGender(e.target.value);
    setIsFilterSubmit(false);
  };
  // filter gender
  const handelGenderFilter = () => {
    setCurrentPage(1);
    setIsFilterSubmit(true);
  };
  // filter gender
  useEffect(() => {
    const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
    const data = {
      institution_id: navigationData.state === null ? newData.institution_id : navigationData.state.institution_id,
      grade: navigationData?.state === null ? newData.grade : navigationData?.state?.grade,
      shift_id:
        hasShift?.data?.has_shift === 'yes'
          ? navigationData?.state === null
            ? newData.shift_id
            : navigationData?.state?.shift_id
          : undefined,
      section_id:
        navigationData?.state === null
          ? newData.section_id
          : navigationData?.state?.id || navigationData?.state?.section_id,
      gender: gender,
      pagination_limit: pageLimit,
      // page: null,
    };
    if (isFilterSubmit) {
      getStudent(data);
    }
  }, [gender, getStudent, isFilterSubmit, hasShift, navigationData, pageLimit]);

  // pagination
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return studentList?.data?.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, studentList?.data]);

  // go to add student page
  const gotoAddStudentPage = () => {
    localStorage.setItem(
      'sectionInfo',
      JSON.stringify({
        grade: newData.grade,
        institution_id: newData.institution_id,
        shift_id: newData.shift_id,
        class_name_en: newData.class_name_en,
        class_name_bn: newData.class_name_bn,
        has_shift: hasShift?.data?.has_shift === 'yes' ? hasShift?.data?.has_shift : undefined,
        shift_name_bn: newData.shift_name_bn,
        shift_name_en: newData.shift_name_en,
        section_id: newData.section_id,
        section_name_bn: newData.section_name_bn,
        section_name_en: newData.section_name_en,
      })
    );
    createSectionStudentFromShiftRequest();
    if (hasShift?.data?.has_shift === 'yes') {
      navigate(
        `/classes/shift-list/${shiftNameConverter(newData?.shift_id)}/section-${
          navigationData?.state === null ? newData.section_name_en : navigationData?.state?.section_name_en
        }-student-list/add-student`,
        { state: newData }
      );
    } else {
      navigate(`/classes/section-list/section-${newData?.section_name_en}-student-list/add-student`, {
        state: newData,
      });
    }
  };
  // go to edit page
  const goToEditPage = (student) => {
    updateSectionStudentRequest();
    localStorage.setItem(
      'sectionInfo',
      JSON.stringify({
        grade: student.grade,
        institution_id: student.institution_id,
        shift_id: student.shift_id,
        section_id: student.section_id,
        class_name_bn: student.class_name_bn,
        class_name_en: student.class_name_en,
        section_name_bn: student.section_name_bn,
        section_name_en: student.section_name_en,
        shift_name_bn: student.shift_name_bn,
        shift_name_en: student.shift_name_en,
        gender: student.gender,
        birth_date: student.birth_date,
        age: student.age,
      })
    );
    if (hasShift?.data?.has_shift === 'yes') {
      navigate(
        `/classes/shift-list/${shiftNameConverter(newData?.shift_id)}/section-${
          newData?.section_name_en
        }-student-list/edit-student`,
        { state: student }
      );
    } else {
      navigate(`/classes/section-list/section-${newData?.section_name_en}-student-list/edit-student`, {
        state: student,
      });
    }
  };
  // go to profile page
  const gotoProfilePage = (student) => {
    if (hasShift?.data?.has_shift === 'yes') {
      navigate(
        `/classes/shift-list/${shiftNameConverter(newData?.shift_id)}/section-${
          newData?.section_name_en
        }-student-list/student-profile`,
        { state: student }
      );
    } else {
      navigate(`/classes/section-list/section-${newData?.section_name_en}-student-list/student-profile`, {
        state: student,
      });
    }
  };

  // student delete

  const [studentUploadModal, setStudentUploadModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteStudentInfo, setDeleteStudentInfo] = useState({});
  const handelStudentDelete = (data) => {
    setDeleteStudentInfo(data);
    setIsDeleteModalOpen(true);
    deleteSectionStudentRequest();
  };

  const handelModalClose = () => {
    setIsDeleteModalOpen(false);
    setStudentUploadModal(false);
    setUploadStudentList('');
    setFileSelectTime(null);
  };

  // close modal if Successfully delete
  useEffect(() => {
    if (message && message === 'Student Deleted Successfully') {
      setTimeout(function () {
        setIsDeleteModalOpen(false);
        deleteSectionStudentRequest();
      }, 2000);
    }
  }, [message]);

  // close upload modal if success
  useEffect(() => {
    if (uploadStudents && uploadStudents?.message === 'Student Import Successfully') {
      setTimeout(function () {
        setStudentUploadModal(false);
        // setUploadStudentList('');
      }, 2000);
    }
  }, [uploadStudents?.message]);

  //NOTE: import student CSV file upload

  const [isUpload, setIsUpload] = useState(false);
  const handelCsvUpload = () => {
    const isTimeOver = detectUploadTime(fileSelectTime);

    if (isTimeOver) {
      toast.error('Please select this file again.');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    const data = {
      institution_id: navigationData.state === null ? newData.institution_id : navigationData.state.institution_id,
      grade: navigationData?.state === null ? newData.grade : navigationData?.state?.grade,
      shift_id:
        hasShift?.data?.has_shift === 'yes'
          ? navigationData?.state === null
            ? newData.shift_id
            : navigationData?.state?.shift_id
          : undefined,
      section_id:
        navigationData?.state === null
          ? newData.section_id
          : navigationData?.state?.id || navigationData?.state?.section_id,
      import_file: uploadStudentList,
    };
    studentUpload(data, () => {
      getInitialStudents();
      getClassList();
      setUploadStudentList('');
    });
    setIsUpload(true);
    setStudentUploadModal(true);
  };

  useEffect(() => {
    if (isUpload) {
      setTimeout(function () {
        // setUploadStudentList('');

        setIsUpload(false);
      }, 1000);
    }
  }, [isUpload]);

  const detectUploadTime = (time) => {
    if (time) {
      const diff = moment().diff(time, 'seconds');
      // console.log(diff);

      if (diff > 60) {
        // console.log('true');
        return true;
      } else {
        // console.log('false');
        return false;
      }
    }
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      {isDeleteModalOpen && (
        <StudentDeletePopUp title='delete student' data={deleteStudentInfo} onCancel={handelModalClose} />
      )}
      {studentUploadModal && <StudentUploadPopUp data={uploadStudents && uploadStudents} onCancel={handelModalClose} />}

      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* <!-- search/add student section  --> */}
        <div className='container mx-auto'>
          <div className='flex flex-wrap items-center w-full px-4 sm:w-4/5 mx-auto mb-5'>
            {/* <!-- add student  --> */}
            <div className=''>
              <h1 className='text-xl space-x-3 md:text-3xl capitalize font-semibold text-black'>
                <span className=' mr-3'>
                  {defaultLanguage === 'en' ? newData?.class_name_en : newData.class_name_bn}
                </span>
                {hasShift?.data?.has_shift === 'yes' && (
                  <span className=' mr-3'>
                    {defaultLanguage === 'en' ? newData?.shift_name_en : newData?.shift_name_bn}
                  </span>
                )}
                <span className=' mr-3'>
                  {defaultLanguage === 'en' ? newData?.section_name_en : newData?.section_name_bn}
                </span>
              </h1>
            </div>
            {/* <!-- student search input  --> */}
            <div className=' ml-auto relative order-2 lg:order-1 w-full lg:w-auto mt-4 lg:mt-0'>
              {/* <form onSubmit={handelStudentSearch}>
								<input
									className='form-select capitalize block w-full pl-3 pr-6 py-1.5 text-base font-normal text-gray-700 bg-white0 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-full transition ease-in-out m-0
								focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
									type='search'
									name="studentNameSearch"
									id='studentNameSearch'
									// value={studentNameSearch}
									placeholder='search'
									// onChange={(e)=>setStudentNameSearch(e.target.value)}
								/>
								<button type="submit"  className='text-gray-500 hover:text-green-600 cursor-pointer absolute top-2 right-2'>
									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</button>
							</form> */}
            </div>

            {/* <!-- button  --> */}
            <div className='ml-auto lg:ml-4 order-1 lg:order-2'>
              <div className='flex flex-row gap-2'>
                <div className='relative overflow-hidden'>
                  <button
                    type='button'
                    className={` ${
                      uploadStudentList ? 'bg-[#FEEBDB] border-[#F87D2C]' : 'bg-white border-green-600'
                    } md:w-36 flex cursor-pointer align-middle gap-x-2 py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-black border border-green-600 mr-0`}
                  >
                    <span className='relative bottom-[-1px]'>
                      <img className='w-[15px]' src={uploadIcon} alt='' />
                    </span>
                    <span className='relative top-[-1px]'>
                      {uploadStudentList ? (
                        uploadStudentList?.name?.slice(0, 7) + '.....'
                      ) : (
                        <FormattedMessage id='selectFile' />
                      )}
                    </span>
                  </button>
                  <input
                    onChange={(e) => {
                      setUploadStudentList(e.target.files[0]);
                      // console.log(e.target.files[0].name);
                      setFileSelectTime(new Date());
                    }}
                    className=' overflow-hidden absolute top-0 opacity-0 cursor-pointer '
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  />
                </div>
                <button
                  className=' md:w-36   py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-white bg-gradient hover_gradient'
                  onClick={handelCsvUpload}
                >
                  <FormattedMessage id='upload' />
                </button>
                <a
                  href={sampleDownload && sampleDownload?.data}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='md:w-36 text-center py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-white bg-gradient hover_gradient'
                >
                  <FormattedMessage id='sampleFile' />
                </a>
              </div>
            </div>
          </div>

          <div className='flex flex-wrap items-center w-full px-4 sm:w-4/5 mx-auto'>
            {/* <!-- add student  --> */}
            <div className=''>
              <div className=' ml-auto relative order-2 lg:order-1 w-full lg:w-auto mt-4 lg:mt-0'>
                <form onSubmit={handelStudentSearch}>
                  <input
                    className='form-select capitalize block w-full pl-3 pr-6 py-1.5 text-base font-normal text-gray-700 bg-white0 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-full transition ease-in-out m-0
								focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    type='search'
                    name='studentNameSearch'
                    id='studentNameSearch'
                    // value={studentNameSearch}
                    placeholder={intl.formatMessage({ id: 'search' })}
                    // onChange={(e)=>setStudentNameSearch(e.target.value)}
                  />
                  <button
                    type='submit'
                    className='text-gray-500 hover:text-green-600 cursor-pointer absolute top-2 right-2'
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </button>
                </form>
              </div>
            </div>
            {/* <!-- student search input  --> */}
            <div className=' ml-auto relative order-2 lg:order-1 w-full lg:w-auto mt-4 lg:mt-0'></div>

            {/* <!-- button  --> */}
            <div className='ml-auto lg:ml-4 order-1 lg:order-2'>
              <Link
                to='/upgrade'
                state={{ sectionInfo: localStorage.getItem('sectionInfo') }}
                type='button'
                style={{ minWidth: 185 }}
                className=' w-36 py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-black bg-[#FEEBDB] border border-[#F87D2C] mr-2'
              >
                <span>
                  <FormattedMessage id='upgradeClass' />
                </span>
              </Link>

              <button
                onClick={() => {
                  gotoAddStudentPage();
                }}
                type='button'
                className=' w-36  py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded-full text-white bg-gradient hover_gradient'
              >
                <span>
                  <FormattedMessage id='add-student' />
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* table section  start   */}
        {studentList && studentList.loading ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            <React.Fragment>
              <div className='container mx-auto'>
                <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
                  <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                    {/* <!-- total number  --> */}
                    <div className='flex flex-wrap flex-col md:flex-row items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                      <div className='mb-3 md:mb-0'>
                        <p className='text-base text-black capitalize'>
                          <FormattedMessage id='totalStudent' />:{' '}
                          <span>
                            {studentMetaData && defaultLanguage === 'bn'
                              ? toBnNumber(studentMetaData.total)
                              : studentMetaData.total}
                          </span>{' '}
                        </p>
                      </div>
                      <div className='flex items-center space-x-3'>
                        <div className=''>
                          <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                            <FormattedMessage id='gender' />
                          </label>
                        </div>
                        {/* <!--select  class --> */}
                        <div className=''>
                          <select
                            onChange={handelGender}
                            value={gender}
                            id='institute'
                            name='gender'
                            className='form-select block	w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                            aria-label='Default select example'
                          >
                            <option value='0'>{intl.formatMessage({ id: 'selectGender' })}</option>
                            {studentGender?.map((gender) => (
                              <option value={gender.name_en}>
                                {defaultLanguage === 'en' ? gender.name_en : gender.name_bn}
                              </option>
                            ))}
                            {/* <option value='boy'>boy</option>
                            <option value='girl'>girl</option> */}
                          </select>
                        </div>
                        {/* <!-- button  --> */}
                        <div className=''>
                          <button
                            onClick={handelGenderFilter}
                            type='button'
                            className='  py-2 px-6 capitalize text-[14px] rounded text-white bg-gradient'
                          >
                            <span>
                              <FormattedMessage id='filter' />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <!-- table header  --> */}
                    {currentTableData && currentTableData?.length >= 1 ? (
                      <React.Fragment>
                        <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                          <thead className=' text-left bg-[#BFE3B5]'>
                            <tr>
                              <th className=' border border-gray-300 capitalize p-2'>
                                {<FormattedMessage id='rollNo' />}
                              </th>
                              <th className=' border border-gray-300 capitalize p-2'>
                                {<FormattedMessage id='studentName' />}
                              </th>
                              <th className=' border border-gray-300 capitalize p-2'>
                                {<FormattedMessage id='gender' />}
                              </th>
                              <th className=' border border-gray-300 capitalize p-2'>
                                {<FormattedMessage id='age' />}
                              </th>
                              <th className=' border border-gray-300 capitalize p-2'>
                                {<FormattedMessage id='option' />}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <!-- table single row --> */}
                            {currentTableData &&
                              currentTableData.map((student, index) => {
                                return (
                                  <tr key={student.name + index} className='hover:bg-green-50 cursor-pointer'>
                                    <td
                                      className=' border border-gray-300 p-2'
                                      onClick={() => gotoProfilePage(student)}
                                    >
                                      {student.roll}
                                    </td>
                                    <td
                                      className=' border border-gray-300 p-2 capitalize'
                                      onClick={() => gotoProfilePage(student)}
                                    >
                                      {student.name}
                                    </td>
                                    <td
                                      className=' border border-gray-300 p-2'
                                      onClick={() => gotoProfilePage(student)}
                                    >
                                      {student.gender}
                                    </td>
                                    <td
                                      className=' border border-gray-300 p-2'
                                      onClick={() => gotoProfilePage(student)}
                                    >
                                      {student.age}
                                    </td>
                                    <td className=' border border-gray-300 p-2 space-x-2 relative z-10'>
                                      <button onClick={() => goToEditPage(student)}>
                                        <span className='cursor-pointer w-8 h-8 leading-8 bg-[#FEEBDB] text-[#F87D2C] inline-block text-center text-base rounded-full'>
                                          <FontAwesomeIcon icon={faPenToSquare} />
                                        </span>
                                      </button>

                                      <button onClick={() => handelStudentDelete(student)}>
                                        <span className='cursor-pointer w-8 h-8 leading-8 bg-[#FDE4E9] text-[#EF5E76] inline-block text-center text-base rounded-full'>
                                          <FontAwesomeIcon icon={faTrashCan} />
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            {/* <!-- table single row --> */}
                          </tbody>
                        </table>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </div>
            </React.Fragment>
            {((studentList && studentList?.data?.length === 0) ||
              (studentList && studentList?.data?.length === null) ||
              (studentList && studentList?.data?.length === undefined)) && (
              <EmptyList message='Student List is empty' border />
            )}
          </React.Fragment>
        )}

        {/* table section  end   */}

        {/* pagination section start */}
        {/* {studentMetaData && studentMetaData.total>=10 && ( */}
        <div className='container mx-auto'>
          <div className='w-full px-4 sm:w-4/5 mx-auto'>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={studentList?.data?.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>

        {/* // )} */}
        {/* pagination section end */}
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    studentData: state.studentData,
    studentList: state?.studentData,
    studentMetaData: state?.studentData?.meta,
    message: state.shiftStudent.message,
    uploadStudents: state.uploadStudents,
    sampleDownload: state.download,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStudent: (studentInfo) => dispatch(sectionStudentAction(studentInfo)),
    deleteSectionStudentRequest: () => dispatch(deleteSectionStudentRequest()),
    createSectionStudentFromShiftRequest: () => dispatch(createSectionStudentFromShiftRequest()),
    updateSectionStudentRequest: () => dispatch(updateSectionStudentRequest()),
    studentUpload: (dataInfo, callback) => dispatch(studentUploadAction(dataInfo, callback)),
    studentSampleDownload: () => dispatch(studentSampleDownloadAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentListFromShiftPage);
