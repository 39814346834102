import React, { useEffect, useState, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../../Components/LoadingSpinner';
import BreadCrumb from '../../../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../../../Components/EmptyList';
import {
  getClassWiseShiftListAction,
  getReferralStudentListAction,
  getSectionListFromShiftAction,
  getStockListAction,
  getSubjectListAction,
  getUpgradeClassListsAction,
  getWeekNumberAction,
} from '../../../../../redux';
import ProgramHeading from '../ProgramHeading';
import StudentSearch from '../StudentSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import pdfIcon from '../../../../../assets/image/nutritions-page/pdf-icon.png';
import { useNavigate } from 'react-router-dom';
import { postReferralStudentRequest } from '../../../../../redux/actions/upgradeClass.action';
import StoreContext from '../../../../../context/StoreContext';
import Pagination from '../../../../../Components/Pagination/Pagination';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageContext from '../../../../../context/LanguageContext';
import { toBnNumber } from '../../../../../common/functions';
const Referral = ({ hasShift, getStudentList, studentList, studentMetaData, postMsg, postReferralStudentRequest }) => {
  const intl = useIntl();
  // const { defaultLanguage } = useContext(LanguageContext);
  const { classList } = useContext(StoreContext);
  const { defaultLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [pageNumber, setPageNumber] = useState(1);
  const [InitialClassId, setInitialClassId] = useState(null);
  const [gradeId, setGrade] = useState(null);
  const [shiftId, setShift] = useState(null);
  const [sectionId, setSection] = useState(null);
  const [studentIds, setStudentIds] = useState([]);
  const [IsSubmit, setIsSubmit] = useState(false);

  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(250);

  const [rendererList, setRendererList] = useState([]);

  useEffect(() => {
    setRendererList(studentList?.data?.data);
  }, [studentList?.data?.data]);

  useEffect(() => {
    const allClass = classList;
    let defaultSectionId = '';
    setGrade(allClass[0]?.grade);

    if (hasShift?.data?.has_shift === 'yes') {
      setShift(allClass[0]?.shift_section[0]?.shift_id);
      setSection(allClass[0]?.shift_section[0]?.section[0]?.id);
      defaultSectionId = allClass[0]?.shift_section[0]?.section[0]?.id;
      setShiftArray(allClass[0]?.shift_section);
      setSectionArray(allClass[0]?.shift_section[0]?.section);
    } else {
      setSection(allClass[0]?.shift_section[0]?.id);
      defaultSectionId = allClass[0]?.shift_section[0]?.id;
      setSectionArray(allClass[0]?.shift_section);
      // console.log(allClass[0]?.shift_section)
    }

    const data = {
      institution_id: institutionId,
      grade: allClass[0]?.grade,
      section_id: defaultSectionId,
    };

    if (hasShift?.data?.has_shift === 'yes') {
      data['shift_id'] = allClass[0]?.shift_section[0]?.shift_id;
    }
  }, [classList]);

  // call api on selection class/shift/section
  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setInitialClassId(e.target.value);
    setShift('');
    setSection('');

    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    // setGradeName(singleClass?.name_en);

    if (hasShift?.data?.has_shift === 'yes') {
      //   getShiftLists(data);

      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      //   getSectionLists(data);

      setSectionArray(singleClass?.shift_section);
    }
  };

  // call section based on shift selection

  const handelSelectShift = (e) => {
    setShift(e.target.value);

    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    // setShiftName(singleShift?.shift_name_en);
    setSectionArray(singleShift?.section);
    setSection('');
  };

  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      if (gradeId && shiftId && sectionId) {
        const data = {
          institution_id: institutionId,
          grade: gradeId,
          shift_id: shiftId,
          section_id: sectionId,
          pagination_limit: pageLimit,
        };

        getStudentList(data);
      }
    } else if (hasShift?.data?.has_shift === 'no') {
      if (gradeId && sectionId) {
        const data = {
          institution_id: institutionId,
          grade: gradeId,
          section_id: sectionId,
          pagination_limit: pageLimit,
        };

        getStudentList(data);
      }
    }
  }, [gradeId, shiftId, sectionId]);

  // section select
  const handelSelectSection = (e) => {
    setSection(e.target.value);
  };

  // error handel from server

  const [ErrorStudentId, setErrorStudentId] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  useEffect(() => {
    setErrorStudentId(postMsg?.error?.student_id);
  }, [postMsg?.error?.student_id]);
  useEffect(() => {
    setResponseMessage(postMsg.message);
  }, [postMsg.message]);

  /********students search*********/
  const [searchResult, setSearchResult] = useState('');
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const handelStudentSearch = (e) => {
    e.preventDefault();
    setSearchResult(e.target.value);
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const { name, value } = e.target.nameSearch;
    // console.log(value);
    // console.log(studentList?.data?.data);
    const filterResults = studentList?.data?.data?.filter((student) =>
      student.name.toLowerCase().includes(value.toLowerCase())
    );
    // console.log(filterResults);
    setRendererList(filterResults);
    setIsSubmitSearch(true);
    setPageSize(10);
    setCurrentPage(1);
  };

  // pagination
  const [pageSize, setPageSize] = useState(10);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return rendererList && rendererList?.slice(firstPageIndex, lastPageIndex);
  }, [pageSize, currentPage, rendererList]);

  // go to referral student page
  const goToReFerralPage = (student) => {
    postReferralStudentRequest();
    if (student.participation_status === 'no') {
      navigate('/referral-list/add-referral', { state: student });
    }
  };

  // download pdf
  const downloadPdf = (student) => {
    navigate('/referral-list/referral-pdf', { state: student });
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <React.Fragment>
        <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
          {/* program heading */}
          <ProgramHeading title={<FormattedMessage id='referral' />}>
            <StudentSearch
              onSubmit={handelSubmit}
              onChange={handelStudentSearch}
              value={searchResult}
              name='nameSearch'
            />
          </ProgramHeading>
          {/* initial query class/shift/section start*/}
          <div className='container mx-auto'>
            <div className='flex flex-wrap items-center w-full px-4  sm:w-4/5 mx-auto '>
              <div className='w-full flex flex-row space-x-5 bg-[#399737] p-4 rounded-md'>
                {/* class */}
                <div className='w-full'>
                  <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                    <FormattedMessage id='class' />
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    value={gradeId && gradeId}
                    onChange={handelSelectClass}
                    name='class'
                    id='class'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value='0'>{intl.formatMessage({ id: 'selectClass' })}</option>
                    {classList &&
                      classList?.map((classes, index) => {
                        return (
                          <option key={index} value={classes.grade}>
                            {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {/* shift */}
                {hasShift?.data?.has_shift === 'yes' && (
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                      <FormattedMessage id='shift' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={shiftId && shiftId}
                      onChange={handelSelectShift}
                      name='shift'
                      id='shift'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value='0'>{intl.formatMessage({ id: 'selectShift' })}</option>
                      {shiftArray &&
                        shiftArray?.map((shift, index) => {
                          return (
                            <option value={shift.shift_id} key={index}>
                              {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
                {/* section */}
                <div className='w-full'>
                  <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                    <FormattedMessage id='section' />
                    <span className=' text-red-700'>*</span>
                  </label>
                  <select
                    value={sectionId && sectionId}
                    onChange={handelSelectSection}
                    name='section'
                    id='section'
                    className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value='0'>{intl.formatMessage({ id: 'selectSection' })}</option>
                    {sectionArray &&
                      sectionArray?.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* initial query class/shift/section end*/}

          {/* student list with filter section */}
          <div className='container mx-auto'>
            <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
              <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                {/* <!-- total number  --> */}
                <div className='flex flex-wrap flex-col md:flex-row items-center md:items-center justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                  <div className='mb-3 md:mb-0'>
                    <p className='text-base text-black capitalize'>
                      <FormattedMessage id='totalStudent' /> :{' '}
                      <span>
                        {studentMetaData && defaultLanguage === 'bn'
                          ? toBnNumber(studentMetaData?.total)
                          : studentMetaData?.total}
                      </span>{' '}
                    </p>
                  </div>
                </div>
                {/* <!-- table header start --> */}
                {studentList && studentList.loading ? (
                  <LoadingSpinner />
                ) : (
                  <React.Fragment>
                    {currentTableData && currentTableData?.length ? (
                      <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                        <thead className=' text-left bg-[#BFE3B5]'>
                          <tr>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='rollNo' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='studentName' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='gender' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='age' />
                            </th>
                            <th className=' border border-gray-300 capitalize p-2'>
                              <FormattedMessage id='status' />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTableData.map((student, index) => {
                            const participate = student.participation_status === 'yes';
                            return (
                              <tr
                                key={index}
                                className='hover:bg-green-50 cursor-pointer'
                                onClick={() => goToReFerralPage(student)}
                              >
                                <td className='border border-gray-300 p-2'>{student.roll}</td>
                                <td className='border border-gray-300 p-2 capitalize'>{student.name}</td>
                                <td className='border border-gray-300 p-2 capitalize'>{student.gender}</td>
                                <td className='border border-gray-300 p-2'>{student.age}</td>

                                <td className=' border border-gray-300 p-2 flex space-x-2'>
                                  <span className='w-[50px] h-[50px] leading-[50px] text-center text-green-600 text-2xl  grid place-items-center'>
                                    {participate ? <FontAwesomeIcon icon={faCheck} /> : null}
                                  </span>
                                  <span
                                    onClick={() => downloadPdf(student)}
                                    className='w-[50px] h-[50px] leading-[50px] text-center  grid place-items-center'
                                  >
                                    {participate ? <img src={pdfIcon} alt='pdf-icon' /> : null}
                                  </span>
                                  {/* NOTE: DOWNLOAD DOCUMENT */}
                                  {/* <PDFDownloadLink document={<MyDoc data={student}/>} fileName={`${student.name}-referral.pdf`}>
                                                                    <span className="w-[50px] h-[50px] leading-[50px] text-center  grid place-items-center">
                                                                        {participate ? <img src={pdfIcon} alt="pdf-icon"/>:null}
                                                                    </span>
                                                                </PDFDownloadLink> */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <EmptyList message='Your section List is empty' border={false} />
                    )}
                  </React.Fragment>
                )}
                {/* <!-- table header end --> */}
              </div>

              {/* pagination section start */}

              <div className='container mx-auto my-5'>
                <div className='w-full px-4 sm:w-4/5 mx-auto'>
                  <Pagination
                    className='pagination-bar'
                    currentPage={currentPage}
                    totalCount={rendererList?.length || 0}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
              {/* pagination section end */}
              {/* <!-- submit button  --> */}
              {/* <div className="flex justify-center mt-4">
                                <button
                                    onClick={handelSubmitToFilter}
                                    type="button"
                                    className="w-1/3 py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded text-white bg-gradient hover_gradient">
                                    <span>submit</span>
                                </button>
                            </div> */}

              {responseMessage && responseMessage && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {responseMessage}
                  </h1>
                </div>
              )}
              {ErrorStudentId && ErrorStudentId && (
                <div className=' mt-2 flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-500'>
                    {ErrorStudentId}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentList: state.ProgramStudentList,
    studentMetaData: state.ProgramStudentList?.data?.meta,
    subjectList: state.subjects,
    weekNumber: state.weeks?.data?.week,
    stockList: state.stockList,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
    // show message for posted data info
    postMsg: state.ParticipatedStudents,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getSubjectList: () => dispatch(getSubjectListAction()),
    getStockList: (dataInfo) => dispatch(getStockListAction(dataInfo)),
    getStudentList: (studentInfo) => dispatch(getReferralStudentListAction(studentInfo)),
    getWeekNumber: (dateInfo) => dispatch(getWeekNumberAction(dateInfo)),
    postReferralStudentRequest: () => dispatch(postReferralStudentRequest()),
    // postStudentIdForProgram: (dateInfo) => dispatch(postDewormingProgramStudentListAction(dateInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
