import { data } from 'jquery';
import {
  GET_SHIFT_CHECK_REQUEST,
  GET_SHIFT_CHECK_SUCCESS,
  GET_SHIFT_CHECK_FAILED,
  GET_UPGRADE_CLASS_LIST_REQUEST,
  GET_UPGRADE_CLASS_LIST_SUCCESS,
  GET_UPGRADE_CLASS_LIST_FAILED,
  GET_CLASS_WISE_SHIFT_LIST_REQUEST,
  GET_CLASS_WISE_SHIFT_LIST_SUCCESS,
  GET_CLASS_WISE_SHIFT_LIST_FAILED,
  GET_SECTION_LIST_REQUEST,
  GET_SECTION_LIST_SUCCESS,
  GET_SECTION_LIST_FAILED,
  CREATE_SECTION_LIST_REQUEST,
  CREATE_SECTION_LIST_SUCCESS,
  CREATE_SECTION_LIST_FAILED,
  UPDATE_SECTION_LIST_REQUEST,
  UPDATE_SECTION_LIST_SUCCESS,
  UPDATE_SECTION_LIST_FAILED,
  DELETE_SECTION_LIST_REQUEST,
  DELETE_SECTION_LIST_SUCCESS,
  DELETE_SECTION_LIST_FAILED,
  CREATE_SECTION_TEACHER_REQUEST,
  CREATE_SECTION_TEACHER_SUCCESS,
  CREATE_SECTION_TEACHER_FAILED,
  DELETE_TEACHER_REQUEST,
  DELETE_TEACHER_SUCCESS,
  DELETE_TEACHER_FAILED,
  UPDATE_TEACHER_REQUEST,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_TEACHER_FAILED,
  GET_TEACHER_PROFILE_REQUEST,
  GET_TEACHER_PROFILE_SUCCESS,
  GET_TEACHER_PROFILE_FAILED,
  GET_SECTION_STUDENT_LIST_REQUEST,
  GET_SECTION_STUDENT_LIST_SUCCESS,
  GET_SECTION_STUDENT_LIST_FAILED,
  GET_STUDENT_PROFILE_REQUEST,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_STUDENT_PROFILE_FAILED,
  CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST,
  CREATE_SECTION_STUDENT_FROM_SHIFT_SUCCESS,
  CREATE_SECTION_STUDENT_FROM_SHIFT_FAILED,
  DELETE_SECTION_STUDENT_REQUEST,
  DELETE_SECTION_STUDENT_SUCCESS,
  DELETE_SECTION_STUDENT_FAILED,
  UPDATE_SECTION_STUDENT_REQUEST,
  UPDATE_SECTION_STUDENT_SUCCESS,
  UPDATE_SECTION_STUDENT_FAILED,
  GET_SUBJECT_LIST_REQUEST,
  GET_SUBJECT_LIST_SUCCESS,
  GET_SUBJECT_LIST_FAILED,
  GET_STOCK_LIST_REQUEST,
  GET_STOCK_LIST_SUCCESS,
  GET_STOCK_LIST_FAILED,
  GET_NUTRITION_PROGRAM_STUDENT_LIST_REQUEST,
  GET_NUTRITION_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_NUTRITION_PROGRAM_STUDENT_LIST_FAILED,
  GET_WEEK_NUMBER_REQUEST,
  GET_WEEK_NUMBER_SUCCESS,
  GET_WEEK_NUMBER_FAILED,
  POST_PROGRAM_STUDENT_LIST_REQUEST,
  POST_PROGRAM_STUDENT_LIST_SUCCESS,
  POST_PROGRAM_STUDENT_LIST_FAILED,
  POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
  POST_WIFA_PROGRAM_STUDENT_LIST_SUCCESS,
  POST_WIFA_PROGRAM_STUDENT_LIST_FAILED,
  GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
  GET_WIFA_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_WIFA_PROGRAM_STUDENT_LIST_FAILED,
  GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
  GET_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,
  POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
  POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
  POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,
  GET_BMI_PROGRAM_STUDENT_LIST_REQUEST,
  GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_BMI_PROGRAM_STUDENT_LIST_FAILED,
  GET_FEET_TO_METER_REQUEST,
  GET_FEET_TO_METER_SUCCESS,
  GET_FEET_TO_METER_FAILED,
  POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST,
  POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS,
  POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED,
  GET_WIFA_TOTAL_TABLET_REQUEST,
  GET_WIFA_TOTAL_TABLET_SUCCESS,
  GET_WIFA_TOTAL_TABLET_FAILED,
  GET_DEWORMING_TOTAL_TABLET_REQUEST,
  GET_DEWORMING_TOTAL_TABLET_SUCCESS,
  GET_DEWORMING_TOTAL_TABLET_FAILED,
  GET_WIFA_USED_TABLET_REQUEST,
  GET_WIFA_USED_TABLET_SUCCESS,
  GET_WIFA_USED_TABLET_FAILED,
  GET_DEWORMING_USED_TABLET_REQUEST,
  GET_DEWORMING_USED_TABLET_SUCCESS,
  GET_DEWORMING_USED_TABLET_FAILED,
  GET_WIFA_HISTORY_REQUEST,
  GET_WIFA_HISTORY_SUCCESS,
  GET_WIFA_HISTORY_FAILED,
  GET_DEWORMING_HISTORY_REQUEST,
  GET_DEWORMING_HISTORY_SUCCESS,
  GET_DEWORMING_HISTORY_FAILED,
  ADD_WIFA_TABLET_REQUEST,
  ADD_WIFA_TABLET_SUCCESS,
  ADD_WIFA_TABLET_FAILED,
  ADD_DEWORMING_TABLET_REQUEST,
  ADD_DEWORMING_TABLET_SUCCESS,
  ADD_DEWORMING_TABLET_FAILED,
  GET_REPORT_PROGRAM_LIST_REQUEST,
  GET_REPORT_PROGRAM_LIST_SUCCESS,
  GET_REPORT_PROGRAM_LIST_FAILED,
  GET_WEEKLY_REPORT_REQUEST,
  GET_WEEKLY_REPORT_SUCCESS,
  GET_WEEKLY_REPORT_FAILED,
  GET_MONTHLY_REPORT_REQUEST,
  GET_MONTHLY_REPORT_SUCCESS,
  GET_MONTHLY_REPORT_FAILED,
  GET_ANNUAL_REPORT_REQUEST,
  GET_ANNUAL_REPORT_SUCCESS,
  GET_ANNUAL_REPORT_FAILED,
  GET_REFERRAL_REPORT_REQUEST,
  GET_REFERRAL_REPORT_SUCCESS,
  GET_REFERRAL_REPORT_FAILED,
  GET_REFERRAL_STUDENT_LIST_REQUEST,
  GET_REFERRAL_STUDENT_LIST_SUCCESS,
  GET_REFERRAL_STUDENT_LIST_FAILED,
  POST_REFERRAL_STUDENT_REQUEST,
  POST_REFERRAL_STUDENT_SUCCESS,
  POST_REFERRAL_STUDENT_FAILED,
  GET_REFERRAL_STUDENT_DATA_REQUEST,
  GET_REFERRAL_STUDENT_DATA_SUCCESS,
  GET_REFERRAL_STUDENT_DATA_FAILED,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILED,
  STUDENT_UPLOAD_REQUEST,
  STUDENT_UPLOAD_SUCCESS,
  STUDENT_UPLOAD_FAILED,
  TEACHER_LIST_REQUEST,
  TEACHER_LIST_SUCCESS,
  TEACHER_LIST_FAILED,
  STUDENT_SAMPLE_DOWNLOAD_REQUEST,
  STUDENT_SAMPLE_DOWNLOAD_SUCCESS,
  STUDENT_SAMPLE_DOWNLOAD_FAILED,
  UPGRADE_STUDENT_REQUEST,
  UPGRADE_STUDENT_SUCCESS,
  UPGRADE_STUDENT_FAILED,
  POST_UPGRADE_STUDENT_REQUEST,
  POST_UPGRADE_STUDENT_SUCCESS,
  POST_UPGRADE_STUDENT_FAILED,
} from '../constance/upgradeClasses.constant';

// GET_SHIFT_CHECK_REQUEST,
// GET_SHIFT_CHECK_SUCCESS,
// GET_SHIFT_CHECK_FAILED,

const shiftState = {
  status: true,
  error: false,
  message: '',
  data: null,
  loading: false,
};
export const checkShiftStatusReducer = (state = shiftState, action) => {
  switch (action.type) {
    case GET_SHIFT_CHECK_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        data: null,
        loading: true,
      };
    case GET_SHIFT_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_SHIFT_CHECK_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: null,
      };
    default:
      return state;
  }
};

const initialClassState = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const upgradeClassesReducer = (state = initialClassState, action) => {
  switch (action.type) {
    case GET_UPGRADE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    case GET_UPGRADE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_UPGRADE_CLASS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

// get shift lists
const initialShiftState = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const getClassWiseShiftListReducer = (state = initialShiftState, action) => {
  switch (action.type) {
    case GET_CLASS_WISE_SHIFT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    case GET_CLASS_WISE_SHIFT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_CLASS_WISE_SHIFT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// get section lists
const initialSectionState = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const getSectionListReducer = (state = initialSectionState, action) => {
  switch (action.type) {
    case GET_SECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case GET_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_SECTION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    // create
    case CREATE_SECTION_LIST_REQUEST:
      return {
        ...state,
        message: '',
        data: state.data,
      };
    case CREATE_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [...state.data, action.payload.data],
      };
    // delete
    case DELETE_SECTION_LIST_REQUEST:
      return {
        ...state,
        message: '',
        data: state.data,
      };
    case DELETE_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    // update section lists
    case UPDATE_SECTION_LIST_REQUEST:
      return {
        ...state,
        message: '',
        data: state.data,
      };

    case UPDATE_SECTION_LIST_SUCCESS:
      state.data.map((section) => {
        if (section.id === action.payload.data.id) {
          section.section_name_en = action.payload.data.section_name_en;
          section.section_name_bn = action.payload.data.section_name_bn;
        }
      });
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: state.data,
      };

    default:
      return state;
  }
};

// create section lists

const initialSectionListState = {
  status: true,
  error: null,
  loading: true,
  message: '',
  data: [],
};
export const createSectionListReducer = (state = initialSectionListState, action) => {
  switch (action.type) {
    case CREATE_SECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case CREATE_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [...state.data, action.payload.data],
      };
    case CREATE_SECTION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    case UPDATE_SECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case UPDATE_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case UPDATE_SECTION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    case DELETE_SECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case DELETE_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: state.data.filter((item) => item.id !== action.payload.data),
      };
    case DELETE_SECTION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };

    default:
      return state;
  }
};

// SECTION TEACHER CREATE REDUCER

const initialSectionTeacherState = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

// ----------------------teacher-----------------------------------

// UPDATE_TEACHER_REQUEST,
// TEACHER_LIST_REQUEST
// TEACHER_LIST_SUCCESS
// TEACHER_LIST_FAILED
export const createSectionTeacherReducer = (state = initialSectionTeacherState, action) => {
  switch (action.type) {
    case TEACHER_LIST_REQUEST:
      return {
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };

    case TEACHER_LIST_SUCCESS:
      return {
        ...state,
        status: true,
        error: false,
        loading: false,
        message: '',
        data: action.payload.data,
      };
    case TEACHER_LIST_FAILED:
      return {
        status: true,
        error: false,
        loading: false,
        message: '',
        data: action.payload.data,
      };

    case CREATE_SECTION_TEACHER_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case CREATE_SECTION_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case CREATE_SECTION_TEACHER_FAILED:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    case DELETE_TEACHER_REQUEST:
      return {
        ...state,
        status: true,
        // loading:true,
        error: false,
        message: '',
        data: state.data,
      };
    case DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case DELETE_TEACHER_FAILED:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case UPDATE_TEACHER_REQUEST:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: false,
        message: '',
        data: [],
      };
    case UPDATE_TEACHER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: true,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case UPDATE_TEACHER_FAILED:
      return {
        ...state,
        status: action.payload.status,
        loading: true,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
// --------------------------teacher profile-----------------------
const initialTeacherProfileState = {
  loading: false,
  data: [],
  error: '',
  message: '',
};

export const teacherProfileReducer = (state = initialTeacherProfileState, action) => {
  switch (action.type) {
    case GET_TEACHER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        data: [],
        message: '',
      };
    case GET_TEACHER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: action.payload.error,
        message: action.payload.message,
      };
    case GET_TEACHER_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error,
        message: action.payload.message,
      };

    default:
      return state;
  }
};

// GET_SECTION_STUDENT,

const initialStudentData = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
  links: null,
  meta: null,
  // paginationData: null,
};

export const sectionStudentReducer = (state = initialStudentData, action) => {
  switch (action.type) {
    case GET_SECTION_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
        links: null,
        meta: null,
      };
    case GET_SECTION_STUDENT_LIST_SUCCESS:
      // console.log('=============================action.payload=====', action.payload);
      return {
        ...state,
        status: true,
        error: false,
        loading: false,
        data: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
        // paginationData: action.payload.pagination,
      };
    case GET_SECTION_STUDENT_LIST_FAILED:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST:
      return {
        ...state,
        data: state.data,
        message: '',
      };
    // delete student
    case DELETE_SECTION_STUDENT_REQUEST:
      return {
        ...state,
        // loading: true,
        message: '',
        error: '',
        data: state.data,
      };
    case DELETE_SECTION_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        data: state.data.filter((item) => item.id !== action.payload.id),
        error: '',
      };
    default:
      return state;
  }
};

// GET_STUDENT_PROFILE_REQUEST,
// GET_STUDENT_PROFILE_SUCCESS,
// GET_STUDENT_PROFILE_FAILED,

const initialStudentProfileData = {
  loading: false,
  status: true,
  data: null,
  error: false,
  message: '',
};

export const studentProfileReducer = (state = initialStudentProfileData, action) => {
  switch (action.type) {
    case GET_STUDENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case GET_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        data: action.payload.data,
        message: action.payload.message,
      };
    case GET_STUDENT_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        data: [],
        message: action.payload.message,
      };

    default:
      return state;
  }
};

const initialStudentFromShift = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};
export const createSectionStudentFromShiftReducer = (state = initialStudentFromShift, action) => {
  switch (action.type) {
    case CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case CREATE_SECTION_STUDENT_FROM_SHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
        error: '',
      };
    case CREATE_SECTION_STUDENT_FROM_SHIFT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.error,
        data: [],
      };
    // update student
    case UPDATE_SECTION_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
        error: '',
        data: state.data,
      };
    case UPDATE_SECTION_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: '',
        data: action.payload.data,
      };
    case UPDATE_SECTION_STUDENT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.error,
        data: [],
      };
    // delete student
    case DELETE_SECTION_STUDENT_REQUEST:
      return {
        ...state,
        message: '',
        error: '',
        data: state.data,
      };
    case DELETE_SECTION_STUDENT_SUCCESS:
      console.log(
        '=====================DELETE_SECTION_STUDENT_SUCCESS==action.payload.data=============',
        action.payload
      );
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        // data: state.data.filter((item) => item.id !== action.payload.id),
        data: action.payload.data,
        error: '',
      };
    case DELETE_SECTION_STUDENT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        data: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};

// GET_SUBJECT_LIST_REQUEST,

const subjectState = {
  status: true,
  error: false,
  message: '',
  loading: true,
  data: [],
};
export const getSubjectListReducer = (state = subjectState, action) => {
  switch (action.type) {
    case GET_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_SUBJECT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_STOCK_LIST_REQUEST,
// GET_STOCK_LIST_SUCCESS,
// GET_STOCK_LIST_FAILED,

const stockState = {
  status: true,
  error: false,
  message: '',
  loading: true,
  data: [],
};

export const getStockListReducer = (state = stockState, action) => {
  switch (action.type) {
    case GET_STOCK_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_STOCK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_STOCK_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_WEEK_NUMBER_REQUEST,
// GET_WEEK_NUMBER_SUCCESS,
// GET_WEEK_NUMBER_FAILED,

const weeks = {
  loading: true,
  status: true,
  error: false,
  message: '',
  data: [],
};
export const getWeekNumberReducer = (state = weeks, action) => {
  switch (action.type) {
    case GET_WEEK_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        status: true,
        error: false,
        message: '',
        data: [],
      };
    case GET_WEEK_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_WEEK_NUMBER_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_PROGRAM_STUDENT_LIST_REQUEST,
const ProgramStudentList = {
  status: true,
  error: false,
  message: '',
  loading: true,
  data: [],
};
// GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
// GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST
// GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,

// GET_BMI_PROGRAM_STUDENT_LIST_REQUEST,
// GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS,
// GET_BMI_PROGRAM_STUDENT_LIST_FAILED,

// GET_REFERRAL_STUDENT_LIST_REQUEST,
// GET_REFERRAL_STUDENT_LIST_SUCCESS,
// GET_REFERRAL_STUDENT_LIST_FAILED,

export const getProgramStudentListReducer = (state = ProgramStudentList, action) => {
  switch (action.type) {
    // nutrition
    case GET_NUTRITION_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_NUTRITION_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: false,
        data: action.payload,
      };
    case GET_NUTRITION_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        error: false,
        message: '',
        loading: false,
        data: [],
      };
    // wifa programs participated student id
    case GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_WIFA_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: false,
        data: action.payload,
      };
    case GET_WIFA_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        error: false,
        message: '',
        loading: false,
        data: [],
      };
    // deworming
    case GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: false,
        data: action.payload,
      };
    case GET_DEWORMING_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        error: false,
        message: '',
        loading: false,
        data: [],
      };
    // BMI
    case GET_BMI_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: false,
        data: action.payload,
      };
    case GET_BMI_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        error: false,
        message: '',
        loading: false,
        data: [],
      };
    // referral
    case GET_REFERRAL_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_REFERRAL_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: false,
        data: action.payload,
      };
    case GET_REFERRAL_STUDENT_LIST_FAILED:
      return {
        ...state,
        error: false,
        message: '',
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};

// POST_PROGRAM_STUDENT_LIST_REQUEST,
// POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST
// POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
const PostProgramStudentList = {
  status: true,
  error: false,
  message: '',
  loading: true,
  data: [],
};

export const postProgramStudentListReducer = (state = PostProgramStudentList, action) => {
  switch (action.type) {
    case POST_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case POST_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case POST_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    // wifa
    case POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case POST_WIFA_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case POST_WIFA_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    // deworming
    case POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED:
      return {
        ...state,
        loading: true,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// feet to meter
// GET_FEET_TO_METER_REQUEST,
// GET_FEET_TO_METER_SUCCESS,
// GET_FEET_TO_METER_FAILED,
const initialFeet = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};
export const getFeetToMeterReducer = (state = initialFeet, action) => {
  switch (action.type) {
    case GET_FEET_TO_METER_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };

    case GET_FEET_TO_METER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };

    case GET_FEET_TO_METER_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };

    default:
      return state;
  }
};

// POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST,
// POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS,
// POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED

const bmiResult = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const bmiResultReducer = (state = bmiResult, action) => {
  switch (action.type) {
    case POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_TOTAL_TABLET_REQUEST,
// GET_TOTAL_TABLET_SUCCESS,
// GET_TOTAL_TABLET_FAILED,

// wifa-tablet

const tableWifaTStock = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const getWifaTabletStock = (state = tableWifaTStock, action) => {
  switch (action.type) {
    case GET_WIFA_TOTAL_TABLET_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case GET_WIFA_TOTAL_TABLET_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_WIFA_TOTAL_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    // add tablet
    case ADD_WIFA_TABLET_REQUEST:
      return {
        ...state,
        // status: true,
        // error:false,
        // loading: true,
        message: '',
        data: state.data,
      };
    case ADD_WIFA_TABLET_SUCCESS:
      let newTablet = Number(state.data) + Number(action.payload.data.entry_tablet);
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: newTablet,
      };
    case ADD_WIFA_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};
// ADD_WIFA_TABLET_REQUEST,
// ADD_WIFA_TABLET_SUCCESS,
// ADD_WIFA_TABLET_FAILED,

const wifaAddTabletState = {
  status: true,
  error: false,
  loading: false,
  message: '',
  data: [],
};

export const wifaAddTabletReducer = (state = wifaAddTabletState, action) => {
  switch (action.type) {
    case ADD_WIFA_TABLET_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case ADD_WIFA_TABLET_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case ADD_WIFA_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_DEWORMING_TOTAL_TABLET_REQUEST,

const tabletDewormingStock = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const getDewormingTabletStock = (state = tabletDewormingStock, action) => {
  switch (action.type) {
    case GET_DEWORMING_TOTAL_TABLET_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case GET_DEWORMING_TOTAL_TABLET_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_DEWORMING_TOTAL_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    // add tablet
    case ADD_DEWORMING_TABLET_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: false,
        message: '',
        data: state.data,
      };
    case ADD_DEWORMING_TABLET_SUCCESS:
      const newTablet = Number(state.data) + Number(action.payload.data.entry_tablet);
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: newTablet,
      };
    case ADD_DEWORMING_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };

    default:
      return state;
  }
};
// ADD_DEWORMING_TABLET_REQUEST,
// ADD_DEWORMING_TABLET_SUCCESS,
// ADD_DEWORMING_TABLET_FAILED,

const dewormingAddTabletState = {
  status: true,
  error: false,
  loading: false,
  message: '',
  data: [],
};

export const dewormingAddTabletReducer = (state = dewormingAddTabletState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

// GET_DEWORMING_USED_TABLET_REQUEST,

const usedTabletDewormingStock = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const getDewormingTabletUsedStockReducer = (state = usedTabletDewormingStock, action) => {
  switch (action.type) {
    case GET_DEWORMING_USED_TABLET_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case GET_DEWORMING_USED_TABLET_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_DEWORMING_USED_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_WIFA_USED_TABLET_REQUEST,
const usedTabletWifaStock = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};

export const getWifaTabletUsedStockReducer = (state = usedTabletWifaStock, action) => {
  switch (action.type) {
    case GET_WIFA_USED_TABLET_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case GET_WIFA_USED_TABLET_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_WIFA_USED_TABLET_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_WIFA_HISTORY_REQUEST,
const wifaHistoryState = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};
export const wifaHistoryReducer = (state = wifaHistoryState, action) => {
  switch (action.type) {
    case GET_WIFA_HISTORY_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case GET_WIFA_HISTORY_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_WIFA_HISTORY_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_DEWORMING_HISTORY_REQUEST,

const dewormingHistoryState = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};
export const dewormingHistoryReducer = (state = dewormingHistoryState, action) => {
  switch (action.type) {
    case GET_DEWORMING_HISTORY_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case GET_DEWORMING_HISTORY_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_DEWORMING_HISTORY_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_REPORT_PROGRAM_LIST_REQUEST,
// GET_REPORT_PROGRAM_LIST_SUCCESS,
// GET_REPORT_PROGRAM_LIST_FAILED,

const programState = {
  status: true,
  error: false,
  message: '',
  loading: true,
  data: [],
};
export const getProgramListReducer = (state = programState, action) => {
  switch (action.type) {
    case GET_REPORT_PROGRAM_LIST_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_REPORT_PROGRAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case GET_REPORT_PROGRAM_LIST_FAILED:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        data: [],
      };
    default:
      return state;
  }
};

// GET_WEEKLY_REPORT_REQUEST,
// GET_WEEKLY_REPORT_SUCCESS,
// GET_WEEKLY_REPORT_FAILED,

// GET_MONTHLY_REPORT_REQUEST,
// GET_MONTHLY_REPORT_SUCCESS,
// GET_MONTHLY_REPORT_FAILED,

// GET_ANNUAL_REPORT_REQUEST,
// GET_ANNUAL_REPORT_SUCCESS,
// GET_ANNUAL_REPORT_FAILED,

// GET_REFERRAL_REPORT_REQUEST,
// GET_REFERRAL_REPORT_SUCCESS,
// GET_REFERRAL_REPORT_FAILED,

// GET_DASHBOARD_DATA_REQUEST,
// GET_DASHBOARD_DATA_SUCCESS,
// GET_DASHBOARD_DATA_FAILED,

const reportState = {
  status: true,
  error: false,
  message: '',
  loading: false,
  data: [],
};

export const getReportReducer = (state = reportState, action) => {
  switch (action.type) {
    // week
    case GET_WEEKLY_REPORT_REQUEST:
      return {
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_WEEKLY_REPORT_SUCCESS:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [action.payload.data],
      };
    case GET_WEEKLY_REPORT_FAILED:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    // month
    case GET_MONTHLY_REPORT_REQUEST:
      return {
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_MONTHLY_REPORT_SUCCESS:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [action.payload.data],
      };
    case GET_MONTHLY_REPORT_FAILED:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    // annual
    case GET_ANNUAL_REPORT_REQUEST:
      return {
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_ANNUAL_REPORT_SUCCESS:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [action.payload.data],
      };
    case GET_ANNUAL_REPORT_FAILED:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    // REFERRAL
    case GET_REFERRAL_REPORT_REQUEST:
      return {
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_REFERRAL_REPORT_SUCCESS:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [action.payload.data],
      };
    case GET_REFERRAL_REPORT_FAILED:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    // dashboard
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [action.payload.data],
      };
    case GET_DASHBOARD_DATA_FAILED:
      return {
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};

// POST_REFERRAL_STUDENT_REQUEST,
// POST_REFERRAL_STUDENT_SUCCESS,
// POST_REFERRAL_STUDENT_FAILED,
const addReferralState = {
  status: true,
  error: false,
  message: '',
  loading: false,
  data: [],
};

export const ReferralReducer = (state = addReferralState, action) => {
  switch (action.type) {
    case POST_REFERRAL_STUDENT_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        // loading:true,
        data: [],
      };
    case POST_REFERRAL_STUDENT_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: action.payload.data,
      };
    case POST_REFERRAL_STUDENT_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};

// GET_REFERRAL_STUDENT_DATA_REQUEST,
// GET_REFERRAL_STUDENT_DATA_SUCCESS,
// GET_REFERRAL_STUDENT_DATA_FAILED,

const referralStudentProfile = {
  status: true,
  error: false,
  message: '',
  loading: false,
  data: [],
};

export const referralStudentProfileReducer = (state = referralStudentProfile, action) => {
  switch (action.type) {
    case GET_REFERRAL_STUDENT_DATA_REQUEST:
      return {
        ...state,
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case GET_REFERRAL_STUDENT_DATA_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: action.payload.data,
      };
    case GET_REFERRAL_STUDENT_DATA_FAILED:
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};

// STUDENT_UPLOAD_REQUEST,
// STUDENT_UPLOAD_SUCCESS,
// STUDENT_UPLOAD_FAILED,
const initialUpload = {
  status: true,
  error: false,
  message: '',
  loading: false,
  data: [],
};
export const uploadStudentReducer = (state = initialUpload, action) => {
  switch (action.type) {
    case STUDENT_UPLOAD_REQUEST:
      return {
        status: true,
        error: false,
        message: '',
        loading: true,
        data: [],
      };
    case STUDENT_UPLOAD_SUCCESS:
      return {
        status: true,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: action.payload.data,
      };
    case STUDENT_UPLOAD_FAILED:
      return {
        status: true,
        error: action.payload.error,
        message: action.payload.message,
        loading: false,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

// STUDENT_SAMPLE_DOWNLOAD_REQUEST,
// STUDENT_SAMPLE_DOWNLOAD_SUCCESS,
// STUDENT_SAMPLE_DOWNLOAD_FAILED,

const initialDownloadState = {
  status: true,
  loading: false,
  error: false,
  message: '',
  data: null,
};
export const studentSampleDownloadReducer = (state = initialDownloadState, action) => {
  switch (action.type) {
    case STUDENT_SAMPLE_DOWNLOAD_REQUEST:
      return {
        ...state,
        status: true,
        loading: true,
        error: false,
        message: '',
        data: null,
      };
    case STUDENT_SAMPLE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };
    case STUDENT_SAMPLE_DOWNLOAD_FAILED:
      return {
        ...state,
        status: true,
        loading: false,
        error: action.payload.error,
        message: action.payload.message,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

// UPGRADE_STUDENT_REQUEST,
// UPGRADE_STUDENT_SUCCESS,
// UPGRADE_STUDENT_FAILED,

// POST_UPGRADE_STUDENT_REQUEST
// POST_UPGRADE_STUDENT_SUCCESS
// POST_UPGRADE_STUDENT_FAILED

const initialUpgradeData = {
  status: true,
  error: false,
  loading: true,
  message: '',
  data: [],
};
export const upgradeStudentReducer = (state = initialUpgradeData, action) => {
  switch (action.type) {
    case UPGRADE_STUDENT_REQUEST:
      // console.log(action.payload);
      return {
        ...state,
        status: true,
        error: false,
        loading: true,
        message: '',
        data: [],
      };
    case UPGRADE_STUDENT_SUCCESS:
      // console.log(action.payload.data);
      return {
        ...state,
        status: true,
        error: false,
        loading: false,
        message: '',
        data: action.payload,
      };
    case UPGRADE_STUDENT_FAILED:
      // console.log(action.payload);
      return {
        ...state,
        status: true,
        error: false,
        loading: false,
        message: '',
        data: [],
      };
    // student upgrade post request
    case POST_UPGRADE_STUDENT_REQUEST:
      console.log(action.payload);
      return {
        ...state,
        data: state.data,
      };
    case POST_UPGRADE_STUDENT_SUCCESS:
      return {
        ...state,
        data: state.data,
        message: action.payload.message,
      };
    case POST_UPGRADE_STUDENT_FAILED:
      return {
        ...state,
        status: true,
        error: action.payload.error,
        loading: false,
        message: action.payload.message,
        data: state.data,
      };
    default:
      return state;
  }
};
