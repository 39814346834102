import axios from 'axios';
import {
  GET_SHIFT_CHECK_REQUEST,
  GET_SHIFT_CHECK_SUCCESS,
  GET_SHIFT_CHECK_FAILED,
  GET_UPGRADE_CLASS_LIST_REQUEST,
  GET_UPGRADE_CLASS_LIST_SUCCESS,
  GET_UPGRADE_CLASS_LIST_FAILED,
  GET_CLASS_WISE_SHIFT_LIST_REQUEST,
  GET_CLASS_WISE_SHIFT_LIST_SUCCESS,
  GET_CLASS_WISE_SHIFT_LIST_FAILED,
  GET_SECTION_LIST_REQUEST,
  GET_SECTION_LIST_SUCCESS,
  GET_SECTION_LIST_FAILED,
  CREATE_SECTION_LIST_REQUEST,
  CREATE_SECTION_LIST_SUCCESS,
  CREATE_SECTION_LIST_FAILED,
  UPDATE_SECTION_LIST_REQUEST,
  UPDATE_SECTION_LIST_SUCCESS,
  UPDATE_SECTION_LIST_FAILED,
  DELETE_SECTION_LIST_REQUEST,
  DELETE_SECTION_LIST_SUCCESS,
  DELETE_SECTION_LIST_FAILED,
  CREATE_SECTION_TEACHER_REQUEST,
  CREATE_SECTION_TEACHER_SUCCESS,
  CREATE_SECTION_TEACHER_FAILED,
  DELETE_TEACHER_REQUEST,
  DELETE_TEACHER_SUCCESS,
  DELETE_TEACHER_FAILED,
  UPDATE_TEACHER_REQUEST,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_TEACHER_FAILED,
  GET_TEACHER_PROFILE_REQUEST,
  GET_TEACHER_PROFILE_SUCCESS,
  GET_TEACHER_PROFILE_FAILED,
  GET_SECTION_STUDENT_LIST_REQUEST,
  GET_SECTION_STUDENT_LIST_SUCCESS,
  GET_SECTION_STUDENT_LIST_FAILED,
  GET_STUDENT_PROFILE_REQUEST,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_STUDENT_PROFILE_FAILED,
  CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST,
  CREATE_SECTION_STUDENT_FROM_SHIFT_SUCCESS,
  CREATE_SECTION_STUDENT_FROM_SHIFT_FAILED,
  DELETE_SECTION_STUDENT_REQUEST,
  DELETE_SECTION_STUDENT_SUCCESS,
  DELETE_SECTION_STUDENT_FAILED,
  UPDATE_SECTION_STUDENT_REQUEST,
  UPDATE_SECTION_STUDENT_SUCCESS,
  UPDATE_SECTION_STUDENT_FAILED,
  GET_SUBJECT_LIST_REQUEST,
  GET_SUBJECT_LIST_SUCCESS,
  GET_SUBJECT_LIST_FAILED,
  GET_STOCK_LIST_REQUEST,
  GET_STOCK_LIST_SUCCESS,
  GET_STOCK_LIST_FAILED,
  GET_NUTRITION_PROGRAM_STUDENT_LIST_REQUEST,
  GET_NUTRITION_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_NUTRITION_PROGRAM_STUDENT_LIST_FAILED,
  GET_WEEK_NUMBER_REQUEST,
  GET_WEEK_NUMBER_SUCCESS,
  GET_WEEK_NUMBER_FAILED,
  POST_PROGRAM_STUDENT_LIST_REQUEST,
  POST_PROGRAM_STUDENT_LIST_SUCCESS,
  POST_PROGRAM_STUDENT_LIST_FAILED,
  POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
  POST_WIFA_PROGRAM_STUDENT_LIST_SUCCESS,
  POST_WIFA_PROGRAM_STUDENT_LIST_FAILED,
  GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
  GET_WIFA_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_WIFA_PROGRAM_STUDENT_LIST_FAILED,
  GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
  GET_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,
  POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
  POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
  POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,
  GET_BMI_PROGRAM_STUDENT_LIST_REQUEST,
  GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS,
  GET_BMI_PROGRAM_STUDENT_LIST_FAILED,
  GET_FEET_TO_METER_REQUEST,
  GET_FEET_TO_METER_SUCCESS,
  GET_FEET_TO_METER_FAILED,
  POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST,
  POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS,
  POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED,
  GET_WIFA_TOTAL_TABLET_REQUEST,
  GET_WIFA_TOTAL_TABLET_SUCCESS,
  GET_WIFA_TOTAL_TABLET_FAILED,
  GET_DEWORMING_TOTAL_TABLET_REQUEST,
  GET_DEWORMING_TOTAL_TABLET_SUCCESS,
  GET_DEWORMING_TOTAL_TABLET_FAILED,
  GET_WIFA_USED_TABLET_REQUEST,
  GET_WIFA_USED_TABLET_SUCCESS,
  GET_WIFA_USED_TABLET_FAILED,
  GET_DEWORMING_USED_TABLET_REQUEST,
  GET_DEWORMING_USED_TABLET_SUCCESS,
  GET_DEWORMING_USED_TABLET_FAILED,
  GET_WIFA_HISTORY_REQUEST,
  GET_WIFA_HISTORY_SUCCESS,
  GET_WIFA_HISTORY_FAILED,
  GET_DEWORMING_HISTORY_REQUEST,
  GET_DEWORMING_HISTORY_SUCCESS,
  GET_DEWORMING_HISTORY_FAILED,
  ADD_WIFA_TABLET_REQUEST,
  ADD_WIFA_TABLET_SUCCESS,
  ADD_WIFA_TABLET_FAILED,
  ADD_DEWORMING_TABLET_REQUEST,
  ADD_DEWORMING_TABLET_SUCCESS,
  ADD_DEWORMING_TABLET_FAILED,
  GET_REPORT_PROGRAM_LIST_REQUEST,
  GET_REPORT_PROGRAM_LIST_SUCCESS,
  GET_REPORT_PROGRAM_LIST_FAILED,
  GET_WEEKLY_REPORT_REQUEST,
  GET_WEEKLY_REPORT_SUCCESS,
  GET_WEEKLY_REPORT_FAILED,
  GET_MONTHLY_REPORT_REQUEST,
  GET_MONTHLY_REPORT_SUCCESS,
  GET_MONTHLY_REPORT_FAILED,
  GET_ANNUAL_REPORT_REQUEST,
  GET_ANNUAL_REPORT_SUCCESS,
  GET_ANNUAL_REPORT_FAILED,
  GET_REFERRAL_REPORT_REQUEST,
  GET_REFERRAL_REPORT_SUCCESS,
  GET_REFERRAL_REPORT_FAILED,
  GET_REFERRAL_STUDENT_LIST_REQUEST,
  GET_REFERRAL_STUDENT_LIST_SUCCESS,
  GET_REFERRAL_STUDENT_LIST_FAILED,
  POST_REFERRAL_STUDENT_REQUEST,
  POST_REFERRAL_STUDENT_SUCCESS,
  POST_REFERRAL_STUDENT_FAILED,
  GET_REFERRAL_STUDENT_DATA_REQUEST,
  GET_REFERRAL_STUDENT_DATA_SUCCESS,
  GET_REFERRAL_STUDENT_DATA_FAILED,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILED,
  STUDENT_UPLOAD_REQUEST,
  STUDENT_UPLOAD_SUCCESS,
  STUDENT_UPLOAD_FAILED,
  TEACHER_LIST_REQUEST,
  TEACHER_LIST_SUCCESS,
  TEACHER_LIST_FAILED,
  STUDENT_SAMPLE_DOWNLOAD_REQUEST,
  STUDENT_SAMPLE_DOWNLOAD_SUCCESS,
  STUDENT_SAMPLE_DOWNLOAD_FAILED,
  // upgrade student
  UPGRADE_STUDENT_REQUEST,
  UPGRADE_STUDENT_SUCCESS,
  UPGRADE_STUDENT_FAILED,
  POST_UPGRADE_STUDENT_REQUEST,
  POST_UPGRADE_STUDENT_SUCCESS,
  POST_UPGRADE_STUDENT_FAILED,
} from '../constance/upgradeClasses.constant';
import { getTokenAndId } from '../service/service';

export const postShiftCheckRequest = () => {
  return {
    type: GET_SHIFT_CHECK_REQUEST,
    payload: 'check shift request',
  };
};

export const postShiftCheckSuccess = (res) => {
  return {
    type: GET_SHIFT_CHECK_SUCCESS,
    payload: res,
  };
};

export const postShiftCheckFailed = (err) => {
  return {
    type: GET_SHIFT_CHECK_FAILED,
    payload: err,
  };
};

export const postShiftCheckAction = (dataInfo, callback) => {
  let { apiUrl } = getTokenAndId();
  const token = JSON.parse(localStorage.token);
  const bodyData = {
    institution_id: dataInfo.institution_id,
    has_shift: dataInfo?.has_shift,
  };
  return (dispatch) => {
    dispatch(postShiftCheckRequest());
    axios
      .post(`${apiUrl}shift/status/add`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (callback) {
          callback();
        }
        dispatch(postShiftCheckSuccess(response.data));
      })
      .catch((error) => {
        console.log('============================', error.response.data);
        dispatch(postShiftCheckFailed(error.response.data));
      });
  };
};

// get class list with section,shift,total student
// -----------------------------------------------
export const getUpgradeClassListRequest = () => {
  return {
    type: GET_UPGRADE_CLASS_LIST_REQUEST,
    payload: 'upgrade class list request for section, shift, and students',
  };
};
export const getUpgradeClassListSuccess = (res) => {
  return {
    type: GET_UPGRADE_CLASS_LIST_SUCCESS,
    payload: res,
  };
};
export const getUpgradeClassListFailed = (error) => {
  return {
    type: GET_UPGRADE_CLASS_LIST_FAILED,
    payload: error,
  };
};
//* get all class list's under institutions
export const getUpgradeClassListsAction = () => {
  let { apiUrl, config } = getTokenAndId();
  return (dispatch) => {
    dispatch(getUpgradeClassListRequest());
    axios
      .get(`${apiUrl}class/list`, config)
      .then((response) => {
        // console.log(response.data);
        dispatch(getUpgradeClassListSuccess(response.data));
      })
      .catch((error) => {
        // console.log(error.message);
        dispatch(getUpgradeClassListFailed(error.message));
      });
  };
};

// get all shift under the institution
// -----------------------------------

export const getClassWiseShiftRequest = () => {
  return {
    type: GET_CLASS_WISE_SHIFT_LIST_REQUEST,
    payload: 'get all shift list under the institute',
  };
};

export const getClassWiseShiftSuccess = (res) => {
  return {
    type: GET_CLASS_WISE_SHIFT_LIST_SUCCESS,
    payload: res,
  };
};

export const getClassWiseShiftFailed = (error) => {
  return {
    type: GET_CLASS_WISE_SHIFT_LIST_FAILED,
    payload: error,
  };
};

export const getClassWiseShiftListAction = (classInfo) => {
  let { apiUrl, config } = getTokenAndId();
  const token = JSON.parse(localStorage.token);

  return (dispatch) => {
    dispatch(getClassWiseShiftRequest());
    axios
      .get(
        `${apiUrl}class/shift/list?institution_id=${classInfo.institutionId}${
          classInfo.grade === undefined ? '' : `&grade=${classInfo.grade}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        dispatch(getClassWiseShiftSuccess(response.data));
      })
      .catch((error) => {
        // console.log(error.message);
        dispatch(getClassWiseShiftFailed(error.message));
      });
  };
};

// get all section lists under institution
// ---------------------------------------

export const getSectionListRequest = () => {
  return {
    type: GET_SECTION_LIST_REQUEST,
    payload: 'get all section request',
  };
};
export const getSectionListSuccess = (res) => {
  return {
    type: GET_SECTION_LIST_SUCCESS,
    payload: res,
  };
};
export const getSectionListFailed = (error) => {
  return {
    type: GET_SECTION_LIST_FAILED,
    payload: error,
  };
};
export const getSectionListFromShiftAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined || dataInfo[key] === '0') {
      delete dataInfo[key];
    }
  });

  let { apiUrl, config } = getTokenAndId();
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await dataInfo;
    dispatch(getSectionListRequest());
    axios
      .get(
        `${apiUrl}section/list?institution_id=${dataInfo.institutionId}${
          dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 139 ~ .then ~ response", response)

        dispatch(getSectionListSuccess(response.data));
      })
      .catch((error) => {
        // console.log(error.message);
        dispatch(getSectionListFailed(error.message));
      });
  };
};

// section lists creation
export const createSectionListRequest = () => {
  return {
    type: CREATE_SECTION_LIST_REQUEST,
    payload: 'create section lists',
  };
};

export const createSectionListSuccess = (res) => {
  return {
    type: CREATE_SECTION_LIST_SUCCESS,
    payload: res,
  };
};

export const createSectionListFailed = (err) => {
  return {
    type: CREATE_SECTION_LIST_FAILED,
    payload: err,
  };
};

export const createSectionListAction = (listInfo, callback) => {
  let { apiUrl, config } = getTokenAndId();
  const token = JSON.parse(localStorage.token);
  let bodyData = {
    institution_id: listInfo.institution_id,
    grade: listInfo.grade,
    has_shift: listInfo.has_shift,
    shift_id: listInfo.shift_id,
    name_en: listInfo.name_en,
    name_bn: listInfo.name_bn,
  };
  return (dispatch) => {
    dispatch(createSectionListRequest());
    axios
      .post(`${apiUrl}section/add`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: teacher.actions.js ~ line 149 ~ .then ~ response", response)
        dispatch(createSectionListSuccess(response.data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(createSectionListFailed(error.response.data));
      });
  };
};

// update section lists
export const updateSectionListRequest = () => {
  return {
    type: UPDATE_SECTION_LIST_REQUEST,
    payload: 'update section list',
  };
};
export const updateSectionListSuccess = (res) => {
  return {
    type: UPDATE_SECTION_LIST_SUCCESS,
    payload: res,
  };
};
export const updateSectionListFailed = (err) => {
  return {
    type: UPDATE_SECTION_LIST_FAILED,
    payload: err,
  };
};

export const updateSectionListAction = (listInfo, callback) => {
  let bodyData = {
    section_id: listInfo.section_id,
    name_en: listInfo.name_en,
    name_bn: listInfo.name_bn,
  };

  let { apiUrl, config } = getTokenAndId();
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(updateSectionListRequest());
    axios
      .post(`${apiUrl}section/update`, bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateSectionListSuccess(response.data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(updateSectionListFailed(error.response.data));
      });
  };
};

// section list delete

export const deleteSectionListRequest = () => {
  return {
    type: DELETE_SECTION_LIST_REQUEST,
    payload: 'delete section list',
  };
};

export const deleteSectionListSuccess = (res) => {
  return {
    type: DELETE_SECTION_LIST_SUCCESS,
    payload: res,
  };
};
export const deleteSectionListFailed = (err) => {
  return {
    type: DELETE_SECTION_LIST_FAILED,
    payload: err,
  };
};

export const deleteSectionListAction = (listInfo, callback) => {
  let bodyData = {
    section_id: listInfo.section_id,
  };

  let { apiUrl, config } = getTokenAndId();
  const token = JSON.parse(localStorage.token);

  return (dispatch) => {
    dispatch(deleteSectionListRequest());
    axios
      .get(`${apiUrl}section/delete?section_id=${bodyData.section_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          const dataId = { id: bodyData.section_id };
          const returnedTarget = Object.assign(response.data, dataId);
          dispatch(deleteSectionListSuccess(returnedTarget));
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(deleteSectionListFailed(error?.response?.data));
      });
  };
};

// section teacher creation action  --------------------------TEACHER--------------------------
// TEACHER_LIST_REQUEST
// TEACHER_LIST_SUCCESS
// TEACHER_LIST_FAILED

export const teacherListRequest = () => {
  return {
    type: TEACHER_LIST_REQUEST,
    payload: 'teacher list request',
  };
};
export const teacherListSuccess = (res) => {
  return {
    type: TEACHER_LIST_SUCCESS,
    payload: res,
  };
};
export const teacherListFailed = (err) => {
  return {
    type: TEACHER_LIST_FAILED,
    payload: err,
  };
};

export const teacherListAction = () => {
  // get this function from service folder
  let { apiUrl, config } = getTokenAndId();

  return (dispatch) => {
    dispatch(teacherListRequest());
    axios
      .get(`${apiUrl}teacher/list`, config)
      .then((response) => {
        dispatch(teacherListSuccess(response.data));
      })
      .catch((error) => {
        console.log('🚀 error', error.message);
        dispatch(teacherListFailed(error.message));
      });
  };
};

export const createSectionTeacherRequest = () => {
  return {
    type: CREATE_SECTION_TEACHER_REQUEST,
    payload: 'create section teacher',
  };
};

export const createSectionTeacherSuccess = (res) => {
  return {
    type: CREATE_SECTION_TEACHER_SUCCESS,
    payload: res,
  };
};

export const createSectionTeacherFailed = (err) => {
  return {
    type: CREATE_SECTION_TEACHER_FAILED,
    payload: err,
  };
};

export const createSectionTeacherAction = (teacherInfo, avatar, callback) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    let data = new FormData();
    (await teacherInfo?.institution_id) && data.append('institution_id', teacherInfo?.institution_id);
    (await teacherInfo?.grade) && data.append('grade', teacherInfo?.grade);
    (await teacherInfo?.section_id) && data.append('section_id', teacherInfo?.section_id);
    (await teacherInfo?.teacherInd) && data.append('nid', teacherInfo?.teacherInd.toString());
    (await teacherInfo?.teacherName) && data.append('name', teacherInfo?.teacherName);
    (await teacherInfo?.shift_id) && data.append('shift_id', teacherInfo?.shift_id);
    (await avatar) && data.append('photo', avatar);

    dispatch(createSectionTeacherRequest());
    axios
      .post(`${apiUrl}section/add/teacher`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(createSectionTeacherSuccess(response.data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        dispatch(createSectionTeacherFailed(error.response.data));
      });
  };
};
// DELETE_TEACHER_REQUEST,

export const deleteTeacherRequest = () => {
  return {
    type: DELETE_TEACHER_REQUEST,
    payload: 'delete teacher from list',
  };
};
export const deleteTeacherSuccess = (res) => {
  return {
    type: DELETE_TEACHER_SUCCESS,
    payload: res,
  };
};
export const deleteTeacherFailed = (error) => {
  return {
    type: DELETE_TEACHER_FAILED,
    payload: error,
  };
};
export const deleteTeacherAction = (dataInfo) => {
  const dataParams = {
    institution_id: dataInfo.institution_id,
    teacher_id: dataInfo.teacher_id,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(deleteTeacherRequest());
    axios
      .get(`${apiUrl}teacher/delete?teacher_id=${dataParams.teacher_id}&institution_id=${dataParams.institution_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const dataId = { id: dataParams.teacher_id };
        const returnedTarget = Object.assign(response.data, dataId);
        dispatch(deleteTeacherSuccess(returnedTarget));
      })
      .catch((error) => {
        dispatch(deleteTeacherFailed(error.data));
      });
  };
};

// UPDATE_TEACHER_REQUEST,

export const updateTeacherRequest = () => {
  return {
    type: UPDATE_TEACHER_REQUEST,
    payload: 'update teacher...',
  };
};
export const updateTeacherSuccess = (res) => {
  return {
    type: UPDATE_TEACHER_SUCCESS,
    payload: res,
  };
};
export const updateTeacherFailed = (err) => {
  return {
    type: UPDATE_TEACHER_FAILED,
    payload: err,
  };
};

export const updateTeacherAction = (teacherInfo, photo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    let data = new FormData();
    await data.append('teacher_id', teacherInfo.teacher_id);
    await data.append('institution_id', teacherInfo.institution_id);
    await data.append('nid', teacherInfo.teacherInd);
    await data.append('grade', teacherInfo.grade);
    await data.append('name', teacherInfo.teacherName);
    (await teacherInfo.shift_id) && data.append('shift_id', teacherInfo.shift_id);
    (await teacherInfo.section_id) && data.append('section_id', teacherInfo.section_id);
    (await photo) && data.append('photo', photo);
    dispatch(updateTeacherRequest());

    axios
      .post(`${apiUrl}teacher/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateTeacherSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateTeacherFailed(error.response.data));
      });
  };
};

export const teacherProfileRequest = () => {
  return {
    type: GET_TEACHER_PROFILE_REQUEST,
  };
};

export const teacherProfileSuccess = (res) => {
  return {
    type: GET_TEACHER_PROFILE_SUCCESS,
    payload: res,
  };
};
export const teacherProfileFailed = (error) => {
  return {
    type: GET_TEACHER_PROFILE_FAILED,
    payload: error,
  };
};

export const getTeacherProfileAction = (teacherData) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    teacherProfileRequest();
    axios
      .get(
        `${apiUrl}teacher/profile?teacher_id=${teacherData?.teacher_id}&institution_id=${teacherData?.institution_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log('----------------------------------------------------------------------🚀--------', response.data);
        dispatch(teacherProfileSuccess(response.data));
      })
      .catch((error) => {
        // console.log('🚀', error.message);
        dispatch(teacherProfileFailed(error.message));
      });
  };
};

// section student list
export const getSectionStudentListRequest = () => {
  return {
    type: GET_SECTION_STUDENT_LIST_REQUEST,
    payload: 'get section student list',
  };
};

export const getSectionStudentListSuccess = (res) => {
  return {
    type: GET_SECTION_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};

export const getSectionStudentListFailed = (err) => {
  return {
    type: GET_SECTION_STUDENT_LIST_FAILED,
    payload: err,
  };
};

export const sectionStudentAction = (studentInfo) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined || studentInfo[key] === '') {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await studentInfo;
    dispatch(getSectionStudentListRequest());
    axios
      .get(
        `${apiUrl}student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`}${
          studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.page === undefined ? '' : `&page=${studentInfo.page}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // const links = response?.data?.meta?.links?.map((link, i, total) => {
        //   if (i === 0) {
        //     return {
        //       ...link,
        //       label: '<',
        //     };
        //   }

        //   if (total.length - 1 === i) {
        //     return {
        //       ...link,
        //       label: '>',
        //     };
        //   }

        //   return link;
        // });

        // const newDatas = { ...response?.data, pagination: links };
        // console.log(newDatas)
        dispatch(getSectionStudentListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getSectionStudentListFailed(error.data));
      });
  };
};

// student profile
export const getStudentProfileRequest = () => {
  return {
    type: GET_STUDENT_PROFILE_REQUEST,
    info: 'fetch student profile',
  };
};
export const getStudentProfileSuccess = (res) => {
  return {
    type: GET_STUDENT_PROFILE_SUCCESS,
    payload: res,
  };
};
export const getStudentProfileFailed = (error) => {
  return {
    type: GET_STUDENT_PROFILE_FAILED,
    payload: error,
  };
};

export const getStudentProfileAction = (info) => {
  const data = {
    institution_id: info.institution_id,
    student_id: info.student_id,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getStudentProfileRequest());
    axios
      .get(`${apiUrl}student/profile?institution_id=${data.institution_id}&student_id=${data.student_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        dispatch(getStudentProfileSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.message);
        dispatch(getStudentProfileFailed(error));
      });
  };
};

// CREATE SECTION STUDENT FROM SHIFT
// ------------------------------------

export const createSectionStudentFromShiftRequest = () => {
  return {
    type: CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST,
    payload: 'create student from shift and section',
  };
};
export const createSectionStudentFromShiftSuccess = (res) => {
  return {
    type: CREATE_SECTION_STUDENT_FROM_SHIFT_SUCCESS,
    payload: res,
  };
};
export const createSectionStudentFromShiftFailed = (err) => {
  return {
    type: CREATE_SECTION_STUDENT_FROM_SHIFT_FAILED,
    payload: err,
  };
};

export const createSectionStudentFromShiftAction = (studentInfo, avatar, callback) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    let data = new FormData();
    await data.append('name', studentInfo?.name);
    await data.append('roll', studentInfo?.roll);
    await data.append('gender', studentInfo?.gender);
    await data.append('birth_date', studentInfo?.birth_date);
    await data.append('institution_id', studentInfo?.institution_id);
    await data.append('grade', studentInfo?.grade);
    await data.append('section_id', studentInfo?.section_id);
    await data.append('age', studentInfo?.age);
    await data.append('birth_certificate_num', studentInfo?.birth_certificate_num);
    await data.append('shift_id', studentInfo?.shift_id);
    (await avatar) && data.append('student_profile_image', avatar);

    dispatch(createSectionStudentFromShiftRequest());
    axios
      .post(`${apiUrl}section/add/student`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(createSectionStudentFromShiftSuccess(response.data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        dispatch(createSectionStudentFromShiftFailed(error.response.data));
      });
  };
};

// DELETE_SECTION_STUDENT_

export const deleteSectionStudentRequest = () => {
  return {
    type: DELETE_SECTION_STUDENT_REQUEST,
    payload: 'delete section student',
  };
};
export const deleteSectionStudentSuccess = (res) => {
  return {
    type: DELETE_SECTION_STUDENT_SUCCESS,
    payload: res,
  };
};
export const deleteSectionStudentFailed = (err) => {
  return {
    type: DELETE_SECTION_STUDENT_FAILED,
    payload: err,
  };
};

export const deleteSectionStudentAction = (studentInfo, callback) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined) {
      delete studentInfo[key];
    }
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    await studentInfo;
    dispatch(deleteSectionStudentRequest());
    axios
      .get(
        `${apiUrl}student/delete?${studentInfo.student_id === undefined ? '' : `student_id=${studentInfo.student_id}`}${
          studentInfo.institution_id === undefined ? '' : `&institution_id=${studentInfo.institution_id}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log('response.data ', response.data);
        const dataId = { id: studentInfo.student_id };
        const returnedTarget = Object.assign(response.data, dataId);
        dispatch(deleteSectionStudentSuccess(returnedTarget));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 957 ~ return ~ error', error);
        dispatch(deleteSectionStudentFailed(error.response.data));
      });
  };
};

// UPDATE_SECTION_STUDENT

export const updateSectionStudentRequest = () => {
  return {
    type: UPDATE_SECTION_STUDENT_REQUEST,
    payload: 'update section student list',
  };
};
export const updateSectionStudentSuccess = (res) => {
  return {
    type: UPDATE_SECTION_STUDENT_SUCCESS,
    payload: res,
  };
};
export const updateSectionStudentFailed = (err) => {
  return {
    type: UPDATE_SECTION_STUDENT_FAILED,
    payload: err,
  };
};
export const updateSectionStudentAction = (dataInfo, callback) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    dispatch(updateSectionStudentRequest());
    let data = new FormData();
    await data.append('student_id', dataInfo?.student_id);
    await data.append('name', dataInfo?.name);
    await data.append('roll', dataInfo?.roll);
    await data.append('gender', dataInfo?.gender);
    await data.append('birth_date', dataInfo?.birth_date);
    await data.append('institution_id', dataInfo?.institution_id);
    await data.append('grade', dataInfo?.grade);
    await data.append('section_id', dataInfo?.section_id);
    await data.append('age', dataInfo?.age);
    await data.append('birth_certificate_num', dataInfo?.birth_certificate_num);
    (await dataInfo?.shift_id) && data.append('shift_id', dataInfo?.shift_id);
    (await dataInfo?.student_profile_image) && data.append('student_profile_image', dataInfo?.student_profile_image);
    axios
      .post(`${apiUrl}student/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(updateSectionStudentSuccess(response.data));
        if (callback) callback();
      })
      .catch((error) => {
        console.log(error.response.data);
        dispatch(updateSectionStudentFailed(error.response.data));
      });
  };
};

// programs
// GET_WEEK_NUMBER_REQUEST,

export const getWeekNumberRequest = () => {
  return {
    type: GET_WEEK_NUMBER_REQUEST,
    payload: 'get week number from date select',
  };
};
export const getWeekNumberSuccess = (res) => {
  return {
    type: GET_WEEK_NUMBER_SUCCESS,
    payload: res,
  };
};
export const getWeekNumberFailed = (error) => {
  return {
    type: GET_WEEK_NUMBER_FAILED,
    payload: error,
  };
};
export const getWeekNumberAction = (dateInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getWeekNumberRequest);
    axios
      .get(`${apiUrl}date/week?date=${dateInfo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(getWeekNumberSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getWeekNumberFailed(error));
      });
  };
};

// GET_SUBJECT_LIST_REQUEST,

export const getSubjectListRequest = () => {
  return {
    type: GET_SUBJECT_LIST_REQUEST,
    payload: 'program subject list request',
  };
};
export const getSubjectListSuccess = (res) => {
  return {
    type: GET_SUBJECT_LIST_SUCCESS,
    payload: res,
  };
};
export const getSubjectListFailed = (error) => {
  return {
    type: GET_SUBJECT_LIST_FAILED,
    payload: error,
  };
};
export const getSubjectListAction = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getSubjectListRequest());
    axios
      .get(`${apiUrl}subject-list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSubjectListSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSubjectListFailed(error));
      });
  };
};
// GET_STOCK_LIST_REQUEST,

export const getStockListRequest = () => {
  return {
    type: GET_STOCK_LIST_REQUEST,
    payload: 'STOCK list request',
  };
};
export const getStockListSuccess = (res) => {
  return {
    type: GET_STOCK_LIST_SUCCESS,
    payload: res,
  };
};
export const getStockListFailed = (error) => {
  return {
    type: GET_STOCK_LIST_FAILED,
    payload: error,
  };
};
export const getStockListAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getStockListRequest());
    axios
      .get(`${apiUrl}stock/list?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(getStockListSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getStockListFailed(error));
      });
  };
};

// GET_PROGRAM_STUDENT_LIST_REQUEST,

export const getProgramStudentListRequest = () => {
  return {
    type: GET_NUTRITION_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'program student list request',
  };
};
export const getProgramStudentListSuccess = (res) => {
  return {
    type: GET_NUTRITION_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const getProgramStudentListFailed = (error) => {
  return {
    type: GET_NUTRITION_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};
export const getProgramStudentListAction = (studentInfo) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined) {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await studentInfo;
    dispatch(getProgramStudentListRequest());
    axios
      .get(
        `${apiUrl}nutrition/education/student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`}${
          studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.subject_id === undefined ? '' : `&subject_id=${studentInfo.subject_id}`}${
          studentInfo.week === undefined ? '' : `&week=${studentInfo.week}`
        }${studentInfo.date === undefined ? '' : `&date=${studentInfo.date}`}${
          studentInfo.page === undefined ? '' : `&page=${studentInfo.page}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response", response)
        dispatch(getProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error)
        console.log(error);
        dispatch(getProgramStudentListFailed(error));
      });
  };
};

// POST_PROGRAM_STUDENT_LIST_REQUEST,

export const postProgramStudentListRequest = () => {
  return {
    type: POST_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'post program student id in nutrition',
  };
};
export const postProgramStudentListSuccess = (res) => {
  return {
    type: POST_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const postProgramStudentListFailed = (error) => {
  return {
    type: POST_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};

export const postProgramStudentListAction = (dataInfo) => {
  const formData = {
    institution_id: dataInfo?.institution_id,
    grade: dataInfo?.grade,
    shift_id: dataInfo?.shift_id,
    section_id: dataInfo?.section_id,
    date: dataInfo?.date,
    week: dataInfo?.week,
    subject_id: dataInfo?.subject_id,
    student_id: `[${dataInfo?.student_id}]`,
    // student_id : dataInfo?.student_id,
    has_shift: dataInfo?.has_shift,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    dispatch(postProgramStudentListRequest());
    axios
      .post(`${apiUrl}nutrition/education/add/participate`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(postProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(postProgramStudentListFailed(error.response.data));
      });
  };
};

// GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
export const getWifaProgramStudentListRequest = () => {
  return {
    type: GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'program student list request',
  };
};
export const getWifaProgramStudentListSuccess = (res) => {
  return {
    type: GET_WIFA_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const getWifaProgramStudentListFailed = (error) => {
  return {
    type: GET_WIFA_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};
export const getWifaProgramStudentListAction = (studentInfo) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined) {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await studentInfo;
    dispatch(getWifaProgramStudentListRequest());
    axios
      .get(
        `${apiUrl}wifa/student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`}${
          studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.subject_id === undefined ? '' : `&subject_id=${studentInfo.subject_id}`}${
          studentInfo.week === undefined ? '' : `&week=${studentInfo.week}`
        }${studentInfo.date === undefined ? '' : `&date=${studentInfo.date}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response", response)
        dispatch(getWifaProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error)
        console.log(error);
        dispatch(getWifaProgramStudentListFailed(error));
      });
  };
};

// POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST,

// POST_PROGRAM_STUDENT_LIST_REQUEST,

export const postWifaProgramStudentListRequest = () => {
  return {
    type: POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'post wifa program student id in nutrition',
  };
};
export const postWifaProgramStudentListSuccess = (res) => {
  return {
    type: POST_WIFA_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const postWifaProgramStudentListFailed = (error) => {
  return {
    type: POST_WIFA_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};

export const postWifaProgramStudentListAction = (dataInfo) => {
  const formData = {
    institution_id: dataInfo?.institution_id,
    grade: dataInfo?.grade,
    shift_id: dataInfo?.shift_id,
    section_id: dataInfo?.section_id,
    date: dataInfo?.date,
    week: dataInfo?.week,
    stock_id: dataInfo?.stock_id,
    student_id: `[${dataInfo?.student_id}]`,
    // student_id : dataInfo?.student_id ,
    has_shift: dataInfo?.has_shift,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    dispatch(postWifaProgramStudentListRequest());
    axios
      .post(`${apiUrl}wifa/add/participate`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(postWifaProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        console.log('++++++++++++++++++++++++++++++++++++++++', error.response.data);
        dispatch(postWifaProgramStudentListFailed(error.response.data));
      });
  };
};

// GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
export const getDewormingProgramStudentListRequest = () => {
  return {
    type: GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'program student list request',
  };
};
export const getDewormingProgramStudentListSuccess = (res) => {
  return {
    type: GET_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const getDewormingProgramStudentListFailed = (error) => {
  return {
    type: GET_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};
export const getDewormingProgramStudentListAction = (studentInfo) => {
  // console.log('====================================studentInfo=',studentInfo);
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined || studentInfo[key] === '') {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    await studentInfo;
    dispatch(getDewormingProgramStudentListRequest());
    axios
      .get(
        `${apiUrl}deworming/student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`}${
          studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.subject_id === undefined ? '' : `&subject_id=${studentInfo.subject_id}`}${
          studentInfo.month === undefined ? '' : `&month=${studentInfo.month}`
        }${studentInfo.date === undefined ? '' : `&date=${studentInfo.date}`}${
          studentInfo.round === undefined ? '' : `&round=${studentInfo.round}`
        }${studentInfo.page === undefined ? '' : `&page=${studentInfo.page}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response", response)
        dispatch(getDewormingProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error)
        console.log(error);
        dispatch(getDewormingProgramStudentListFailed(error));
      });
  };
};

export const getRegularProgramStudentListAction = (studentInfo) => {
  // console.log('====================================studentInfo=',studentInfo);
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined || studentInfo[key] === '') {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    await studentInfo;
    dispatch(getDewormingProgramStudentListRequest());
    axios
      .get(
        `${apiUrl}student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`}${
          studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.subject_id === undefined ? '' : `&subject_id=${studentInfo.subject_id}`}${
          studentInfo.month === undefined ? '' : `&month=${studentInfo.month}`
        }${studentInfo.date === undefined ? '' : `&date=${studentInfo.date}`}${
          studentInfo.round === undefined ? '' : `&round=${studentInfo.round}`
        }${studentInfo.page === undefined ? '' : `&page=${studentInfo.page}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response", response)
        dispatch(getDewormingProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error)
        console.log(error);
        dispatch(getDewormingProgramStudentListFailed(error));
      });
  };
};

// POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
// POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
// POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,

export const postDewormingProgramStudentListRequest = () => {
  return {
    type: POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'post wifa program student id in nutrition',
  };
};
export const postDewormingProgramStudentListSuccess = (res) => {
  return {
    type: POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const postDewormingProgramStudentListFailed = (error) => {
  return {
    type: POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};

export const postDewormingProgramStudentListAction = (dataInfo) => {
  const formData = {
    institution_id: dataInfo?.institution_id,
    grade: dataInfo?.grade,
    shift_id: dataInfo?.shift_id,
    section_id: dataInfo?.section_id,
    date: dataInfo?.date,
    month: dataInfo?.month,
    stock_id: dataInfo?.stock_id,
    has_shift: dataInfo?.has_shift,
    round: dataInfo?.round,
    student_id: `[${dataInfo?.student_id}]`,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(postDewormingProgramStudentListRequest());
    axios
      .post(`${apiUrl}deworming/add/participate`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(postDewormingProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        console.log('++++++++++++++++++++++++++++++++++++++++', error.response.data);
        dispatch(postDewormingProgramStudentListFailed(error.response.data));
      });
  };
};

// GET_BMI_PROGRAM_STUDENT_LIST_REQUEST,
// GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS,
// GET_BMI_PROGRAM_STUDENT_LIST_FAILED,

export const getBmiProgramStudentListRequest = () => {
  return {
    type: GET_BMI_PROGRAM_STUDENT_LIST_REQUEST,
    payload: 'program student list request',
  };
};
export const getBmiProgramStudentListSuccess = (res) => {
  return {
    type: GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const getBmiProgramStudentListFailed = (error) => {
  return {
    type: GET_BMI_PROGRAM_STUDENT_LIST_FAILED,
    payload: error,
  };
};
export const getBmiProgramStudentListAction = (studentInfo) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined) {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await studentInfo;
    dispatch(getBmiProgramStudentListRequest());
    axios
      .get(
        `${apiUrl}bmi/student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`}${
          studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.subject_id === undefined ? '' : `&subject_id=${studentInfo.subject_id}`}${
          studentInfo.week === undefined ? '' : `&week=${studentInfo.week}`
        }${studentInfo.date === undefined ? '' : `&date=${studentInfo.date}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response", response)
        dispatch(getBmiProgramStudentListSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error)
        console.log(error);
        dispatch(getBmiProgramStudentListFailed(error));
      });
  };
};

// POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST,
// POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS,
// POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED,

export const postBmiProgramParticipatedStudentRequest = () => {
  return {
    type: POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST,
    payload: 'post BMI participation data',
  };
};
export const postBmiProgramParticipatedStudentSuccess = (res) => {
  return {
    type: POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS,
    payload: res,
  };
};
export const postBmiProgramParticipatedStudentFailed = (err) => {
  return {
    type: POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED,
    payload: err,
  };
};

export const postBmiProgramParticipatedStudentAction = (studentInfo) => {
  const formData = {
    institution_id: studentInfo.institution_id,
    grade: studentInfo.grade,
    has_shift: studentInfo.has_shift,
    shift_id: studentInfo.shift_id,
    section_id: studentInfo.section_id,
    student_id: studentInfo.student_id,
    round: studentInfo.round,
    date: studentInfo.date,
    weight: studentInfo.weight,
    feet: studentInfo.feet,
    inch: studentInfo.inch,
    height_in_meter: studentInfo.height_in_meter,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(postBmiProgramParticipatedStudentRequest());
    axios
      .post(`${apiUrl}bmi/add/participate`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response', response);
        dispatch(postBmiProgramParticipatedStudentSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        console.log(error);
        dispatch(postBmiProgramParticipatedStudentFailed(error.response.data));
      });
  };
};

// GET_FEET_TO_METER_REQUEST,

export const getFeetToMeterRequest = () => {
  return {
    type: GET_FEET_TO_METER_REQUEST,
    payload: 'feet to meter request',
  };
};
export const getFeetToMeterSuccess = (res) => {
  return {
    type: GET_FEET_TO_METER_SUCCESS,
    payload: res,
  };
};
export const getFeetToMeterFailed = (err) => {
  return {
    type: GET_FEET_TO_METER_FAILED,
    payload: err,
  };
};

export const getFeetToMeterAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined) {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await dataInfo;
    dispatch(getFeetToMeterRequest());
    axios
      .get(
        `${apiUrl}feet/to/meter?${dataInfo.feet === undefined ? '' : `feet=${dataInfo.feet}`}${
          dataInfo.inch === undefined ? '' : `&inch=${dataInfo.inch}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(getFeetToMeterSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(getFeetToMeterFailed(error.response.data));
      });
  };
};

// GET_TOTAL_TABLET_REQUEST

export const getTotalTabletRequest = () => {
  return {
    type: GET_WIFA_TOTAL_TABLET_REQUEST,
    payload: 'wifa tablet request',
  };
};

export const getTotalTabletSuccess = (res) => {
  return {
    type: GET_WIFA_TOTAL_TABLET_SUCCESS,
    payload: res,
  };
};

export const getTotalTabletFailed = (err) => {
  return {
    type: GET_WIFA_TOTAL_TABLET_FAILED,
    payload: err,
  };
};

export const getWifaTotalTabletAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getTotalTabletRequest());
    axios
      .get(`${apiUrl}stock/tablet/total?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(getTotalTabletSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(getTotalTabletFailed(error.response.data));
      });
  };
};

// GET_WIFA_USED_TABLET_REQUEST,

export const getWifaUsedTabletRequest = () => {
  return {
    type: GET_WIFA_USED_TABLET_REQUEST,
    payload: 'wifa tablet request used',
  };
};

export const getWifaUsedTabletSuccess = (res) => {
  return {
    type: GET_WIFA_USED_TABLET_SUCCESS,
    payload: res,
  };
};

export const getWifaUsedTabletFailed = (err) => {
  return {
    type: GET_WIFA_USED_TABLET_FAILED,
    payload: err,
  };
};

export const getWifaWifaUsedTabletAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getWifaUsedTabletRequest());
    axios
      .get(`${apiUrl}stock/tablet/used?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(getWifaUsedTabletSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(getWifaUsedTabletFailed(error.response.data));
      });
  };
};

// GET_DEWORMING_TOTAL_TABLET_REQUEST,

export const getDewormingTotalTabletRequest = () => {
  return {
    type: GET_DEWORMING_TOTAL_TABLET_REQUEST,
    payload: 'get deworming total',
  };
};
export const getDewormingTotalTabletSuccess = (res) => {
  return {
    type: GET_DEWORMING_TOTAL_TABLET_SUCCESS,
    payload: res,
  };
};
export const getDewormingTotalTabletFailed = (err) => {
  return {
    type: GET_DEWORMING_TOTAL_TABLET_FAILED,
    payload: err,
  };
};
export const getDewormingTotalTabletAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getDewormingTotalTabletRequest());
    axios
      .get(`${apiUrl}stock/tablet/total?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(getDewormingTotalTabletSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(getDewormingTotalTabletFailed(error.response.data));
      });
  };
};

// GET_DEWORMING_USED_TABLET_REQUEST,

export const getDewormingUsedTabletRequest = () => {
  return {
    type: GET_DEWORMING_USED_TABLET_REQUEST,
    payload: 'get deworming USED',
  };
};
export const getDewormingUsedTabletSuccess = (res) => {
  return {
    type: GET_DEWORMING_USED_TABLET_SUCCESS,
    payload: res,
  };
};
export const getDewormingUsedTabletFailed = (err) => {
  return {
    type: GET_DEWORMING_USED_TABLET_FAILED,
    payload: err,
  };
};
export const getDewormingUsedTabletAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getDewormingUsedTabletRequest());
    axios
      .get(`${apiUrl}stock/tablet/used?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(getDewormingUsedTabletSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(getDewormingUsedTabletFailed(error.response.data));
      });
  };
};

// GET_WIFA_HISTORY_REQUEST,
// GET_WIFA_HISTORY_SUCCESS,
// GET_WIFA_HISTORY_FAILED,

export const getWifaHistoryRequest = () => {
  return {
    type: GET_WIFA_HISTORY_REQUEST,
    payload: 'wifa history list',
  };
};
export const getWifaHistorySuccess = (res) => {
  return {
    type: GET_WIFA_HISTORY_SUCCESS,
    payload: res,
  };
};
export const getWifaHistoryFailed = (err) => {
  return {
    type: GET_WIFA_HISTORY_FAILED,
    payload: err,
  };
};
export const getWifaHistoryAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getWifaHistoryRequest());
    axios
      .get(`${apiUrl}stock/tablet/history?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(getWifaHistorySuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(getWifaHistoryFailed(error.response.data));
      });
  };
};

// GET_DEWORMING_HISTORY_REQUEST,
// GET_DEWORMING_HISTORY_SUCCESS,
// GET_DEWORMING_HISTORY_FAILED,

export const geDewormingHistoryRequest = () => {
  return {
    type: GET_DEWORMING_HISTORY_REQUEST,
    payload: 'deworming history list',
  };
};
export const geDewormingHistorySuccess = (res) => {
  return {
    type: GET_DEWORMING_HISTORY_SUCCESS,
    payload: res,
  };
};
export const geDewormingHistoryFailed = (err) => {
  return {
    type: GET_DEWORMING_HISTORY_FAILED,
    payload: err,
  };
};
export const geDewormingHistoryAction = (dataInfo) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(geDewormingHistoryRequest());
    axios
      .get(`${apiUrl}stock/tablet/history?institution_id=${dataInfo.institution_id}&type=${dataInfo.type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee", response)
        dispatch(geDewormingHistorySuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error.response.data)
        dispatch(geDewormingHistoryFailed(error.response.data));
      });
  };
};

// ADD_DEWORMING_TABLET_REQUEST,
// ADD_DEWORMING_TABLET_SUCCESS,
// ADD_DEWORMING_TABLET_FAILED,

export const addDewormingTabletRequest = () => {
  return {
    type: ADD_DEWORMING_TABLET_REQUEST,
    payload: 'add wifa tablet request',
  };
};
export const addDewormingTabletSuccess = (res) => {
  return {
    type: ADD_DEWORMING_TABLET_SUCCESS,
    payload: res,
  };
};
export const addDewormingTabletFailed = (err) => {
  return {
    type: ADD_DEWORMING_TABLET_FAILED,
    payload: err,
  };
};
export const addDewormingTabletAction = (dataInfo, setIsBtnLoading) => {
  const formData = {
    institution_id: dataInfo?.institution_id,
    type: dataInfo?.type,
    name: dataInfo?.name,
    entry_date: dataInfo?.entry_date,
    expired_date: dataInfo?.expired_date,
    entry_tablet: dataInfo?.entry_tablet,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    // dispatch(addDewormingTabletRequest())
    axios
      .post(`${apiUrl}stock/tablet/add`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(addDewormingTabletSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addDewormingTabletFailed(error.response.data));
        setIsBtnLoading(false);
      });
  };
};

// ADD_WIFA_TABLET_REQUEST,
// ADD_WIFA_TABLET_SUCCESS,
// ADD_WIFA_TABLET_FAILED,

export const addWifaTabletRequest = () => {
  return {
    type: ADD_WIFA_TABLET_REQUEST,
    payload: 'add wifa tablet request',
  };
};
export const addWifaTabletSuccess = (res) => {
  return {
    type: ADD_WIFA_TABLET_SUCCESS,
    payload: res,
  };
};
export const addWifaTabletFailed = (err) => {
  return {
    type: ADD_WIFA_TABLET_FAILED,
    payload: err,
  };
};
export const addWifaTabletAction = (dataInfo, setIsBtnLoading) => {
  const formData = {
    institution_id: dataInfo?.institution_id,
    type: dataInfo?.type,
    name: dataInfo?.name,
    entry_date: dataInfo?.entry_date,
    expired_date: dataInfo?.expired_date,
    entry_tablet: dataInfo?.entry_tablet,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    // dispatch(addWifaTabletRequest())
    axios
      .post(`${apiUrl}stock/tablet/add`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee', response);
        dispatch(addWifaTabletSuccess(response.data));
      })
      .catch((error) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error', error.response.data);
        dispatch(addWifaTabletFailed(error.response.data));
        setIsBtnLoading(false);
      });
  };
};

// REPORT PROGRAMS
// GET_REPORT_PROGRAM_LIST_REQUEST
// GET_REPORT_PROGRAM_LIST_SUCCESS
// GET_REPORT_PROGRAM_LIST_FAILED

export const getReportProgramListRequest = () => {
  return {
    type: GET_REPORT_PROGRAM_LIST_REQUEST,
    payload: 'get report program list',
  };
};
export const getReportProgramListSuccess = (res) => {
  return {
    type: GET_REPORT_PROGRAM_LIST_SUCCESS,
    payload: res,
  };
};
export const getReportProgramListFailed = (err) => {
  return {
    type: GET_REPORT_PROGRAM_LIST_FAILED,
    payload: err,
  };
};

export const getReportProgramListAction = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(getReportProgramListRequest());
    axios
      .get(`${apiUrl}programs/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(getReportProgramListSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getReportProgramListFailed(error));
      });
  };
};

// GET_WEEKLY_REPORT_REQUEST,
// GET_WEEKLY_REPORT_SUCCESS,
// GET_WEEKLY_REPORT_FAILED,

export const getWeeklyReportRequest = () => {
  return {
    type: GET_WEEKLY_REPORT_REQUEST,
    payload: 'weekly report',
  };
};
export const getWeeklyReportSuccess = (res) => {
  return {
    type: GET_WEEKLY_REPORT_SUCCESS,
    payload: res,
  };
};
export const getWeeklyReportFailed = (err) => {
  return {
    type: GET_WEEKLY_REPORT_FAILED,
    payload: err,
  };
};
export const getWeeklyReportAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined || dataInfo[key] === '') {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await dataInfo;
    dispatch(getWeeklyReportRequest());
    axios
      .get(
        `${apiUrl}report/weekly?${
          dataInfo.institution_id === undefined ? '' : `&institution_id=${dataInfo.institution_id}`
        }${dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`}${
          dataInfo.has_shift === undefined ? '' : `&has_shift=${dataInfo.has_shift}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}${
          dataInfo.section_id === undefined ? '' : `&section_id=${dataInfo.section_id}`
        }${dataInfo.program === undefined ? '' : `&program=${dataInfo.program}`}${
          dataInfo.year === undefined ? '' : `&year=${dataInfo.year}`
        }${dataInfo.month === undefined ? '' : `&month=${dataInfo.month}`}${
          dataInfo.week === undefined ? '' : `&week=${dataInfo.week}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getWeeklyReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getWeeklyReportFailed(error.response.data));
      });
  };
};

// GET_MONTHLY_REPORT_REQUEST,
// GET_MONTHLY_REPORT_SUCCESS,
// GET_MONTHLY_REPORT_FAILED,

export const getMonthlyReportRequest = () => {
  return {
    type: GET_MONTHLY_REPORT_REQUEST,
    payload: 'Monthly report',
  };
};
export const getMonthlyReportSuccess = (res) => {
  return {
    type: GET_MONTHLY_REPORT_SUCCESS,
    payload: res,
  };
};
export const getMonthlyReportFailed = (err) => {
  return {
    type: GET_MONTHLY_REPORT_FAILED,
    payload: err,
  };
};
export const getMonthlyReportAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined) {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await dataInfo;
    dispatch(getMonthlyReportRequest());
    axios
      .get(
        `${apiUrl}report/monthly?${
          dataInfo.institution_id === undefined ? '' : `&institution_id=${dataInfo.institution_id}`
        }${dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`}${
          dataInfo.has_shift === undefined ? '' : `&has_shift=${dataInfo.has_shift}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}${
          dataInfo.section_id === undefined ? '' : `&section_id=${dataInfo.section_id}`
        }${dataInfo.program === undefined ? '' : `&program=${dataInfo.program}`}${
          dataInfo.year === undefined ? '' : `&year=${dataInfo.year}`
        }${dataInfo.month === undefined ? '' : `&month=${dataInfo.month}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getMonthlyReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getMonthlyReportFailed(error.response.data));
      });
  };
};
// GET_ANNUAL_REPORT_REQUEST,
// GET_ANNUAL_REPORT_SUCCESS,
// GET_ANNUAL_REPORT_FAILED,

export const getAnnualReportRequest = () => {
  return {
    type: GET_ANNUAL_REPORT_REQUEST,
    payload: 'Annual report',
  };
};
export const getAnnualReportSuccess = (res) => {
  return {
    type: GET_ANNUAL_REPORT_SUCCESS,
    payload: res,
  };
};
export const getAnnualReportFailed = (err) => {
  return {
    type: GET_ANNUAL_REPORT_FAILED,
    payload: err,
  };
};
export const getAnnualReportAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined) {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await dataInfo;
    dispatch(getAnnualReportRequest());
    axios
      .get(
        `${apiUrl}report/annually?${
          dataInfo.institution_id === undefined ? '' : `&institution_id=${dataInfo.institution_id}`
        }${dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`}${
          dataInfo.has_shift === undefined ? '' : `&has_shift=${dataInfo.has_shift}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}${
          dataInfo.section_id === undefined ? '' : `&section_id=${dataInfo.section_id}`
        }${dataInfo.program === undefined ? '' : `&program=${dataInfo.program}`}${
          dataInfo.year === undefined ? '' : `&year=${dataInfo.year}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getAnnualReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAnnualReportFailed(error.response.data));
      });
  };
};
// GET_REFERRAL_REPORT_REQUEST,
// GET_REFERRAL_REPORT_SUCCESS,
// GET_REFERRAL_REPORT_FAILED,
export const getReferralReportRequest = () => {
  return {
    type: GET_REFERRAL_REPORT_REQUEST,
    payload: 'Referral report',
  };
};
export const getReferralReportSuccess = (res) => {
  return {
    type: GET_REFERRAL_REPORT_SUCCESS,
    payload: res,
  };
};
export const getReferralReportFailed = (err) => {
  return {
    type: GET_REFERRAL_REPORT_FAILED,
    payload: err,
  };
};
export const getReferralReportAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined) {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  // referral/report?institution_id=22016&grade=6&has_shift=yes&shift_id=1&section_id=1&from_date=2022-10-01&to_date=2022-10-05
  return async (dispatch) => {
    await dataInfo;
    dispatch(getReferralReportRequest());
    axios
      .get(
        `${apiUrl}referral/report?${
          dataInfo.institution_id === undefined ? '' : `&institution_id=${dataInfo.institution_id}`
        }${dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`}${
          dataInfo.has_shift === undefined ? '' : `&has_shift=${dataInfo.has_shift}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}${
          dataInfo.section_id === undefined ? '' : `&section_id=${dataInfo.section_id}`
        }${dataInfo.from_date === undefined ? '' : `&from_date=${dataInfo.from_date}`}${
          dataInfo.to_date === undefined ? '' : `&to_date=${dataInfo.to_date}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getReferralReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getReferralReportFailed(error.response.data));
      });
  };
};

// GET_REFERRAL_STUDENT_LIST_REQUEST,
// GET_REFERRAL_STUDENT_LIST_SUCCESS,
// GET_REFERRAL_STUDENT_LIST_FAILED,

export const getReferralStudentListRequest = () => {
  return {
    type: GET_REFERRAL_STUDENT_LIST_REQUEST,
    payload: 'referral student list request',
  };
};
export const getReferralStudentListSuccess = (res) => {
  return {
    type: GET_REFERRAL_STUDENT_LIST_SUCCESS,
    payload: res,
  };
};
export const getReferralStudentListFailed = (error) => {
  return {
    type: GET_REFERRAL_STUDENT_LIST_FAILED,
    payload: error,
  };
};
export const getReferralStudentListAction = (studentInfo) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined) {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    await studentInfo;
    dispatch(getReferralStudentListRequest());
    axios
      .get(
        `${apiUrl}referral/student/list?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.shift_id === undefined ? '' : `&shift_id=${studentInfo.shift_id}`}${
          studentInfo.section_id === undefined ? '' : `&section_id=${studentInfo.section_id}`
        }${studentInfo.gender === undefined ? '' : `&gender=${studentInfo.gender}`}${
          studentInfo.pagination_limit === undefined ? '' : `&pagination_limit=${studentInfo.pagination_limit}`
        }${studentInfo.subject_id === undefined ? '' : `&subject_id=${studentInfo.subject_id}`}${
          studentInfo.month === undefined ? '' : `&month=${studentInfo.month}`
        }${studentInfo.date === undefined ? '' : `&date=${studentInfo.date}`}${
          studentInfo.page === undefined ? '' : `&page=${studentInfo.page}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response", response)
        dispatch(getReferralStudentListSuccess(response.data));
      })
      .catch((error) => {
        // console.log("🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error", error)
        console.log(error);
        dispatch(getReferralStudentListFailed(error));
      });
  };
};

// POST_REFERRAL_STUDENT_REQUEST,
// POST_REFERRAL_STUDENT_SUCCESS,
// POST_REFERRAL_STUDENT_FAILED,

export const postReferralStudentRequest = () => {
  return {
    type: POST_REFERRAL_STUDENT_REQUEST,
    payload: 'post referral student',
  };
};
export const postReferralStudentSuccess = (res) => {
  return {
    type: POST_REFERRAL_STUDENT_SUCCESS,
    payload: res,
  };
};
export const postReferralStudentFailed = (err) => {
  return {
    type: POST_REFERRAL_STUDENT_FAILED,
    payload: err,
  };
};

export const postReferralStudentAction = (dataInfo) => {
  const formData = {
    institution_id: dataInfo?.institution_id,
    grade: dataInfo?.grade,
    has_shift: dataInfo?.has_shift,
    shift_id: dataInfo?.shift_id,
    section_id: dataInfo?.section_id,
    student_id: dataInfo?.student_id,
    teacher_name: dataInfo?.teacher_name,
    teacher_designation: dataInfo?.teacher_designation,
    referral_institution: dataInfo?.referral_institution,
    referral_reason: dataInfo?.referral_reason,
    date: dataInfo?.date,
    health_facilitator_name: dataInfo?.health_facilitator_name,
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return (dispatch) => {
    dispatch(postReferralStudentRequest());
    axios
      .post(`${apiUrl}referral/add/participate`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ responseee', response?.data);
        dispatch(postReferralStudentSuccess(response.data));
      })
      .catch((error) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error', error.response.data);
        dispatch(postReferralStudentFailed(error.response.data));
      });
  };
};

// GET_REFERRAL_STUDENT_DATA_REQUEST
// GET_REFERRAL_STUDENT_DATA_SUCCESS
// GET_REFERRAL_STUDENT_DATA_FAILED

export const getReferralStudentDataRequest = () => {
  return {
    type: GET_REFERRAL_STUDENT_DATA_REQUEST,
    payload: 'get single student data',
  };
};
export const getReferralStudentDataSuccess = (res) => {
  return {
    type: GET_REFERRAL_STUDENT_DATA_SUCCESS,
    payload: res,
  };
};
export const getReferralStudentDataFailed = (err) => {
  return {
    type: GET_REFERRAL_STUDENT_DATA_FAILED,
    payload: err,
  };
};

export const getReferralStudentDataAction = (studentInfo) => {
  Object.keys(studentInfo).forEach((key) => {
    if (studentInfo[key] === null || studentInfo[key] === undefined) {
      delete studentInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await studentInfo;
    dispatch(getReferralStudentDataRequest());
    axios
      .get(
        `${apiUrl}referral/single/student/data?institution_id=${studentInfo.institution_id}${
          studentInfo.grade === undefined ? '' : `&grade=${studentInfo.grade}`
        }${studentInfo.student_id === undefined ? '' : `&student_id=${studentInfo.student_id}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 843 ~ .then ~ response', response);
        dispatch(getReferralStudentDataSuccess(response.data));
      })
      .catch((error) => {
        console.log('🚀 ~ file: upgradeClass.action.js ~ line 847 ~ return ~ error', error);
        console.log(error);
        dispatch(getReferralStudentDataFailed(error));
      });
  };
};

// GET_DASHBOARD_DATA_REQUEST,
// GET_DASHBOARD_DATA_SUCCESS,
// GET_DASHBOARD_DATA_FAILED,

export const getDashboardDataRequest = () => {
  return {
    type: GET_DASHBOARD_DATA_REQUEST,
    payload: 'dashboard data report',
  };
};
export const getDashboardDataSuccess = (res) => {
  return {
    type: GET_DASHBOARD_DATA_SUCCESS,
    payload: res,
  };
};
export const getDashboardDataFailed = (err) => {
  return {
    type: GET_DASHBOARD_DATA_FAILED,
    payload: err,
  };
};
export const getDashboardDataAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined) {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  // report/dashboard?institution_id=22016&grade=6&has_shift=yes&shift_id=1&section_id=74&program=4&year=2022&month=10
  return async (dispatch) => {
    await dataInfo;
    dispatch(getDashboardDataRequest());
    axios
      .get(
        `${apiUrl}report/dashboard?${
          dataInfo.institution_id === undefined ? '' : `&institution_id=${dataInfo.institution_id}`
        }${dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`}${
          dataInfo.has_shift === undefined ? '' : `&has_shift=${dataInfo.has_shift}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}${
          dataInfo.section_id === undefined ? '' : `&section_id=${dataInfo.section_id}`
        }${dataInfo.program === undefined ? '' : `&program=${dataInfo.program}`}${
          dataInfo.year === undefined ? '' : `&year=${dataInfo.year}`
        }${dataInfo.month === undefined ? '' : `&month=${dataInfo.month}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getDashboardDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDashboardDataFailed(error.response.data));
      });
  };
};

// STUDENT_UPLOAD_REQUEST,
// STUDENT_UPLOAD_SUCCESS,
// STUDENT_UPLOAD_FAILED,

export const studentUploadRequest = () => {
  return {
    type: STUDENT_UPLOAD_REQUEST,
    payload: 'student upload',
  };
};

export const studentUploadSuccess = (res) => {
  return {
    type: STUDENT_UPLOAD_SUCCESS,
    payload: res,
  };
};

export const studentUploadFailed = (err) => {
  return {
    type: STUDENT_UPLOAD_FAILED,
    payload: err,
  };
};

export const studentUploadAction = (dataInfo, callback) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    let data = new FormData();
    await data.append('institution_id', dataInfo?.institution_id);
    await data.append('grade', dataInfo?.grade);
    await data.append('section_id', dataInfo?.section_id);
    await data.append('import_file', dataInfo?.import_file);
    (await dataInfo?.shift_id) && data.append('shift_id', dataInfo?.shift_id);
    dispatch(studentUploadRequest());
    axios
      .post(`${apiUrl}section/import/student`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(studentUploadSuccess(response.data));

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(studentUploadFailed(error.response.data));
      });
  };
};

// STUDENT_SAMPLE_DOWNLOAD_REQUEST,
// STUDENT_SAMPLE_DOWNLOAD_SUCCESS,
// STUDENT_SAMPLE_DOWNLOAD_FAILED,

export const studentSampleDownloadRequest = () => {
  return {
    type: STUDENT_SAMPLE_DOWNLOAD_REQUEST,
    payload: 'student sample download request',
  };
};

export const studentSampleDownloadSuccess = (res) => {
  return {
    type: STUDENT_SAMPLE_DOWNLOAD_SUCCESS,
    payload: res,
  };
};

export const studentSampleDownloadFailed = (err) => {
  return {
    type: STUDENT_SAMPLE_DOWNLOAD_FAILED,
    payload: err,
  };
};
export const studentSampleDownloadAction = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);

  return async (dispatch) => {
    dispatch(studentSampleDownloadRequest());
    axios
      .get(`${apiUrl}section/import/student/sample/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(studentSampleDownloadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(studentSampleDownloadFailed(error.response.data));
      });
  };
};

// UPGRADE_STUDENT_REQUEST,
// UPGRADE_STUDENT_SUCCESS,
// UPGRADE_STUDENT_FAILED,

export const upgradeStudentRequest = () => {
  return {
    type: UPGRADE_STUDENT_REQUEST,
    payload: 'upgrade class student request',
  };
};
export const upgradeStudentSuccess = (res) => {
  return {
    type: UPGRADE_STUDENT_SUCCESS,
    payload: res,
  };
};
export const upgradeStudentFailed = (err) => {
  return {
    type: UPGRADE_STUDENT_FAILED,
    payload: err,
  };
};
export const upgradeStudentAction = (dataInfo) => {
  Object.keys(dataInfo).forEach((key) => {
    if (dataInfo[key] === null || dataInfo[key] === undefined) {
      delete dataInfo[key];
    }
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  return async (dispatch) => {
    await dataInfo;
    dispatch(upgradeStudentRequest());
    // student/list/for/upgrade?institution_id=22016&has_shift=yes&shift_id=1&grade=6&section_id=74&academic_year=2022&pagination_limit=1
    axios
      .get(
        `${apiUrl}student/list/for/upgrade?${
          dataInfo.institution_id === undefined ? '' : `&institution_id=${dataInfo.institution_id}`
        }${dataInfo.grade === undefined ? '' : `&grade=${dataInfo.grade}`}${
          dataInfo.has_shift === undefined ? '' : `&has_shift=${dataInfo.has_shift}`
        }${dataInfo.shift_id === undefined ? '' : `&shift_id=${dataInfo.shift_id}`}${
          dataInfo.section_id === undefined ? '' : `&section_id=${dataInfo.section_id}`
        }${dataInfo.academic_year === undefined ? '' : `&academic_year=${dataInfo.academic_year}`}${
          dataInfo.pagination_limit === undefined ? '' : `&pagination_limit=${dataInfo.pagination_limit}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log('🚀 ~ file: upgradeClass.action.js:2712 ~ .then ~ response', response);
        dispatch(upgradeStudentSuccess(response.data));
      })
      .catch((error) => {
        // console.log('🚀 ~ file: upgradeClass.action.js:2716 ~ return ~ error', error);
        dispatch(upgradeStudentFailed(error.response.data));
      });
  };
};

// POST_UPGRADE_STUDENT_REQUEST,
// POST_UPGRADE_STUDENT_SUCCESS,
// POST_UPGRADE_STUDENT_FAILED,

export const postUpgradeStudentRequest = () => {
  return {
    type: POST_UPGRADE_STUDENT_REQUEST,
    payload: 'student upgrade post request',
  };
};
export const postUpgradeStudentSuccess = (res) => {
  return {
    type: POST_UPGRADE_STUDENT_SUCCESS,
    payload: res,
  };
};
export const postUpgradeStudentFailed = (err) => {
  return {
    type: POST_UPGRADE_STUDENT_FAILED,
    payload: err,
  };
};

export const postUpgradeStudentAction = (dataInfo, callback) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = JSON.parse(localStorage.token);
  const data = dataInfo;
  return (dispatch) => {
    dispatch(postUpgradeStudentRequest());
    axios
      .post(`${apiUrl}student/upgrade`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        dispatch(postUpgradeStudentSuccess(response.data));
        if (callback) callback(response.data);
      })
      .catch((error) => {
        // console.log(error.response.data);
        dispatch(postUpgradeStudentFailed(error.response.data));
        if (callback) callback();
      });
  };
};
