import React from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import ChartResultList from './ChartResultList';
const NutritionEducationResult = (props, { hasShift }) => {
  const {
    month,
    participatedBoy,
    participatedBoyPercentage,
    participatedGirl,
    participatedGirlPercentage,
    program,
    totalStudent,
    totalStudentPercentage,
    week,
    year,
    age10to14,
    age10to14Percentage,
    age15to19,
    age15to19Percentage,
    age10to14Boy,
    age15to19Boy,
    age10to14Girl,
    age15to19Girl,
    age15to19Age,
  } = props.report.data[0];

  // chart data
  const charData = {
    options: {
      colors: ['#5197D3', '#E3488A'],
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: ['10-14', '15-19'],
      },
      dataLabels: {
        style: {
          colors: ['#304758'],
        },
      },
      legend: {
        onItemHover: {
          highlightDataSeries: false,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    },
    series: [
      {
        name: `boy`, //boys
        data: [age10to14Boy, age15to19Boy],
        // data: [10,15],
      },
      {
        name: `girs`, //girls
        data: [age10to14Girl, age15to19Girl],
        // data: [15,10],
      },
    ],
  };
  return (
    <React.Fragment>
      <div className='container mx-auto'>
        <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
          <div className='border border-gray-300 w-full rounded p-10'>
            <div className='flex justify-between'>
              <div className='text-left'>
                <h1 className='mb-4 text-base font-semibold md:text-2xl md:font-bold capitalize'>
                  {props.GradeName === undefined ? '' : ` ${props.GradeName} `}
                  {/* {hasShift?.data?.has_shift==='yes' && (
                                            <span>
                                             janina   {props.shiftName} -
                                            </span>
                                        )} */}
                  {props.shiftName === undefined ? '' : ` ${props.shiftName} `}
                  {props.sectionName === undefined ? '' : ` ${props.sectionName} `}
                  {/* class 6 shift - section */}
                </h1>
                <h1 className=' text-base text-black md:text-lg capitalize font-bold'>
                  <span className=' text-gray-500 font-semibold'>Program : </span>
                  <span>{props.title}</span>
                </h1>
                <h1 className=' text-base text-black md:text-lg capitalize font-bold'>
                  <span className=' text-gray-500 font-semibold'>year : </span>
                  <span>{year}</span>
                </h1>
              </div>
              <div className=''>
                {month && (
                  <h1 className=' text-base text-black md:text-lg capitalize font-bold'>
                    <span className=' text-gray-500 font-semibold'>Month : </span>
                    <span>{month}</span>
                  </h1>
                )}
                {week && (
                  <h1 className=' text-base text-black md:text-lg capitalize font-bold'>
                    <span className=' text-gray-500 font-semibold'>Week : </span>
                    <span>{week}</span>
                  </h1>
                )}
              </div>
            </div>

            <div id='bar_chart' className='w-full md:w-2/3 mx-auto'>
              <Chart options={charData.options} series={charData.series} type='bar' height={320} />
            </div>

            <ChartResultList
              title='participated boys'
              totalNumber={participatedBoy}
              percentage={participatedBoyPercentage}
            />
            <ChartResultList
              title='participated girls'
              totalNumber={participatedGirl}
              percentage={participatedGirlPercentage}
            />
            <ChartResultList title='10 - 14 Age' totalNumber={age10to14} percentage={age10to14Percentage} />
            <ChartResultList title='15 - 19 Age' totalNumber={age15to19Age} percentage={age15to19Percentage} />
            <ChartResultList title=' total students' totalNumber={totalStudent} percentage={totalStudentPercentage} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getStudent: (studentInfo) => dispatch(sectionStudentAction(studentInfo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NutritionEducationResult);
