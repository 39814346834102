// check is shift yes or no
export const GET_SHIFT_CHECK_REQUEST = 'GET_SHIFT_CHECK_REQUEST';
export const GET_SHIFT_CHECK_SUCCESS = 'GET_SHIFT_CHECK_SUCCESS';
export const GET_SHIFT_CHECK_FAILED = 'GET_SHIFT_CHECK_FAILED';

// upgraded class lists
export const GET_UPGRADE_CLASS_LIST_REQUEST = 'GET_UPGRADE_CLASS_LIST_REQUEST';
export const GET_UPGRADE_CLASS_LIST_SUCCESS = 'GET_UPGRADE_CLASS_LIST_SUCCESS';
export const GET_UPGRADE_CLASS_LIST_FAILED = 'GET_UPGRADE_CLASS_LIST_FAILED';

// constants for shift under the class
export const GET_CLASS_WISE_SHIFT_LIST_REQUEST = 'GET_CLASS_WISE_SHIFT_LIST_REQUEST';
export const GET_CLASS_WISE_SHIFT_LIST_SUCCESS = 'GET_CLASS_WISE_SHIFT_LIST_SUCCESS';
export const GET_CLASS_WISE_SHIFT_LIST_FAILED = 'GET_CLASS_WISE_SHIFT_LIST_FAILED';

// sections lists
export const GET_SECTION_LIST_REQUEST = 'GET_SECTION_LIST_REQUEST';
export const GET_SECTION_LIST_SUCCESS = 'GET_SECTION_LIST_SUCCESS';
export const GET_SECTION_LIST_FAILED = 'GET_SECTION_LIST_FAILED';

// section creation
export const CREATE_SECTION_LIST_REQUEST = 'CREATE_SECTION_LIST_REQUEST';
export const CREATE_SECTION_LIST_SUCCESS = 'CREATE_SECTION_LIST_SUCCESS';
export const CREATE_SECTION_LIST_FAILED = 'CREATE_SECTION_LIST_FAILED';

// section update
export const UPDATE_SECTION_LIST_REQUEST = 'UPDATE_SECTION_LIST_REQUEST';
export const UPDATE_SECTION_LIST_SUCCESS = 'UPDATE_SECTION_LIST_SUCCESS';
export const UPDATE_SECTION_LIST_FAILED = 'UPDATE_SECTION_LIST_FAILED';

// section delete
export const DELETE_SECTION_LIST_REQUEST = 'DELETE_SECTION_LIST_REQUEST';
export const DELETE_SECTION_LIST_SUCCESS = 'DELETE_SECTION_LIST_SUCCESS';
export const DELETE_SECTION_LIST_FAILED = 'DELETE_SECTION_LIST_FAILED';

// section teacher create  -------TEACHER-------
export const TEACHER_LIST_REQUEST = 'TEACHER_LIST_REQUEST';
export const TEACHER_LIST_SUCCESS = 'TEACHER_LIST_SUCCESS';
export const TEACHER_LIST_FAILED = 'TEACHER_LIST_FAILED';

export const CREATE_SECTION_TEACHER_REQUEST = 'CREATE_SECTION_TEACHER_REQUEST';
export const CREATE_SECTION_TEACHER_SUCCESS = 'CREATE_SECTION_TEACHER_SUCCESS';
export const CREATE_SECTION_TEACHER_FAILED = 'CREATE_SECTION_TEACHER_FAILED';

export const DELETE_TEACHER_REQUEST = 'DELETE_TEACHER_REQUEST';
export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';
export const DELETE_TEACHER_FAILED = 'DELETE_TEACHER_FAILED';

export const UPDATE_TEACHER_REQUEST = 'UPDATE_TEACHER_REQUEST';
export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export const UPDATE_TEACHER_FAILED = 'UPDATE_TEACHER_FAILED';

export const GET_TEACHER_PROFILE_REQUEST = 'GET_TEACHER_PROFILE_REQUEST';
export const GET_TEACHER_PROFILE_SUCCESS = 'GET_TEACHER_PROFILE_SUCCESS';
export const GET_TEACHER_PROFILE_FAILED = 'GET_TEACHER_PROFILE_FAILED';

// shift student list page
export const GET_SECTION_STUDENT_LIST_REQUEST = 'GET_SECTION_STUDENT_LIST_REQUEST';
export const GET_SECTION_STUDENT_LIST_SUCCESS = 'GET_SECTION_STUDENT_LIST_SUCCESS';
export const GET_SECTION_STUDENT_LIST_FAILED = 'GET_SECTION_STUDENT_LIST_FAILED';

// *STUDENT PROFILE
export const GET_STUDENT_PROFILE_REQUEST = 'GET_STUDENT_PROFILE_REQUEST';
export const GET_STUDENT_PROFILE_SUCCESS = 'GET_STUDENT_PROFILE_SUCCESS';
export const GET_STUDENT_PROFILE_FAILED = 'GET_STUDENT_PROFILE_FAILED';

// student add from shift
export const CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST = 'CREATE_SECTION_STUDENT_FROM_SHIFT_REQUEST';
export const CREATE_SECTION_STUDENT_FROM_SHIFT_SUCCESS = 'CREATE_SECTION_STUDENT_FROM_SHIFT_SUCCESS';
export const CREATE_SECTION_STUDENT_FROM_SHIFT_FAILED = 'CREATE_SECTION_STUDENT_FROM_SHIFT_FAILED';

// student delete
export const DELETE_SECTION_STUDENT_REQUEST = 'DELETE_SECTION_STUDENT_REQUEST';
export const DELETE_SECTION_STUDENT_SUCCESS = 'DELETE_SECTION_STUDENT_SUCCESS';
export const DELETE_SECTION_STUDENT_FAILED = 'DELETE_SECTION_STUDENT_FAILED';

// student update
export const UPDATE_SECTION_STUDENT_REQUEST = 'UPDATE_SECTION_STUDENT_REQUEST';
export const UPDATE_SECTION_STUDENT_SUCCESS = 'UPDATE_SECTION_STUDENT_SUCCESS';
export const UPDATE_SECTION_STUDENT_FAILED = 'UPDATE_SECTION_STUDENT_FAILED';

// programs
export const GET_SUBJECT_LIST_REQUEST = 'GET_SUBJECT_LIST_REQUEST';
export const GET_SUBJECT_LIST_SUCCESS = 'GET_SUBJECT_LIST_SUCCESS';
export const GET_SUBJECT_LIST_FAILED = 'GET_SUBJECT_LIST_FAILED';
// stock list
export const GET_STOCK_LIST_REQUEST = 'GET_STOCK_LIST_REQUEST';
export const GET_STOCK_LIST_SUCCESS = 'GET_STOCK_LIST_SUCCESS';
export const GET_STOCK_LIST_FAILED = 'GET_STOCK_LIST_FAILED';

// get week number
export const GET_WEEK_NUMBER_REQUEST = 'GET_WEEK_NUMBER_REQUEST';
export const GET_WEEK_NUMBER_SUCCESS = 'GET_WEEK_NUMBER_SUCCESS';
export const GET_WEEK_NUMBER_FAILED = 'GET_WEEK_NUMBER_FAILED';

// Nutrition
export const GET_NUTRITION_PROGRAM_STUDENT_LIST_REQUEST = 'GET_NUTRITION_PROGRAM_STUDENT_LIST_REQUEST';
export const GET_NUTRITION_PROGRAM_STUDENT_LIST_SUCCESS = 'GET_NUTRITION_PROGRAM_STUDENT_LIST_SUCCESS';
export const GET_NUTRITION_PROGRAM_STUDENT_LIST_FAILED = 'GET_NUTRITION_PROGRAM_STUDENT_LIST_FAILED';

export const POST_PROGRAM_STUDENT_LIST_REQUEST = 'POST_PROGRAM_STUDENT_LIST_REQUEST';
export const POST_PROGRAM_STUDENT_LIST_SUCCESS = 'POST_PROGRAM_STUDENT_LIST_SUCCESS';
export const POST_PROGRAM_STUDENT_LIST_FAILED = 'POST_PROGRAM_STUDENT_LIST_FAILED';

// WIfa
export const GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST = 'GET_WIFA_PROGRAM_STUDENT_LIST_REQUEST';
export const GET_WIFA_PROGRAM_STUDENT_LIST_SUCCESS = 'GET_WIFA_PROGRAM_STUDENT_LIST_SUCCESS';
export const GET_WIFA_PROGRAM_STUDENT_LIST_FAILED = 'GET_WIFA_PROGRAM_STUDENT_LIST_FAILED';

export const POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST = 'POST_WIFA_PROGRAM_STUDENT_LIST_REQUEST';
export const POST_WIFA_PROGRAM_STUDENT_LIST_SUCCESS = 'POST_WIFA_PROGRAM_STUDENT_LIST_SUCCESS';
export const POST_WIFA_PROGRAM_STUDENT_LIST_FAILED = 'POST_WIFA_PROGRAM_STUDENT_LIST_FAILED';

// deworming

export const GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST = 'GET_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST';
export const GET_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS = 'GET_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS';
export const GET_DEWORMING_PROGRAM_STUDENT_LIST_FAILED = 'GET_DEWORMING_PROGRAM_STUDENT_LIST_FAILED';

export const POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST = 'POST_DEWORMING_PROGRAM_STUDENT_LIST_REQUEST';
export const POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS = 'POST_DEWORMING_PROGRAM_STUDENT_LIST_SUCCESS';
export const POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED = 'POST_DEWORMING_PROGRAM_STUDENT_LIST_FAILED';

// BMI
export const GET_BMI_PROGRAM_STUDENT_LIST_REQUEST = 'GET_BMI_PROGRAM_STUDENT_LIST_REQUEST';
export const GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS = 'GET_BMI_PROGRAM_STUDENT_LIST_SUCCESS';
export const GET_BMI_PROGRAM_STUDENT_LIST_FAILED = 'GET_BMI_PROGRAM_STUDENT_LIST_FAILED';

export const POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST = 'POST_BMI_PROGRAM_PARTICIPATED_STUDENT_REQUEST';
export const POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS = 'POST_BMI_PROGRAM_PARTICIPATED_STUDENT_SUCCESS';
export const POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED = 'POST_BMI_PROGRAM_PARTICIPATED_STUDENT_FAILED';

// feet to meter
export const GET_FEET_TO_METER_REQUEST = 'GET_FEET_TO_METER_REQUEST';
export const GET_FEET_TO_METER_SUCCESS = 'GET_FEET_TO_METER_SUCCESS';
export const GET_FEET_TO_METER_FAILED = 'GET_FEET_TO_METER_FAILED';

// tablet stock
export const GET_WIFA_TOTAL_TABLET_REQUEST = 'GET_WIFA_TOTAL_TABLET_REQUEST';
export const GET_WIFA_TOTAL_TABLET_SUCCESS = 'GET_WIFA_TOTAL_TABLET_SUCCESS';
export const GET_WIFA_TOTAL_TABLET_FAILED = 'GET_WIFA_TOTAL_TABLET_FAILED';

export const GET_WIFA_USED_TABLET_REQUEST = 'GET_WIFA_USED_TABLET_REQUEST';
export const GET_WIFA_USED_TABLET_SUCCESS = 'GET_WIFA_USED_TABLET_SUCCESS';
export const GET_WIFA_USED_TABLET_FAILED = 'GET_WIFA_USED_TABLET_FAILED';

export const GET_WIFA_HISTORY_REQUEST = 'GET_WIFA_HISTORY_REQUEST';
export const GET_WIFA_HISTORY_SUCCESS = 'GET_WIFA_HISTORY_SUCCESS';
export const GET_WIFA_HISTORY_FAILED = 'GET_WIFA_HISTORY_FAILED';

export const ADD_WIFA_TABLET_REQUEST = 'ADD_WIFA_TABLET_REQUEST';
export const ADD_WIFA_TABLET_SUCCESS = 'ADD_WIFA_TABLET_SUCCESS';
export const ADD_WIFA_TABLET_FAILED = 'ADD_WIFA_TABLET_FAILED';

export const GET_DEWORMING_TOTAL_TABLET_REQUEST = 'GET_DEWORMING_TOTAL_TABLET_REQUEST';
export const GET_DEWORMING_TOTAL_TABLET_SUCCESS = 'GET_DEWORMING_TOTAL_TABLET_SUCCESS';
export const GET_DEWORMING_TOTAL_TABLET_FAILED = 'GET_DEWORMING_TOTAL_TABLET_FAILED';

export const GET_DEWORMING_USED_TABLET_REQUEST = 'GET_DEWORMING_USED_TABLET_REQUEST';
export const GET_DEWORMING_USED_TABLET_SUCCESS = 'GET_DEWORMING_USED_TABLET_SUCCESS';
export const GET_DEWORMING_USED_TABLET_FAILED = 'GET_DEWORMING_USED_TABLET_FAILED';

export const GET_DEWORMING_HISTORY_REQUEST = 'GET_DEWORMING_HISTORY_REQUEST';
export const GET_DEWORMING_HISTORY_SUCCESS = 'GET_DEWORMING_HISTORY_SUCCESS';
export const GET_DEWORMING_HISTORY_FAILED = 'GET_DEWORMING_HISTORY_FAILED';

export const ADD_DEWORMING_TABLET_REQUEST = 'ADD_DEWORMING_TABLET_REQUEST';
export const ADD_DEWORMING_TABLET_SUCCESS = 'ADD_DEWORMING_TABLET_SUCCESS';
export const ADD_DEWORMING_TABLET_FAILED = 'ADD_DEWORMING_TABLET_FAILED';

// report PROGRAMS
export const GET_REPORT_PROGRAM_LIST_REQUEST = 'GET_REPORT_PROGRAM_LIST_REQUEST';
export const GET_REPORT_PROGRAM_LIST_SUCCESS = 'GET_REPORT_PROGRAM_LIST_SUCCESS';
export const GET_REPORT_PROGRAM_LIST_FAILED = 'GET_REPORT_PROGRAM_LIST_FAILED';

export const GET_WEEKLY_REPORT_REQUEST = 'GET_WEEKLY_REPORT_REQUEST';
export const GET_WEEKLY_REPORT_SUCCESS = 'GET_WEEKLY_REPORT_SUCCESS';
export const GET_WEEKLY_REPORT_FAILED = 'GET_WEEKLY_REPORT_FAILED';

export const GET_MONTHLY_REPORT_REQUEST = 'GET_MONTHLY_REPORT_REQUEST';
export const GET_MONTHLY_REPORT_SUCCESS = 'GET_MONTHLY_REPORT_SUCCESS';
export const GET_MONTHLY_REPORT_FAILED = 'GET_MONTHLY_REPORT_FAILED';

export const GET_ANNUAL_REPORT_REQUEST = 'GET_ANNUAL_REPORT_REQUEST';
export const GET_ANNUAL_REPORT_SUCCESS = 'GET_ANNUAL_REPORT_SUCCESS';
export const GET_ANNUAL_REPORT_FAILED = 'GET_ANNUAL_REPORT_FAILED';

export const GET_REFERRAL_REPORT_REQUEST = 'GET_REFERRAL_REPORT_REQUEST';
export const GET_REFERRAL_REPORT_SUCCESS = 'GET_REFERRAL_REPORT_SUCCESS';
export const GET_REFERRAL_REPORT_FAILED = 'GET_REFERRAL_REPORT_FAILED';

// referral
export const GET_REFERRAL_STUDENT_LIST_REQUEST = 'GET_REFERRAL_STUDENT_LIST_REQUEST';
export const GET_REFERRAL_STUDENT_LIST_SUCCESS = 'GET_REFERRAL_STUDENT_LIST_SUCCESS';
export const GET_REFERRAL_STUDENT_LIST_FAILED = 'GET_REFERRAL_STUDENT_LIST_FAILED';

export const POST_REFERRAL_STUDENT_REQUEST = 'POST_REFERRAL_STUDENT_REQUEST';
export const POST_REFERRAL_STUDENT_SUCCESS = 'POST_REFERRAL_STUDENT_SUCCESS';
export const POST_REFERRAL_STUDENT_FAILED = 'POST_REFERRAL_STUDENT_FAILED';

export const GET_REFERRAL_STUDENT_DATA_REQUEST = 'GET_REFERRAL_STUDENT_DATA_REQUEST';
export const GET_REFERRAL_STUDENT_DATA_SUCCESS = 'GET_REFERRAL_STUDENT_DATA_SUCCESS';
export const GET_REFERRAL_STUDENT_DATA_FAILED = 'GET_REFERRAL_STUDENT_DATA_FAILED';

// dashboard
export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILED = 'GET_DASHBOARD_DATA_FAILED';

// import student
export const STUDENT_UPLOAD_REQUEST = 'STUDENT_UPLOAD_REQUEST';
export const STUDENT_UPLOAD_SUCCESS = 'STUDENT_UPLOAD_SUCCESS';
export const STUDENT_UPLOAD_FAILED = 'STUDENT_UPLOAD_FAILED';

export const STUDENT_SAMPLE_DOWNLOAD_REQUEST = 'STUDENT_SAMPLE_DOWNLOAD_REQUEST';
export const STUDENT_SAMPLE_DOWNLOAD_SUCCESS = 'STUDENT_SAMPLE_DOWNLOAD_SUCCESS';
export const STUDENT_SAMPLE_DOWNLOAD_FAILED = 'STUDENT_SAMPLE_DOWNLOAD_FAILED';

// upgrade class student

export const UPGRADE_STUDENT_REQUEST = 'UPGRADE_STUDENT_REQUEST';
export const UPGRADE_STUDENT_SUCCESS = 'UPGRADE_STUDENT_SUCCESS';
export const UPGRADE_STUDENT_FAILED = 'UPGRADE_STUDENT_FAILED';

export const POST_UPGRADE_STUDENT_REQUEST = 'POST_UPGRADE_STUDENT_REQUEST';
export const POST_UPGRADE_STUDENT_SUCCESS = 'POST_UPGRADE_STUDENT_SUCCESS';
export const POST_UPGRADE_STUDENT_FAILED = 'POST_UPGRADE_STUDENT_FAILED';
