import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import ChartResultList from '../ChartResultList';
const DashboardWifa = ({ title, report }) => {
  console.log('🚀 ~ file: DashboardWifa.jsx ~ line 5 ~ DashboardWifa ~ report', report?.data[0]);

  // "data": {
  //     "gender": {
  //         "total": 5,
  //         "totalPercentage": 60,

  //         "totalIntake": 3,
  //         "totalIntakePercentage": 60,

  //         "totalNotIntake": 2,
  //         "totalNotIntakePercentage": 40
  //     },
  //     "age": {
  //         "total": 5,
  //         "totalPercentage": 60,

  //         "age10to14": 1,
  //         "age10to14Percentage": 33,

  //         "age15to19": 0,
  //         "age15to19Percentage": 0,

  //         "age10to14GirlIntake": 1,
  //         "age10to14GirlTotal": 3,

  //         "age15to19GirlIntake": 0,
  //         "age15to19GirlTotal": 0
  //     },
  //     "others": {
  //         "program": "wifas",
  //         "year": 2022,
  //         "month": "October"
  //     }
  // }

  // Gender tabs
  const [activeTabs, setActiveTabs] = useState('intake');
  const handelGenderTab = (e) => {
    e.preventDefault();
    setActiveTabs('intake');
  };
  const handelAgeTab = (e) => {
    e.preventDefault();
    setActiveTabs('age');
  };

  const intakeData = {
    options: {
      colors: ['#FFC426', '#E3488A'],
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: [''],
      },
      stroke: {
        colors: ['transparent'],
        width: 50,
      },
      dataLabels: {
        style: {
          colors: ['#304758'],
        },
      },
      legend: {
        onItemHover: {
          highlightDataSeries: false,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    },
    series: [
      {
        name: 'total',
        data: [report?.data[0]?.gender?.total],
        // data: [10],
      },
      {
        name: 'intake',
        data: [report?.data[0]?.gender?.totalIntake],
        // data: [20],
      },
    ],
  };
  const ageData = {
    options: {
      colors: ['#FFC426', '#E3488A'],
      chart: {
        id: 'apexchart-example',
      },
      xaxis: {
        categories: [''],
      },
      stroke: {
        colors: ['transparent'],
        width: 10,
      },
    },
    series: [
      {
        name: '10 - 14',
        data: [report?.data[0]?.age?.age10to14GirlTotal, report?.data[0]?.age?.age15to19GirlTotal],
        // data: [20, 5],
      },
      {
        name: '15 - 19',
        data: [report?.data[0]?.age?.age10to14GirlIntake, report?.data[0]?.age?.age15to19GirlIntake],
        // data: [15, 5],
      },
    ],
  };

  return (
    <React.Fragment>
      <div className='flex justify-between items-center pt-16 pb-16 border-t border-gray-300'>
        <div className=''>
          <h1 className=' text-base text-black md:text-lg capitalize font-bold'>
            <span className=' text-gray-500 font-semibold'>Program : </span> <span>{title && title}</span>
          </h1>
        </div>
        <div className=''>
          <div className=' rounded p-1 border border-green-600 inline-block'>
            <button
              onClick={handelGenderTab}
              type='button'
              className={`${
                activeTabs === 'intake' ? 'active' : null
              } py-2 px-6 capitalize text-[18px] rounded text-gray-600 hover:text-white hover_gradient bg_gradient_active`}
            >
              <span>intake </span>
            </button>
            <button
              onClick={handelAgeTab}
              type='button'
              className={`${
                activeTabs === 'age' ? 'active' : null
              } py-2 px-6 capitalize text-[18px] rounded text-gray-600 hover:text-white hover_gradient bg_gradient_active`}
            >
              <span>age</span>
            </button>
          </div>
        </div>
      </div>
      <div className=''>
        {activeTabs === 'intake' ? (
          <React.Fragment>
            <div className='w-full lg:w-2/5 mx-auto'>
              <Chart options={intakeData.options} series={intakeData.series} type='bar' height={320} />
            </div>
            <ChartResultList
              title='intake'
              totalNumber={report?.data[0]?.gender?.totalIntake}
              percentage={report?.data[0]?.gender?.totalIntakePercentage}
            />
            <ChartResultList
              title='not intake'
              totalNumber={report?.data[0]?.gender?.totalNotIntake}
              percentage={report?.data[0]?.gender?.totalNotIntakePercentage}
            />
            <ChartResultList
              title='total Girls'
              totalNumber={report?.data[0]?.gender?.total}
              percentage={report?.data[0]?.gender?.totalPercentage}
            />
          </React.Fragment>
        ) : null}
        {activeTabs === 'age' ? (
          <React.Fragment>
            <div className='w-full lg:w-2/5 mx-auto'>
              <Chart options={ageData.options} series={ageData.series} type='bar' height={320} />
            </div>
            <ChartResultList
              title='10 - 14 Age'
              totalNumber={report?.data[0]?.age?.age10to14}
              percentage={report?.data[0]?.age?.age10to14Percentage}
            />
            <ChartResultList
              title='15 - 19 Age'
              totalNumber={report?.data[0]?.age?.age15to19}
              percentage={report?.data[0]?.age?.age15to19Percentage}
            />
            <ChartResultList
              title='total Girls'
              totalNumber={report?.data[0]?.age?.total}
              percentage={report?.data[0]?.age?.total}
            />
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default DashboardWifa;
