export const LEARNING_MODULE_REQUEST = 'LEARNING_MODULE_REQUEST';
export const LEARNING_MODULE_SUCCESS = 'LEARNING_MODULE_SUCCESS';
export const LEARNING_MODULE_FAILED = 'LEARNING_MODULE_FAILED';

export const LEARNING_MODULE_CATEGORY_REQUEST = 'LEARNING_MODULE_CATEGORY_REQUEST';
export const LEARNING_MODULE_CATEGORY_SUCCESS = 'LEARNING_MODULE_CATEGORY_SUCCESS';
export const LEARNING_MODULE_CATEGORY_FAILED = 'LEARNING_MODULE_CATEGORY_FAILED';

export const LEARNING_MODULE_DETAILS_REQUEST = 'LEARNING_MODULE_DETAILS_REQUEST';
export const LEARNING_MODULE_DETAILS_SUCCESS = 'LEARNING_MODULE_DETAILS_SUCCESS';
export const LEARNING_MODULE_DETAILS_FAILED = 'LEARNING_MODULE_DETAILS_FAILED';
