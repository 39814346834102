import React from 'react';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { months, years } from '../../../../common/datas';
import { toBnNumber } from '../../../../common/functions';
import BreadCrumb from '../../../../Components/BreadCrumb/BreadCrumb';
import LanguageContext from '../../../../context/LanguageContext';
import StoreContext from '../../../../context/StoreContext';
import {
  getClassWiseShiftListAction,
  getDashboardDataAction,
  getReportProgramListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
} from '../../../../redux';
import { getDashboardDataRequest } from '../../../../redux/actions/upgradeClass.action';
import DashboardBmiResult from './dashboardReport/DashboardBmiResult';
import DashboardNutrition from './dashboardReport/DashboardNutrition';
import DashboardWifa from './dashboardReport/DashboardWifa';
import Heading from './Heading';
const Dashboard = ({
  report,
  getReport,
  getDashboardDataRequest,

  hasShift,
}) => {
  const intl = useIntl();
  const { classList, programList } = useContext(StoreContext);
  const { defaultLanguage } = useContext(LanguageContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [gradeId, setGrade] = useState('');
  const [shiftId, setShift] = useState('');
  const [sectionId, setSection] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [ProgramId, setProgramId] = useState('');
  const [GradeName, setGradeName] = useState('');
  const [shiftArray, setShiftArray] = useState([]);
  const [shiftName, setShiftName] = useState('');
  const [sectionArray, setSectionArray] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [IsButtonDisabled, setIsButtonDisabled] = useState(true);
  const [PostData, setPostData] = useState(null);

  useEffect(() => {
    getDashboardDataRequest();
  }, [getDashboardDataRequest]);
  useEffect(() => {
    if (gradeId && sectionId && ProgramId && year && month) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [ProgramId, gradeId, month, sectionId, year]);

  // event handler
  // class
  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setShift('');
    setSection('');

    const singleClass = classList.find((classes) => classes.grade === Number(e.target.value));

    setGradeName(singleClass?.name_en);
    if (hasShift?.data?.has_shift === 'yes') {
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      setSectionArray(singleClass?.shift_section);
    }
  };
  // shift
  const handelSelectShift = (e) => {
    setShift(e.target.value);
    const singleShift = shiftArray.find((shifts) => shifts.shift_id === Number(e.target.value));
    setShiftName(singleShift?.shift_name_en);

    setSectionArray(singleShift?.section);
    setSection('');
  };

  // section
  const handelSelectSection = (e) => {
    setSection(e.target.value);
    const singleSection = sectionArray.find((section) => section.id === Number(e.target.value));
    setSectionName(singleSection?.section_name_en);
  };
  // program
  const handelProgram = (e) => {
    setProgramId(e.target.value);
  };
  // click to filter button to get report data
  const handleSubmit = () => {
    const data = {
      institution_id: institutionId,
      grade: gradeId,
      has_shift: hasShift?.data?.has_shift,
      shift_id: shiftId,
      section_id: sectionId,
      program: ProgramId,
      year: year,
      month: month,
    };
    getReport(data);
  };

  // error handel
  const [ErrorGrade, setErrorGrade] = useState(null);
  useEffect(() => {
    setErrorGrade(report?.error?.grade);
  }, [report?.error?.grade]);
  const [ErrorSection, setErrorSection] = useState(null);
  useEffect(() => {
    setErrorSection(report?.error?.section_id);
  }, [report?.error?.section_id]);
  const [ErrorProgram, setErrorProgram] = useState(null);
  useEffect(() => {
    setErrorProgram(report?.error?.program);
  }, [report?.error?.program]);
  const [ErrorYear, setErrorYear] = useState(null);
  useEffect(() => {
    setErrorYear(report?.error?.year);
  }, [report?.error?.year]);
  const [ErrorMonth, setErrorMonth] = useState(null);
  useEffect(() => {
    setErrorMonth(report?.error?.month);
  }, [report?.error?.month]);
  const [ErrorShift, setErrorShift] = useState(null);
  useEffect(() => {
    setErrorShift(report?.error?.shift_id);
  }, [report?.error?.shift_id]);

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <Heading title={<FormattedMessage id='dashboardReport' />} />
        <div className='container mx-auto'>
          <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
            <div className='border border-gray-300 w-full rounded p-10'>
              <div className='grid grid-cols-3 gap-4 pb-8 items-center'>
                {/* <!-- class  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                    {' '}
                    <FormattedMessage id='class' />
                  </label>
                  <select
                    value={gradeId}
                    onChange={handelSelectClass}
                    id='class'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                    {classList &&
                      classList?.map((classes, index) => {
                        return (
                          <option key={index} value={classes.grade}>
                            {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorGrade && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorGrade}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- shift  --> */}
                {hasShift?.data?.has_shift === 'yes' && (
                  <div className=' xl:w-full flex flex-col'>
                    <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                      {' '}
                      <FormattedMessage id='shift' />
                    </label>
                    <select
                      value={shiftId && shiftId}
                      onChange={handelSelectShift}
                      id='shift'
                      className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                      {shiftArray &&
                        shiftArray?.map((shift, index) => {
                          return (
                            <option value={shift.shift_id} key={index}>
                              {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                            </option>
                          );
                        })}
                    </select>
                    {ErrorShift && (
                      <p>
                        {' '}
                        <small className='text-red-700'>{ErrorShift}</small>{' '}
                      </p>
                    )}
                  </div>
                )}
                {/* <!-- section  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                    {' '}
                    <FormattedMessage id='section' />
                  </label>
                  <select
                    value={sectionId && sectionId}
                    onChange={handelSelectSection}
                    id='section'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                    {sectionArray &&
                      sectionArray?.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorSection && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorSection}</small>{' '}
                    </p>
                  )}
                </div>

                {/* <!-- year   --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='year'>
                    {' '}
                    <FormattedMessage id='year' />
                  </label>
                  <select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    id='year'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                    {years?.map((year) => (
                      <option value={year}>{defaultLanguage === 'en' ? year : toBnNumber(year)}</option>
                    ))}
                  </select>
                  {ErrorYear && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorYear}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- month   --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='month'>
                    {' '}
                    <FormattedMessage id='month' />
                  </label>
                  <select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    id='month'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectMonth' })}</option>
                    {months?.map((month) => (
                      <option value={month.id}>{defaultLanguage === 'en' ? month.name_en : month.name_bn}</option>
                    ))}
                  </select>
                  {ErrorMonth && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorMonth}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- programs   --> */}
                <div className={`xl:w-full flex flex-col`}>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='programs'>
                    <FormattedMessage id='programs' />
                  </label>
                  <select
                    onChange={handelProgram}
                    value={ProgramId}
                    id='programs'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectPrograms' })}</option>
                    {programList &&
                      programList?.map((program, index) => {
                        return (
                          <option value={program.id} key={index}>
                            {intl.formatMessage({ id: program.name })}
                            {/* {program.name} */}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorProgram && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorProgram}</small>{' '}
                    </p>
                  )}
                </div>
                <div className=' flex flex-col col-end-4 col-span-0'>
                  <button
                    disabled={IsButtonDisabled}
                    title={`${
                      IsButtonDisabled
                        ? 'Select all the field to enable this Button'
                        : 'Select all the field to enable button'
                    }`}
                    onClick={handleSubmit}
                    className={`py-2 px-6 capitalize text-[14px] lg:text-[18px] mt-4 rounded ${
                      IsButtonDisabled
                        ? ' bg-gray-300 text-black '
                        : ' hover_gradient bg-gradient hover:text-white text-white'
                    }`}
                  >
                    <FormattedMessage id='filter' />
                  </button>
                </div>

                <div className='col-span-3  text-center mt-6'>
                  {report && report.message && (
                    <div className=' flex flex-col text-center justify-center'>
                      <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                        {report.message}
                      </h1>
                    </div>
                  )}
                </div>
              </div>
              {/* show result here start */}
              {report && report?.data?.length > 0 && (
                <React.Fragment>
                  {report?.data[0]?.others?.program === 'nutrition_education' && (
                    <DashboardNutrition title='Nutrition Education' report={report} />
                  )}
                  {report?.data[0]?.others?.program === 'wifas' && <DashboardWifa title='Wifa' report={report} />}
                  {report?.data[0]?.others?.program === 'dewormings' && (
                    <DashboardNutrition title='Deworming' report={report} />
                  )}
                  {report?.data[0]?.others?.program === 'bmis' && (
                    <React.Fragment>
                      <DashboardNutrition title='Bmi' report={report} />
                      <div className='mt-20 w-full'>
                        <DashboardBmiResult report={report} />
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {/* show result here end */}
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    // studentList     : state.ProgramStudentList,
    // studentMetaData : state.ProgramStudentList?.data?.meta,
    programList: state.programList,
    // weekNumber      : state.weeks?.data?.week,
    // stockList       : state.stockList,

    // check is shift yes or not
    hasShift: state.login?.currentUser,
    report: state.report,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getProgramList: () => dispatch(getReportProgramListAction()),
    // getStudentList: (studentInfo) => dispatch(getDewormingProgramStudentListAction(studentInfo)),
    getReport: (dateInfo) => dispatch(getDashboardDataAction(dateInfo)),
    getDashboardDataRequest: () => dispatch(getDashboardDataRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
