import {
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILED,
} from '../constance/passwordChange.constants';

const initialValues = {
  status: true,
  loading: false,
  data: [],
  error: '',
  message: '',
};

export const changePasswordReducer = (state = initialValues, action) => {
  switch (action.type) {
    case PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        status: true,
        loading: false,
        message: '',
        error: '',
        data: [],
      };
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        loading: false,
        data: action.payload,
        message: action.payload.message,
        error: action.payload.error,
      };
    case PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        loading: true,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
