import React from 'react';
const ChartResultList = ({ title = 'title', totalNumber = '0', percentage = '0' }) => {
  return (
    <React.Fragment>
      <div className='w-full md:w-1/2 mx-auto'>
        <div className="relative after:content-['.......................................'] after:ml-0.5 after:absolute after:top-0 after:left-1/2 after:-translate-x-1/2 after:text-black">
          <div className='flex justify-between  '>
            <span className='flex justify-between grow-1 text-base md:text-xl capitalize text-black font-semibold md:font-bold '>
              {title}
            </span>
            <span className=' grow-1 text-base md:text-xl capitalize text-black font-semibold md:font-bold'>
              {/* {`${totalNumber &&totalNumber}  (${percentage &&percentage}%)`}  */}
              {`${totalNumber && totalNumber}`}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ChartResultList;
