import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { connect } from 'react-redux';
import LanguageContext from '../context/LanguageContext';
import { languageChangeAction } from '../redux';

const LanguageButton = ({ lang, getLanguageChange }) => {
  const { defaultLanguage, changeLanguage } = useContext(LanguageContext);
  const [isEng, setIsEng] = useState(true);

  const handelChangeLanguage = () => {
    // console.log(isEng);
    if (isEng) {
      changeLanguage('bn');
    } else {
      changeLanguage('en');
    }
    setIsEng(!isEng);

    // window.localStorage.setItem('isEng', !isEng);
    getLanguageChange(JSON.parse(!isEng));

    // setTimeout(() => {
    //   setIsEng(!isEng);
    // }, 500);

    // setTimeout(() => {
    //   console.log(isEng);
    // }, 1000);
  };

  useEffect(() => {
    if (defaultLanguage === 'en') {
      setIsEng(true);
    } else if (defaultLanguage === 'bn') {
      setIsEng(false);
    } else {
      setIsEng(true);
    }
    // console.log(defaultLanguage);
  }, [defaultLanguage]);

  // useEffect(() => {
  //   //   window.localStorage.setItem('isEng', isEng);
  //   // console.log(typeof isEng, isEng);
  //   if (isEng) {
  //     changeLanguage('en');
  //   } else {
  //     changeLanguage('bn');
  //   }
  // }, [isEng]);

  return (
    <React.Fragment>
      <div className='w-12 mx-2'>
        <div className='flex items-center justify-center'>
          <div className='relative cursor-pointer' onClick={handelChangeLanguage}>
            <div
              className={`w-12 h-5 bg-green-600 rounded-full shadow-inner flex  items-center ${
                isEng ? 'justify-start' : 'justify-end'
              } `}
            >
              {isEng ? (
                <React.Fragment>
                  <span className='capitalize text-white text-[10px] p-1'>বাং</span>
                  <span className='capitalize text-green-600 text-[10px] p-1 flex items-center justify-center w-7 h-7 bg-white rounded-full shadow  -top-1 transition'>
                    eng
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className='capitalize text-green-600 text-[10px] p-1 flex items-center justify-center w-7 h-7 bg-white rounded-full shadow  -top-1 transition'>
                    বাং
                  </span>
                  <span className='capitalize text-white text-[10px] p-1'>eng</span>
                </React.Fragment>
              )}
            </div>
            {/* {console.log(typeof isEng, isEng)} */}
            {/* <div
							className={` absolute flex items-center justify-center w-7 h-7 bg-white rounded-full shadow  -top-1 transition ${
								language ? '-right-1' : '-left-1'
							}`}
						>
							<span className='capitalize text-[10px]'>
								{language ? 'eng' : 'বাং'}
							</span>
						</div> */}
          </div>
        </div>
        {/* <!-- end toggle button  --> */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.lang?.isEng,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguageChange: (language) => dispatch(languageChangeAction(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageButton);
