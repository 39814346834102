import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import StoreContext from '../../../context/StoreContext';
import { getLoggedInUser, postShiftCheckAction } from '../../../redux';
import RadioInput from './RadioInput';

const CheckShiftModal = ({
  message = 'add required message !',
  onClose,
  onDelete,
  onCancel,
  postShiftStatus,
  getLoggedInUser,
  IsShiftStatusOpen,
  setIsShiftStatusOpen,
  userMessage,
}) => {
  const { getClassList } = useContext(StoreContext);
  const [shiftStatus, setShiftStatus] = useState('');
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  console.log('🚀 ~ file: CheckShiftModal.jsx ~ line 11 ~ CheckShiftModal ~ shiftStatus', shiftStatus);
  const handelChangeShift = (e) => {
    const { name, value } = e.target;
    setShiftStatus(value);
  };
  const onSubmit = () => {
    if (!shiftStatus) {
      alert('Please select an option.');
      return;
    }

    const data = {
      institution_id: institutionId,
      has_shift: shiftStatus,
    };
    // console.log('🚀 ~ file: CheckShiftModal.jsx ~ line 31 ~ onSubmit ~ data', data);
    postShiftStatus(data, () => {
      getClassList();
      getLoggedInUser();
      setTimeout(() => {
        setIsShiftStatusOpen(false);
      }, 2000);
    });
    // if (data.has_shift==='yes' || data.has_shift==='no') {
    // }
  };
  return (
    <React.Fragment>
      <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
        <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
          <div className='flex justify-between'>
            <h1 className=' text-black font-semibold text-xl capitalize'>
              Institution <span className='font-bold'>shift</span> Status
            </h1>
            <span className=' cursor-pointer hover:text-green-600'>
              <FontAwesomeIcon icon={faXmark} onClick={() => setIsShiftStatusOpen(!IsShiftStatusOpen)} />
            </span>
          </div>
          {/* <!-- horizontal line  --> */}
          <div className=' my-2 border border-gray-300 '></div>
          {/* <!-- update form start  --> */}
          <div className='w-full pt-5 pb-5'>
            {/* <!-- login forms  --> */}
            <div className='flex justify-center  flex-col  space-y-5  px-4'>
              {/* <!-- delete message  --> */}
              <div className='  mx-auto  py-10'>
                <RadioInput
                  name='check_shift'
                  label='yes, has shift'
                  id='has_shift'
                  value='yes'
                  type='radio'
                  onChange={handelChangeShift}
                />
                <RadioInput
                  name='check_shift'
                  label='no, shift'
                  id='no_shift'
                  value='no'
                  type='radio'
                  onChange={handelChangeShift}
                />
              </div>

              {/* <!-- button  --> */}
              <div className=' text-center w-full flex  space-x-5'>
                <button
                  onClick={() => setIsShiftStatusOpen(!IsShiftStatusOpen)}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
                >
                  <span className=''> cancel </span>
                </button>

                <button
                  onClick={onSubmit}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=''>continue</span>
                </button>
              </div>

              {/* <!-- submit success-fully  --> */}
              {userMessage && userMessage?.message && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {userMessage?.message}
                  </h1>
                </div>
              )}
              {userMessage && userMessage?.error && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-500'>
                    {userMessage?.error?.has_shift}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    classData: state.upgradedClassState,
    checkShift: state.login?.currentUser,
    // // check is shift yes or not
    userMessage: state.shiftStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getClasses: () => dispatch(getClassList()),
    postShiftStatus: (dataInfo, callback) => dispatch(postShiftCheckAction(dataInfo, callback)),
    getLoggedInUser: () => dispatch(getLoggedInUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckShiftModal);
