import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import LogInPopup from '../Components/LogInPopup';
import { getLoggedInUser } from '../redux';

const Layout = ({ children, getLoggedInUser }) => {
  //NOTE: if modal open is home page
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.localStorage.setItem('isModalOpen', isModalOpen);
  }, [isModalOpen]);

  // check if user loggedin or not
  const [userLoggedIn] = useState(() => window.localStorage.token);

  // check login/logout
  useEffect(() => {
    if (userLoggedIn) {
      getLoggedInUser();
    }
  }, [getLoggedInUser, userLoggedIn]);

  return (
    <React.Fragment>
      {isModalOpen && isModalOpen ? <LogInPopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} /> : null}

      <Header setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <div className='manik min-h-screen'>{children}</div>

      <Footer setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.login,
    isEng: state.lang?.isEng,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoggedInUser: () => dispatch(getLoggedInUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
