// get all programs
export const GET_PROGRAMS_REQUEST = 'GET_PROGRAMS_REQUEST';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';
export const GET_PROGRAMS_FAILED = 'GET_PROGRAMS_FAILED';

// *nutrition education
export const POST_NUTRITION_EDUCATION_REQUEST = 'POST_NUTRITION_EDUCATION_REQUEST';
export const POST_NUTRITION_EDUCATION_SUCCESS = 'POST_NUTRITION_EDUCATION_SUCCESS';
export const POST_NUTRITION_EDUCATION_FAILED = 'POST_NUTRITION_EDUCATION_FAILED';
export const FILTER_NUTRITION_EDUCATION_REQUEST = 'FILTER_NUTRITION_EDUCATION_REQUEST';
export const FILTER_NUTRITION_EDUCATION_SUCCESS = 'FILTER_NUTRITION_EDUCATION_SUCCESS';
export const FILTER_NUTRITION_EDUCATION_FAILED = 'FILTER_NUTRITION_EDUCATION_FAILED';

// *nutrition wifa
export const POST_NUTRITION_WIFA_REQUEST = 'POST_NUTRITION_WIFA_REQUEST';
export const POST_NUTRITION_WIFA_SUCCESS = 'POST_NUTRITION_WIFA_SUCCESS';
export const POST_NUTRITION_WIFA_FAILED = 'POST_NUTRITION_WIFA_FAILED';
export const FILTER_NUTRITION_WIFA_REQUEST = 'FILTER_NUTRITION_WIFA_REQUEST';
export const FILTER_NUTRITION_WIFA_SUCCESS = 'FILTER_NUTRITION_WIFA_SUCCESS';
export const FILTER_NUTRITION_WIFA_FAILED = 'FILTER_NUTRITION_WIFA_FAILED';

// *nutrition Deworming
export const POST_NUTRITION_DEWORMING_REQUEST = 'POST_NUTRITION_DEWORMING_REQUEST';
export const POST_NUTRITION_DEWORMING_SUCCESS = 'POST_NUTRITION_DEWORMING_SUCCESS';
export const POST_NUTRITION_DEWORMING_FAILED = 'POST_NUTRITION_DEWORMING_FAILED';
export const FILTER_NUTRITION_DEWORMING_REQUEST = 'FILTER_NUTRITION_DEWORMING_REQUEST';
export const FILTER_NUTRITION_DEWORMING_SUCCESS = 'FILTER_NUTRITION_DEWORMING_SUCCESS';
export const FILTER_NUTRITION_DEWORMING_FAILED = 'FILTER_NUTRITION_DEWORMING_FAILED';

// *BMI CALCULATION
export const BMI_CALCULATION_REQUEST = 'BMI_CALCULATION_REQUEST';
export const BMI_CALCULATION_SUCCESS = 'BMI_CALCULATION_SUCCESS';
export const BMI_CALCULATION_FAILED = 'BMI_CALCULATION_FAILED';

// *BMI TABLET
export const GET_TABLET_STOCK_REQUEST = 'GET_TABLET_STOCK_REQUEST';
export const GET_TABLET_STOCK_SUCCESS = 'GET_TABLET_STOCK_SUCCESS';
export const GET_TABLET_STOCK_FAILED = 'GET_TABLET_STOCK_FAILED';
export const ADD_TABLET_STOCK_REQUEST = 'ADD_TABLET_STOCK_REQUEST';
export const ADD_TABLET_STOCK_SUCCESS = 'ADD_TABLET_STOCK_SUCCESS';
export const ADD_TABLET_STOCK_FAILED = 'ADD_TABLET_STOCK_FAILED';

// * REFERRAL
export const GET_REFERRAL_REQUEST = 'GET_REFERRAL_REQUEST';
export const GET_REFERRAL_SUCCESS = 'GET_REFERRAL_SUCCESS';
export const GET_REFERRAL_FAILED = 'GET_REFERRAL_FAILED';

// REFERRAL DATA POST
export const POST_REFERRAL_REQUEST = 'POST_REFERRAL_REQUEST';
export const POST_REFERRAL_SUCCESS = 'POST_REFERRAL_SUCCESS';
export const POST_REFERRAL_FAILED = 'POST_REFERRAL_FAILED';

// GET REFERRAL DATA FOR DOWNLOAD PDF
export const REFERRAL_PDF_DATA_REQUEST = 'REFERRAL_PDF_DATA_REQUEST';
export const REFERRAL_PDF_DATA_SUCCESS = 'REFERRAL_PDF_DATA_SUCCESS';
export const REFERRAL_PDF_DATA_FAILED = 'REFERRAL_PDF_DATA_FAILED';
