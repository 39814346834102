import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import InputField from '../../../Components/InputField';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import StoreContext from '../../../context/StoreContext';
import { createSectionListAction } from '../../../redux';
import { toast } from 'react-toastify';

const SectionCreatePopUp = ({
  hasShift,
  errMessage,
  sectionLists,
  createSectionLists,
  title = 'set title',
  message = 'add required message !',
  data,
  onDelete,
  onCancel,
  handelModalClose,
}) => {
  // console.log("🚀 ~ file: SectionCreatePopUp.jsx ~ line 22 ~ handelModalClose", handelModalClose)
  const intl = useIntl();
  const { getClassList } = useContext(StoreContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));

  const [className, setClassName] = useState('');
  const [shift, setShift] = useState('');
  const [grade, setGrade] = useState('');
  const [shiftId, setShiftId] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShiftExist, setIsShiftExist] = useState('');
  useEffect(() => {
    setClassName(hasShift?.data?.has_shift === 'yes' ? data.class_name_en : data.name_en);
    setShift(data.name_en === undefined ? data?.shift_name_en : data.name_en);
    setGrade(data.grade === undefined ? data?.grade : data?.grade);
    setShiftId(data.id === undefined ? data?.shift_id : data.id);
    setIsShiftExist(data.has_shift);
  }, [data.class_name_en, data.grade, data.has_shift, data.id, data.name_en]);

  const sectionName = {
    name_en: '',
    name_bn: '',
  };
  const [sectionNames, setSectionNames] = useState(sectionName);
  const [formError, setFormError] = useState({});
  const [postData, setPostData] = useState({});

  const handelFormChange = (e) => {
    const { name, value } = e.target;
    setSectionNames({ ...sectionNames, [name]: value });
  };

  function hasSpecialCharacters(input) {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?1234567890১২৩৪৫৬৭৮৯০]/;
    return regex.test(input);
  }

  const onSubmit = () => {
    let data = {
      institution_id: institutionId,
      grade: grade,
      has_shift: hasShift?.data?.has_shift,

      name_en: sectionNames.name_en,
      name_bn: sectionNames.name_bn,
    };

    if (hasShift?.data?.has_shift === 'yes') {
      data['shift_id'] = shiftId;
    }

    if (hasSpecialCharacters(sectionNames.name_en) || hasSpecialCharacters(sectionNames.name_bn)) {
      toast.error('Section name can not be numbers or special characters.');
      return;
    }

    setFormError(validateForm(data));
    setPostData(data);
    setIsSubmit(true);
  };
  const validateForm = (data) => {
    const error = {};

    if (!data.name_en || data.name_en === '0') {
      error.name_en = true;
    }
    if (!data.name_bn || data.name_bn === '0' || data.name_bn <= 0) {
      error.name_bn = true;
    }

    return error;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      createSectionLists(postData, getClassList);
      //   setTimeout(() => {
      //     getClassList();
      //   }, 2000);
    }
  }, [createSectionLists, formError, isSubmit, postData]);

  const [errEnName, setErrEnName] = useState(null);
  const [errBnName, setErrBnName] = useState(null);
  useEffect(() => {
    setErrEnName(sectionLists?.error?.name_en);
  }, [sectionLists?.error?.name_en]);
  useEffect(() => {
    setErrBnName(sectionLists?.error?.name_bn);
  }, [sectionLists?.error?.name_bn]);

  const enErrMsg = errEnName && (
    <p>
      {' '}
      <small className='text-red-700'>{errEnName}</small>{' '}
    </p>
  );
  const bnErrMsg = errBnName && (
    <p>
      {' '}
      <small className='text-red-700'>{errBnName}</small>{' '}
    </p>
  );

  useEffect(() => {
    if (sectionLists?.message === 'Section Save Successfully') {
      setTimeout(function () {
        handelModalClose();
      }, 2000);
    }
  }, [handelModalClose, sectionLists?.message]);

  return (
    <React.Fragment>
      <div className='p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
        <div className='p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
          <div className='flex justify-between'>
            <h1 className=' text-black font-semibold text-xl capitalize'>{title}</h1>
            <span className='cursor-pointer hover:text-green-600'>
              <FontAwesomeIcon icon={faXmark} onClick={onCancel} />
            </span>
          </div>
          {/* <!-- horizontal line  --> */}
          <div className=' my-2 border border-gray-300 '></div>
          {/* <!-- update form start  --> */}
          <div className='w-full pt-5 pb-5'>
            {/* <!-- login forms  --> */}
            <div className='flex justify-center  flex-col space-y-5 px-4'>
              {/* <!-- delete message  --> */}
              <div className=''>
                <InputField
                  label={intl.formatMessage({ id: 'class' })}
                  readOnly={true}
                  value={className}
                  name='grade'
                  type='text'
                />
              </div>
              {hasShift?.data?.has_shift === 'yes' && (
                <div className=''>
                  <InputField
                    label={intl.formatMessage({ id: 'shift' })}
                    readOnly={true}
                    value={shift}
                    name='shift'
                    type='text'
                  />
                </div>
              )}
              <div className=''>
                <InputField
                  label={intl.formatMessage({ id: 'enterSectionEn' })}
                  value={sectionNames.name_en}
                  placeholder='A'
                  name='name_en'
                  type='text'
                  onChange={handelFormChange}
                />
                {formError.name_en && <p className=' text-red-500'>This field is required!</p>}
                {enErrMsg}
              </div>
              <div className=''>
                <InputField
                  label={intl.formatMessage({ id: 'enterSectionBn' })}
                  value={sectionNames.name_bn}
                  placeholder='ক'
                  name='name_bn'
                  type='text'
                  onChange={handelFormChange}
                />
                {formError.name_bn && <p className=' text-red-500'>This field is required!</p>}
                {bnErrMsg}
              </div>

              {/* <!-- button  --> */}
              <div className=' text-center w-full flex  space-x-5'>
                <button
                  onClick={onCancel}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
                >
                  <span className=''>
                    {' '}
                    <FormattedMessage id='cancel' />{' '}
                  </span>
                </button>

                <button
                  onClick={onSubmit}
                  type='button'
                  className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                >
                  <span className=''>
                    <FormattedMessage id='submit' />
                  </span>
                </button>
              </div>

              {/* <!-- submit success-fully  --> */}
              {/* {sectionLists && sectionLists.loading && <LoadingSpinner/>} */}
              {/* {sectionLists && sectionLists.error ? (
								<div className=' flex flex-col text-center justify-center'>
								<h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-500'>
									{sectionLists.error}
								</h1>
							</div>
							):(
							)} */}
              {sectionLists && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {sectionLists?.message}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    sectionLists: state.sectionLists,
    errMessage: state.sectionLists?.error || null,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSectionLists: (listInfo, getClassList) => dispatch(createSectionListAction(listInfo, getClassList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCreatePopUp);
