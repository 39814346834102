import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import './teacherstyle.css';
import logo from '../../assets/image/logo/logo.png';
const ImageUpload = ({ inputFile, avatar }) => {
  return (
    <React.Fragment>
      <div className=''>
        <div className='relative w-52 h-52 m-auto'>
          <div className='profile_image  top-0  ml-auto mr-auto'>
            <div className='image_input_overlay'></div>
            {avatar && avatar ? <img src={avatar} alt='' /> : <img src={logo} alt='' />}
            <span className='camera_icon'>
              <FontAwesomeIcon icon={faCamera} />
            </span>
            <input className='image_input' onChange={inputFile} type='file' accept='image/png, image/jpeg' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImageUpload;
