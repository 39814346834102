import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileBreadCrumb from '../../../Components/BreadCrumb/ProfileBreadCrumb';
import BreadCrumbImage from '../../../assets/image/breadcrumb/breadcrumb.png';
import Layout from '../../../layout/Layout';
import { getTeacherProfileAction } from '../../../redux';
import logo from '../../../assets/image/logo/logo.png';
import { FormattedMessage } from 'react-intl';
import LanguageContext from '../../../context/LanguageContext';
import { toBnNumber } from '../../../common/functions';
const TeacherProfilePage = ({ teacherProfile, profileData, hasShift }) => {
  const navigate = useNavigate();
  const { defaultLanguage } = useContext(LanguageContext);
  // const {name,nid,photo,class_name_en,shift_name_en,section_name_en}=profileData?.data
  const teacherData = useLocation();
  // console.log('🚀 ~ file: TeacherProfilePage.jsx ~ line 11 ~ TeacherProfilePage ~ teacherData', teacherData.state);
  const { institution_id, id } = teacherData.state;

  useEffect(() => {
    const data = {
      institution_id: institution_id,
      teacher_id: id,
    };
    // teacherProfile(data);
  }, [id, institution_id, teacherProfile]);

  // go to edit page
  const goToEditPage = (profileData) => {
    navigate(`/teacher-list/edit-teacher`, { state: profileData });
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      {/* profile breadcrumb start */}
      <ProfileBreadCrumb title={<FormattedMessage id='teacherProfile' />} image={BreadCrumbImage} />
      {/* profile breadcrumb end */}
      <section className='mx-auto bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
        <div className='container flex flex-col space-y-10 justify-center mx-auto lg:w-1/2 xl:w-2/4 2xl:w-1/3'>
          {/* <!-- login forms  --> */}
          <div className='flex justify-center  flex-col  space-y-5   border border-green-200 p-8 shadow-md rounded'>
            {/* <!-- heading  --> */}
            <div className='justify-center text-center flex flex-col items-center'>
              <img
                className='student_profile_image mr-2 w-36 h-30 rounded-full p-[2px] border border-green-600'
                src={`${teacherData?.state?.photo !== null ? teacherData?.state?.photo : logo}`}
                alt='profile avatar'
              />
              <div className=''>
                <h1 className=' text-black font-semibold capitalize text-2xl lg:text-4xl'>
                  {teacherData?.state?.name && teacherData?.state?.name}
                </h1>
                <p className=' text-base capitalize text-gray-700'>
                  <FormattedMessage id='nidNo' />:{' '}
                  <span>
                    {teacherData && teacherData?.state && teacherData?.state?.nid && (
                      <>{defaultLanguage === 'en' ? teacherData?.state?.nid : toBnNumber(teacherData?.state?.nid)}</>
                    )}
                  </span>
                </p>
              </div>
            </div>

            {/* <!-- student class --> */}
            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='class' />
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
							focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {/* {teacherData?.state?.class_name_en && teacherData?.state?.class_name_en} */}
                {defaultLanguage === 'en' ? teacherData?.state?.class_name_en : teacherData?.state?.class_name_bn}
              </div>
            </div>
            {/* shift  */}
            {hasShift?.data?.has_shift === 'yes' && (
              <div className=' xl:w-full flex flex-col'>
                <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                  <FormattedMessage id='shift' />
                </div>
                <div
                  className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
									focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
                >
                  {/* {teacherData?.state?.shift_name_en === null ? 'Not Assign' : teacherData?.state?.shift_name_en} */}
                  {defaultLanguage === 'en' ? teacherData?.state?.shift_name_en : teacherData?.state?.shift_name_bn}
                </div>
              </div>
            )}

            <div className=' xl:w-full flex flex-col'>
              <div className='text-[#212529] capitalize text-[12px] lg:text-[14px]'>
                <FormattedMessage id='section' />
              </div>
              <div
                className='form-select capitalize block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-100 bg-clip-padding bg-no-repeat border border-solid border-green-600 rounded transition ease-in-out m-0
                                focus:text-gray-700 focus:bg-gray-100 focus:border-green-600 focus:outline-none'
              >
                {/* {teacherData?.state?.section_name_en === null ? 'Not Assign' : teacherData?.state?.section_name_en} */}
                {defaultLanguage === 'en' ? teacherData?.state?.section_name_en : teacherData?.state?.section_name_bn}
              </div>
            </div>

            {/* <!-- button  --> */}
            <div className='flex space-x-5 text-center w-full'>
              <button
                onClick={() => navigate(-1)}
                type='button'
                className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded  border border-green-500 hover:border-green-500 hover:text-white text-black hover_gradient '
              >
                <span className=''>
                  <FormattedMessage id='back' />
                </span>
              </button>
              <button
                onClick={() => goToEditPage(teacherData?.state)}
                type='button'
                className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
              >
                <span className=''>
                  <FormattedMessage id='editProfile' />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.teacherProfile?.data,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    teacherProfile: (dataInfo) => dispatch(getTeacherProfileAction(dataInfo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TeacherProfilePage);
