import React from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toBnNumber } from '../../../common/functions';
import LanguageContext from '../../../context/LanguageContext';
import { shiftNameConverter } from '../../../redux/service/service';

const SingleShift = ({ data, hasShift }) => {
  const navigate = useNavigate();
  const { defaultLanguage } = useContext(LanguageContext);
  const { grade, name_bn, shift_name_en, total_section, total_student, shift_id, shift_name_bn } = data;
  // console.log('🚀 ~ file: SingleShift.jsx ~ line 8 ~ SingleShift ~ data', data);

  const gotoNextPage = () => {
    const localStorageData = localStorage.getItem('sectionInfo');
    if (localStorageData) {
      const parseLs = JSON.parse(localStorageData);
      // console.log(parseLs);
      // console.log(data);
      const lSData = {
        grade: parseLs?.grade,
        shift_id: data?.shift_id,
        class_name_en: parseLs?.class_name_en,
        class_name_bn: parseLs?.class_name_bn,
        has_shift: hasShift?.data?.has_shift,
        shift_name_bn: data?.shift_name_bn,
        shift_name_en: data?.shift_name_en,
      };

      // console.log(lSData);

      localStorage.setItem('sectionInfo', JSON.stringify(lSData));
      navigate(`/classes/shift-list/${shiftNameConverter(shift_id)}`, { state: lSData });
    }
  };

  return (
    <React.Fragment>
      <div
        onClick={gotoNextPage}
        className={`single-shift bg-gradient-to-r from-[#22721A] to-[#79B14D] w-full border border-green-600 p-4 flex justify-between items-center rounded shadow-lg`}
      >
        <div className='text-white'>
          <h1 className='text-base font-semibold md:text-lg md:font-bold capitalize text-white'>
            {defaultLanguage === 'en' ? shift_name_en : shift_name_bn}
          </h1>
          <div className=' flex space-x-6 capitalize'>
            <span>
              <FormattedMessage id='students' />: {defaultLanguage === 'en' ? total_student : toBnNumber(total_student)}
            </span>
            <span>
              <FormattedMessage id='section' />: {defaultLanguage === 'en' ? total_section : toBnNumber(total_section)}
            </span>
          </div>
        </div>
        <div
          onClick={gotoNextPage}
          // to={`/classes/${shiftNameConverter(id)}`}
          // state={data}
        >
          <button
            type='button'
            className='shadow-md bg-[#79B14D] py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white hover:text-white '
          >
            <span className=''>
              <FormattedMessage id='view' />
            </span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    hasShift: state.login?.currentUser,
  };
};

export default connect(mapStateToProps)(SingleShift);
