import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import facebook from '../assets/image/media-icons/logo-fb-simple.png';
import instagram from '../assets/image/media-icons/logo-instagram.png';
import twitter from '../assets/image/media-icons/logo-twitter.png';
const Footer = ({ auth, setIsModalOpen, isModalOpen }) => {
  return (
    <React.Fragment>
      <footer className='footer mx-auto bg-green-50 border-t-2 border-green-600 mt-0'>
        <div className='container mx-auto px-4 py-4 md:py-0'>
          <div className='flex flex-row flex-wrap md:flex-nowrap space-x-3'>
            {/*  footer column  */}
            <div className='basis-3/3 md:basis-1/3 lg:basis-2/3 py-3'>
              <h1 className='capitalize text-black mb-2 text-base'>
                <strong>
                  <FormattedMessage id='goTo' />
                </strong>
              </h1>

              <ul className='m-0 p-0 flex flex-col space-y-2'>
                {auth && auth.isLoggedIn ? (
                  <React.Fragment>
                    <li>
                      <Link
                        to='/dashboard'
                        className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
                        href='$'
                      >
                        <FormattedMessage id='dashboard' />
                      </Link>
                    </li>
                    {/* <li>
											<Link
												to='/nutrition'
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												href='$'
											>
												nutritions programs
											</Link>
										</li> */}
                    <li>
                      <Link
                        className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
                        to='/learning-module'
                      >
                        <FormattedMessage id='learning-module' />
                      </Link>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li>
                      <button
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        type='button'
                        className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
                      >
                        <FormattedMessage id='dashboard' />
                      </button>
                    </li>
                    {/* <li>
											<button
												onClick={() => setIsModalOpen(!isModalOpen)}
												type='button'
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												href='$'
											>
												nutritions programs
											</button>
										</li> */}
                    <li>
                      <button
                        className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        type='button'
                      >
                        <FormattedMessage id='learning-module' />
                      </button>
                    </li>
                  </React.Fragment>
                )}

                <li>
                  <Link className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize' to='/faq'>
                    <FormattedMessage id='faq' />
                  </Link>
                </li>
              </ul>
            </div>
            {/* footer column  */}
            <div className='basis-3/3 md:basis-1/3 lg:basis-1/3 py-3'>
              {/* <h1 className='capitalize text-black mb-2 text-base'>
								<strong>go to</strong>
							</h1>

							<ul className='m-0 p-0 flex flex-col space-y-2'>
								{auth && auth.isLoggedIn ? (
									<React.Fragment>
										<li>
											<Link
												to='/dashboard'
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												href='$'
											>
												dashboard
											</Link>
										</li>
										<li>
											<Link
												to='/nutrition/nutrition-education'
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												href='$'
											>
												nutritions programs
											</Link>
										</li>
										<li>
											<Link
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												to='/learning-module'
											>
												learning module
											</Link>
										</li>
									</React.Fragment>
								) : (
									<React.Fragment>
										<li>
											<button
												onClick={() => setIsModalOpen(!isModalOpen)}
												type='button'
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
											>
												dashboard
											</button>
										</li>
										<li>
											<button
												onClick={() => setIsModalOpen(!isModalOpen)}
												type='button'
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												href='$'
											>
												nutritions programs
											</button>
										</li>
										<li>
											<button
												className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
												onClick={() => setIsModalOpen(!isModalOpen)}
												type='button'
											>
												learning module
											</button>
										</li>
									</React.Fragment>
								)}

								<li>
									<Link
										className='text-[14px] text-gray-600 hover:underline hover:text-black capitalize'
										to='/faq'
									>
										faq
									</Link>
								</li>
							</ul> */}
            </div>
            {/* footer column   */}
            <div className='basis-3/3 md:basis-1/3 lg:basis-1/3 py-3'>
              <h1 className='capitalize text-black mb-2 text-base'>
                <strong>
                  <FormattedMessage id='followUs' />
                </strong>
              </h1>
              <p className='text-[14px] text-gray-700'>{/* Lorem ipsum dolor sit, amet consectetur */}</p>
              <div className='flex space-x-5 '>
                <a href='/#' target='_blank' rel='noopener noreferrer' className='mt-4'>
                  <img src={facebook} className='w-full h-full ' alt='facebook logo' />
                </a>
                <a href='/#' target='_blank' rel='noopener noreferrer' className='mt-4'>
                  <img src={instagram} className='w-full h-full ' alt='instagram logo' />
                </a>
                <a href='/#' target='_blank' rel='noopener noreferrer' className='mt-4'>
                  <img src={twitter} className='w-full h-full ' alt='twitter logo' />
                </a>
              </div>
            </div>
          </div>
          {/* NOTE: developer address */}
          <div className='flex justify-center items-center py-5'>
            <p className='capitalize text-[14px] text-gray-700'>
              <FormattedMessage id='designedDeveloped' />
              <span className='text-orange-500'>
                <strong>
                  <a href='https://riseuplabs.com/' target='_blank' rel='noopener noreferrer'>
                    {' '}
                    <FormattedMessage id='riseupLabs' />
                  </a>
                </strong>
              </span>
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.login,
  };
};
export default connect(mapStateToProps, null)(Footer);
