import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import './BreadCrumbStyle.css';

const BreadCrumb = ({ path }) => {
  const location = useLocation();
  const { pathname } = location;

  const pathnames = pathname.split('/').filter((x) => x);

  return (
    <React.Fragment>
      <section className='mx-auto bg-[#FAFBFB] py-4 lg:py-10'>
        <div className='container mx-auto'>
          <nav className='breadcrumb flex justify-center' aria-label='Breadcrumb'>
            <ol className='inline-flex items-center'>
              <li>
                <Link
                  to='/'
                  className='ml-1 text-[16px] font-medium text-green-500 hover:text-[#22262A] md:ml-2 capitalize'
                >
                  <FormattedMessage id='nav-home' />
                </Link>
              </li>

              {pathnames.map((name, index) => {
                const routeTo = `/${pathnames?.slice(0, index + 1)?.join('/')}`;
                const isLast = index === pathnames?.length - 1;
                return isLast ? (
                  <li className='capitalize flex' key={name + index} style={{ textTransform: 'capitalize' }}>
                    {(name.includes('section') && name.includes('student-list')) ||
                    (name.includes('section') && name.includes('add-teacher')) ? (
                      <>
                        {name.split('-').map((_, i, arr) => (
                          <div key={i}>
                            {<FormattedMessage id={_} />}
                            {arr.length !== i + 1 && '-'}
                          </div>
                        ))}
                      </>
                    ) : (
                      <FormattedMessage id={name} />
                    )}
                  </li>
                ) : (
                  <li className='flex'>
                    <Link
                      key={name + index}
                      to={routeTo}
                      className='flex ml-1 text-[16px] font-medium text-green-500 hover:text-[#22262A] md:ml-2 capitalize'
                    >
                      {/* {<FormattedMessage id={name} />} */}
                      {(name.includes('section') && name.includes('student-list')) ||
                      (name.includes('section') && name.includes('add-teacher')) ? (
                        <>
                          {name.split('-').map((_, i, arr) => (
                            <div key={i}>
                              {<FormattedMessage id={_} />}
                              {arr.length !== i + 1 && '-'}
                            </div>
                          ))}
                        </>
                      ) : (
                        <FormattedMessage id={name} />
                      )}
                    </Link>
                  </li>
                );
              })}
            </ol>
          </nav>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BreadCrumb;
