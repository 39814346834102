import React, { useState } from 'react';
import BreadCrumbImage from '../../assets/image/breadcrumb/learning.png';
import { useLocation } from 'react-router-dom';
import ProfileBreadCrumb from '../../Components/BreadCrumb/ProfileBreadCrumb';
import { useEffect } from 'react';
import LanguageContext from '../../context/LanguageContext';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { scrollToTop } from '../../redux/service/service';
import axios from 'axios';
import LoadingSpinner from '../../Components/LoadingSpinner';
import EmptyList from '../../Components/EmptyList';
import { isDesktop } from 'react-device-detect';

const LearningDetailsPageV2 = () => {
  const { defaultLanguage } = useContext(LanguageContext);
  const moduleData = useLocation();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchSinglePdf(moduleData?.state?.id);
  }, [moduleData]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const fetchSinglePdf = async (id) => {
    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}module-wise/pdf`, {
        params: { id: id },
      })
      .then((resp) => {
        // console.log(resp?.data?.data);
        setIsError(false);
        setData(resp?.data?.data);
      })
      .catch((error) => {
        // console.log(error?.response);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      {/* <Layout> */}
      {/* <!-- TODO: simple breadCrumb (design 1 ) START  --> */}
      <ProfileBreadCrumb title={<FormattedMessage id='learning-module' />} image={BreadCrumbImage} />
      {/* <!-- TODO: simple breadCrumb END --> */}

      {/* details containers  */}
      {isLoading ? (
        <div className='py-10'>
          <LoadingSpinner />
        </div>
      ) : (
        <div className='bg-white pt-5 pb-7 sm:pt-8 sm:pb-12 2xl:pt-14 2xl:pb-40'>
          {isError ? (
            <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
              <div className='border border-gray-200 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
                <EmptyList message='Sorry, no pdf found.' />
              </div>
            </div>
          ) : (
            <div className='w-full sm:w-4/5 mx-auto px-4'>
              {/* <!-- title  --> */}
              <div className='flex justify-between items-center pb-5 border-b border-gray-300'>
                <span className='text-base font-semibold md:text-2xl md:font-bold text-black capitalize'>
                  {defaultLanguage === 'en' ? moduleData?.state?.title_en : moduleData?.state?.title_bn}
                </span>
                <button
                  type='button'
                  className='disabled:opacity-50 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white hover:text-white bg-gradient  hover_gradient '
                >
                  <a
                    target='_blank'
                    href={`${defaultLanguage === 'en' ? data?.pdf_en_path : data?.pdf_bn_path}`}
                    rel='noreferrer'
                  >
                    {' '}
                    <FormattedMessage id='downloadPdf' />
                  </a>
                </button>
              </div>

              {/* <!-- content  --> */}
              <div className='py-4'>
                {isDesktop ? (
                  <object
                    className='w-full h-screen'
                    data={defaultLanguage === 'en' ? data?.pdf_en_path : data?.pdf_bn_path}
                    // data='https://www.ruet.ac.bd/public/storage/notices/iees-admission-test-2023-24.pdf'
                  >
                    <p>
                      Click Here to view the PDF Document{' '}
                      <a
                        target='_blank'
                        href={`${defaultLanguage === 'en' ? data?.pdf_en_path : data?.pdf_bn_path}`}
                        rel='noreferrer'
                        className='block text-[16px] font-medium text-green-500 duration-300 hover:text-[#22262A] capitalize'
                      >
                        PDF Link
                      </a>
                    </p>
                  </object>
                ) : (
                  <iframe
                    className='w-full h-screen'
                    title='Iframe Doc'
                    src={defaultLanguage === 'en' ? data?.pdf_en_path : data?.pdf_bn_path}
                    // src='https://www.ruet.ac.bd/public/storage/notices/iees-admission-test-2023-24.pdf'
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {/* </Layout> */}
    </React.Fragment>
  );
};

export default LearningDetailsPageV2;
