import axios from 'axios';
import React, { useState, useEffect, createContext } from 'react';
import { getTokenAndId } from '../redux/service/service';

const StoreContext = createContext();

export default StoreContext;

export function StoreProvider(props) {
  const [classList, setClassList] = useState([]);
  const [classLoading, setClassLoading] = useState(true);
  const [programList, setProgramList] = useState([]);
  const [programLoading, setProgramLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [apiToken, setApiToken] = useState('');

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    if (getToken) {
      setIsLoggedIn(true);
      getClassList();
      getProgramList();
      setApiToken(JSON.parse(getToken));
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const getClassList = async () => {
    const getToken = localStorage.getItem('token');
    if (getToken) {
      let { apiUrl, config } = getTokenAndId();
      await axios
        .get(`${apiUrl}class/list`, config)
        .then((response) => {
          // console.log(response.data.data);
          setClassList(response?.data?.data);
          setClassLoading(false);
          // dispatch(getUpgradeClassListSuccess(response.data));
        })
        .catch((error) => {
          // console.log(error.response);
          if (error.response.status === 401) {
            handleLogout();
          }
          // dispatch(getUpgradeClassListFailed(error.message));
          setClassLoading(false);
        });
    }
  };

  const getProgramList = async () => {
    const getToken = localStorage.getItem('token');
    if (getToken) {
      let { apiUrl, config } = getTokenAndId();
      await axios
        .get(`${apiUrl}programs/list`, config)
        .then((response) => {
          // console.log(response.data.data);
          setProgramList(response?.data?.data);
          // dispatch(getUpgradeClassListSuccess(response.data));
          setProgramLoading(false);
        })
        .catch((error) => {
          // console.log(error.message);
          if (error.response.status === 401) {
            handleLogout();
          }
          // dispatch(getUpgradeClassListFailed(error.message));
          setProgramLoading(false);
        });
    }
  };

  const handleLogout = () => {
    const getLocalLanguage = localStorage.getItem('defaultLanguage');
    window.localStorage.clear();
    if (getLocalLanguage) {
      localStorage.setItem('defaultLanguage', getLocalLanguage);
    }
    window.location.reload();
    window.localStorage.setItem('isEng', true);
  };

  const values = {
    classList,
    getClassList,
    isLoggedIn,
    setIsLoggedIn,
    apiToken,
    setApiToken,
    programList,
    getProgramList,
    classLoading,
    programLoading,
  };

  return <StoreContext.Provider value={values}>{props.children}</StoreContext.Provider>;
}
