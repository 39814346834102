import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import ImageUpload from '../../../Components/imageUpload/ImageUpload';
import InputField from '../../../Components/InputField';
import LanguageContext from '../../../context/LanguageContext';
import StoreContext from '../../../context/StoreContext';
import {
  createSectionTeacherAction,
  getClassWiseShiftListAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
} from '../../../redux';
import { validateNid } from '../../../redux/service/service';
const AddTeacher = ({
  getClassLists,
  classLists,
  // class wise shift
  getShiftLists,
  shiftList,
  // section list
  getSectionLists,
  sectionList,
  // teacher data
  teacherData,
  addTeacher,

  hasShift,
}) => {
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList, getClassList } = useContext(StoreContext);
  const [gradeId, setGrade] = useState(null);
  const [shiftId, setShift] = useState(null);
  const [sectionId, setSection] = useState(null);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));

  const [shiftArray, setShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);

  // call api for section class list
  //   useEffect(() => {
  //     getClassLists();
  //   }, [getClassLists]);
  // shift api call
  //   useEffect(() => {
  //     const data = {
  //       institutionId: institutionId,
  //       grade: gradeId,
  //     };
  //     if (gradeId && gradeId !== '0') {
  //       getShiftLists(data);
  //     }
  //   }, [gradeId, institutionId, getShiftLists]);
  // section api call
  //   useEffect(() => {
  //     const data = {
  //       institutionId: institutionId,
  //       grade: gradeId,
  //       shift_id: hasShift?.data?.has_shift === 'yes' ? shiftId : undefined,
  //     };
  //     if (gradeId && gradeId !== '0' && institutionId !== null) {
  //       getSectionLists(data);
  //     }
  //   }, [getSectionLists, gradeId, institutionId, shiftId, hasShift]);

  // name validation
  const [teacherName, setTeacherName] = useState(null);
  // const [nameError, setNameError] = useState(false)
  const handelNameField = (e) => {
    setTeacherName(e.target.value);
  };

  // handel NID
  const [nid, setNid] = useState(null);
  const [errorNid, setErrorNid] = useState(false);
  console.log('🚀 ~ file: AddTeacher.jsx ~ line 65 ~ errorNid', errorNid);
  const handelNid = (e) => {
    setNid(e.target.value);
  };

  //handel class
  const [errorClass, setErrorClass] = useState(false);
  const handelSelectClass = (e) => {
    setGrade(e.target.value);

    setShift('');
    setSection('');

    const singleClass = classList.find((classes) => classes.grade === Number(e.target.value));

    if (hasShift?.data?.has_shift === 'yes') {
      //   getShiftLists(data);
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      //   getSectionLists(data);
      setSectionArray(singleClass?.shift_section);
    }
  };

  // handel shift
  const [shiftError, setShiftError] = useState(false);
  const handelSelectShift = (e) => {
    setShift(e.target.value);
    setSection('');
    const singleShift = shiftArray.find((shifts) => shifts.shift_id === Number(e.target.value));

    setSectionArray(singleShift?.section);
  };
  // section list
  // const [sectionError, setSectionError] = useState(false)
  const handelSelectSection = (e) => {
    setSection(e.target.value);
  };

  const navigate = useNavigate();
  const navigationData = useLocation();

  // all form data
  const [avatar, setAvatar] = useState(null);
  const [toViewAvatar, setToViewAvatar] = useState(null);
  // input file avatar for teacher
  const inputFile = (event) => {
    setToViewAvatar(URL.createObjectURL(event.target.files[0]));
    setAvatar(event.target.files[0]);
  };

  const [isSubmit, setIsSubmit] = useState(false);
  const [postData, setSetPostData] = useState({});

  const handelSubmitTeacherInfo = (e) => {
    e.preventDefault();

    let namePattern = /^[a-zA-Z. ]{2,30}$/;

    if (!namePattern.test(teacherName)) {
      toast.error('Please enter a valid name.');
      return;
    }

    const data = {
      institution_id: institutionId,
      grade: gradeId,
      shift_id: hasShift?.data?.has_shift === 'yes' ? shiftId : undefined,
      section_id: sectionId,
      teacherInd: nid,
      teacherName: teacherName,
      // photo:avatar,
    };
    // setErrorClass(validateClass(gradeId))
    // setNameError(validNameField(studentName))
    // setShiftError(validateShift(shiftId))
    // setSectionError(validateSection(sectionId))
    setErrorNid(validateNid(nid));
    setSetPostData(data);
    setIsSubmit(true);
  };

  useEffect(() => {
    if (isSubmit) {
      addTeacher(postData, avatar, getClassList);
    }
  }, [addTeacher, isSubmit, postData, avatar]);

  // error validate from server

  const [nidError, setNidError] = useState(null);
  const [photoError, setPhotoError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [gradeError, setGradeError] = useState(null);
  const [sectionError, setSectionError] = useState(null);
  useEffect(() => {
    setNidError(teacherData?.error?.nid);
  }, [teacherData?.error?.nid]);
  useEffect(() => {
    setPhotoError(teacherData?.error?.photo);
  }, [teacherData?.error?.photo]);
  useEffect(() => {
    setNameError(teacherData?.error?.name);
  }, [teacherData?.error?.name]);
  useEffect(() => {
    setGradeError(teacherData?.error?.grade);
  }, [teacherData?.error?.grade]);
  useEffect(() => {
    setSectionError(teacherData?.error?.section_id);
  }, [teacherData?.error?.section_id]);

  const msgPhoto = photoError && (
    <p>
      {' '}
      <small className='text-red-700'>{photoError}</small>{' '}
    </p>
  );

  useEffect(() => {
    if (teacherData?.message === 'Teacher Save Successfully') {
      setTimeout(() => {
        navigate('/teacher-list');
      }, 2000);
    }
  }, [navigate, teacherData?.message]);

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* <!-- teacher added  forms  --> */}
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col items-center w-full px-4 lg:w-1/2 mx-auto'>
            {/* <!-- title  --> */}
            <div className='border border-gray-300 w-full  py-4 rounded'>
              <div className='border-b border-gray-300'>
                <h1 className=' text-black font-semibold text-xl capitalize pb-4 px-4'>
                  <FormattedMessage id='add-teacher' />
                </h1>
              </div>
              {/* <!-- teacher added form  --> */}
              <div className='w-full pt-5 pb-5'>
                {/* <!--  forms  --> */}
                <form action='' onSubmit={handelSubmitTeacherInfo}>
                  <div className='flex justify-center  flex-col  space-y-5  px-4'>
                    {/* image upload  */}
                    <div className=' text-center '>
                      <ImageUpload avatar={toViewAvatar} inputFile={inputFile} />
                      {msgPhoto}
                    </div>
                    {/* <!-- teacher name --> */}
                    <div className=''>
                      <InputField
                        required
                        onChange={handelNameField}
                        label={<FormattedMessage id='teacherName' />}
                        placeholder={intl.formatMessage({ id: 'teacherNamePlaceholder' })}
                        value={teacherName}
                        type='text'
                      />
                      {nameError && (
                        <p>
                          <small className='text-red-700'>{nameError && nameError}</small>
                        </p>
                      )}
                    </div>
                    {/* teacher nid */}
                    <div className=''>
                      <InputField
                        onChange={handelNid}
                        required
                        name='nid'
                        placeholder={intl.formatMessage({ id: 'teacherNid' })}
                        label={<FormattedMessage id='teacherNid' />}
                        value={nid}
                        type='number'
                      />

                      {nidError && (
                        <p>
                          <small className='text-red-700'>{nidError}</small>
                        </p>
                      )}
                      {errorNid && (
                        <p>
                          <small className='text-red-700'>{errorNid}</small>
                        </p>
                      )}
                    </div>

                    {/* class */}
                    <div className='xl:w-full'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                        <FormattedMessage id='class' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        value={gradeId}
                        onChange={handelSelectClass}
                        name='class'
                        id='institute'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectClass' })}</option>
                        {classList &&
                          classList?.map((classes, index) => {
                            return (
                              <option key={index} value={classes.grade}>
                                {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                              </option>
                            );
                          })}
                      </select>
                      {gradeError && (
                        <p>
                          <small className='text-red-700'>{gradeError}</small>
                        </p>
                      )}
                    </div>

                    {/* shift */}
                    {hasShift?.data?.has_shift === 'yes' && (
                      <div className='xl:w-full'>
                        <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                          <FormattedMessage id='shift' />
                          <span className=' text-red-700'>*</span>
                        </label>
                        <select
                          value={shiftId}
                          onChange={handelSelectShift}
                          name='shift'
                          id='institute'
                          className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        >
                          <option value='0'>{intl.formatMessage({ id: 'selectShift' })}</option>
                          {shiftArray &&
                            shiftArray?.map((shift, index) => {
                              return (
                                <option value={shift.shift_id} key={index}>
                                  {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                                </option>
                              );
                            })}
                        </select>
                        {shiftError && (
                          <p>
                            <small className='text-red-700'>shift is required!</small>
                          </p>
                        )}
                      </div>
                    )}

                    {/* section */}
                    <div className='xl:w-full'>
                      <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='institute'>
                        <FormattedMessage id='section' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        value={sectionId}
                        onChange={handelSelectSection}
                        name='section'
                        id='institute'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      >
                        <option value='0'>{intl.formatMessage({ id: 'selectSection' })}</option>
                        {sectionArray &&
                          sectionArray?.map((section, index) => {
                            return (
                              <option value={section.id} key={index}>
                                {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                              </option>
                            );
                          })}
                      </select>
                      {sectionError && (
                        <p>
                          <small className='text-red-700'>{sectionError}</small>
                        </p>
                      )}
                    </div>

                    {/* <!-- button  --> */}
                    <div className=' text-center w-full flex  space-x-5'>
                      <button
                        onClick={() => navigate('/teacher-list')}
                        type='button'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
                      hover:border-transparent hover_gradient '
                      >
                        <span className=''>
                          <FormattedMessage id='back' />
                        </span>
                      </button>

                      <button
                        type='submit'
                        className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
                      >
                        <span className=''>
                          <FormattedMessage id='submit' />
                        </span>
                      </button>
                    </div>

                    {/* <!-- submit success-fully  --> */}
                    {teacherData.message && (
                      <div className=' flex justify-center'>
                        <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                          {teacherData.message}
                        </h1>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    teacherData: state.sectionTeachers,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    addTeacher: (teacherInfo, avatar, getClassList) =>
      dispatch(createSectionTeacherAction(teacherInfo, avatar, getClassList)),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTeacher);
