import { LANGUAGE_CHANGE } from '../constance/language.constant';

export const getLanguageChange = (language) => {
  return {
    type: LANGUAGE_CHANGE,
    payload: language,
  };
};

export const languageChangeAction = (language) => {
  return (dispatch) => {
    dispatch(getLanguageChange(language));
  };
};
