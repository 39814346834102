import { LANGUAGE_CHANGE } from '../constance/language.constant';

const initialState = {
  isEng: JSON.parse(window.localStorage.getItem('isEng')),
};

export const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_CHANGE:
      return {
        ...state,
        isEng: action.payload,
      };
    default:
      return state;
  }
};
