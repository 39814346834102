import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
const BmiWeights = ({ bmiData }) => {
  const [toolTip, setToolTip] = useState(false);
  const handelToolTip = () => {
    setToolTip(!toolTip);
  };
  return (
    <React.Fragment>
      <div className=' border border-green-600 rounded shadow-md w-full lg:w-1/2 p-4 bg-[#F9F8D1]'>
        {/* info tooltip start */}
        <div className='w-full relative'>
          <div className='flex justify-end '>
            <div
              onClick={handelToolTip}
              onMouseOver={handelToolTip}
              onMouseLeave={handelToolTip}
              className='cursor-pointer w-[25px] h-[25px] border border-gray-700 rounded-full leading-[21px] text-center'
            >
              <FontAwesomeIcon icon={faInfo} />
            </div>
          </div>

          {toolTip && toolTip ? (
            <React.Fragment>
              <div className='flex justify-end absolute top-9 right-0'>
                <div className=' math_equation text-white bg-black p-4 rounded'>
                  <span className='tooltip_arraw'></span>
                  <div className='  text-right'>Weight (kg)</div>
                  <div className=' uppercase inline-flex items-center space-x-3 '>
                    <span>bmi</span>
                    <span> = </span>
                    <span className=' w-[120px] h-[1px] bg-white'></span>
                  </div>
                  <div className=' text-right'>
                    Height (Meter)<sup>2</sup>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>

        {/* info tooltip end */}
        <div className='text-center pb-4'>
          <h1 className='text-2xl font-bold'>BMI - {bmiData && bmiData?.bmi}</h1>
          <p className=' text-base text-green-500 font-bold capitalize'>{bmiData && bmiData?.bmi_status}</p>
        </div>
        {/* <div className=' flex justify-between'>
          <div className='text-center'>
            <h1 className='text-base font-bold'>{bmiData && bmiData?.present_weight_kg}</h1>
            <p className=' text-base text-green-500 font-semibold capitalize'>Present Weight</p>
          </div>
          <div className='text-center'>
            <h1 className='text-base font-bold'>{bmiData && bmiData?.ideal_weight_kg}</h1>
            <p className=' text-base text-green-500 font-semibold capitalize'>Ideal Weight</p>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    bmiData: state.bmiResult?.data,
  };
};
export default connect(mapStateToProps, null)(BmiWeights);
