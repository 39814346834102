import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
const SingleLearningModule = ({ module, defaultLanguage }) => {
  const navigate = useNavigate();
  const openInNewTab = (url, module) => {
    // console.log(module);
    navigate(url, { state: module });
  };

  const [linkUrl, setLinkUrl] = useState('');

  return (
    <React.Fragment>
      <div className='w-full  p-4 flex justify-between items-center rounded shadow-md'>
        <span className='text-base font-semibold md:text-lg md:font-bold capitalize text-black'>
          {module && defaultLanguage === 'en' ? module.title_en : module.title_bn}
        </span>
        <button
          onClick={() => openInNewTab('/learning-module/learning-details', module)}
          type='button'
          className='  py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white hover:text-white bg-gradient  hover_gradient '
        >
          <span className=''>{<FormattedMessage id='view' />}</span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default SingleLearningModule;
