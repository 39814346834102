export const GET_STUDENT_LIST_REQUEST = 'GET_STUDENT_LIST_REQUEST';
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_FAILED = 'GET_STUDENT_LIST_FAILED';

// *DELETE CONSTANTS
export const GET_STUDENT_DELETE_REQUEST = 'GET_STUDENT_DELETE_REQUEST';
export const GET_STUDENT_DELETE_SUCCESS = 'GET_STUDENT_DELETE_SUCCESS';
export const GET_STUDENT_DELETE_FAILED = 'GET_STUDENT_DELETE_FAILED';

// *EDIT CONSTANTS
export const STUDENT_EDIT_REQUEST = 'STUDENT_EDIT_REQUEST';
export const STUDENT_EDIT_SUCCESS = 'STUDENT_EDIT_SUCCESS';
export const STUDENT_EDIT_FAILED = 'STUDENT_EDIT_FAILED';

// *ADD STUDENT
export const STUDENT_ADD_REQUEST = 'STUDENT_ADD_REQUEST';
export const STUDENT_ADD_SUCCESS = 'STUDENT_ADD_SUCCESS';
export const STUDENT_ADD_FAILED = 'STUDENT_ADD_FAILED';

// STUDENT SEARCH
export const STUDENT_SEARCH_REQUEST = 'STUDENT_SEARCH_REQUEST';
export const STUDENT_SEARCH_SUCCESS = 'STUDENT_SEARCH_SUCCESS';
export const STUDENT_SEARCH_FAILED = 'STUDENT_SEARCH_FAILED';
