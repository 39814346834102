import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../../Components/InputField';
import { currentDate } from '../../../../../redux/service/service';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getErrors } from '../../../../../common/functions';

function minDate() {
  let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  // console.log([year, month, day].join('-'));

  return [year, month, day].join('-');
}

function dateRange(type) {
  const year = new Date().getFullYear();
  if (type === 'min') {
    return `${year}-01-01`;
  } else {
    return `${year}-12-31`;
  }
}

const AddEditMoadl = ({ institutionId, onCancel, handelSubmit, modalType, editData }) => {
  const intl = useIntl();

  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [CurrentDate, setCurrentDate] = useState(() => currentDate());
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    institution_id: institutionId,
    type: modalType?.name || '',
    name: '',
    entry_date: CurrentDate,
    expired_date: CurrentDate,
    entry_tablet: '',
  });

  useEffect(() => {
    if (editData) {
      // console.log(editData);
      const data = {
        id: editData?.id,
        institution_id: editData?.institution_id,
        name: editData?.name,
        entry_date: editData?.entry_date,
        expired_date: editData?.expired_date,
        entry_tablet: editData?.entry_tablet,
        type: editData?.type,
      };
      // console.log(data);
      setFormValues(data);
    }
  }, [editData]);

  const handleInputField = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = () => {
    if (!formValues?.name) {
      toast.error('Please enter stock name.');
      return;
    }

    if (formValues?.name && formValues?.name?.length > 100) {
      toast.error('Stock name can not be greater than 100 character.');
      return;
    }

    if (!formValues?.entry_date) {
      toast.error('Please enter stock receiving  date.');
      return;
    }

    if (!formValues?.expired_date) {
      toast.error('Please enter stock expired date.');
      return;
    }

    if (!formValues?.entry_tablet) {
      toast.error('Please enter a valid stock number.');
      return;
    }

    if (editData) {
      // console.log('edit modal');
      editTablet(formValues);
      return;
    }

    addTablet(formValues);
    // console.log(formValues);
  };

  const editTablet = (data) => {
    setIsBtnLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}stock/tablet/single/update`, data)
      .then((response) => {
        // console.log(response.data);
        handelSubmit(data);
        onCancel();
        setIsBtnLoading(false);
        setError(null);
        toast.success('Tablet updated successfully.');
      })
      .catch((error) => {
        setIsBtnLoading(false);
        const errorMsg = getErrors(error?.response?.data?.error);
        // console.log(errorMsg);
        setError(errorMsg);
      });
  };

  const addTablet = (data) => {
    setIsBtnLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}stock/tablet/add`, data)
      .then((response) => {
        // console.log(response.data);
        handelSubmit(data);
        onCancel();
        setIsBtnLoading(false);
        setError(null);
        toast.success('Tablet added successfully.');
      })
      .catch((error) => {
        setIsBtnLoading(false);
        const errorMsg = getErrors(error?.response?.data?.error);
        // console.log(errorMsg);
        setError(errorMsg);
      });
  };

  const getModalTitle = () => {
    if (editData) {
      // console.log(editData);
      return `${editData?.name}, ${intl.formatMessage({ id: editData?.type })} ${intl.formatMessage({
        id: 'tablet',
      })} ${intl.formatMessage({
        id: 'edit',
      })} `;
    } else {
      if (modalType?.name === 'wifa') {
        return <FormattedMessage id='addWifaTablets' />;
      } else if (modalType?.name === 'deworming') {
        return <FormattedMessage id='addDewormingTablets' />;
      } else {
        return 'Modal Title';
      }
    }
  };

  return (
    <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
      <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
        <div className='flex justify-between'>
          <h1 className=' text-black font-semibold text-xl capitalize'>{getModalTitle()}</h1>
          <span className=' cursor-pointer hover:text-green-600'>
            <FontAwesomeIcon icon={faXmark} onClick={onCancel} />
          </span>
        </div>
        {/* <!-- horizontal line  --> */}
        <div className=' my-2 border border-gray-300 '></div>
        {/* <!-- update form start  --> */}
        <div className='w-full pt-5 pb-5'>
          {/* <!-- login forms  --> */}
          <div className='flex justify-center  flex-col  space-y-5  px-4'>
            {/* <!-- input field  --> */}
            <div className=''>
              <InputField
                onChange={handleInputField}
                label={<FormattedMessage id='stockName' />}
                placeholder={intl.formatMessage({ id: 'enterStockName' })}
                value={formValues.name}
                name='name'
                type='text'
              />
              {/* {ErrorName && (
                <p>
                  <small className='text-red-700'>{ErrorName}</small>
                </p>
              )} */}
            </div>
            <div className=''>
              <InputField
                onChange={handleInputField}
                label={<FormattedMessage id='receivingDate' />}
                placeholder='10-10-2022'
                value={formValues.entry_date}
                name='entry_date'
                type='date'
                min={dateRange('min')}
                max={dateRange('max')}
              />
            </div>
            <div className=''>
              <InputField
                onChange={handleInputField}
                label={<FormattedMessage id='expiredDate' />}
                placeholder='10-10-2022'
                value={formValues.expired_date}
                name='expired_date'
                type='date'
                min={minDate()}
              />
            </div>
            <div className=''>
              <InputField
                onChange={handleInputField}
                label={<FormattedMessage id='number' />}
                placeholder='100'
                value={formValues.entry_tablet}
                name='entry_tablet'
                type='number'
              />
              {/* {ErrorTabletNumber && (
                <p>
                  <small className='text-red-700'>{ErrorTabletNumber}</small>
                </p>
              )} */}
            </div>

            {/* <!-- button  --> */}
            <div className=' text-center w-full flex  space-x-5'>
              <button
                onClick={onCancel}
                type='button'
                className='w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent hover_gradient '
              >
                <span className=''>
                  {' '}
                  <FormattedMessage id='cancel' />{' '}
                </span>
              </button>

              <button
                disabled={isBtnLoading ? 'disabled' : ''}
                onClick={handleSave}
                type='button'
                className='disabled:opacity-50 w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
              >
                <span className='flex justify-center items-center gap-2'>
                  {isBtnLoading ? (
                    <>
                      <FontAwesomeIcon icon={faCircleNotch} className='fontAwesomeLoading' />
                      <FormattedMessage id='loading' />
                    </>
                  ) : (
                    <>{editData ? <FormattedMessage id='update' /> : <FormattedMessage id='add' />}</>
                  )}
                </span>
              </button>
            </div>

            {error && (
              <div>
                <h1 className='text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-red-400'>
                  Validation Error
                </h1>
                {error.map((err, i) => (
                  <p className='text-red-500'>
                    {i + 1}. {err.value}
                  </p>
                ))}
              </div>
            )}

            {/* {tabletState && tabletState.loading && <LoadingSpinner/>} */}
            {/* <!-- submit success-fully  --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditMoadl;
