import {
  GET_STUDENT_LIST_REQUEST,
  GET_STUDENT_LIST_SUCCESS,
  GET_STUDENT_LIST_FAILED,
  GET_STUDENT_DELETE_REQUEST,
  GET_STUDENT_DELETE_SUCCESS,
  GET_STUDENT_DELETE_FAILED,
  STUDENT_EDIT_REQUEST,
  STUDENT_EDIT_SUCCESS,
  STUDENT_EDIT_FAILED,
  STUDENT_ADD_REQUEST,
  STUDENT_ADD_SUCCESS,
  STUDENT_ADD_FAILED,
  STUDENT_SEARCH_REQUEST,
  STUDENT_SEARCH_SUCCESS,
  STUDENT_SEARCH_FAILED,
} from '../constance/student.constants';

const initialStudentState = {
  loading: false,
  students: [],
  error: '',
  updateSuccess: false,
  message: '',
  status: '',
};

export const studentReducer = (state = initialStudentState, action) => {
  switch (action.type) {
    //* get student lists
    case GET_STUDENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        updateSuccess: false,
        message: '',
      };
    case GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload,
        error: '',
        updateSuccess: false,
        message: '',
      };
    case GET_STUDENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload,
        updateSuccess: false,
        message: '',
      };
    // *DELETE STUDENT
    case GET_STUDENT_DELETE_REQUEST:
      return {
        ...state,
        loading: false,
        students: action.payload,
        error: '',
        message: '',
      };
    case GET_STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: state.students?.data.filter((item) => item.id !== action.payload),
        error: '',
        message: '',
      };
    case GET_STUDENT_DELETE_FAILED:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload,
        message: '',
      };
    // * student edit
    case STUDENT_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        students: action.payload,
        error: '',
        updateSuccess: false,
        message: '',
      };
    case STUDENT_EDIT_SUCCESS:
      console.log('------------------------------------------------------------------');
      console.log(action.payload);
      console.log('------------------------------------------------------------------');
      return {
        ...state,
        loading: false,
        students: action.payload,
        error: action.payload.data.error,
        updateSuccess: true,
        message: action.payload.data.message,
      };
    case STUDENT_EDIT_FAILED:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload,
        updateSuccess: false,
        message: '',
      };

    //* student ADD
    case STUDENT_ADD_REQUEST:
      return {
        ...state,
        loading: false,
        message: '',
      };
    case STUDENT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload.data,
        error: action.payload.error,
        updateSuccess: true,
        message: action.payload.message,
      };
    case STUDENT_ADD_FAILED:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload,
        message: '',
      };

    // STUDENT SEARCH
    case STUDENT_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        message: '',
      };
    case STUDENT_SEARCH_SUCCESS:
      // console.log('actions : ',action.payload.data)
      return {
        ...state,
        loading: false,
        students: action.payload.data,
        error: '',
        message: '',
      };
    case STUDENT_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        students: [],
        error: action.payload,
        message: '',
      };
    default:
      return state;
  }
};
