import React, { useContext, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../../../Components/BreadCrumb/BreadCrumb';
import EmptyList from '../../../../Components/EmptyList';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import Pagination from '../../../../Components/Pagination/Pagination';
import StoreContext from '../../../../context/StoreContext';
import { postUpgradeStudentAction, upgradeStudentAction } from '../../../../redux/actions/upgradeClass.action';
import { generateArrayOfYears } from '../../../../redux/service/service';
import ProgramHeading from '../programs/ProgramHeading';
import StudentSearch from '../programs/StudentSearch';
import StudentRow from './StudentRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import LanguageContext from '../../../../context/LanguageContext';
import { toBnNumber } from '../../../../common/functions';

const UpgradeStudent = ({ hasShift, getUpgradeStudent, postUpgradeStudent, studentList, studentMetaData }) => {
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList, classLoading, getClassList } = useContext(StoreContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [GradId, setGradId] = useState('');
  const [newGradeId, setNewGradeId] = useState('');
  const [ShiftId, setShiftId] = useState('');
  const [newShiftId, setNewShiftId] = useState('');
  const [SectionId, setSectionId] = useState('');
  const [newSectionId, setNewSectionId] = useState('');
  const [YearId, setYearId] = useState('');
  const [newYearId, setNewYearId] = useState('');
  const [yearArray, setYearArray] = useState([]);
  const [newYearArray, setNewYearArray] = useState([]);
  const [shiftArray, setShiftArray] = useState([]);
  const [newShiftArray, setNewShiftArray] = useState([]);
  const [sectionArray, setSectionArray] = useState([]);
  const [newSectionArray, setNewSectionArray] = useState([]);
  const [pageLimit, setPageLimit] = useState(250);
  const [checkedStd, setCheckedStd] = useState([]);
  const [newStdInputValues, setNewStdInputValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentArray, setStudentArray] = useState([]);
  // console.log('🚀 ~ file: UpgradeStudent.jsx:36 ~ UpgradeStudent ~ StudentArray', StudentArray);
  const location = useLocation();

  // const { from } = location.state;
  // console.log(from); // output: "the-page-id"

  const [isAllChecked, setIsAllChecked] = useState(false);

  const [toClassArray, setToClassArray] = useState([]);

  const [tempRoll, setTempRoll] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   setToClassArray(classList);
  // }, []);

  useEffect(() => {
    const data = {
      institution_id: institutionId,
      grade: GradId,
      has_shift: hasShift?.data?.has_shift,
      section_id: SectionId,
      academic_year: YearId,
      pagination_limit: pageLimit,
    };

    if (hasShift?.data?.has_shift === 'yes') {
      data['shift_id'] = ShiftId;
      if (GradId && ShiftId && SectionId && YearId) {
        getUpgradeStudent(data);
      }
    } else {
      if (GradId && SectionId && YearId) {
        getUpgradeStudent(data);
      }
    }
  }, [GradId, ShiftId, SectionId, YearId]);

  // initialize all class,shift,section
  useEffect(() => {
    let year = generateArrayOfYears();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    // console.log(currentYear);
    const selectedYear = year.find((y) => y.value === currentYear);
    // console.log(selectedYear);

    const newYearArrayYYY = year.filter((t) => t.value > selectedYear.value);
    // console.log(newYearArrayYYY);

    // console.log(year);

    // const removeFirstTwo = year.splice(0, 2);
    // console.log(removeFirstTwo);
    // console.log(year);

    setYearId(selectedYear.value);
    setNewYearId(newYearArrayYYY[0].value);
    setYearArray(year);
    setNewYearArray([newYearArrayYYY[0]]);

    if (location?.state?.sectionInfo) {
      const data = JSON.parse(location?.state?.sectionInfo);
      // console.log(data);

      const selectedClass = data.grade;
      const selectedShift = data.shift_id;
      const selectedSection = data.section_id;

      // console.log(selectedClass, selectedShift, selectedSection);

      const newClassList = classList.filter((c) => c.grade >= selectedClass);

      // console.log(newShiftList);
      // console.log(selectedShift);
      // console.log(newSectionList);

      setToClassArray(selectedClass === 10 ? [newClassList[0]] : [newClassList[0], newClassList[1]]);

      setGradId(newClassList[0]?.grade);
      const allClass = classList;
      // console.log(classList);
      if (hasShift?.data?.has_shift === 'yes') {
        const newShiftList = newClassList[0]?.shift_section;
        const newSectionList = newShiftList?.find((s) => s.shift_id === selectedShift)?.section;

        setShiftId(selectedShift);
        setSectionId(selectedSection);
        setShiftArray(newShiftList);

        // setNewShiftArray(newShiftList);
        setSectionArray(newSectionList);
        // setNewSectionArray(allClass[0]?.shift_section[0]?.section);
      } else {
        const newSectionList = newClassList[0]?.shift_section;
        setSectionArray(newSectionList);
        setSectionId(selectedSection);
      }
    } else {
      setToClassArray([classList[0], classList[1]]);
      // console.log([classList[0], classList[1]]);
      // let year = generateArrayOfYears();
      // console.log(year);
      // setYearId(year[0].value);
      // setNewYearId(year[1].value);
      // setYearArray(year);
      // setNewYearArray(year.slice(1));
      // setNewYearArray([year[1]]);
      // console.log([year[1]]);
      const allClass = classList;
      let defaultSectionId = '';
      setGradId(allClass[0]?.grade);
      // setNewGradeId(allClass[0]?.grade);
      if (hasShift?.data?.has_shift === 'yes') {
        setShiftId(allClass[0]?.shift_section[0]?.shift_id);
        // setNewShiftId(allClass[0]?.shift_section[0]?.shift_id);
        setSectionId(allClass[0]?.shift_section[0]?.section[0]?.id);
        // setNewSectionId(allClass[0]?.shift_section[0]?.section[0]?.id);
        defaultSectionId = allClass[0]?.shift_section[0]?.section[0]?.id;
        setShiftArray(allClass[0]?.shift_section);
        // setNewShiftArray(allClass[0]?.shift_section);
        setSectionArray(allClass[0]?.shift_section[0]?.section);
        // setNewSectionArray(allClass[0]?.shift_section[0]?.section);
      } else {
        setSectionId(allClass[0]?.shift_section[0]?.id);
        // setNewSectionId(allClass[0]?.shift_section[0]?.id);
        defaultSectionId = allClass[0]?.shift_section[0]?.id;
        setSectionArray(allClass[0]?.shift_section);
        // setNewSectionArray(allClass[0]?.shift_section);
      }
    }
  }, [classList]);

  useEffect(() => {
    setStudentArray(studentList?.data?.data);
  }, [studentList]);

  // class select handler
  const handelSelectClass = (e) => {
    setGradId(e.target.value);
    setShiftId('');
    setSectionId('');
    setTempRoll('');
    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    if (hasShift?.data?.has_shift === 'yes') {
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      setSectionArray(singleClass?.shift_section);
    }

    // console.log(classList);
    // console.log(e.target.value);
    // console.log(typeof e.target.value);

    if (e.target.value !== '10') {
      const newClsArray = classList.filter((c) => c.grade >= e.target.value);
      // console.log([newClsArray[0], newClsArray[1]]);
      setToClassArray([newClsArray[0], newClsArray[1]]);
      setNewGradeId('');
    } else {
      // console.log(e.target.value);
      const newClsArray = classList[classList.length - 1];
      setToClassArray([newClsArray]);
      setNewGradeId('');
    }
  };
  // shift select handler
  const handelSelectShift = (e) => {
    setShiftId(e.target.value);
    setTempRoll('');
    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    // setShiftName(singleShift?.shift_name_en);
    setSectionArray(singleShift?.section);
    setSectionId('');
  };
  // section select handler
  const handelSelectSection = (e) => {
    setSectionId(e.target.value);
    setTempRoll('');
  };

  // filter options for upgrade classes
  const handelNewSelectClass = (e) => {
    setNewGradeId(e.target.value);
    setTempRoll('');
    setNewShiftId('');
    setNewSectionId('');
    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    if (hasShift?.data?.has_shift === 'yes') {
      setNewShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      setNewSectionArray(singleClass?.shift_section);
    }
  };
  // new shift
  const handelNewSelectShift = (e) => {
    setNewShiftId(e.target.value);
    setTempRoll('');
    const singleShift = newShiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    setNewSectionArray(singleShift?.section);
    setNewSectionId('');
  };
  // new section
  const handelNewSelectSection = (e) => {
    setNewSectionId(e.target.value);
    setTempRoll('');
  };

  /********students search*********/
  // const [searchResult, setSearchResult] = useState('');
  // const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  // const handelStudentSearch = (e) => {
  //   e.preventDefault();
  //   setSearchResult(e.target.value);
  // };
  // const handelSubmit = (e) => {
  //   e.preventDefault();
  //   const { name, value } = e.target.nameSearch;
  //   setIsSubmitSearch(true);
  // };

  const studentCheckedOrNot = (e, std) => {
    setCheckedStd((prev) => {
      if (e.target.checked) return [...prev, std];
      else {
        let values = { ...newStdInputValues };
        values[std.student_id] = '';
        setNewStdInputValues(values);
        return prev.filter((stdItem) => stdItem.student_id !== std.student_id);
      }
    });

    let data = [];

    if (e.target.checked) {
      data = [...checkedStd, std];
    } else {
      data = checkedStd.filter((stdItem) => stdItem.student_id !== std.student_id);
    }

    const sList = StudentArray?.map((list) => list?.student_id).sort((a, b) => b - a);
    const cList = data?.map((list) => list?.student_id).sort((a, b) => b - a);
    // console.log(sList);
    // console.log(cList);

    if (JSON.stringify(sList) === JSON.stringify(cList)) {
      // console.log('checked');
      setIsAllChecked(true);
    } else {
      // console.log('not checked');
      setIsAllChecked(false);
    }
  };

  useEffect(() => {
    return () => {
      getClassList();
    };
  }, []);

  // submit for upgrade class
  const onSubmit = () => {
    let students = [...checkedStd].reduce((acc, eachStd) => {
      let stdID = eachStd.student_id;
      let has = newStdInputValues.hasOwnProperty(parseInt(stdID));
      if (has)
        acc.push({
          student_id: stdID,
          gender: eachStd.gender,
          birth_date: eachStd.birth_date,
          roll: newStdInputValues[stdID],
        });
      return acc;
    }, []);

    // data for submit
    const data = {
      institution_id: institutionId,
      has_shift: hasShift?.data?.has_shift,
      shift_id: hasShift?.data?.has_shift === 'yes' ? newShiftId : undefined,
      grade: newGradeId,
      section_id: newSectionId,
      academic_year: newYearId,
      students: students,
      previous_grade: GradId,
      previous_academic_year: YearId,
    };

    // let alertData = data.students.

    // const results = data.students.filter(element => {
    //   if (Object.keys(element).length === 0) {
    //     return true;
    //   }
    //   return false;
    // });

    if (data.students.length) {
      postUpgradeStudent(data, (resData) => {
        const data2 = {
          institution_id: institutionId,
          grade: GradId,
          has_shift: hasShift?.data?.has_shift,
          section_id: SectionId,
          academic_year: YearId,
          pagination_limit: pageLimit,
        };

        if (hasShift?.data?.has_shift === 'yes') {
          data2['shift_id'] = ShiftId;
          if (GradId && ShiftId && SectionId && YearId) {
            setTimeout(() => {
              getUpgradeStudent(data2);
              setErrors([]);
              // getClassList();
            }, 5000);
          }
        } else {
          if (GradId && SectionId && YearId) {
            setTimeout(() => {
              getUpgradeStudent(data2);
              setErrors([]);
              // getClassList();
            }, 5000);
          }
        }
      });

      setNewStdInputValues({});
      setCheckedStd([]);
      setIsModalOpen(false);
      setIsAllChecked(false);
    } else {
      alert('you must full-up New Roll No / ID and Checkbox');
    }
  };

  // pagination
  let PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return StudentArray && StudentArray.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, StudentArray]);
  // console.log(
  //   '🚀 ~ file: UpgradeStudent.jsx:263 ~ currentTableData ~ currentTableData',
  //   currentTableData,
  //   StudentArray?.length
  // );

  const handleSelectAll = (e) => {
    const { checked } = e.currentTarget;
    // console.log(checked);
    if (checked) {
      setCheckedStd(StudentArray);
      setIsAllChecked(true);
    } else {
      setCheckedStd([]);
      setIsAllChecked(false);
    }
    // setCheckedStd(StudentArray);
    // console.log(StudentArray);
  };

  // useEffect(() => {
  //   const sList = StudentArray?.map((list) => list?.student_id).sort((a, b) => b - a);
  //   const cList = checkedStd?.map((list) => list?.student_id).sort((a, b) => b - a);
  //   console.log(sList);
  //   console.log(cList);

  //   if (sList === cList) {
  //     // console.log('checked');
  //     setIsAllChecked(true);
  //   } else {
  //     // console.log('not checked');
  //     setIsAllChecked(false);
  //   }
  // }, [StudentArray, checkedStd]);

  function handleSelectYear(e) {
    setYearId(e.target.value);
    setCurrentPage(1);
    setTempRoll('');
    let year = generateArrayOfYears();
    const lastYear = year[year.length - 1];

    if (e.target.value != lastYear.value) {
      const newYears = year.filter((y) => {
        return y.value > e.target.value;
      });
      // console.log([newYears[0]]);
      setNewYearId(newYears[0].value);
      setNewYearArray([newYears[0]]);
    } else {
      // console.log('last year');
      // console.log([lastYear]);
      setNewYearArray([lastYear]);
      setNewYearId(lastYear.value);
    }

    // console.log(year[year.length - 1]);
    // console.log(e.target.value);
  }

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (studentList?.error) {
      // console.log(studentList?.error);
      let errorArray = [];
      for (const key in studentList?.error) {
        const element = studentList?.error[key][0];
        errorArray.push({ id: key, name: element });
      }
      // console.log(errorArray);
      setErrors(errorArray);
    }
  }, [studentList]);

  function handleSubmitCheck() {
    if (!GradId) {
      toast.error('Please select from class.');
      return;
    }

    if (hasShift?.data?.has_shift === 'yes') {
      if (!ShiftId) {
        toast.error('Please select from shift.');
        return;
      }
    }

    if (!SectionId) {
      toast.error('Please select from section.');
      return;
    }

    if (!YearId) {
      toast.error('Please select from year.');
      return;
    }

    if (!newGradeId) {
      toast.error('Please select to class.');
      return;
    }

    if (hasShift?.data?.has_shift === 'yes') {
      if (!newShiftId) {
        toast.error('Please select to shift.');
        return;
      }
    }

    if (!newSectionId) {
      toast.error('Please select to section.');
      return;
    }

    if (!newYearId) {
      toast.error('Please select to year.');
      return;
    }

    let students = [...checkedStd].reduce((acc, eachStd) => {
      let stdID = eachStd.student_id;
      let has = newStdInputValues.hasOwnProperty(parseInt(stdID));
      if (has)
        acc.push({
          student_id: stdID,
          gender: eachStd.gender,
          birth_date: eachStd.birth_date,
          roll: newStdInputValues[stdID],
        });
      return acc;
    }, []);

    // data for submit
    const data = {
      institution_id: institutionId,
      has_shift: hasShift?.data?.has_shift,
      shift_id: hasShift?.data?.has_shift === 'yes' ? newShiftId : undefined,
      grade: newGradeId,
      section_id: newSectionId,
      academic_year: newYearId,
      students: students,
      previous_grade: GradId,
      previous_academic_year: YearId,
    };

    if (!data.students.length) {
      toast.error('You must full-up New Roll No / ID and Checkbox.');
      return;
    }

    setIsModalOpen(true);
    console.log(data);
  }

  function shiftIdToName(id, array) {
    // console.log(array);
    // console.log(id);

    if (!id) {
      return '';
    } else {
      const name = array.find((f) => f.shift_id === parseInt(id));
      // console.log(name?.shift_name_en);
      return name?.shift_name_en;
    }
  }

  function sectionIdToName(id, array) {
    // console.log(array);
    // console.log(id);

    if (!id) {
      return '';
    } else {
      const name = array.find((f) => f.id === parseInt(id));
      // console.log(name?.shift_name_en);
      return name?.section_name_en;
    }
  }

  const handleRollInput = (e) => {
    if (e.target.value.length <= 4) {
      setNewStdInputValues((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      toast.error('Roll number must not exceed four characters.');
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* search student  */}
        <ProgramHeading title={<FormattedMessage id='upgradeClass1' />}>
          {/* <StudentSearch
            onSubmit={handelSubmit}
            onChange={handelStudentSearch}
            value={searchResult}
            name='nameSearch'
          /> */}
        </ProgramHeading>
        <div className='container mx-auto flex flex-col gap-5'>
          {/* filter section one  */}
          {!classLoading ? (
            <React.Fragment>
              <div className='flex flex-wrap items-center w-full px-4  sm:w-4/5 mx-auto '>
                <div className='w-full lg:flex lg:flex-row lg:space-x-5 bg-[#399737] p-4 rounded-md'>
                  {/* class  */}
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                      <FormattedMessage id='fromClass' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={GradId}
                      onChange={handelSelectClass}
                      name='class'
                      id='class'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                      {classList &&
                        classList?.map((grade, index) => {
                          return (
                            <option key={index} value={grade.grade}>
                              {defaultLanguage === 'en' ? grade.name_en : grade.name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* shift  */}
                  {hasShift?.data?.has_shift === 'yes' && (
                    <div className='w-full'>
                      <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                        <FormattedMessage id='fromShift' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        value={ShiftId}
                        onChange={handelSelectShift}
                        name='shift'
                        id='shift'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                        {shiftArray &&
                          shiftArray?.map((shift, index) => {
                            return (
                              <option value={shift.shift_id} key={index}>
                                {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {/* section */}
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                      <FormattedMessage id='fromSection' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={SectionId}
                      onChange={handelSelectSection}
                      name='section'
                      id='section'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                      {sectionArray &&
                        sectionArray?.map((section, index) => {
                          return (
                            <option value={section.id} key={index}>
                              {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* selected year */}
                  <div className='w-full'>
                    <label className='text-white capitalize text-[12px] lg:text-[14px]' htmlFor='year'>
                      <FormattedMessage id='fromSelectedYear' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={YearId}
                      onChange={handleSelectYear}
                      name='year'
                      id='year'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                      {yearArray &&
                        yearArray?.map((year) => {
                          return (
                            <option key={year.value} value={year.value}>
                              {defaultLanguage === 'en' ? year.yearName : toBnNumber(year.yearName)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              {/* filter section two  */}
              <div className='flex flex-wrap items-center w-full px-4  sm:w-4/5 mx-auto '>
                <div className='w-full lg:flex lg:flex-row lg:space-x-5 bg-[#F3F3F3] p-4 rounded-md'>
                  {/* class  */}
                  <div className='w-full'>
                    <label className='text-gray-700 capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                      <FormattedMessage id='toClass' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      onChange={handelNewSelectClass}
                      value={newGradeId}
                      name='class'
                      id='class'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                      {toClassArray &&
                        toClassArray?.map((grade, index) => {
                          return (
                            <option key={index} value={grade.grade}>
                              {defaultLanguage === 'en' ? grade.name_en : grade.name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* shift  */}
                  {hasShift?.data?.has_shift === 'yes' && (
                    <div className='w-full'>
                      <label className='text-gray-700 capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                        <FormattedMessage id='toShift' />
                        <span className=' text-red-700'>*</span>
                      </label>
                      <select
                        onChange={handelNewSelectShift}
                        value={newShiftId}
                        name='shift'
                        id='shift'
                        className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                        aria-label='Default select example'
                      >
                        <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                        {newShiftArray &&
                          newShiftArray?.map((shift, index) => {
                            return (
                              <option value={shift.shift_id} key={index}>
                                {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  )}
                  {/* section */}
                  <div className='w-full'>
                    <label className='text-gray-700 capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                      <FormattedMessage id='toSection' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={newSectionId}
                      onChange={handelNewSelectSection}
                      name='section'
                      id='section'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                      {newSectionArray &&
                        newSectionArray?.map((section, index) => {
                          return (
                            <option value={section.id} key={index}>
                              {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {/* selected year */}
                  <div className='w-full'>
                    <label className='text-gray-700 capitalize text-[12px] lg:text-[14px]' htmlFor='year'>
                      <FormattedMessage id='toYear' />
                      <span className=' text-red-700'>*</span>
                    </label>
                    <select
                      value={newYearId}
                      onChange={(e) => {
                        setNewYearId(e.target.value);
                        setTempRoll('');
                      }}
                      name='year'
                      id='year'
                      className='form-select capitalize  block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectYear' })}</option>
                      {newYearArray &&
                        newYearArray?.map((year) => {
                          return (
                            <option key={year.value} value={year.value}>
                              {defaultLanguage === 'en' ? year.yearName : toBnNumber(year.yearName)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <LoadingSpinner />
          )}
        </div>
        {/* student lists start */}
        <div className='container mx-auto'>
          <div className=' flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
            <div className='border border-gray-300 w-full rounded overflow-x-scroll md:overflow-x-hidden'>
              <div className='flex flex-wrap flex-col md:flex-row items-center md:items-end justify-center md:justify-between border-b border-gray-300 px-4 py-2'>
                <div className='mb-3 md:mb-0'>
                  <p className='text-base text-black capitalize'>
                    {<FormattedMessage id='totalStudent' />} : <span>{studentMetaData && studentMetaData.total}</span>{' '}
                  </p>
                </div>
              </div>
              {studentList && studentList.loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  {currentTableData && currentTableData?.length ? (
                    <table className='table-fixed w-full border-collapse border border-gray-300 min-w-[500px]'>
                      <thead className=' text-left bg-[#BFE3B5]'>
                        <tr>
                          <th className=' border border-gray-300 capitalize p-2'>
                            <FormattedMessage id='rollNiId' />
                          </th>
                          <th className=' border border-gray-300 capitalize p-2'>
                            {<FormattedMessage id='studentName' />}
                          </th>
                          <th className=' border border-gray-300 capitalize p-2'>{<FormattedMessage id='gender' />}</th>
                          <th className=' border border-gray-300 capitalize p-2'>
                            <FormattedMessage id='rollNiIdNew' />
                          </th>
                          <th className=' border border-gray-300 capitalize p-2 flex items-center'>
                            <span className='mr-2'>
                              <FormattedMessage id='selectAll' />
                            </span>
                            <label htmlFor='student' className='gradient-checkbox-container'>
                              <input
                                type='checkbox'
                                id='student'
                                name='student'
                                onChange={handleSelectAll}
                                checked={isAllChecked ? 'checked' : ''}
                                onClick={() => setTempRoll('')}
                              />
                              <span className='checkmark' />
                            </label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTableData?.map((student, index) => {
                          return (
                            <tr key={student.student_id + uuidv4()} className='hover:bg-green-50'>
                              <td className=' border border-gray-300 p-2'>{student.roll}</td>
                              <td className=' border border-gray-300 p-2 capitalize'>{student.name}</td>
                              <td className=' border border-gray-300 p-2'>{student.gender}</td>
                              <td className=' border border-gray-300 p-2'>
                                <input
                                  className='std_roll_input px-2 outline-none rounded-[3px] border border-[#bdbdbd] w-full'
                                  type='number'
                                  name={student.student_id}
                                  autoFocus={student.student_id === tempRoll}
                                  placeholder='Enter New Roll'
                                  value={newStdInputValues[student.student_id]}
                                  onChange={handleRollInput}
                                  onClick={() => setTempRoll(student.student_id)}
                                />
                              </td>
                              <td className=' border border-gray-300 p-2 '>
                                <label className='gradient-checkbox-container'>
                                  <input
                                    type='checkbox'
                                    name='student'
                                    // defaultChecked={false}
                                    onChange={(e) => studentCheckedOrNot(e, student)}
                                    checked={
                                      checkedStd.filter((l) => l.student_id === student.student_id).length > 0
                                        ? 'checked'
                                        : ''
                                    }
                                    onClick={() => setTempRoll('')}
                                  />
                                  <span className='checkmark' />
                                </label>
                                {/* {checkedStd.filter((l) => l.student_id === student.student_id).length > 0
                                  ? 'Checked'
                                  : 'Not Checked'} */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <EmptyList message='Student List is empty' />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          {/* pagination section start */}

          <div className='my-5'>
            <div className='w-full px-4 sm:w-4/5 mx-auto'>
              <Pagination
                className='pagination-bar'
                currentPage={currentPage}
                totalCount={StudentArray?.length || 0}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>

          {/* pagination section end */}
        </div>
        {/* student lists end */}
        <div className='flex justify-center mt-4'>
          <button
            className={`w-1/3 py-1 sm:py-2 px-3 sm:px-6 capitalize text-[13px] md:text-[14px] rounded hover_gradient bg-gradient hover:text-white text-white `}
            type='button'
            // onClick={onSubmit}
            onClick={handleSubmitCheck}
          >
            {<FormattedMessage id='submit' />}
          </button>
        </div>

        {studentList && studentList?.message && (
          <div className='flex justify-center mt-4'>
            <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
              {studentList?.message !== 'Validation Error' && studentList?.message}
            </h1>
          </div>
        )}

        {studentList && studentList?.error?.roll && (
          <div className='flex justify-center mt-4'>
            <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-rose-500'>
              {studentList?.error.roll}
            </h1>
          </div>
        )}

        {errors?.map((error) => (
          <div className='flex justify-center mt-1' key={error?.id}>
            <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-rose-500'>
              {error?.name}
            </h1>
          </div>
        ))}
      </section>

      {isModalOpen && (
        <ConfirmModal
          setIsModalOpen={setIsModalOpen}
          GradId={GradId}
          SectionId={sectionIdToName(SectionId, sectionArray)}
          ShiftId={shiftIdToName(ShiftId, shiftArray)}
          YearId={YearId}
          newGradeId={newGradeId}
          newShiftId={shiftIdToName(newShiftId, newShiftArray)}
          newSectionId={sectionIdToName(newSectionId, newSectionArray)}
          newYearId={newYearId}
          onSubmit={onSubmit}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentList: state.upgrade,
    studentMetaData: state.upgrade?.data?.meta,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getClassLists: () => dispatch(getUpgradeClassListsAction()),
    // getSubjectList: () => dispatch(getSubjectListAction()),
    getUpgradeStudent: (dataInfo) => dispatch(upgradeStudentAction(dataInfo)),
    postUpgradeStudent: (dataInfo, callback) => dispatch(postUpgradeStudentAction(dataInfo, callback)),
    // getNutritionEducationStudentList: (studentInfo) => dispatch(getProgramStudentListAction(studentInfo)),
    // getWeekNumber: (dateInfo) => dispatch(getWeekNumberAction(dateInfo)),
    // postStudentForProgram: (dateInfo) => dispatch(postProgramStudentListAction(dateInfo)),
    // postProgramStudentListRequest: () => dispatch(postProgramStudentListRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeStudent);

const ConfirmModal = ({
  setIsModalOpen,
  GradId,
  SectionId,
  ShiftId,
  YearId,
  newGradeId,
  newShiftId,
  newSectionId,
  newYearId,
  onSubmit,
}) => (
  <div className=' p-4 fixed top-0 right-0 bottom-0 left-0 w-full h-full grid place-items-center bg-black bg-opacity-75 z-50'>
    <div className=' p-4 w-full lg:w-1/2 xl:w-2/4 2xl:w-1/3 bg-white rounded shadow-lg'>
      <div className='flex justify-between'>
        <h1 className=' text-black font-semibold text-xl capitalize'>
          <FormattedMessage id='confirm' />
        </h1>
        <span className=' cursor-pointer hover:text-green-600'>
          <FontAwesomeIcon icon={faXmark} onClick={() => setIsModalOpen(false)} />
        </span>
      </div>
      {/* <!-- horizontal line  --> */}
      <div className=' my-2 border border-gray-300 '></div>
      {/* <!-- update form start  --> */}
      <div className='w-full pt-5 pb-5'>
        {/* <!-- login forms  --> */}
        <div className='flex justify-center  flex-col space-y-5   px-4'>
          {/* <!-- delete message  --> */}
          <div className='text-center py-10'>
            <h1 className='text-black font-semibold text-base md:text-xl'>
              Are ou sure you wan to upgrade student from
              <span className=' text-green-700 uppercase'>
                {` ${GradId}(${SectionId})`} {ShiftId} {YearId}
              </span>{' '}
              to
              <span className=' text-red-700 uppercase'>
                {` ${newGradeId}(${newSectionId})`} {newShiftId} {newYearId}{' '}
              </span>
            </h1>
          </div>

          {/* <!-- button  --> */}
          <div className=' text-center w-full flex  space-x-5'>
            <button
              onClick={() => setIsModalOpen(false)}
              type='button'
              className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-gray-600 border border-green-600 hover:text-white
							hover:border-transparent  hover_gradient '
            >
              <span className=''>
                <FormattedMessage id='cancel' />
              </span>
            </button>

            <button
              onClick={onSubmit}
              type='button'
              className=' w-1/2 py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded text-white bg-gradient hover_gradient '
            >
              <span className=''>
                <FormattedMessage id='yes' />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
