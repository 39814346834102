import {
  GET_REFERRAL_REQUEST,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAILED,
  POST_REFERRAL_REQUEST,
  POST_REFERRAL_SUCCESS,
  POST_REFERRAL_FAILED,
  REFERRAL_PDF_DATA_REQUEST,
  REFERRAL_PDF_DATA_SUCCESS,
  REFERRAL_PDF_DATA_FAILED,
} from '../constance/programs.constants';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

export const referralStudentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRAL_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: '',
      };
    case GET_REFERRAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
      };
    case GET_REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    // referral post
    case POST_REFERRAL_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: '',
      };
    case POST_REFERRAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
      };
    case POST_REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

const initialReferralState = {
  loading: false,
  data: [],
  error: '',
};

export const getReferralPdfReducer = (state = initialReferralState, action) => {
  switch (action.type) {
    case REFERRAL_PDF_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case REFERRAL_PDF_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case REFERRAL_PDF_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
