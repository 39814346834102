import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import BreadCrumb from '../../../../Components/BreadCrumb/BreadCrumb';
import InputField from '../../../../Components/InputField';
import LanguageContext from '../../../../context/LanguageContext';
import StoreContext from '../../../../context/StoreContext';
import {
  getClassWiseShiftListAction,
  getRegularProgramStudentListAction,
  getReferralReportAction,
  getSectionListFromShiftAction,
  getUpgradeClassListsAction,
} from '../../../../redux';
import { getReferralReportRequest } from '../../../../redux/actions/upgradeClass.action';
import { currentDate } from '../../../../redux/service/service';
import Heading from './Heading';
import ReferralResultResult from './ReferralResultResult';
import { toBnNumber } from '../../../../common/functions';

const ReferralReport = ({
  getClassLists,
  classLists,

  getShiftLists,
  shiftList,

  getSectionLists,
  sectionList,

  getStudentList,
  studentList,
  studentMetaData,

  getReport,
  report,

  hasShift,
  getReferralReportRequest,
}) => {
  const intl = useIntl();
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList } = useContext(StoreContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const [InitialClassId, setInitialClassId] = useState('');
  const [gradeId, setGrade] = useState('');
  const [shiftId, setShift] = useState('');
  const [sectionId, setSection] = useState('');
  const [FromDate, setFromDate] = useState(() => currentDate());
  const [ToDate, setToDate] = useState(() => currentDate());
  const [hasShiftStatus, setHasShiftStatus] = useState('');

  const [classArray, setClassArray] = useState([]);
  const [GradeName, setGradeName] = useState('');
  const [shiftArray, setShiftArray] = useState([]);
  const [shiftName, setShiftName] = useState('');
  const [sectionArray, setSectionArray] = useState([]);
  const [sectionName, setSectionName] = useState();

  const [IsButtonDisabled, setIsButtonDisabled] = useState(true);
  const [PostData, setPostData] = useState(null);

  useEffect(() => {
    getReferralReportRequest();
  }, [getReferralReportRequest]);

  useEffect(() => {
    if (gradeId && sectionId && FromDate && ToDate) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [FromDate, gradeId, sectionId, ToDate]);

  // shift status - stay
  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      setHasShiftStatus('yes');
    } else {
      setHasShiftStatus('no');
    }
  }, [hasShift]);

  const handelSelectClass = (e) => {
    setGrade(e.target.value);
    setInitialClassId(e.target.value);
    setShift('');
    setSection('');

    const singleClass = classList?.find((classes) => classes?.grade === Number(e.target.value));
    setGradeName(singleClass?.name_en);

    if (hasShift?.data?.has_shift === 'yes') {
      //   getShiftLists(data);
      setShiftArray(singleClass?.shift_section);
    } else if (hasShift?.data?.has_shift === 'no') {
      //   getSectionLists(data);
      setSectionArray(singleClass?.shift_section);
    }
  };

  // call section based on shift selection

  const handelSelectShift = (e) => {
    setShift(e.target.value);
    // console.log(e.target.value);

    const singleShift = shiftArray?.find((shifts) => shifts.shift_id === Number(e.target.value));
    setShiftName(singleShift?.shift_name_en);
    setSectionArray(singleShift?.section);
    setSection('');
  };

  // section select
  const handelSelectSection = (e) => {
    setSection(e.target.value);
    const singleSection = sectionArray?.find((section) => section.id === Number(e.target.value));
    setSectionName(singleSection?.section_name_en);
  };

  useEffect(() => {
    if (hasShift?.data?.has_shift === 'yes') {
      if (gradeId && shiftId && sectionId) {
        const data = {
          institution_id: institutionId,
          grade: gradeId,
          shift_id: shiftId,
          section_id: sectionId,
        };

        getStudentList(data);
      }
    } else if (hasShift?.data?.has_shift === 'no') {
      if (gradeId && sectionId) {
        const data = {
          institution_id: institutionId,
          grade: gradeId,
          section_id: sectionId,
        };

        getStudentList(data);
      }
    }
  }, [gradeId, shiftId, sectionId]);

  const [ErrorInstitute, setErrorInstitute] = useState(null);
  useEffect(() => {
    setErrorInstitute(report?.error?.institution_id);
  }, [report?.error?.institution_id]);
  const [ErrorGrade, setErrorGrade] = useState(null);
  useEffect(() => {
    setErrorGrade(report?.error?.grade);
  }, [report?.error?.grade]);
  const [ErrorSection, setErrorSection] = useState(null);
  useEffect(() => {
    setErrorSection(report?.error?.section_id);
  }, [report?.error?.section_id]);
  const [ErrorFromDate, setErrorFromDate] = useState(null);
  useEffect(() => {
    setErrorFromDate(report?.error?.from_date);
  }, [report?.error?.from_date]);
  const [ErrorToDate, setErrorToDate] = useState(null);
  useEffect(() => {
    setErrorToDate(report?.error?.to_date);
  }, [report?.error?.to_date]);
  const [ErrorShift, setErrorShift] = useState(null);
  useEffect(() => {
    setErrorShift(report?.error?.shift_id);
  }, [report?.error?.shift_id]);

  const handleSubmit = () => {
    const data = {
      institution_id: institutionId,
      grade: gradeId,
      has_shift: hasShiftStatus,
      shift_id: shiftId,
      section_id: sectionId,
      from_date: FromDate,
      to_date: ToDate,
    };
    const title = {
      FromDate: FromDate,
      ToDate: ToDate,
      GradeName: GradeName,
      shiftName: shiftName,
      sectionName: sectionName,
    };
    getReport(data);
    setPostData(title);
  };

  return (
    <>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        <Heading title={<FormattedMessage id='referral-report' />} />
        {/* report form input  */}
        <div className='container mx-auto'>
          <div className='flex flex-wrap flex-col  w-full px-4 sm:w-4/5 mx-auto'>
            <div className='border border-gray-300 w-full rounded p-10'>
              <div className='grid grid-cols-2 gap-4 pb-8 items-center'>
                {/* <!-- class  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='class'>
                    {' '}
                    <FormattedMessage id='class' />
                  </label>
                  <select
                    value={gradeId && gradeId}
                    onChange={handelSelectClass}
                    id='class'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectClass' })}</option>
                    {classList &&
                      classList.map((classes, index) => {
                        return (
                          <option key={index} value={classes.grade}>
                            {defaultLanguage === 'en' ? classes.name_en : classes.name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorGrade && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorGrade}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- shift  --> */}
                {hasShift?.data?.has_shift === 'yes' && (
                  <div className=' xl:w-full flex flex-col'>
                    <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='shift'>
                      {' '}
                      <FormattedMessage id='shift' />
                    </label>
                    <select
                      value={shiftId && shiftId}
                      onChange={handelSelectShift}
                      id='shift'
                      className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                      aria-label='Default select example'
                    >
                      <option value=''>{intl.formatMessage({ id: 'selectShift' })}</option>
                      {shiftArray &&
                        shiftArray?.map((shift, index) => {
                          return (
                            <option value={shift.shift_id} key={index}>
                              {defaultLanguage === 'en' ? shift.shift_name_en : shift.shift_name_bn}
                            </option>
                          );
                        })}
                    </select>
                    {ErrorShift && (
                      <p>
                        {' '}
                        <small className='text-red-700'>{ErrorShift}</small>{' '}
                      </p>
                    )}
                  </div>
                )}
                {/* <!-- section  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='section'>
                    <FormattedMessage id='section' />
                  </label>
                  <select
                    value={sectionId && sectionId}
                    onChange={handelSelectSection}
                    id='section'
                    className='form-select block w-auto px-3 py-1.5 text-base capitalize font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition	ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none'
                    aria-label='Default select example'
                  >
                    <option value=''>{intl.formatMessage({ id: 'selectSection' })}</option>
                    {sectionArray &&
                      sectionArray?.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {defaultLanguage === 'en' ? section.section_name_en : section.section_name_bn}
                          </option>
                        );
                      })}
                  </select>
                  {ErrorSection && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorSection}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!--total student  --> */}
                <div className=' xl:w-full flex flex-col'>
                  <label className='text-[#212529] capitalize text-[12px] lg:text-[14px]' htmlFor='total-student'>
                    <FormattedMessage id='totalStudent' />
                  </label>
                  {hasShift?.data?.has_shift === 'yes' ? (
                    <input
                      className='form-select capitalize block w-full min-w-[75px] px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-200 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                                        focus:text-gray-700 focus:bg-gray-200 focus:border-green-600 focus:outline-none'
                      type='text'
                      name=''
                      id='total-student'
                      placeholder='00'
                      value={
                        gradeId && shiftId && sectionId && studentMetaData
                          ? defaultLanguage === 'en'
                            ? studentMetaData.total
                            : toBnNumber(studentMetaData.total)
                          : defaultLanguage === 'en'
                          ? '0'
                          : toBnNumber(0)
                      }
                      readOnly
                    />
                  ) : (
                    <input
                      className='form-select capitalize block w-full min-w-[75px] px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-200 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0
                                        focus:text-gray-700 focus:bg-gray-200 focus:border-green-600 focus:outline-none'
                      type='text'
                      name=''
                      id='total-student'
                      placeholder='00'
                      value={
                        gradeId && sectionId && studentMetaData
                          ? defaultLanguage === 'en'
                            ? studentMetaData.total
                            : toBnNumber(studentMetaData.total)
                          : defaultLanguage === 'en'
                          ? '0'
                          : toBnNumber(0)
                      }
                      readOnly
                    />
                  )}
                </div>
                {/* <!-- From Date   --> */}
                <div className='xl:w-full flex flex-col'>
                  <InputField
                    label={<FormattedMessage id='fromDate' />}
                    type='Date'
                    value={FromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  {ErrorFromDate && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorFromDate}</small>{' '}
                    </p>
                  )}
                </div>
                {/* <!-- to Date   --> */}
                <div className='xl:w-full flex flex-col'>
                  <InputField
                    label={<FormattedMessage id='toDate' />}
                    type='Date'
                    value={ToDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                  {ErrorToDate && (
                    <p>
                      {' '}
                      <small className='text-red-700'>{ErrorToDate}</small>{' '}
                    </p>
                  )}
                </div>

                <div className='col-span-2  text-center mt-6'>
                  <button
                    disabled={IsButtonDisabled}
                    title={`${
                      IsButtonDisabled
                        ? 'Select all the field to enable this Button'
                        : 'Select all the field to enable button'
                    }`}
                    onClick={handleSubmit}
                    className={`w-full md:w-1/2  py-2 px-6 capitalize text-[14px] lg:text-[18px] rounded ${
                      IsButtonDisabled
                        ? ' bg-gray-300 text-black '
                        : ' hover_gradient bg-gradient hover:text-white text-white'
                    }`}
                    type='button'
                  >
                    <span>
                      <FormattedMessage id='viewReport' />
                    </span>
                  </button>
                </div>
              </div>
              {report && report.message && (
                <div className=' flex flex-col text-center justify-center'>
                  <h1 className=' text-sm lg:text-2xl xl:text-3xl font-bold lg:font-medium xl:font-semibold text-green-500'>
                    {report.message}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* report result start */}
        {/* nutrition education program report  */}
        {report && report.loading ? (
          <span></span>
        ) : (
          <React.Fragment>
            {report && report?.data?.length !== 0 && (
              <React.Fragment>
                <ReferralResultResult
                  from={PostData?.FromDate}
                  to={PostData?.ToDate}
                  sectionName={PostData?.sectionName}
                  shiftName={PostData?.shiftName}
                  GradeName={PostData?.GradeName}
                  report={report}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {/* <React.Fragment>
          {report && report.loading ? (
            <span></span>
          ) : (
            <React.Fragment>
              {report.data.length >= 1 ? (
                <React.Fragment>
                  <ReferralResultResult
                    from={FromDate}
                    to={ToDate}
                    sectionName={sectionName}
                    shiftName={shiftName}
                    GradeName={GradeName}
                    report={report}
                  />
                </React.Fragment>
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment> */}

        {/* report result end */}
      </section>
      {/* </Layout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    classLists: state.upgradedClassState?.data,
    shiftList: state.shiftData,
    sectionList: state.sectionData,
    studentList: state.ProgramStudentList,
    studentMetaData: state.ProgramStudentList?.data?.meta,
    // check is shift yes or not
    hasShift: state.login?.currentUser,
    report: state.report,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassLists: () => dispatch(getUpgradeClassListsAction()),
    getShiftLists: (dataInfo) => dispatch(getClassWiseShiftListAction(dataInfo)),
    getSectionLists: (dataInfo) => dispatch(getSectionListFromShiftAction(dataInfo)),
    getStudentList: (studentInfo) => dispatch(getRegularProgramStudentListAction(studentInfo)),
    getReport: (dateInfo) => dispatch(getReferralReportAction(dateInfo)),
    getReferralReportRequest: () => dispatch(getReferralReportRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReferralReport);
