import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BreadCrumb from '../../../Components/BreadCrumb/BreadCrumb';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import LanguageContext from '../../../context/LanguageContext';
import StoreContext from '../../../context/StoreContext';
import { getClassWiseShiftListAction } from '../../../redux';
import SingleShift from './SingleShift';

const ShiftsPage = ({ getShiftLists, shiftData }) => {
  const { defaultLanguage } = useContext(LanguageContext);
  const { classList, classLoading } = useContext(StoreContext);
  const [institutionId] = useState(() => window.localStorage.getItem('institution'));
  const navigationData = useLocation();

  const [shiftLists, setShiftLists] = useState([]);

  useEffect(() => {
    const newData = JSON.parse(window.localStorage.getItem('sectionInfo'));
    const grade = navigationData?.state === null ? newData?.grade : navigationData?.state?.grade;
    let data = {
      institutionId: institutionId,
      grade: grade,
    };
    // getShiftLists(data);
    const allShifts = classList?.find((c) => c.grade === Number(grade));
    // console.log(allShifts?.shift_section);
    setShiftLists(allShifts?.shift_section);
    // console.log(data);
    // console.log(newData?.grade);
    // console.log(navigationData?.state?.grade);
  }, [classList]);

  return (
    <React.Fragment>
      {/* <Layout> */}
      <BreadCrumb />
      <section className='mx-auto bg-white py-4 lg:py-10 space-y-4'>
        {/* <!-- all classes --> */}
        <div className='container mx-auto'>
          {/* <!-- class title  --> */}
          <div className='flex justify-center w-full px-4 sm:w-4/5 mx-auto pb-4 lg:pb-10'>
            <h1 className=' capitalize text-2xl text-black font-semibold md:font-bold'>
              {' '}
              {defaultLanguage === 'en' ? navigationData?.state?.name_en : navigationData?.state?.name_bn}{' '}
              <FormattedMessage id='shift-list' />
            </h1>
          </div>
          {classLoading ? (
            <LoadingSpinner />
          ) : (
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 lg:gap-6  w-full px-4 sm:w-4/5 mx-auto '>
              {shiftLists &&
                shiftLists?.map((data, index) => {
                  return <SingleShift key={index} data={data} bgColor='#D1ECFF' bdColor='#68C1FF' />;
                })}
            </div>
          )}
        </div>
      </section>
      {/* </Layout> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    shiftData: state.shiftData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getShiftLists: (classInfo) => dispatch(getClassWiseShiftListAction(classInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsPage);
