export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_PASSWORD_INCORRECT = 'USER_PASSWORD_INCORRECT';

//! get logged in user
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_FAILED = 'GET_LOGGED_IN_USER_FAILED';
export const GET_LOGGED_OUT_USER = 'GET_LOGGED_OUT_USER';
// logout
export const USER_LOGGED_OUT_REQUEST = 'USER_LOGGED_OUT_REQUEST';
export const USER_LOGGED_OUT_SUCCESS = 'USER_LOGGED_OUT_SUCCESS';
export const USER_LOGGED_OUT_FAILED = 'USER_LOGGED_OUT_FAILED';
