import {
  GET_MONTHLY_REPORT_REQUEST,
  GET_MONTHLY_REPORT_SUCCESS,
  GET_MONTHLY_REPORT_FAILED,
  GET_WEEKLY_REPORT_REQUEST,
  GET_WEEKLY_REPORT_SUCCESS,
  GET_WEEKLY_REPORT_FAILED,
  GET_ANNUAL_REPORT_REQUEST,
  GET_ANNUAL_REPORT_SUCCESS,
  GET_ANNUAL_REPORT_FAILED,
  GET_REFERRAL_REPORT_REQUEST,
  GET_REFERRAL_REPORT_SUCCESS,
  GET_REFERRAL_REPORT_FAILED,
} from '../constance/report.constant';
import { GET_PROGRAMS_REQUEST, GET_PROGRAMS_SUCCESS, GET_PROGRAMS_FAILED } from '../constance/programs.constants';

const initialPrograms = {
  loading: false,
  programs: [],
  error: '',
};

export const getAllProgramsReducer = (state = initialPrograms, action) => {
  switch (action.type) {
    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: action.payload,
        error: '',
      };
    case GET_PROGRAMS_FAILED:
      return {
        ...state,
        loading: true,
        programs: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialMonthlyState = {
  loading: false,
  data: [],
  error: '',
};

export const monthlyReportReducer = (state = initialMonthlyState, action) => {
  switch (action.type) {
    // *monthly
    case GET_MONTHLY_REPORT_REQUEST:
      return {
        loading: true,
        data: [],
        error: '',
      };
    case GET_MONTHLY_REPORT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case GET_MONTHLY_REPORT_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    // *weeklu
    case GET_WEEKLY_REPORT_REQUEST:
      return {
        loading: true,
        data: [],
        error: '',
      };
    case GET_WEEKLY_REPORT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case GET_WEEKLY_REPORT_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialWeeklyState = {
  loading: false,
  data: [],
  error: '',
};

export const weeklyReportReducer = (state = initialWeeklyState, action) => {
  switch (action.type) {
    // *weeklY
    case GET_WEEKLY_REPORT_REQUEST:
      return {
        loading: true,
        data: [],
        error: '',
      };
    case GET_WEEKLY_REPORT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case GET_WEEKLY_REPORT_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialAnnualState = {
  loading: false,
  data: [],
  error: '',
};

export const annualReportReducer = (state = initialAnnualState, action) => {
  switch (action.type) {
    // *annually
    case GET_ANNUAL_REPORT_REQUEST:
      return {
        loading: true,
        data: [],
        error: '',
      };
    case GET_ANNUAL_REPORT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case GET_ANNUAL_REPORT_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialReferralState = {
  loading: false,
  data: [],
  error: '',
};

export const referralReportReducer = (state = initialReferralState, action) => {
  switch (action.type) {
    // *referral
    case GET_REFERRAL_REPORT_REQUEST:
      return {
        loading: true,
        data: [],
        error: '',
      };
    case GET_REFERRAL_REPORT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case GET_REFERRAL_REPORT_FAILED:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
