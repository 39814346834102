import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
const ProfileBreadCrumb = ({ title = 'title', image }) => {
  const location = useLocation();
  const params = useParams();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter((x) => x);
  return (
    <React.Fragment>
      <section className='mx-auto bg-green-50 py-4 lg:py-10'>
        <div className='container mx-auto'>
          <div className='w-full sm:w-4/5 mx-auto px-4'>
            <div className='flex items-center justify-between'>
              <div className=''>
                <h1 className='text-lg text-black font-semibold capitalize md:text-2xl lg:text-3xl mb-2'>{title}</h1>
                {/* <!-- breadcrumb nav  --> */}
                <nav className='breadcrumb flex justify-start' aria-label='Breadcrumb'>
                  <ol className='inline-flex flex-wrap items-center space-x-1 md:space-x-3'>
                    {/* <!-- items  --> */}
                    <li className='inline-flex items-center'>
                      <Link
                        key='masud'
                        to='/'
                        className='inline-flex items-center text-sm font-medium text-green-500 hover:text-[#22262A]  capitalize'
                      >
                        <FormattedMessage id='nav-home' />
                      </Link>
                    </li>

                    {pathnames.map((name, index) => {
                      const routeTo = `/${pathnames?.slice(0, index + 1)?.join('/')}`;
                      const isLast = index === pathnames?.length - 1;
                      return isLast ? (
                        <li className='capitalize flex' key={name + index} style={{ textTransform: 'capitalize' }}>
                          {/* {<FormattedMessage id={name} />} */}
                          {(name.includes('section') && name.includes('student-list')) ||
                          (name.includes('section') && name.includes('add-teacher')) ? (
                            <>
                              {name.split('-').map((_, i, arr) => (
                                <div key={i}>
                                  {<FormattedMessage id={_} />}
                                  {arr.length !== i + 1 && '-'}
                                </div>
                              ))}
                            </>
                          ) : (
                            <FormattedMessage id={name} />
                          )}
                        </li>
                      ) : (
                        <li className='flex'>
                          <Link
                            key={name + index}
                            to={routeTo}
                            className='flex ml-1 text-[16px] font-medium text-green-500 hover:text-[#22262A] md:ml-2 capitalize'
                          >
                            {/* {<FormattedMessage id={name} />} */}
                            {(name.includes('section') && name.includes('student-list')) ||
                            (name.includes('section') && name.includes('add-teacher')) ? (
                              <>
                                {name.split('-').map((_, i, arr) => (
                                  <div key={i}>
                                    {<FormattedMessage id={_} />}
                                    {arr.length !== i + 1 && '-'}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <FormattedMessage id={name} />
                            )}
                          </Link>
                        </li>
                      );
                    })}
                  </ol>
                </nav>
              </div>
              {/* <!-- breadcrumb image  --> */}
              <div className='w-1/2 flex justify-end'>
                <span></span>
                <img src={image} alt='breadcrumb' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default ProfileBreadCrumb;
